import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPrintModule } from 'ngx-print';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { SwitchThemeState } from 'src/app/shared';

@Component({
    selector: 'app-merchant-bill',
    standalone: true,
    imports: [CommonModule, NgxPrintModule],
    templateUrl: './merchant-bill.component.html',
    styleUrls: ['./merchant-bill.component.scss'],
})
export class MerchantBillComponent {
    @Input() successMessage!: {
        name: string;
        debit_account: string;
        date: string;
        printable_text: string;
        merchantName: string;
        amount: string;
        code: string;
        product?: {
            name: string;
            value: string;
        };
        description: string;
        adress: string;
        credit_account: string;
    };

    cardContent!: HTMLElement;

    theme$: Observable<any>;
    theme: any;

    constructor(private store: Store) {
        this.theme$ = this.store.select(SwitchThemeState.GetTheme);
    }

    ngOnInit() {
        this.theme$.subscribe({
            next: (theme) => {
                this.theme = theme;
            },
        });
    }

    ngAfterViewInit() {
        this.cardContent = document.getElementById(
            'printable_text'
        ) as HTMLElement;

        if (this.successMessage.printable_text) {
            this.cardContent.innerHTML = this.successMessage.printable_text;
        }
    }
}
