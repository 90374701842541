<!-- <script src="https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/leaflet.js"></script> -->
<!-- <script
    async
    src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCPIdvQpL7SFKXEwG-tUah9dZBiO7d05PY"
></script> -->

<div class="map">
    <google-map
        height="100%"
        width="100%"
        [center]="mapCenter"
        [zoom]="mapZoom"
    >
        <map-marker
            *ngFor="let coord of coords"
            [position]="{
                lat: coord?.geometry?.coordinates[0],
                lng: coord?.geometry?.coordinates[0]
            }"
            [options]="markerOptions"
            (mapClick)="markerClick($event, coord)"
        ></map-marker>
    </google-map>
</div>
