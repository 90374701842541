import { Injectable } from '@angular/core';

import { GetClientList, GetWalletsList } from './client.actions';
import { ClientModel } from './client.models';

import {
    State,
    StateToken,
    Selector,
    Action,
    StateContext,
    Store,
} from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { ClientService } from '../../../core';

const CLIENT_MODEL = new StateToken<ClientModel[]>('client');

@State<ClientModel>({
    name: CLIENT_MODEL,
    defaults: {
        clients: [],
    },
})
@Injectable()
export class ClientState {
    constructor(private store: Store, private clientService: ClientService) {}

    @Action(GetClientList)
    getClientList(ctx: StateContext<ClientModel>, action: GetClientList) {
        const pagination = {
            filters: { offset: action.payload.offset, limit: 20 },
        };

        return this.clientService.getClientList(pagination).pipe(
            tap(
                (result: {
                    objects: {
                        clients: [];
                    };
                }) => {
                    console.log('Client', result.objects);
                    ctx.patchState({
                        // operator: {
                        //     corporates: result.objects,
                        // },
                        clients: result.objects,
                    });
                }
            )
        );
    }
}
