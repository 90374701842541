import { Injectable, inject } from '@angular/core';

import { map, Observable } from 'rxjs';
import { Store } from '@ngxs/store';

import { ApiService } from './api.service';
import { GeneralService } from './general.service';
import { MenuState } from '../../shared';

@Injectable({
    providedIn: 'root',
})
export class AccessService {
    subMenus$: Observable<any>;
    private generalService = inject(GeneralService);

    constructor(private apiService: ApiService, private store: Store) {
        this.subMenus$ = this.store.select(MenuState.GetSubMenus);
    }

    getSubMenu(menu_id: any) {
        return this.apiService
            .get('/hr/access/acl/roles/?menu_role__menu=' + menu_id)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    getAccess() {
        const pathname = window.location.pathname;
        const empty: any = [];

        this.subMenus$.subscribe({
            next: (subMenus) => {
                let pathname = window.location.pathname;

                // just for removing language prefixes in case i18n is activated

                if (['en', 'fr'].includes(pathname.split('/')[1])) {
                    pathname = pathname.slice(3);
                }

                // ******************************************************** //
                if (subMenus && subMenus.length > 0) {
                    const subMenuSimularToPathname =
                        this.generalService.findMostSimilar(
                            subMenus.map(
                                (submenus: any) => submenus.component_url
                            ),
                            pathname
                        );
                    const subMenuId = subMenus.find(
                        (subMenu: any) =>
                            subMenu.component_url === subMenuSimularToPathname
                    ).id;
                    if (subMenuId) {
                        this.getSubMenu(subMenuId).subscribe({
                            next: (subMenus: any) => {
                                console.log('ttyyuuuuuu', subMenus);
                                return subMenus.objects;
                            },
                        });
                    } else {
                        return empty;
                    }
                } else {
                    return empty;
                }
            },
        });
    }

    getSelectedMenuAcceses(menu: any) {
        const url = `/hr/access/acl/roles/?menu_role_menu=${menu}&resume=true`;
        return this.apiService.get(url).pipe((data) => data);
    }
}
