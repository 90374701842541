export class Login {
    static readonly type = '[Auth] Login';
    constructor(
        public payload: {
            username: string;
            password: string;
        }
    ) {}
}

export class PopulateUser {
    static readonly type = '[Populate] Populate User';
}

export class Logout {
    static readonly type = '[Auth] Logout';
}

export class PopulateOperate {
    static readonly type = '[Populate] Populate Operator';
}

export class LogoutCorporate {
    static readonly type = '[Logout] Logout Corporate';
    // constructor(
    //     public payload: {
    //         corporateOnly: boolean;
    //     }
    // ) {}
}

export class PopulateClient {
    static readonly type = '[Populate] Populate client';
}

export class ConnectUser {
    static readonly type = '[Connect] Connect Operator';
    constructor(
        public payload: {
            username: string;
            token: string;
            email: string;
        }
    ) {}
}

export class ClearCorporates {
    static readonly type = '[Clear] Clear corporate';
}

export class ConfirmPinPossession {
    static readonly type = '[confirm] Confirm pin';
}
