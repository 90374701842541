<div class="card lg-radius overflow-hidden border-0" style="width: 800px">
    <div
        class="card-header bg-primary border-bottom-0 py-2 px-5 d-flex justify-content-between align-items-center"
    >
        <div class="" style="height: 80px; width: 157px">
            <img
                class="img-fluid"
                src="/assets/images/ihela3.png"
                alt="IHELA Logo"
            />
        </div>
        <div class="" style="width: 100px; height: 57px">
            <img
                class="img-fluid lg-radius"
                src="/assets/images/OBR.png"
                alt="OBR Logo"
            />
        </div>
    </div>
    <div class="card-body py-1 level-1 position-relative">
        <span
            class="position-absolute d-flex justify-content-center align-items-center"
            style="inset: 0; opacity: 0.1"
        >
            <img
                style="height: 53%"
                src="/assets/images/ihela-black.svg"
                alt=""
            />
        </span>
        <section>
            <div
                class="col-9 mx-auto d-flex justify-content-between align-items-center mt-1"
            >
                <strong class="text-dark"
                    ><span i18n>Quitance N<sup>o</sup> :</span>
                    <span>
                        {{ successMessage.receipt_num }}
                    </span>
                </strong>
                <strong class="text-dark"
                    ><span i18n>BUREAU :</span>
                    <span> {{ successMessage.office }}</span>
                </strong>
            </div>

            <section class="d-flex flex-column px-4 mt-3">
                <div
                    class="d-flex justify-content-between align-items-center border-bottom mb-3 text-dark"
                >
                    <div>
                        <strong i18n>Délivrée à : </strong>
                        <span>{{ successMessage.deliver_to }}</span>
                    </div>
                    <div>
                        <strong i18n>Date de la quittance : </strong>
                        <span>{{ successMessage.receipt_date }}</span>
                    </div>
                </div>
                <div class="d-flex justify-content-between">
                    <div>
                        <div class="mb-1 text-dark">
                            <strong class="me-2" i18n
                                >NIF de l'entreprise :
                            </strong>
                            <small>{{ successMessage.nif }}</small>
                        </div>
                        <div class="text-dark">
                            <strong class="mb-1" i18n
                                >Nom de l'entreprise :
                            </strong>
                            <div style="display: flex; flex-direction: column">
                                <small>LIFELINE COMPANY</small>
                                <small>AV.DES SCIENCES</small>
                                <small>Q.ROHERO</small>
                                <small>ROHERO</small>
                                <small>BURUNDI</small>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="text-dark">
                            <strong i18n class="me-53 mb-1 me-2"
                                >Code du declarant :
                            </strong>
                            <small>{{ successMessage.declarant_code }}</small>
                        </div>
                        <div class="text-dark">
                            <strong class="mb-1" i18n
                                >Nom du declarant :
                            </strong>
                            <div style="display: flex; flex-direction: column">
                                <small>NAST CLEARING AGENCY</small>
                                <small>PORT DE BUJUMBURA</small>
                                <small>Q.INDUSTRIEL</small>
                            </div>
                        </div>
                    </div>

                    <div class="text-dark">
                        <div class="mb-1">
                            <strong class="me-2" i18n>Agence : </strong>
                            <span>{{ successMessage.agence }}</span>
                        </div>
                        <div class="mb-1">
                            <strong class="me-2" i18n>Ref. Bordereau : </strong>
                            <small>{{ successMessage.ref }}</small>
                        </div>
                        <div class="mb-1">
                            <strong class="me-2" i18n>Montant : </strong>
                            <small>{{ successMessage.amount }}</small>
                        </div>
                        <div class="mb-1">
                            <strong class="me-3" i18n>Deposant : </strong>
                            <small>{{ successMessage.name }}</small>
                        </div>
                        <div class="mb-1">
                            <strong class="me-2" i18n>Type : </strong>
                            <small>{{ successMessage.type }}</small>
                        </div>
                    </div>
                </div>

                <div class="text-center mt-5 mb-1 text-dark">
                    <b i18n
                        >La Banque IHELA CREDIT UNION Affirme avoir recu une
                        somme de cinq cent vingt-quatre mille quatre cent
                        soixante-dix francs({{ successMessage.amount }} BIF)</b
                    >
                </div>
            </section>
        </section>
    </div>
    <div
        class="card-footer border-top-0 bg-primary py-2 px-5 d-flex justify-content-between align-items-center"
    >
        <div
            class=""
            style="height: 50px; width: 50px; opacity: 0; pointer-events: none"
        >
            <i class="fa-solid fa-qrcode h-100 w-100"></i>
        </div>
        <div
            class="d-flex flex-column align-items-end"
            [ngClass]="{
                'text-light': theme === 'light-mode',
                'text-dark': theme === 'dark-mode'
            }"
        >
            <b
                ><small>{{
                    successMessage.receipt_date | date : 'medium'
                }}</small></b
            >
            <b class="d-flex justify-content-end"
                ><small class="me-3">ihela.bi</small><small>76434444</small></b
            >
            <b><small>Q. Asiatique Bujumbura </small></b>
        </div>
    </div>
</div>
