import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

@NgModule({
    declarations: [ConfirmDialogComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule],
    exports: [ConfirmDialogComponent],
})
export class ConfirmDialogModule {}
