<div
    class="card lg-radius overflow-hidden border-0"
    id="bill"
    style="width: 351px; max-width: 351px"
>
    <div
        class="card-header border-bottom-0 bg-primary py-2 px-3 d-flex justify-content-between align-items-center"
    >
        <div class="" style="height: 80px; width: 157px">
            <img
                class="img-fluid"
                src="/assets/images/ihela3.png"
                alt="IHELA Logo"
            />
        </div>
        <div
            class="d-flex flex-column align-items-end"
            [ngClass]="{
                'text-light': theme === 'light-mode',
                'text-dark': theme === 'dark-mode'
            }"
        >
            <span class="text-end" style="font-size: 18px">{{
                successMessage.name
            }}</span>
            <small class="text-end">{{ successMessage.debit_account }}</small>
            <small class="d-flex align-items-center mt-1 noprint">
                <span style="font-size: 14px">Print</span>
                <button
                    printSectionId="bill"
                    ngxPrint
                    [useExistingCss]="true"
                    style="color: rgba(99, 156, 216, 1)"
                    class="ms-2 fs-5 is-clickable btn p-0 active-border-none"
                >
                    <i class="fa-solid fa-print"></i></button
            ></small>
        </div>
    </div>
    <div class="card-body level-1 py-2 px-3 position-relative">
        <span
            class="position-absolute d-flex justify-content-center align-items-center"
            style="inset: 0; opacity: 0.1"
        >
            <img
                style="height: 53%"
                src="/assets/images/ihela-black.svg"
                alt=""
            />
        </span>

        <section
            style="
                min-height: 200px;
                max-height: 296px;
                z-index: 10;
                position: relative;
            "
            class="text-dark d-flex row overflow-auto"
        >
            <div class="d-flex flex-column text-dark col-6">
                <div
                    class="d-flex flex-column mb-2"
                    *ngIf="
                        successMessage.product && successMessage.product.name
                    "
                >
                    <b class="small-2" i18n>Product</b>
                    <div class="d-flex align-items-center">
                        <span
                            style="font-size: 18px"
                            class="fw-bold me-4"
                            i18n
                            >{{ successMessage.product.name }}</span
                        >
                        <small>{{ successMessage.product.value }}</small>
                    </div>
                </div>
                <div
                    class="d-flex flex-column mb-2"
                    *ngIf="successMessage.description"
                >
                    <b class="small-2" i18n>Description</b>
                    <span style="font-size: 18px" class="fw-bold">{{
                        successMessage.description
                    }}</span>
                </div>
                <!-- 
                <div class="d-flex flex-column mb-2">
                    <b class="small-2" i18n>Adress</b>
                    <span style="font-size: 18px" class="fw-bold">{{
                        successMessage.adress
                    }}</span>
                </div> -->
                <div class="d-flex flex-column mb-2">
                    <b class="small-2" i18n>Transaction code</b>
                    <span style="font-size: 18px" class="fw-bold">{{
                        successMessage.code
                    }}</span>
                </div>
                <div class="d-flex flex-column">
                    <b class="small-2" i18n>Total</b>
                    <span style="font-size: 20px" class="fw-bold">{{
                        successMessage.amount | currency : 'BIF '
                    }}</span>
                </div>
            </div>

            <div class="d-flex flex-column text-dark col-6">
                <div class="d-flex flex-column mb-2 align-items-end">
                    <b class="text-end small-2" i18n
                        >Beneficary<br />
                        Account
                    </b>
                    <span style="font-size: 18px" class="fw-bold text-end">{{
                        successMessage.merchantName
                    }}</span>
                </div>
                <div class="d-flex flex-column align-items-end">
                    <b class="text-end small-2" i18n>Credit<br />Account</b>
                    <span style="font-size: 18px" class="fw-bold text-end">{{
                        successMessage.credit_account
                    }}</span>
                </div>
            </div>
            <div id="printable_text" class="my-2"></div>
        </section>
    </div>
    <div
        class="card-footer border-top-0 bg-primary py-2 px-4 d-flex justify-content-between align-items-center"
    >
        <div
            class=""
            style="height: 50px; width: 50px; opacity: 0; pointer-events: none"
        >
            <i class="fa-solid fa-qrcode h-100 w-100"></i>
        </div>
        <div
            class="d-flex flex-column align-items-end"
            [ngClass]="{
                'text-light': theme === 'light-mode',
                'text-dark': theme === 'dark-mode'
            }"
        >
            <b
                ><small>{{ successMessage.date | date : 'medium' }}</small></b
            >
            <b class="d-flex justify-content-end"
                ><small class="me-3">ihela.bi</small><small>76434444</small></b
            >
            <b><small>Q. Asiatique Bujumbura </small></b>
        </div>
    </div>
</div>
