import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartService } from 'src/app/core/services/cart.service';
import { object } from '@amcharts/amcharts5';
import { NotFoundPageComponent } from 'src/app/shared/components/reusable/not-found-page/not-found-page.component';

@Component({
    selector: 'app-paymerchant',
    templateUrl: './paymerchant.component.html',
    styleUrls: ['./paymerchant.component.scss'],
    standalone: true,
    imports: [CommonModule, NotFoundPageComponent],
})
export class PaymerchantComponent implements OnInit {
    @Output() cartItem = new EventEmitter<any>();
    @Input() product: any = '';
    cartItemList: any = [];
    item: any;
    itemQuantity = 0;
    cartAdding: any = 0;
    totalAmount: any;

    constructor(private _cartService: CartService) {
        // comment
    }

    ngOnInit(): void {
        // comment
        window.addEventListener('load', this.initSlider);
    }

    addItemQuantity() {
        this.itemQuantity = this.itemQuantity + 1;
    }
    removeItemQuantity() {
        if (this.itemQuantity > 0) {
            this.itemQuantity = this.itemQuantity - 1;
        }
    }
    addToCart(product: any) {
        if (this.itemQuantity > 0) {
            this.cartAdding = this.cartAdding + 1;
            // Object.assign(product, {
            //     Quantity: this.itemQuantity,
            //     total: product.price * this.itemQuantity,
            // });
            const productData = {
                product: product,
                quantity: this.itemQuantity,
                total: product.price * this.itemQuantity,
            };
            this._cartService.addToCart(productData);
            this.cartItemList = this._cartService.retrieveCart();
            this.afficher();
            this.totalAmount = this.getTotalAmount();
            this.emptyQuant();
        }
    }
    afficher() {
        this.cartItemList = this._cartService.retrieveCart();
    }
    getTotalAmount() {
        let Gtotal = 0;
        this.cartItemList.map((item: any) => {
            Gtotal += item.total;
        });
        return Gtotal;
    }
    inputDetails() {
        this.cartItem.emit(this.cartAdding);
    }
    emptyQuant() {
        this.itemQuantity = 0;
    }
    // getAllProduct(event: any) {
    //     this.allProduct = event;
    //     console.log('ALLproducts', this.allProduct);
    // }
    emptyCart() {
        this._cartService.EmptyCart();
        this.cartAdding = 0;
        this.afficher();
        this.totalAmount = 0;
    }

    initSlider = () => {
        const ButtonSlider = document.querySelectorAll('.dot');
        const imagList: any = document.querySelector('.carousel-inner');
        ButtonSlider.forEach((button) => {
            button.addEventListener('click', () => {
                const directioin = button.id === 'privslide' ? -1 : 1;
                const scroling = imagList.clientWidth * directioin;
                imagList.scrollBy({ left: scroling, behavior: 'smooth' });
            });
        });
    };
}
