import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CustomerBaseService {
    constructor(private apiService: ApiService) {}

    lookupAccount(data: any) {
        return this.apiService
            .post('/banks/clientlookup/', data)
            .pipe(map((data) => data));
    }
}
