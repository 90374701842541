import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { PaginationConfig } from '../models';

@Injectable({
    providedIn: 'root',
})
export class CounterService {
    constructor(
        private apiService: ApiService,
        private httpClient: HttpClient
    ) {
        // comment
    }
    getTellerList(treasurerId: any) {
        return this.apiService
            .get('/hr/tellers/list/?treasurer=' + treasurerId)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
    connectTeller() {
        return this.apiService.get('/hr/connected/teller/').pipe(
            map((data) => {
                return data;
            })
        );
    }
    getClientDetails(bank_id: string) {
        return this.apiService
            .get('/account/details/?' + bank_id)
            .pipe(map((data) => data));
    }

    withdrawFromMerchant(merchant: any) {
        return this.apiService.post(
            '/dbs/merchant/other-operations/',
            merchant
        );
    }

    getTellerDetails(id: any) {
        return this.apiService.get('/hr/tellers/list/' + id + '/');
    }
    getBanksList(bank: any) {
        return this.apiService.get('/banks/list/?' + bank);
    }
    pickUpBankNote() {
        return this.apiService.get('/operations/bank-note/').pipe(
            map((data) => {
                return data;
            })
        );
    }
    getMouvementList(username: any, type: any) {
        return this.apiService.get(
            '/hr/note/mouvement/list/?teller=' + username + '&mif_type=' + type
        );
    }
    addAmount(username: any, amount: any) {
        return this.apiService
            .post('/hr/note/mouvement/add/?teller=' + username, amount)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
    makeAction(username: any, action: any) {
        return this.apiService
            .post('/hr/note/mouvement/action/?teller=' + username, action)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
    performOperation(operation: any) {
        return this.apiService
            .post('/operations/perform/counter/', operation)
            .pipe(
                map((operation) => {
                    return operation;
                })
            );
    }
    changePassword(password: any) {
        return this.apiService
            .post('/hr/operator/change_teller_password/', password)
            .pipe(
                map((password) => {
                    return password;
                })
            );
    }
    performTreasuryTransaction(treasury: any) {
        return this.apiService
            .post('/treasury/institution-transaction/', treasury)
            .pipe(
                map((treasury) => {
                    return treasury;
                })
            );
    }
    getTreasuryBalanceList(search = '', pagination: PaginationConfig) {
        const url = `/treasury/institutions/balances/?search=${search}&limit=${pagination.filters.limit}&offset=${pagination.filters.offset}`;
        return this.apiService.get(url).pipe(
            map((treasuryBalance) => {
                return treasuryBalance;
            })
        );
    }
    addTreasuryBalanceList(balance: any) {
        return this.apiService
            .post('/treasury/institutions/balances/', balance)
            .pipe(
                map((treasury) => {
                    return treasury;
                })
            );
    }
    sendToTeller(teller: any) {
        return this.apiService
            .post('/hr/note/mouvement/teller_to_teller_add/', teller)
            .pipe(
                map((data: any) => {
                    return data;
                })
            );
    }
    validateOrSuspetBalance(balance: any) {
        return this.apiService.post('/treasury/balance-action/', balance).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
    // getMovementFundList() {
    //     return this.apiService.get('/hr/note/movement/manage').pipe(
    //         map((password) => {
    //             return password;
    //         })
    //     );
    // } /treasury/balance-action/
}
