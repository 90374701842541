import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import * as Sentry from '@sentry/angular-ivy';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InstitutionListComponent } from './shared/components/layout-container/institution-list/institution-list.component';
import { LayoutModule } from './shared/components/layout-container/header';
import { LayoutComponent } from './shared/components/layout-container/layout.component';
import { AsideMenuComponent } from './shared/components/layout-container/online-banking/aside-menu/aside-menu.component';
import { AuthCorporateComponent } from './modules/authentication/auth-corporate/auth-corporate.component';
import { WAsideMenuComponent } from './shared/components/layout-container/w-aside-menu/w-aside-menu.component';
import { SettingComponent } from './shared/components/layout-container/setting/setting.component';
import { CoreModule } from './core';
import { SharedModule, StatesModule } from './shared';
import { ConfirmDialogModule } from './shared/components/confirm-dialog';
import { SwitchPlateformIconsComponent } from './shared/components/layout-container/header/switch-plateform-icons/switch-plateform-icons.component';
import { TransferBillComponent } from './modules/global-components/bills-format/transfer-bill/transfer-bill.component';
import { ObrBillComponent } from './modules/global-components/bills-format/obr-bill/obr-bill.component';
import { MerchantBillComponent } from './modules/global-components/bills-format/merchant-bill/merchant-bill.component';
import { SettingAsideMenuComponent } from './shared/components/layout-container/online-banking/settings-aside-menu/setting-aside-menu/setting-aside-menu.component';
import { NotFoundPageComponent } from './shared/components/reusable/not-found-page/not-found-page.component';
import { LandscapeBillComponent } from './modules/global-components/bills-format/landscape-bill/landscape-bill.component';
import { SplashScreenComponent } from './shared/components/layout-container/splash-screen/splash-screen.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        AppComponent,
        InstitutionListComponent,
        LayoutComponent,
        AsideMenuComponent,
        WAsideMenuComponent,
        SettingComponent,
        AuthCorporateComponent,
        SettingAsideMenuComponent,
        SplashScreenComponent,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {
                //code
            },
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
    exports: [LayoutComponent, SettingComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CoreModule,
        StatesModule,
        // FormsModule,
        ReactiveFormsModule,
        // GeneralModule,
        // LookupsModule,
        LayoutModule,
        ConfirmDialogModule,
        SharedModule,
        RouterModule,
        NgxSkeletonLoaderModule,
        SwitchPlateformIconsComponent,
        TransferBillComponent,
        ObrBillComponent,
        MerchantBillComponent,
        NotFoundPageComponent,
        LandscapeBillComponent,
        GoogleMapsModule,
    ],
})
export class AppModule {}
