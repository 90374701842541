import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApiService } from './api.service';
import { SimplePaymentBody } from 'src/app/modules/market/paymerchant/payment.model';

@Injectable({
    providedIn: 'root',
})
export class SimpleMerchantService {
    constructor(private _apiService: ApiService) {}

    postData(data: any) {
        return this._apiService
            .post('/dbs/merchant/simple/payment/', data)
            .pipe(map((data) => data));
    }
}
