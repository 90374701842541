import { Component, OnInit, Input, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Observable, Subject } from 'rxjs';
import { Store } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';

import {
    PaginationConfig,
    GeneralService,
    ExcelService,
} from '../../../../core';
import {
    AmountState,
    SwitchState,
    SwitchThemeState,
    displayAmount,
} from '../../../../shared';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
    // @Input() headers = [{name: "Name",field: "client_full_name",  size:"" }, {name: "Code", field: "client_code", size: ""}, {name: "Client category", size: ""}, { name: "Client category", size: "" }, { name: "Client classification", size: "" } ,  { name:"Type", size:"" }, { name:"Sex", size:"" }, { name:"Activity", size: "" }]
    private onDestroy$: Subject<void> = new Subject<void>();
    @Input() headers: any = [];
    @Input() url = '';
    @Input() title = '';
    @Input() show_searchBar = true;
    showAmount = false;
    showAmount$: Observable<any>;
    @Input() filters: any = [
        // { name: 'Period', title: 'period', value: 'p' },
        {
            name: 'Date',
            title: 'date',
            value: [
                { title: 'Date', value: 'date', type_field: 'date' },
                { title: 'Period', value: 'period', type_field: 'date' },
            ],
        },
        {
            name: 'Status',
            title: 'status',
            value: [
                { title: 'Activate', value: 'A', type_field: 'checkbox' },
                { title: 'Deactivate', value: 'D', type_field: 'checkbox' },
            ],
        },
    ];
    @Input() hasOverview = true;
    @Input() overviewUrl = '';
    @Input() todayDate = false;
    @Input() limit = 20;
    overViewData: any;
    searchName = new FormControl('');
    selectedPeriod: any;

    data_list: any = [];
    clientPagination = new PaginationConfig();
    currentPage = 0;
    response_data: any;
    pages: any;
    selectedFilt: any;
    boolean = false;
    isLoading = false;
    showFilters = false;
    showFilterComponent = false;
    excelData: any = [];
    currentExcelPage = 0;
    excelOffset = 0;
    exportCount = 0;
    round = 0;
    exportingState = 0;
    isExporting = false;
    showNotification = false;
    theme$: Observable<any>;
    theme: any;
    plateform$: Observable<any>;
    plateform = '';
    displayPaginationLimit = false;
    paginationsLimit = [50, 40, 30, 20, 10, 5];
    overviewOption = {
        hidden: false,
        image_url: '../../../../../assets/images/arrow-down.svg',
        title: 'Hide the overview',
    };
    // canMoveNext = false;

    showBalaceStatusEye = false;

    constructor(
        private generalService: GeneralService,
        private excelService: ExcelService,
        private store: Store
    ) {
        this.theme$ = this.store.select(SwitchThemeState.GetTheme);
        this.showAmount$ = this.store.select(AmountState.isShowed);
        this.plateform$ = this.store.select(SwitchState.GetPlateform);
    }

    ngOnInit(): void {
        this.clientPagination.filters.limit = this.limit;
        this.getData();
        this.theme$.subscribe({
            next: (theme) => {
                this.theme = theme;
            },
        });
        this.showAmount$.pipe(takeUntil(this.onDestroy$)).subscribe({
            next: (showAmount) => {
                this.showAmount = showAmount;
            },
        });
        this.plateform$.subscribe({
            next: (plateform) => {
                this.plateform = plateform;
            },
        });
        this.showBalaceStatusEye = this.showCurrencyEye(this.headers);
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            const chng = changes[propName];
            if (propName === 'overviewUrl') {
                this.overviewUrl = chng.currentValue;
                if (this.overviewUrl !== '') {
                    this.getOverviewData();
                }
            }

            if (propName === 'todayDate') {
                this.todayDate = chng.currentValue;
                if (this.todayDate) {
                    const today = new Date();
                    this.selectedPeriod = {
                        startDate: today.toISOString().slice(0, 10),
                        endDate: today.toISOString().slice(0, 10),
                    };
                }
            }

            // if (propName === 'url') {
            //     if (chng.currentValue) {
            //         this.getData();
            //     }
            // }
        }
    }

    showAmounts() {
        this.showAmount = !this.showAmount;
        this.store.dispatch(new displayAmount({ show: this.showAmount }));
    }
    // to do add a routerLink

    getData() {
        this.response_data = null;
        let params: any = [];

        if (this.searchName.value !== '') {
            params = [{ title: 'search', value: this.searchName.value }];
        }

        if (this.selectedPeriod) {
            params.push(
                { title: 'date_from', value: this.selectedPeriod.startDate },
                { title: 'date_to', value: this.selectedPeriod.endDate }
            );
        }

        this.isLoading = true;
        this.generalService
            .getData(this.url, this.clientPagination, params)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (data) => {
                    this.response_data = data;
                    this.data_list = [];
                    if (this.clientPagination.filters.limit) {
                        this.pages = ~~(
                            this.response_data.count /
                            this.clientPagination.filters.limit
                        );
                        // this.canMoveNext = this.response_data.count < (this.currentPage + 1) * this.clientPagination.filters.limit
                    }
                    this.isLoading = false;

                    // for (const row of data.objects) {
                    //     const line = [];
                    //     for (const header of this.headers) {
                    //         // make field as list so we can separate the '.'
                    //         const fields = header['field'];

                    //         // this variable is created just for protected row values in loop, Please if you have a suggestion let me know
                    //         let row1 = row;
                    //         let css = '';
                    //         let icon = '';
                    //         let detail = '';
                    //         let full_field = '';
                    //         let class_type = '';

                    //         // loop for separating fields
                    //         for (const field in fields) {
                    //             row1 = row;
                    //             const all_fields = fields[field].split('.');

                    //             for (const all_field in all_fields) {
                    //                 if (row1) {
                    //                     row1 = row1[all_fields[all_field]];
                    //                 } else {
                    //                     row1 = '------';
                    //                 }
                    //             }
                    //             if (row1) {
                    //                 full_field += row1 + ' ';
                    //             }
                    //         }
                    //         row1 = full_field;

                    //         // loop for separating css
                    //         if (header['css']) {
                    //             let css_fields = header['css'];
                    //             css = row;
                    //             css_fields = css_fields.split('.');
                    //             for (const css_field in css_fields) {
                    //                 css = css[css_fields[css_field]];
                    //             }
                    //         }

                    //         // loop for separating icon
                    //         if (header['icon']) {
                    //             let icon_fields = header['icon'];
                    //             icon = row;
                    //             icon_fields = icon_fields.split('.');
                    //             for (const icon_field in icon_fields) {
                    //                 icon = icon[icon_fields[icon_field]];
                    //             }
                    //         }

                    //         //get link url
                    //         if (header['detail']) {
                    //             const link = header['detail']['link'];
                    //             let detail_fields = header['detail']['field'];
                    //             detail = row;
                    //             detail_fields = detail_fields.split('.');
                    //             for (const detail_field in detail_fields) {
                    //                 detail =
                    //                     detail[detail_fields[detail_field]];
                    //             }
                    //             detail = link + detail;
                    //         }

                    //         if (header['boolean']) {
                    //             this.boolean = true;
                    //         } else {
                    //             this.boolean = false;
                    //         }

                    //         if (
                    //             header['class'] &&
                    //             header['class'] === 'badge'
                    //         ) {
                    //             class_type = 'badge bg-' + css + ' text-' + css;
                    //         }

                    //         // Display or not the field
                    //         if (header['canBeDisplayed'] === false) {
                    //             header['canBeDisplayed'];
                    //         } else {
                    //             header['canBeDisplayed'] = true;
                    //         }

                    //         // data of a one row
                    //         const data = {
                    //             value: row1,
                    //             size: header['size'],
                    //             css: css,
                    //             icon: icon,
                    //             detail: detail,
                    //             boolean: this.boolean,
                    //             format: header['format'],
                    //             class: class_type,
                    //             canBeDisplayed: header['canBeDisplayed'],
                    //         };
                    //         line.push(data);
                    //     }
                    //     this.data_list.push(line);
                    // }
                    if (data.objects) {
                        for (const row of data.objects) {
                            const line = [];
                            for (const header of this.headers) {
                                // make field as list so we can separate the '.'
                                const fields = header['field'];

                                // this variable is created just for protected row values in loop, Please if you have a suggestion let me know
                                let row1 = row;
                                let css = '';
                                let icon = '';
                                let detail = '';
                                let full_field = '';
                                let class_type = '';

                                // loop for separating fields
                                for (const field in fields) {
                                    row1 = row;
                                    const all_fields = fields[field].split('.');

                                    for (const all_field in all_fields) {
                                        if (row1) {
                                            row1 = row1[all_fields[all_field]];
                                        } else {
                                            row1 = '------';
                                        }
                                    }
                                    if (row1) {
                                        full_field += row1 + ' ';
                                    }
                                }
                                row1 = full_field;

                                // loop for separating css
                                if (header['css']) {
                                    let css_fields = header['css'];
                                    css = row;
                                    css_fields = css_fields.split('.');
                                    for (const css_field in css_fields) {
                                        css = css[css_fields[css_field]];
                                    }
                                }

                                // loop for separating icon
                                if (header['icon']) {
                                    let icon_fields = header['icon'];
                                    icon = row;
                                    icon_fields = icon_fields.split('.');
                                    for (const icon_field in icon_fields) {
                                        icon = icon[icon_fields[icon_field]];
                                    }
                                }

                                //get link url
                                if (header['detail']) {
                                    const link = header['detail']['link'];
                                    let detail_fields =
                                        header['detail']['field'];
                                    detail = row;
                                    detail_fields = detail_fields.split('.');
                                    for (const detail_field in detail_fields) {
                                        detail =
                                            detail[detail_fields[detail_field]];
                                    }
                                    detail = link + detail;
                                }

                                if (header['boolean']) {
                                    this.boolean = true;
                                } else {
                                    this.boolean = false;
                                }

                                if (
                                    header['class'] &&
                                    header['class'] === 'badge'
                                ) {
                                    class_type =
                                        'badge bg-' + css + ' text-' + css;
                                }

                                // Display or not the field
                                if (header['canBeDisplayed'] === false) {
                                    header['canBeDisplayed'];
                                } else {
                                    header['canBeDisplayed'] = true;
                                }

                                // data of a one row
                                const data = {
                                    value: row1,
                                    size: header['size'],
                                    css: css,
                                    icon: icon,
                                    detail: detail,
                                    boolean: this.boolean,
                                    format: header['format'],
                                    class: class_type,
                                    canBeDisplayed: header['canBeDisplayed'],
                                    option1: header['option1'],
                                    option2: header['option2'],
                                    value1: header['value1'],
                                    value2: header['value2'],
                                };
                                line.push(data);
                            }
                            this.data_list.push(line);
                        }
                    } else if (data.object.response_data) {
                        for (const row of data.object.response_data) {
                            const line = [];
                            for (const header of this.headers) {
                                // make field as list so we can separate the '.'
                                const fields = header['field'];

                                // this variable is created just for protected row values in loop, Please if you have a suggestion let me know
                                let row1 = row;
                                let css = '';
                                let icon = '';
                                let detail = '';
                                let full_field = '';
                                let class_type = '';

                                // loop for separating fields
                                for (const field in fields) {
                                    row1 = row;
                                    const all_fields = fields[field].split('.');

                                    for (const all_field in all_fields) {
                                        if (row1) {
                                            row1 = row1[all_fields[all_field]];
                                        } else {
                                            row1 = '------';
                                        }
                                    }
                                    if (row1) {
                                        full_field += row1 + ' ';
                                    }
                                }
                                row1 = full_field;

                                // loop for separating css
                                if (header['css']) {
                                    let css_fields = header['css'];
                                    css = row;
                                    css_fields = css_fields.split('.');
                                    for (const css_field in css_fields) {
                                        css = css[css_fields[css_field]];
                                    }
                                }

                                // loop for separating icon
                                if (header['icon']) {
                                    let icon_fields = header['icon'];
                                    icon = row;
                                    icon_fields = icon_fields.split('.');
                                    for (const icon_field in icon_fields) {
                                        icon = icon[icon_fields[icon_field]];
                                    }
                                }

                                //get link url
                                if (header['detail']) {
                                    const link = header['detail']['link'];
                                    let detail_fields =
                                        header['detail']['field'];
                                    detail = row;
                                    detail_fields = detail_fields.split('.');
                                    for (const detail_field in detail_fields) {
                                        detail =
                                            detail[detail_fields[detail_field]];
                                    }
                                    detail = link + detail;
                                }

                                if (header['boolean']) {
                                    this.boolean = true;
                                } else {
                                    this.boolean = false;
                                }

                                if (
                                    header['class'] &&
                                    header['class'] === 'badge'
                                ) {
                                    class_type =
                                        'badge bg-' + css + ' text-' + css;
                                }

                                // Display or not the field
                                if (header['canBeDisplayed'] === false) {
                                    header['canBeDisplayed'];
                                } else {
                                    header['canBeDisplayed'] = true;
                                }

                                // data of a one row
                                const data = {
                                    value: row1,
                                    size: header['size'],
                                    css: css,
                                    icon: icon,
                                    detail: detail,
                                    boolean: this.boolean,
                                    format: header['format'],
                                    class: class_type,
                                    canBeDisplayed: header['canBeDisplayed'],
                                    option1: header['option1'],
                                    option2: header['option2'],
                                    value1: header['value1'],
                                    value2: header['value2'],
                                };
                                line.push(data);
                            }
                            this.data_list.push(line);
                        }
                    }
                },
                error: (msg) => {
                    console.log('Error Getting Location: ', msg);
                    this.isLoading = false;
                },
            });
    }

    doListMove(action: string) {
        if (action === 'next') {
            this.currentPage += 1;
        } else {
            this.currentPage -= 1;
        }

        // condition just for typescript
        if (this.clientPagination.filters.limit) {
            this.clientPagination.filters.offset =
                this.clientPagination.filters.limit * this.currentPage;
            this.getData();
        }
    }

    search() {
        if (this.clientPagination?.filters.offset ?? 0 >= 1) {
            this.clientPagination.filters.offset = 0;
            this.currentPage = 0;
            this.getData();
        } else {
            this.getData();
        }
    }

    displayFilters() {
        if (this.showFilters) {
            this.showFilters = false;
        } else {
            this.showFilters = true;
        }
    }

    doExcelExport(): void {
        const pagination: any = { filters: { offset: 0, limit: 20 } };
        // this.round = 0;
        if (this.currentExcelPage === 0) {
            pagination.filters.offset = this.clientPagination.filters.offset;
            this.getExportData(pagination);
        } else {
            pagination.filters.offset = this.currentExcelPage * 20;
            this.getExportData(pagination);
        }
    }

    getExportData(pagination: any) {
        this.isExporting = true;
        let params: any = [];

        if (this.searchName.value !== '') {
            params = [{ title: 'search', value: this.searchName.value }];
        }

        if (this.selectedPeriod) {
            params.push(
                { title: 'date_from', value: this.selectedPeriod.startDate },
                { title: 'date_to', value: this.selectedPeriod.endDate }
            );
        }
        this.generalService
            .getData(this.url, pagination, params)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (data) => {
                    data.objects.forEach((row: any) => {
                        this.round += 1;
                        const one_row: any = {};
                        for (const header of this.headers) {
                            let full_field = '';
                            let row1 = row;

                            // loop for separating fields
                            const fields = header['field'];
                            for (const field in fields) {
                                row1 = row;
                                const all_fields = fields[field].split('.');

                                for (const all_field in all_fields) {
                                    if (row1) {
                                        row1 = row1[all_fields[all_field]];
                                    } else {
                                        row1 = '------';
                                    }
                                }
                                if (row1) {
                                    full_field += row1 + ' ';
                                }
                            }

                            // data of one row
                            one_row[header['name']] = full_field;
                        }
                        this.excelData.push(one_row);
                    });
                    this.exportingState = Math.round(
                        (this.round * 100) / data.count
                    );
                    if (this.round < data.count && data.objects.length > 0) {
                        this.currentExcelPage += 1;
                        this.doExcelExport();
                    } else {
                        this.showNotification = true;
                        this.isExporting = false;
                        this.currentExcelPage = 0;
                        this.isExporting = false;
                        this.exportingState = 0;
                        this.round = 0;
                        this.hideNotification();
                        this.exportAsXLSX();
                    }
                },
            });
    }

    exportAsXLSX(): void {
        this.excelService.exportAsExcelFile(this.excelData, 'exportdata');
        this.excelData = [];
    }

    refreshExport(): void {
        this.currentExcelPage = 0;
        this.isExporting = false;
        this.excelData = [];
        this.exportingState = 0;
        this.round = 0;
        this.doExcelExport();
    }

    hideNotification(): void {
        setTimeout(() => {
            this.showNotification = false;
        }, 3000);
    }

    getOverviewData() {
        this.generalService
            .getOverviewData(this.overviewUrl)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (data) => {
                    this.overViewData = data.object;
                },
            });
    }

    openPagination() {
        if (this.displayPaginationLimit) {
            this.displayPaginationLimit = false;
        } else {
            this.displayPaginationLimit = true;
        }
    }

    selectPagintationLimit(pagination: number) {
        this.clientPagination.filters.limit = pagination;
        this.openPagination();
        this.getData();
    }

    canMoveNext(limit: number): boolean {
        return this.response_data.count < (this.currentPage + 1) * limit;
    }

    openOverview() {
        if (!this.overviewOption.hidden) {
            this.overviewOption.hidden = true;
            (this.overviewOption.image_url =
                '../../../../../assets/images/arrow-up.svg'),
                (this.overviewOption.title = 'Open the overview');
        } else {
            (this.overviewOption.hidden = false),
                (this.overviewOption.image_url =
                    '../../../../../assets/images/arrow-down.svg'),
                (this.overviewOption.title = 'Hide the overview');
        }
    }

    showCurrencyEye(headers: []): boolean {
        const element = headers.find(
            (object: any) => object['format'] && object.format == 'currency'
        );
        return element ? true : false;
    }

    getSelectedPeriod($event: any) {
        this.showFilters = false;
        this.selectedPeriod = $event;
        this.todayDate = false;
        this.getData();
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
