import { Injectable } from '@angular/core';

import {
    State,
    StateToken,
    Selector,
    Action,
    StateContext,
    Store,
} from '@ngxs/store';
import {
    GetMenuGroup,
    SelectMarket,
    SelectMenuGroup,
    SetSelectedMenuAccess,
    SetSubMenus,
} from './menu.actions';

import { MenuService, VariableService } from 'src/app/core';
import {
    MenuGroupModel,
    SelectedMarketModel,
    SelectedMenuAccesesModel,
    SelectedMenuGroupModel,
    SubMenuModel,
} from './menu.models';

const GROUP_MENU = new StateToken<MenuGroupModel>('menu');
const SELECTED_MENU_GROUP = new StateToken<SelectedMenuGroupModel>(
    'selectedMenuGroup'
);
const SELECTED_MARKET = new StateToken<SelectedMarketModel>('market');
const SUB_MENU_MODEL = new StateToken<SubMenuModel>('submenu');
const SELECTED_MENU_ACCESES = new StateToken<SelectedMenuAccesesModel>(
    'acceses'
);
@State<MenuGroupModel>({
    name: GROUP_MENU,
    defaults: {
        menuGroup: {
            intranet: null,
            desk: null,
            reporting: null,
        },
    },
})
@State<SelectedMenuGroupModel>({
    name: SELECTED_MENU_GROUP,
    defaults: {
        selectedMenu: null,
    },
})
@State<SelectedMarketModel>({
    name: SELECTED_MARKET,
    defaults: {
        selectedMarket: 'market',
    },
})
@State<SubMenuModel>({
    name: SUB_MENU_MODEL,
    defaults: {
        subMenus: [],
    },
})
@State<SelectedMenuAccesesModel>({
    name: SUB_MENU_MODEL,
    defaults: {
        access: [],
    },
})
@Injectable()
export class MenuState {
    constructor(
        private menuService: MenuService,
        private variableService: VariableService
    ) {}

    @Selector()
    static GetIntranetMenu(state: MenuGroupModel): any {
        if (state.menuGroup) {
            return state.menuGroup.intranet;
        } else {
            return null;
        }
    }

    @Selector()
    static GetDeskMenu(state: MenuGroupModel): any {
        if (state.menuGroup) {
            return state.menuGroup.desk;
        } else {
            return null;
        }
    }

    @Selector()
    static GetReportingMenu(state: MenuGroupModel): any {
        if (state.menuGroup) {
            return state.menuGroup.reporting;
        } else {
            return null;
        }
    }

    @Selector()
    static GetSelectedMenuGroup(state: SelectedMenuGroupModel): any {
        if (state.selectedMenu) {
            return state.selectedMenu;
        } else {
            return {};
        }
    }

    @Selector()
    static GetSelectedMarket(state: SelectedMarketModel): any {
        if (state.selectedMarket) {
            return state.selectedMarket;
        }
    }

    @Selector()
    static GetSubMenus(state: SubMenuModel): any {
        if (state.subMenus) {
            return state.subMenus;
        }
    }

    @Selector()
    static GetSelectedMenuAcceses(state: SelectedMenuAccesesModel): any {
        if (state.access) {
            return state.access;
        }
    }

    @Action(GetMenuGroup)
    getMenuGroup(ctx: StateContext<MenuGroupModel>, action: GetMenuGroup) {
        for (const menu of this.variableService.menuGroups) {
            this.menuService.getMenu(menu.value).subscribe({
                next: (data) => {
                    const state = ctx.getState();
                    // console.log("*******33", ctx.getState())
                    if (menu.name === 'Intranet') {
                        ctx.patchState({
                            menuGroup: {
                                intranet: data.objects,
                                desk: state.menuGroup.desk,
                                reporting: state.menuGroup.reporting,
                            },
                        });
                    } else if (menu.name === 'Desk') {
                        ctx.patchState({
                            menuGroup: {
                                desk: data.objects,
                                intranet: state.menuGroup.intranet,
                                reporting: state.menuGroup.reporting,
                            },
                        });
                    } else if (menu.name === 'Reporting') {
                        ctx.patchState({
                            menuGroup: {
                                reporting: data.objects,
                                intranet: state.menuGroup.intranet,
                                desk: state.menuGroup.desk,
                            },
                        });
                    }
                },
            });
        }
    }

    @Action(SelectMenuGroup)
    selectedMenu(
        ctx: StateContext<SelectedMenuGroupModel>,
        action: SelectMenuGroup
    ) {
        if (action.payload) {
            ctx.patchState({ selectedMenu: action.payload.menuGroup });
        }
    }

    @Action(SelectMarket)
    selectMarket(ctx: StateContext<SelectedMarketModel>, action: SelectMarket) {
        if (action.payload) {
            ctx.patchState({
                selectedMarket: action.payload.marketName,
            });
        }
    }

    @Action(SetSubMenus)
    setSubMenus(ctx: StateContext<SubMenuModel>, action: SetSubMenus) {
        if (action.payload) {
            ctx.patchState({ subMenus: action.payload.subMenus });
        }
    }

    @Action(SetSelectedMenuAccess)
    setSelectedMenuAcceses(
        ctx: StateContext<SelectedMenuAccesesModel>,
        action: SetSelectedMenuAccess
    ) {
        if (action.payload) {
            ctx.patchState({ access: action.payload.access });
        }
    }
}
