import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class VerificationService {
    constructor() {
        // comment
    }

    // this function in for deep comparison between two any object

    deepEqual(obj1: any, obj2: any) {
        // Base case: If both objects are identical, return true.
        if (obj1 === obj2) {
            return true;
        }
        // Check if both objects are objects and not null.
        if (
            typeof obj1 !== 'object' ||
            typeof obj2 !== 'object' ||
            obj1 === null ||
            obj2 === null
        ) {
            return false;
        }
        // Get the keys of both objects.
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        // Check if the number of keys is the same.
        if (keys1.length !== keys2.length) {
            return false;
        }
        // Iterate through the keys and compare their values recursively.
        for (const key of keys1) {
            if (!keys2.includes(key) || this.deepEqual(obj1[key], obj2[key])) {
                return false;
            }
        }
        // If all checks pass, the objects are deep equal.
        return true;
    }
}
