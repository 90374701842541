<div style="min-height: 100vh; width: 70px" class="primary-light">
    <div class="p-1">
        <img
            src="../../../../assets/images/logo-magis-reduit1.png"
            style="width: 90%"
            class="mt-2"
        />
    </div>
    <div style="padding-top: 30px">
        <div
            role="button"
            class="text-center mt-2 p-1"
            routerLink="/w"
            [routerLinkActiveOptions]="{ exact: true }"
            routerLinkActive="selectedGroup me-1"
            (click)="selectGroup('dashboard')"
            style="color: #ffffff"
        >
            <img
                src="../../../../../assets/images/Dashboard.png"
                class="img-fluid"
            /><br /><small
                ><b i18n style="font-size: 14px" i18n>Dashboard</b></small
            >
        </div>

        <div
            role="button"
            class="text-center mt-3 p-1"
            routerLink="/w/workstation/intranet"
            routerLinkActive="selectedGroup me-1"
            (click)="selectGroup('intranet')"
            style="color: #ffffff"
        >
            <img
                src="../../../../../assets/images/Intranet.png"
                class="img-fluid"
            /><br /><small><b style="font-size: 14px" i18n>Intranet</b></small>
        </div>
        <div
            role="button"
            class="text-center mt-3 p-1"
            routerLink="/w/workstation/desk"
            routerLinkActive="selectedGroup me-1"
            (click)="selectGroup('desk')"
            style="color: #ffffff"
        >
            <img
                src="../../../../../assets/images/Desk.png"
                class="img-fluid"
            /><br /><small><b style="font-size: 14px" i18n>Desk</b></small>
        </div>
        <div
            role="button"
            class="text-center mt-3 p-1"
            routerLink="/w/workstation/reporting"
            routerLinkActive="selectedGroup me-1"
            (click)="selectGroup('reporting')"
            style="color: #ffffff"
        >
            <img
                src="../../../../../assets/images/Reporting.png"
                class="img-fluid"
            /><br /><small><b style="font-size: 14px" i18n>Reporting</b></small>
        </div>
    </div>
</div>
