<div *ngIf="!bankNote" class="d-flex justify-content-center align-items-center">
    <div class="spinner-border text-center" role="status"></div>
</div>
<div class="row" *ngIf="bankNote" [ngClass]="{ 'text-dark': isDark }">
    <div class="col ps-2">
        <div class="row ps-1" [formGroup]="detailsForm">
            <div class="col-8" *ngIf="!boxTransfer">
                <span i18n><strong>Amount</strong></span
                ><br />
                <div>
                    <app-amount-words
                        (amountEvent)="inputAmount($event)"
                    ></app-amount-words>
                </div>
            </div>
            <!-- <div class="col-5 ms-5">
                <span i18n><strong>Description</strong></span
                ><br />
                <div class="form-floating">
                    <input
                        formControlName="description"
                        type="text"
                        class="form-control input rounded ps-2 field"
                        [ngClass]="{
                            ' background': operation === 'treasure'
                        }"
                        style="border: none"
                        placeholder="Description"
                        (input)="inputDetails()"
                    />
                    <label class="fs-6 text-dark" i18n
                        ><small> Description</small> <code>*</code></label
                    >
                </div>
            </div> -->
        </div>
        <!-- *ngIf="detailsForm.value.amount" -->
        <div>
            <div class="row mt-4 ps-1">
                <span i18n><strong>Ticketing</strong></span>
            </div>
            <div class="row mt-4 ps-3">
                <table class="table-borderless" style="background: none">
                    <thead>
                        <tr>
                            <th
                                class="line"
                                i18n
                                style="color: darkgray; width: 41px"
                            >
                                Note bill
                            </th>
                            <th
                                class="line"
                                i18n
                                style="color: darkgray; width: 28px"
                            ></th>
                            <th
                                class="line"
                                i18n
                                style="color: darkgray; width: 75px"
                            >
                                N⁰ of note
                            </th>
                            <th
                                class="line"
                                i18n
                                style="color: darkgray; width: 30px"
                            ></th>
                            <th class="line" i18n style="color: darkgray">
                                Amount
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let note of bankNote; let i = index"
                            [formGroup]="numberForm"
                        >
                            <th>{{ note.bank_note_value | number }}</th>
                            <td class="text-center">X</td>
                            <td>
                                <input
                                    formControlName="{{ note.bank_note_value }}"
                                    class="form-control form-control-sm field ps-3 mt-1 field"
                                    [ngClass]="{
                                        ' background': operation === 'treasure'
                                    }"
                                    placeholder=""
                                    style="
                                        width: 73px;
                                        border: none;
                                        height: 20px;
                                        border-radius: 10px;
                                        color: #2070b8 !important;
                                    "
                                    (input)="
                                        getNumber(
                                            numberForm.value[
                                                note.bank_note_value
                                            ]
                                        )
                                    "
                                    (input)="
                                        getNoteMovement(
                                            note.id,
                                            numberForm.value[
                                                note.bank_note_value
                                            ]
                                        )
                                    "
                                    (input)="
                                        calculateTotalAmount(
                                            note.bank_note_value,
                                            numberForm.value[
                                                note.bank_note_value
                                            ]
                                        )
                                    "
                                />
                            </td>
                            <th class="text-center">=</th>
                            <th class="ps-3">
                                {{
                                    note.bank_note_value *
                                        numberForm.value[note.bank_note_value]
                                        | number
                                }}
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row mt-4 ps-1">
                <div class="col-12 m-0">
                    <span i18n><strong>Total: </strong></span>
                    <span
                        class="ms-2 btn field text-dark"
                        style="
                            width: 170px;
                            border: 1px solid black;
                            border-radius: 15px;
                            height: 30px;
                        "
                        >{{ generalTotal | number }}</span
                    >
                    <span
                        *ngIf="amountWritten == generalTotal"
                        class="text-success ms-2"
                        ><strong i18n
                            ><i class="fa-solid fa-circle-check"></i> The
                            amounts are equal</strong
                        ></span
                    >
                </div>
            </div>
            <div class="row mt-4 ps-1" *ngIf="!boxTransfer">
                <!-- <div class="d-flex justify-content-between">
                    <span i18n class=""><strong>Done By</strong></span>
                    <span i18n class=""><strong>Telephone Number</strong></span>
                </div> -->
                <form [formGroup]="detailsForm" class="form-floating">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div>
                                <span class=""
                                    ><strong i18n>Done By</strong></span
                                >
                            </div>
                            <input
                                type="text"
                                formControlName="mandator"
                                (input)="inputDetails()"
                                class="form-control text-dark me-3 rounded field"
                                [ngClass]="{
                                    ' background': operation === 'treasure'
                                }"
                                placeholder=""
                                style="height: 52px; width: 200px; border: none"
                            />
                        </div>
                        <div *ngIf="phoneRequired">
                            <div>
                                <span class=""
                                    ><strong i18n
                                        >Telephone Number</strong
                                    ></span
                                >
                            </div>
                            <input
                                type="text"
                                formControlName="mandator_phone_number"
                                (input)="inputDetails()"
                                class="form-control text-dark rounded field"
                                [ngClass]="{
                                    ' background': operation === 'treasure'
                                }"
                                placeholder=""
                                style="height: 52px; width: 200px; border: none"
                            />
                        </div>
                    </div>
                    <div class="col-6 mt-3">
                        <button
                            *ngIf="confirmation && summary"
                            (click)="getAllDetails()"
                            [disabled]="!detailsForm.valid"
                            class="btn bg-primary float-end"
                            style="width: 115px; color: white"
                        >
                            <strong i18n>Generate</strong>
                        </button>
                    </div>
                </form>
            </div>

            <div *ngIf="!summary" class="row mt-4">
                <div>
                    <table
                        class="text-center"
                        style="
                            border-radius: 20px;
                            background-color: #ffffff;
                            color: black;
                            width: 100%;
                            height: 110px;
                        "
                    >
                        <thead class="mx-2">
                            <tr>
                                <th i18n scope="col" style="width: 220px">
                                    Account
                                </th>
                                <th
                                    i18n
                                    scope="col"
                                    style="width: 150px"
                                    *ngIf="description"
                                >
                                    Description
                                </th>
                                <th i18n scope="col" style="width: 250px">
                                    Type of Operation(D/C)
                                </th>
                                <th i18n scope="col" style="width: 150px">
                                    Amount
                                </th>
                            </tr>
                        </thead>
                        <tbody class="mx-4">
                            <tr>
                                <th>{{ clientId }}</th>
                                <td *ngIf="description">
                                    {{ description }}
                                </td>
                                <td i18n *ngIf="operation === 'deposit'">
                                    Deposit
                                </td>
                                <td i18n *ngIf="operation === 'withdrawal'">
                                    Withdrawal
                                </td>
                                <td>{{ amountWritten | number }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- <div *ngIf="!summary">
                <div class="row d-flex justify-content-center mt-3">
                    <div class="col-auto">
                        <span>
                            <button
                                i18n
                                class="btn bg-primary"
                                (click)="confirmOperation()"
                                style="color: white"
                                type="submit"
                            >
                                <span><strong>Confirm</strong> </span>
                            </button>
                        </span>
                    </div>
                    <div class="col-auto mt-2">
                        <span
                            (click)="closeSummary()"
                            i18n
                            class="text-primary"
                            style="
                                text-decoration: underline;
                                cursor: pointer;
                            "
                            ><strong> Cancel</strong></span
                        >
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>
