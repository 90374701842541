import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { PaginationConfig } from '../models';

@Injectable({
    providedIn: 'root',
})
export class SavingDetailService {
    constructor(private apiService: ApiService) {}
    //club details(saving datails)
    getSavingData(tontineId: string) {
        const url = '/tontines/manage/' + tontineId;

        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
    // detail admin(saving admindetail)
    getTontineDetailsAdmin(tontineId: string) {
        const url = '/tontines/details/admin/?tontine_pk=' + tontineId;
        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
    // datail membre dans un  club ( saving admin detail)
    getTontineMembersAdmin(data: any) {
        const url = `/tontines/membership/manage/?tontine=${data.tontineId}&limit=${data.limit}&offset=${data.offset}`;
        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getTontineMembersAdmins(
        data: any,
        search = '',
        pagination: PaginationConfig
    ) {
        const url = `/tontines/membership/manage/?tontine=${data.tontineId}&search=${search}&limit=${pagination.filters.limit}&offset=${pagination.filters.offset}`;
        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
    getallMembers(tontineId: string) {
        const url = '/tontines/membership/manage/?tontine=' + tontineId;
        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
    // detail d un mrmbrew (saving detail)
    getMembershipDetails(data: any) {
        const url = `/tontine/membership/details/?tontine_pk=${data.tontineId}&client_id=${data.clientId}`;

        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
    //list des contributions (contribution list)

    getContributionFeesList() {
        const url = '/tontines/fees/manage/';

        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
    // contribution list ( saving detail yea config)

    getfees(data: any) {
        const url = `/tontines/fees/manage/?membership=${data.membership}&fees_config__year_config=${data.fees_config__year_config}`;

        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    //years config list(saing detail)

    getYearList(data: any) {
        const url = `/tontines/year-config/?tontine=${data.tontine}`;
        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getContributionList(data: any) {
        const url = `/tontine/fees/list/?membership_id=${data.membership_id}`;

        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    // demende d adhesion (adhesion)
    postMembershipRequest(body: any) {
        const url = '/tontine/membership/creation/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }
    //creat meeting
    postCreatMeeting(body: any) {
        const url = '/tontine/meeting/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }
    //start meeting
    postStartMeeting(body: any) {
        const url = '/tontine/start/meetings/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }
    //edn meeting
    postEndMeeting(body: any) {
        const url = '/tontine/end/meetings/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }

    //cancle meeting
    postCancelMeeting(body: any) {
        const url = '/tontine/cancel/meetings/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }

    //list of meeting
    getMettings(tontine: string) {
        const url = '/tontine/meeting/?tontine=' + tontine;
        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    searchMettings(data: any) {
        const url = `/tontine/meeting/?tontine=${data.tontine}&search=${data.search}`;
        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getdetailMeeting(data: any) {
        const url = `/tontine/meeting/${data.meeting_id}/?tontine=${data.tontine}`;
        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    //presence in the meeting

    postmeetingparticipant(body: any) {
        const url = '/meetings/add/participant/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }

    acceptAdhesion(body: any) {
        const url = '/tontine/membership/changestatus/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }

    getMembersDetail(membershipId: string) {
        const url = '/tontines/membership/manage/' + membershipId;

        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getmeetingparticipant(data: any) {
        const url = `/tontines/meeting/participant/?meeting__tontine=${data.meeting__tontine}&meeting=${data.meeting}`;
        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getPenaltiesAdmin() {
        const url = '/tontine/penalties/admin/';

        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getTermDepositList() {
        const url = '/term-deposit/manage/';

        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getTontineMembers(tontine_id: string) {
        const url =
            '/tontine/membership/list/?active_only=true&tontine_pk=' +
            tontine_id;
        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getRequestlist(tontine_id: string) {
        const url =
            '/tontine/membership/request/list/?tontine_pk=' + tontine_id;

        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }
    //requst for member (saving dat)
    getMemberRequestlist(data: any) {
        const url = `/tontine/membership/all/request/?tontine=${data.tontine}&reference_member=${data.reference_member}&status=${data.status}`;

        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }

    addTermDeposit(body: any) {
        const url = '/term-deposit/manage/';

        return this.apiService.post(url, body).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getSuggestedTontines() {
        const url = '/tontines/client/list/?not_registered=true';

        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getClientTontines() {
        const url = '/tontines/list/?registered=true';

        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getTermDepositDetails(depositId: string) {
        const url = '/term-deposit/manage/';

        return this.apiService.get(url + depositId).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getTermDepositPeriod() {
        const url = '/term-deposit/period/';

        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
}
