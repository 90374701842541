import { Injectable } from '@angular/core';

import { map, retry } from 'rxjs/operators';

import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class CreditService {
    apiUrl: any;

    constructor(private apiService: ApiService) {
        //comment
    }
    getCreditsList() {
        const apiUrl = '/loans/manage/';
        return this.apiService.get(apiUrl).pipe(map((data) => data));
    }
    getCreditDetails(creditId: string | number) {
        const apiUrl = '/loans/manage/' + creditId + '/';
        return this.apiService.get(apiUrl).pipe(map((data) => data));
    }

    getCreditLinesList() {
        const apiUrl = '/clients/manage/creditline/';
        return this.apiService.get(apiUrl).pipe(map((data) => data));
    }

    getCreditLinesDetails(lineId: string | number) {
        const apiUrl = '/clients/manage/creditline/' + lineId + '/';
        return this.apiService.get(apiUrl).pipe(map((data) => data));
    }
    getLoanPlan(creditId: string | number) {
        const apiUrl = '/loans/plan/' + creditId + '/';
        return this.apiService.get(apiUrl).pipe(map((data) => data));
    }

    creditRequest(body: any) {
        const url = '/loans/request/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }
    approveOrCancelCreditLine(body: any) {
        const url = '/clients/manage/creditline/status/change/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }
    creditLine(body: any) {
        const url = '/clients/manage/creditline/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }
}
