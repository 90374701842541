<div
    class="category-container"
    [ngClass]="
        theme === 'dark-mode' || theme === 'magis-dark'
            ? 'bg-dark-mode'
            : 'bg-light-mode'
    "
>
    <div class="modal-body lg-radius m-0 p-0">
        <div>
            <div
                class="ms-auto text-end"
                [ngClass]="
                    theme === 'dark-mode' || theme === 'magis-dark'
                        ? 'bg-dark-mode'
                        : 'bg-light-mode'
                "
            >
                <button
                    type="button"
                    title="Close"
                    #closeModal
                    class="text-dark btn fs-4 p-0 pe-1"
                    (click)="close()"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                >
                    <i class="fa-solid fa-xmark"></i>
                </button>
            </div>
            <!-- <div class="d-flex"> -->
            <!-- steps -->

            <div *ngIf="selectedMerchant" class="stepper-wrapper">
                <div
                    class="stepper-item active"
                    *ngIf="selectedSection === 'second'"
                >
                    <div
                        [ngStyle]="{
                            color:
                                selectedSection === 'second'
                                    ? ''
                                    : 'black !important'
                        }"
                        class="step-counter"
                        [ngClass]=""
                        i18n
                    >
                        1
                    </div>
                </div>
                <div
                    class="stepper-item completed"
                    *ngIf="selectedSection === 'first'"
                >
                    <div class="step-counter bg-primary" i18n>1</div>
                </div>
                <div
                    class="stepper-item active"
                    *ngIf="selectedSection === 'first'"
                >
                    <div
                        [ngStyle]="{
                            color:
                                selectedSection === 'first'
                                    ? ''
                                    : 'black !important'
                        }"
                        class="step-counter"
                        i18n
                    >
                        2
                    </div>
                </div>
                <div
                    class="stepper-item completed"
                    *ngIf="selectedSection === 'second'"
                >
                    <div class="step-counter bg-primary" i18n>2</div>
                </div>
            </div>
            <!-- </div> -->
            <div
                class="row m-0 d-flex h-100"
                [ngClass]="{
                    'transition-content':
                        return_lookup_data &&
                        !return_lookup_data.lookup_next_step,
                    'justify-content-center': selectedSection === 'second'
                }"
            >
                <div
                    class="col-lg-5 p-3"
                    *ngIf="selectedSection === 'first'"
                    [ngClass]="
                        theme === 'dark-mode' || theme === 'magis-dark'
                            ? 'bg-dark-mode'
                            : 'bg-light-mode'
                    "
                >
                    <!-- This section is for header info -->
                    <div class="d-flex">
                        <b style="font-size: 25px" class="text-dark"
                            >Pay a Merchant
                        </b>
                        <div class="pt-2 ms-auto">
                            <!-- <div class="offline-dot"></div> -->
                            <span type="button" class="mt-2">
                                <b class="text-dark">
                                    <span>
                                        <i class="fa-solid fa-arrow-left"></i>
                                    </span>
                                    <span
                                        *ngIf="
                                            (!selectedMerchant &&
                                                this.closeFactor ===
                                                    'category') ||
                                            (!selectedProduct &&
                                                this.closeFactor ===
                                                    'biller') ||
                                            (!productArticle &&
                                                this.closeFactor === 'product')
                                        "
                                        data-bs-dismiss="modal"
                                    >
                                        Back
                                    </span>
                                    <span
                                        (click)="goBack()"
                                        *ngIf="
                                            (selectedMerchant &&
                                                this.closeFactor ===
                                                    'category') ||
                                            (selectedProduct &&
                                                this.closeFactor ===
                                                    'biller') ||
                                            (productArticle &&
                                                this.closeFactor === 'product')
                                        "
                                    >
                                        Back
                                    </span>
                                </b>
                            </span>
                        </div>
                    </div>

                    <div
                        class="d-flex"
                        *ngIf="
                            category &&
                            category.sector &&
                            category.category &&
                            !product
                        "
                    >
                        <div class="w-60 mt-3">
                            <div>
                                <b class="">
                                    <span
                                        style="
                                            color: rgb(129, 0, 25);
                                            font-size: 20px;
                                        "
                                    >
                                        <i class="fa-solid fa-play"></i>
                                    </span>
                                    <span class="fs-5 ms-2"
                                        ><b class="">
                                            {{ category.sector.title }}</b
                                        ></span
                                    >
                                </b>
                            </div>
                        </div>

                        <div
                            class="ms-5 mt-3 bg-white sm-radius"
                            style="height: 2%"
                        >
                            <b class="ms-2">
                                <span
                                    class="text-center text-truncate p-1 pt-1"
                                    >{{ category.category.name }}</span
                                >
                                <span
                                    class="float-end text-center pt-1 ms-3"
                                    style="
                                        width: 30px;
                                        background-color: rgb(129, 0, 25);
                                        color: white;
                                        border-top-right-radius: 5px;
                                        border-bottom-right-radius: 5px;
                                    "
                                >
                                    <i class="fa-solid fa-building-columns"></i>
                                </span>
                            </b>
                        </div>
                    </div>

                    <!-- END OF HEADER SECTION     -->

                    <!-- This section if for print merchants by selected Category -->

                    <section *ngIf="!selectedMerchant">
                        <div class="mt-5 row ms-3" *ngIf="merchants">
                            <div
                                *ngFor="let merchant of merchants.objects"
                                class="col-lg-3 col-md-3 col mb-2 align-items-center"
                            >
                                <div class="row justify-content-center">
                                    <p
                                        style="
                                            width: 100px;
                                            height: 100px;
                                            border-radius: 5px;
                                        "
                                        role="button"
                                        class="mb-2 text-center level-3"
                                        (click)="selectMerchant(merchant)"
                                    >
                                        <span class="center1 p-1">
                                            <b class="mt-4">{{
                                                merchant.merchant_title
                                            }}</b>
                                        </span>
                                    </p>
                                    <p class="text-center text-truncate">
                                        <b
                                            role="button"
                                            (click)="selectMerchant(merchant)"
                                            class="text-dark"
                                            >{{ merchant.merchant_title }}</b
                                        >
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="mt-5 row ms-3" *ngIf="!merchants">
                            <div
                                class="col-lg-3 col-md-3 col-sm-6 col me-3"
                                *ngFor="let _ of [1, 2, 3]"
                            >
                                <ngx-skeleton-loader
                                    [theme]="{
                                        'height.px': 100,
                                        'width.px': 100,
                                        'margin-bottom': 0
                                    }"
                                ></ngx-skeleton-loader>
                                <div>
                                    <ngx-skeleton-loader
                                        [theme]="{
                                                'height.px': 10,
                                                'width.px':60,
                                            }"
                                        appearance="line"
                                    ></ngx-skeleton-loader>
                                </div>
                            </div>
                        </div>
                    </section>

                    <!-- END OF MERCHANT SECTION -->

                    <!-- This section is for Products by selected Merchant  -->

                    <section [ngClass]="selectedMerchant ? 'mt-5' : ''">
                        <div class="d-flex">
                            <div
                                style="
                                    width: 246px;
                                    height: 80px;
                                    border-radius: 10px;
                                "
                                class="d-flex level-3 justify-content-around text-center p-3"
                                *ngIf="selectedMerchant"
                            >
                                <div
                                    class="col-3"
                                    style="
                                        width: 60px;
                                        height: 60px;
                                        border-radius: 50%;
                                        background-color: white;
                                        position: relative;
                                        top: -6px;
                                    "
                                >
                                    <!-- merchant_logo -->
                                    <span>
                                        <img
                                            [src]="
                                                selectedMerchant.merchant_logo
                                            "
                                            *ngIf="
                                                selectedMerchant.merchant_logo
                                            "
                                            style="
                                                width: 86%;
                                                position: relative;
                                                top: 18px;
                                            "
                                        />
                                        <img
                                            [src]="biller.lookup_image"
                                            *ngIf="biller.lookup_image"
                                            style="
                                                width: 86%;
                                                position: relative;
                                                top: 18px;
                                            "
                                        />
                                    </span>
                                    <span
                                        *ngIf="
                                            !selectedMerchant.merchant_logo &&
                                            !biller.lookup_image
                                        "
                                    >
                                        <span
                                            class="d-flex align-items-center"
                                            style="
                                                position: relative;
                                                left: 8px;
                                                top: 11px;
                                            "
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="40"
                                                height="38"
                                                viewBox="0 0 78 57"
                                                fill="none"
                                            >
                                                <g opacity="0.5">
                                                    <path
                                                        d="M22.3651 21.1809C23.8852 20.0711 23.6046 17.9695 23.0124 16.3773C22.7772 15.7453 23.2145 15 23.8889 15H27.6549C29.5023 15 31 16.4977 31 18.3451C31 19.9813 29.8173 21.3842 28.1953 21.5983C24.6485 22.0665 20.7817 22.337 22.3651 21.1809Z"
                                                        fill="#D9D9D9"
                                                    />
                                                    <path
                                                        d="M63.5 41C62.0787 40.1472 62.2566 38.5706 62.7776 37.3109C63.0176 36.7307 62.6278 36 62 36H57.6696C56.1952 36 55 37.1953 55 38.6697C55 40.0047 55.9858 41.1379 57.3123 41.2879C60.9147 41.6954 65.1257 41.9755 63.5 41Z"
                                                        fill="#D9D9D9"
                                                    />
                                                    <rect
                                                        x="15"
                                                        width="56"
                                                        height="16"
                                                        rx="8"
                                                        fill="#D9D9D9"
                                                    />
                                                    <rect
                                                        x="12"
                                                        y="41"
                                                        width="66"
                                                        height="16"
                                                        rx="8"
                                                        fill="#D9D9D9"
                                                    />
                                                    <rect
                                                        y="21"
                                                        width="74"
                                                        height="16"
                                                        rx="8"
                                                        fill="#D9D9D9"
                                                    />
                                                </g>
                                            </svg>
                                        </span>
                                        <span
                                            style="
                                                font-size: 25px;
                                                opacity: 0.5;
                                                position: relative;
                                                top: -21px;
                                                left: -3px;
                                            "
                                        >
                                            <i
                                                class="fa-solid fa-cart-shopping"
                                            ></i>
                                        </span>
                                    </span>
                                </div>
                                <div class="col-6">
                                    <div
                                        class="d-flex justify-content-center align-items-center p-0 m-0"
                                    >
                                        <b
                                            *ngIf="
                                                selectedMerchant.merchant_title
                                            "
                                            class="text-truncate"
                                            title="{{
                                                selectedMerchant.merchant_title
                                            }}"
                                            style="
                                                font-size: 18px;
                                                cursor: pointer;
                                            "
                                            >{{
                                                selectedMerchant.merchant_title
                                            }}</b
                                        >
                                        <b
                                            *ngIf="biller.lookup_title"
                                            class="text-truncate"
                                            style="
                                                font-size: 18px;
                                                cursor: pointer;
                                            "
                                            title="{{ biller.lookup_title }}"
                                            >{{ biller.lookup_title }}</b
                                        >
                                    </div>
                                    <div
                                        class="d-flex justify-content-center align-items-center p-0 m-0"
                                    >
                                        <span
                                            *ngIf="
                                                selectedMerchant.merchant_code
                                            "
                                        >
                                            {{ selectedMerchant.merchant_code }}
                                        </span>
                                        <span *ngIf="biller.lookup_subtitle">
                                            {{ biller.lookup_subtitle }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div
                                *ngIf="selectedProduct"
                                class="mx-2 fs-4 d-flex align-items-center"
                            >
                                <i class="fa-solid fa-right-long"></i>
                            </div>
                            <!-- SELECTED PRODUCT NEW EMPLACEMENT -->
                            <div *ngIf="selectedProduct">
                                <div
                                    style="width: 140px; height: 77px"
                                    class="d-flex align-items-center justify-content-center level-3 lg-radius"
                                    role="button"
                                >
                                    <b style="font-size: 18px" class="p-1">{{
                                        selectedProduct.name
                                    }}</b>
                                </div>
                            </div>
                        </div>

                        <div
                            class="ms-3 mt-2"
                            *ngIf="selectedMerchant && !selectedProduct"
                        >
                            <b style="font-size: 18px" class="text-dark"
                                >Product</b
                            ><br />

                            <div
                                *ngIf="products"
                                class="d-flex"
                                style="flex-wrap: wrap"
                            >
                                <div
                                    style="width: 140px; height: 90px"
                                    class="mt-2 level-3 d-flex align-items-center justify-content-center ms-4 lg-radius"
                                    *ngFor="let product of products.objects"
                                    role="button"
                                    (click)="selectProduct(product)"
                                    [ngClass]="{
                                        'border border-bold':
                                            selectedProduct === product
                                    }"
                                >
                                    <span class="text-center">
                                        <b
                                            style="font-size: 18px"
                                            class="p-1"
                                            >{{ product.name }}</b
                                        >
                                    </span>
                                </div>
                            </div>

                            <!-- PRODUCT SELECTED -->

                            <div class="row ms-2" *ngIf="isProductLoading">
                                <div
                                    class="col-lg-3 col-md-3 col-sm-2 col me-3"
                                    *ngFor="let _ of [1, 2, 3]"
                                >
                                    <ngx-skeleton-loader
                                        [theme]="{
                                            'height.px': 59,
                                            'width.px': 130,
                                            'margin-bottom': 0
                                        }"
                                    ></ngx-skeleton-loader>
                                    <div>
                                        <ngx-skeleton-loader
                                            [theme]="{
                                                    'height.px': 10,
                                                    'width.px': 80,
                                                }"
                                            appearance="line"
                                        ></ngx-skeleton-loader>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <!-- END OF PRODUCT SECTION     -->

                    <!-- This section is for Loookup Merchant -->

                    <!-- This is the form for product with lookup_metadata -->

                    <div
                        *ngIf="selectedProduct && selectedProduct.price"
                        class="ps-4 mt-2"
                    >
                        <b i18n>Price: </b
                        ><span>{{ selectedProduct.price | number }} BIF</span>
                    </div>
                    <form
                        [formGroup]="lookupMerchantForm"
                        (ngSubmit)="getMerchantLookup()"
                        *ngIf="
                            !productArticle &&
                            selectedProduct &&
                            selectedProduct.lookup_metadata.length > 0
                        "
                        class="mt-3 text-dark"
                    >
                        <div
                            class="row p-1"
                            *ngFor="
                                let field of selectedProduct.lookup_metadata
                            "
                        >
                            <div class="col">
                                <div class="">
                                    <div
                                        class="form-floating"
                                        *ngIf="
                                            field.meta_type !== 'ChoiceField'
                                        "
                                    >
                                        <input
                                            type="{{ field.field_type }}"
                                            class="form-control"
                                            placeholder="{{ field.name }}"
                                            formControlName="{{
                                                field.field_name
                                            }}"
                                            style="width: 258px"
                                        />
                                        <label
                                            for="{{ field.name }}"
                                            class="form-label"
                                            >{{ field.name }}</label
                                        >

                                        <button
                                            *ngIf="
                                                selectedProduct.lookup_metadata
                                                    .length === 1
                                            "
                                            type="submit"
                                            class="btn"
                                            style="
                                                border: none;
                                                background: none;
                                                width: 5%;
                                                margin-top: -70px;
                                                margin-left: 218px;
                                            "
                                        >
                                            <span *ngIf="isLoading"
                                                ><i
                                                    class="fa-solid fa-spin fa-circle-notch"
                                                ></i
                                            ></span>
                                            <span *ngIf="!isLoading"
                                                ><i
                                                    class="fa-solid is-size-3 fa-magnifying-glass has-text-grey text-dark"
                                                ></i
                                            ></span>
                                        </button>
                                    </div>

                                    <div
                                        class="form-floating"
                                        *ngIf="
                                            field.meta_type === 'ChoiceField'
                                        "
                                    >
                                        <select
                                            class="form-select text-dark"
                                            formControlName="{{
                                                field.field_name
                                            }}"
                                            id="input3"
                                            name="input3"
                                            style="
                                                width: 258px;
                                                cursor: pointer;
                                                border: none;
                                            "
                                        >
                                            <option
                                                *ngFor="
                                                    let choice of field
                                                        .widget_attrs.values
                                                        | keyvalue
                                                "
                                                value="{{ choice.key }}"
                                            >
                                                {{ choice.value }}
                                            </option>
                                        </select>
                                        <label
                                            for="floatingSelect"
                                            class="text-dark"
                                            >{{ field.name }}</label
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="p-1" style="width: 280px">
                            <div class="text-center">
                                <button
                                    class="btn btn-primary is-small is-rounded searchButtonWidth"
                                    type="submit"
                                    *ngIf="
                                        selectedProduct.lookup_metadata.length >
                                        1
                                    "
                                    i18n
                                >
                                    <span *ngIf="!isLoading">Search</span>
                                    <span *ngIf="isLoading"
                                        ><i
                                            class="fa-solid fa-pulse fa-circle-notch fs-4"
                                        ></i
                                    ></span>
                                </button>
                            </div>
                        </div>
                    </form>

                    <!-- END OF LOOKUP SECTION     -->

                    <!-- This section is for printing returned data from lookup -->

                    <div class="row mx-auto">
                        <div
                            class="col mt-3"
                            *ngIf="
                                productArticle ||
                                bundleSelected ||
                                (selectedProduct &&
                                    selectedProduct.lookup_first)
                            "
                        >
                            <div
                                class="float-start bg-light rounded p-2 text-dark"
                            >
                                <div
                                    class="mt-3"
                                    [innerHtml]="productArticle"
                                    *ngIf="type !== 'object'"
                                ></div>
                                <div
                                    *ngIf="
                                        type === 'object' &&
                                        !selectedProduct.lookup_first
                                    "
                                >
                                    <b
                                        >Account :
                                        {{ productArticle.account }}
                                    </b>
                                </div>
                                <p *ngIf="bundleSelected">
                                    <b>Title :</b>
                                    <span> {{ bundleSelected.title }} </span>
                                    <br />
                                    <b>Description :</b>
                                    <span>
                                        {{ bundleSelected.description }}
                                    </span>
                                </p>
                            </div>
                            <div>
                                <span
                                    style="margin-left: -19px"
                                    role="button"
                                    (click)="exit()"
                                >
                                    <i class="fa-solid fa-circle-xmark"></i
                                ></span>
                            </div>
                        </div>

                        <!-- WORKING ON MULTIPLE PAYMENT HAVE TO UNCOMMENT THE DIV BELOW -->

                        <div
                            *ngIf="
                                (selectedProduct &&
                                    !selectedProduct.lookup_first &&
                                    selectedProduct.lookup_metadata.length ==
                                        0) ||
                                bundleSelected ||
                                productArticle
                            "
                        >
                            <div
                                class="text-dark p-1"
                                *ngIf="
                                    selectedProduct.minimun_payment_amount >
                                        0 &&
                                    selectedProduct.maximum_payment_amount > 0
                                "
                            >
                                <span i18n> Acceptable amount is from </span>
                                <b>{{
                                    selectedProduct.minimun_payment_amount
                                        | number
                                }}</b>
                                <span i18n> to </span>
                                <b>{{
                                    selectedProduct.maximum_payment_amount
                                        | number
                                }}</b>
                            </div>
                            <div>
                                <form [formGroup]="productPaymentForm">
                                    <div
                                        class="bg-grey-lighter p-2 is-rounded-small"
                                    >
                                        <div
                                            formGroupName="payment_data"
                                            *ngIf="fieldsLoop"
                                            class="mb-2"
                                        >
                                            <div
                                                *ngFor="let field of fieldsLoop"
                                            >
                                                <div
                                                    class="form-floating"
                                                    *ngIf="
                                                        field.meta_type !==
                                                        'ChoiceField'
                                                    "
                                                >
                                                    <input
                                                        [type]="field.type"
                                                        class="form-control mt-2 is-rounded has-background-white"
                                                        placeholder="{{
                                                            field.placeholder
                                                        }}"
                                                        formControlName="{{
                                                            field.name
                                                        }}"
                                                    />
                                                    <label
                                                        for="{{
                                                            field.placeholder
                                                        }}"
                                                        class="form-label"
                                                        >{{
                                                            field.placeholder
                                                        }}</label
                                                    >
                                                </div>
                                                <div
                                                    class="form-floating"
                                                    *ngIf="
                                                        field.meta_type ===
                                                        'ChoiceField'
                                                    "
                                                >
                                                    <select
                                                        class="form-select text-dark mt-2"
                                                        formControlName="{{
                                                            field.name
                                                        }}"
                                                        id="choice"
                                                        name="choice"
                                                        style="
                                                            width: 258px;
                                                            cursor: pointer;
                                                            border: none;
                                                        "
                                                    >
                                                        <option
                                                            *ngFor="
                                                                let choice of field.choice_field
                                                                    | keyvalue
                                                            "
                                                            value="{{
                                                                choice.key
                                                            }}"
                                                        >
                                                            {{ choice.value }}
                                                        </option>
                                                    </select>
                                                    <label
                                                        for="floatingSelect"
                                                        class="text-dark"
                                                        >{{ field.name }}</label
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="text-center" *ngIf="showDebitSection()">
                            <button
                                (click)="
                                    addProductToPayment(
                                        productArticle,
                                        bundleSelected
                                    )
                                "
                                *ngIf="selectedProduct.accepts_multiple_payment"
                                style="color: #ffffff"
                                class="mt-2 btn bg-primary"
                            >
                                <b i18n>Add</b>
                            </button>
                            <button
                                *ngIf="
                                    !selectedProduct.accepts_multiple_payment
                                "
                                style="color: #ffffff"
                                class="mt-2 btn bg-primary"
                                (click)="confirmPayment()"
                            >
                                <b i18n>Confirm</b>
                            </button>
                        </div>
                    </div>

                    <!-- END OF RETURNED DATA SECTION  -->

                    <!-- This section is for Bundles -->

                    <div
                        class="mx-auto mt-4 p-2 row overflow-y-auto"
                        style="max-height: 250px"
                    >
                        <div
                            class="text-center"
                            *ngIf="
                                selectedProduct &&
                                selectedProduct.lookup_first &&
                                isLoading
                            "
                        >
                            <div *ngFor="let _ of [1, 2, 3]">
                                <ngx-skeleton-loader
                                    [theme]="{
                                        'height.px': 49,
                                        'width.px': 683,
                                        'margin-bottom': 0
                                    }"
                                ></ngx-skeleton-loader>
                            </div>
                        </div>

                        <div
                            class="col"
                            *ngIf="
                                (choice?.length > 0 &&
                                    productArticle &&
                                    !bundleSelected) ||
                                (selectedProduct &&
                                    selectedProduct.lookup_first &&
                                    !bundleSelected)
                            "
                            style="max-height: 480px; overflow-y: auto"
                        >
                            <div class="rounded" *ngFor="let option of choice">
                                <b class="has-text-centered ml-3 text-dark">
                                    {{ option.label }}
                                </b>
                                <div *ngIf="option.choices.length > 0">
                                    <div
                                        class="rounded-sm mb-2"
                                        style="font-size: 0.7rem"
                                        *ngFor="let choices of option.choices"
                                        [ngClass]="{
                                            rounded:
                                                choices.value ===
                                                    bundleSelected?.value ||
                                                choices.value.amount ===
                                                    bundleSelected?.value.amount
                                        }"
                                        (click)="selectBundle(choices)"
                                        role="button"
                                    >
                                        <div
                                            class="p-2 bg-light rounded-sm text-dark"
                                        >
                                            <span class="text-small"
                                                ><b>{{
                                                    choices.title
                                                }}</b></span
                                            ><br />
                                            <span class="text-small">{{
                                                choices.description
                                            }}</span
                                            ><br />
                                            <span
                                                class="text-small"
                                                *ngIf="choices.price"
                                                ><b>{{
                                                    choices.price
                                                }}</b></span
                                            ><br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- MULTIPLE PAYMENT -->
                <div
                    class="col-lg bg-light lg-radius ms-3 p-2"
                    *ngIf="
                        productAdding &&
                        selectedSection === 'first' &&
                        allproductsToPay.length > 0
                    "
                    style="min-height: 30vh; width: 388px"
                >
                    <h5><b i18n>Wait list</b></h5>
                    <div
                        class="d-flex justify-content-between shadow-sm bg-light mx-2 mb-2 text-dark lg-radius p-2"
                        style="width: 455px; min-height: 95px"
                        *ngFor="let product of productsToDisplay"
                    >
                        <div class="col d-flex">
                            <div
                                class="bg-primary mb-3 rounded p-0 m-0"
                                style="width: 7px"
                            ></div>
                            <div class="col rounded ps-1 text-dark">
                                <div
                                    class="mt-3"
                                    [innerHtml]="product.article"
                                    *ngIf="type !== 'object' && product.article"
                                ></div>
                                <div
                                    *ngIf="type === 'object' && product.article"
                                >
                                    <b
                                        >Account :
                                        {{ product.article.account }}
                                    </b>
                                </div>
                                <div
                                    *ngIf="
                                        (product.lookup_data.name ||
                                            product.lookup_data.account) &&
                                        !product.article
                                    "
                                >
                                    <span *ngIf="product.lookup_data.name">
                                        <b>Name :</b>
                                        <span
                                            >{{ product.lookup_data.name }}
                                        </span> </span
                                    ><br />
                                    <span *ngIf="product.lookup_data.account">
                                        <b>Account :</b>
                                        <span
                                            >{{ product.lookup_data.account }}
                                        </span>
                                    </span>
                                    <br />
                                </div>
                                <p class="m-0" *ngIf="product.bundle">
                                    <b>Title :</b>
                                    <span> {{ product.bundle.title }} </span>
                                    <br />
                                    <b>Description :</b>
                                    <span>
                                        {{ product.bundle.description }}
                                    </span>
                                </p>
                                <p
                                    class="m-0"
                                    *ngIf="product.lookup_data.amount_data"
                                >
                                    <b>Title :</b>
                                    <span>
                                        {{
                                            product.lookup_data.amount_data
                                                .offer_name
                                        }}
                                    </span>
                                    <br />
                                    <b>Amount :</b>
                                    <span>
                                        {{
                                            product.lookup_data.amount_data
                                                .amount
                                        }}
                                    </span>
                                </p>
                                <p
                                    class="m-0"
                                    *ngIf="product.payment_data.taxPayerName"
                                >
                                    <b i18n>Name :</b>
                                    <span>
                                        {{ product.payment_data.taxPayerName }}
                                    </span>
                                    <br />
                                </p>
                                <p
                                    class="m-0"
                                    *ngIf="product.payment_data.officeCode"
                                >
                                    <b i18n>Office Code :</b>
                                    <span>
                                        {{ product.payment_data.officeCode }}
                                    </span>
                                    <br />
                                </p>
                                <p
                                    class="m-0"
                                    *ngIf="product.payment_data.codeDeclarant"
                                >
                                    <b i18n>Declarant Code :</b>
                                    <span>
                                        {{ product.payment_data.codeDeclarant }}
                                    </span>
                                    <br />
                                </p>
                                <p
                                    class="m-0"
                                    *ngIf="product.payment_data.companyCode"
                                >
                                    <b i18n>NIF Number :</b>
                                    <span>
                                        {{ product.payment_data.companyCode }}
                                    </span>
                                    <br />
                                </p>
                                <p
                                    class="m-0"
                                    *ngIf="product.payment_data.amount"
                                >
                                    <b i18n>Amount :</b>
                                    <span>
                                        {{ product.payment_data.amount }}
                                    </span>
                                    <br />
                                </p>
                            </div>
                        </div>
                        <div class="col-1">
                            <span
                                style="margin-left: 15px"
                                class="text-primary"
                                role="button"
                                (click)="removeProduct(product)"
                            >
                                <i class="fa-solid fa-circle-xmark"></i
                            ></span>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center mt-3">
                        <button
                            class="btn bg-primary"
                            style="color: white"
                            (click)="confirmPayment()"
                        >
                            <b i18n>Confirm</b>
                        </button>
                    </div>
                </div>

                <!-- <app-multiple-payment_and_account-section
                    [multiplePaymentSection]="true"
                ></app-multiple-payment_and_account-section> -->
                <!-- END OF BUNDLES SECTION -->

                <!-- This section if for choosing debit account and completing additional fields -->
                <!-- the real of the div below is *ngIf="paymentConfirming" -->
                <div
                    *ngIf="selectedSection === 'second'"
                    class="fw-bold text-dark"
                >
                    <span type="button" class="float-end">
                        <span>
                            <i class="fa-solid fa-arrow-left"></i>
                        </span>
                        <span (click)="goBack()"> Back </span>
                    </span>
                </div>
                <div
                    class="col-lg-9 m-0 transition p-3 bg-white"
                    *ngIf="selectedSection === 'second'"
                    style="overflow-x: auto"
                >
                    <div class="d-flex justify-content-center">
                        <app-my-accounts
                            (debitOptions)="getDebitOptions($event)"
                            [isGrey]="true"
                        ></app-my-accounts>
                    </div>

                    <div
                        class="p-1 ml-3"
                        *ngIf="
                            selectedProduct &&
                            selectedProduct.metadata &&
                            ((debitAccount && debitAccount.acc_short_number) ||
                                (debitWallet && debitWallet.code))
                        "
                    >
                        <!-- <div class="text-dark p-1">
                            <span i18n> Acceptable amount is from </span>
                            <b>{{
                                selectedProduct.minimun_payment_amount | number
                            }}</b>
                            <span i18n> to </span>
                            <b>{{
                                selectedProduct.maximum_payment_amount | number
                            }}</b>
                        </div>
                        
                        <div>
                            <form
                                [formGroup]="productPaymentForm"
                                (ngSubmit)="enterPin()"
                            >
                                <div
                                    class="bg-grey-lighter p-2 is-rounded-small"
                                >
                                    <div
                                        formGroupName="payment_data"
                                        *ngIf="fieldsLoop"
                                        class="mb-2"
                                    >
                                        <div *ngFor="let field of fieldsLoop">
                                            <div class="form-floating">
                                                <input
                                                    type="input"
                                                    class="form-control mt-2 is-rounded has-background-white"
                                                    placeholder="{{
                                                        field.placeholder
                                                    }}"
                                                    formControlName="{{
                                                        field.name
                                                    }}"
                                                />
                                                <label
                                                    for="{{
                                                        field.placeholder
                                                    }}"
                                                    class="form-label"
                                                    >{{
                                                        field.placeholder
                                                    }}</label
                                                >
                                            </div>
                                        </div>
                                    </div>

                                    <div class="text-center">
                                        <button
                                            class="btn btn-lg btn-primary rounded"
                                            type="submit"
                                            [disabled]="
                                                !productPaymentForm.valid
                                            "
                                        >
                                            <span>Confirm</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div> -->
                        <div class="text-center">
                            <button
                                class="btn btn-lg btn-primary rounded"
                                type="submit"
                                (click)="enterPin()"
                            >
                                <span>Confirm</span>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- <div
                    class="d-flex justify-content-center col-lg-9 m-0 transition p-3 bg-white"
                    *ngIf="selectedSection === 'second'"
                >
                    <app-multiple-payment_and_account-section
                        [debitSection]="true"
                        [selectedPart]="selectedSection"
                        [selectedProduct]="selectedProduct"
                        [selectedMerchant]="selectedMerchant"
                        [biller]="biller"
                        [allproductsToPay]="allproductsToPay"
                    ></app-multiple-payment_and_account-section>
                </div> -->
                <!-- debit section -->

                <!-- END OF SECTION -->
            </div>
        </div>
    </div>
</div>

<!-- <div class="bill-modal" *ngIf="showSuccessMessage">
    <div class="bill-modal-overlay" (click)="showSuccessMessage = false"></div>
    <div class="bill-modal-content" style="width: 351px">
        <app-merchant-bill
            [successMessage]="successMessage"
        ></app-merchant-bill>
    </div>
</div> -->

<!-- <div
                    class="col-lg-9 m-0 transition p-3 bg-white"
                    *ngIf="selectedSection === 'second'"
                    style="overflow-x: auto"
                >
                    <div class="d-flex justify-content-center">
                        <app-my-accounts
                            (debitOptions)="getDebitOptions($event)"
                            [isGrey]="true"
                        ></app-my-accounts>
                    </div>

                    <div
                        class="p-1 ml-3"
                        *ngIf="
                            selectedProduct &&
                            selectedProduct.metadata &&
                            ((debitAccount && debitAccount.acc_short_number) ||
                                (debitWallet && debitWallet.code))
                        "
                    > -->
<!-- <div class="text-dark p-1">
                            <span i18n> Acceptable amount is from </span>
                            <b>{{
                                selectedProduct.minimun_payment_amount | number
                            }}</b>
                            <span i18n> to </span>
                            <b>{{
                                selectedProduct.maximum_payment_amount | number
                            }}</b>
                        </div> -->
<!-- <div>
                            <form
                                [formGroup]="productPaymentForm"
                                (ngSubmit)="enterPin()"
                            >
                                <div
                                    class="bg-grey-lighter p-2 is-rounded-small"
                                >
                                    <div
                                        formGroupName="payment_data"
                                        *ngIf="fieldsLoop"
                                        class="mb-2"
                                    >
                                        <div *ngFor="let field of fieldsLoop">
                                            <div class="form-floating">
                                                <input
                                                    type="input"
                                                    class="form-control mt-2 is-rounded has-background-white"
                                                    placeholder="{{
                                                        field.placeholder
                                                    }}"
                                                    formControlName="{{
                                                        field.name
                                                    }}"
                                                />
                                                <label
                                                    for="{{
                                                        field.placeholder
                                                    }}"
                                                    class="form-label"
                                                    >{{
                                                        field.placeholder
                                                    }}</label
                                                >
                                            </div>
                                        </div>
                                    </div>

                                    <div class="text-center">
                                        <button
                                            class="btn btn-lg btn-primary rounded"
                                            type="submit"
                                            [disabled]="
                                                !productPaymentForm.valid
                                            "
                                        >
                                            <span>Confirm</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div> -->
<!-- <div class="text-center">
                            <button
                                class="btn btn-lg btn-primary rounded"
                                type="submit"
                                (click)="enterPin()"
                            >
                                <span>Confirm</span>
                            </button>
                        </div>
                    </div>
                </div> -->
