import { Injectable, OnInit } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from '@angular/router';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';

import { SwitchState, OpenConfirmDialog } from '../../shared';

@Injectable({
    providedIn: 'root',
})
export class WorkstationGuard implements CanActivate {
    constructor(private store: Store, private router: Router) {}

    canActivate():
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.store.select(SwitchState.GetPlateform).pipe(
            map((plateform) => {
                if (plateform !== 'workStation') {
                    const data = {
                        title: '',
                        type: 'confirm',
                        message:
                            'This url will connect you in corporate .' +
                            '<br/>' +
                            'Are you sure you want to Login as corporate ?',
                        action: 'loginCorporate',
                    };
                    this.store.dispatch(new OpenConfirmDialog(data));
                    return this.router.navigate(['/x']);
                }
                const response: any = true;
                return response;
            })
        );
    }
}
