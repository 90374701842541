<div>
    <div
        class="p-2 height1"
        [ngClass]="{
            'bg-settings-dark': theme === 'magis-dark',
            'bg-settings-light': theme !== 'magis-dark'
        }"
        style="width: 50px; border-radius: 7px; right: 0"
        (click)="showSettingMenu = true"
        *ngIf="!showSettingMenu"
    >
        <button
            class="text-center fs-3 text-light"
            style="background-color: transparent; border: none"
            disabled
        >
            <i class="fa-solid fa-gear"></i>
        </button>
    </div>

    <div
        class="p-2 height1 level-1"
        style="width: 200px; border-radius: 7px; right: 0; position: absolute"
        *ngIf="showSettingMenu"
    >
        <p
            class="fs-6 ms-3 text-primary"
            role="button"
            (click)="showSettingMenu = false"
        >
            <i class="fa-solid fa-gear me-2"></i> <strong i18n>Settings</strong>
        </p>

        <div class="rounded level-2">
            <div
                class="d-flex"
                title="You are connected in {{
                    organization.institution_client.client_full_name
                }} corporate, Click here if you want to switch"
                role="button"
                *ngIf="organization"
                (click)="openModal()"
            >
                <div style="width: 30%; margin: auto">
                    <img
                        [src]="organization.institution_client.picture"
                        class="img-fluid"
                    />
                </div>
                <div class="me-2">
                    <b class="text-dark" style="font-size: 13px">{{
                        organization.institution_client.client_full_name
                    }}</b>
                </div>
            </div>
        </div>

        <div class="p-1 rounded mt-4 h-100 level-2 w-100">
            <div
                class="w-100 d-flex justify-content-between text-dark align-items-center"
                role="button"
            >
                <div (click)="selectSettingMenu('password')">
                    <b
                        style="font-size: 12px; font-weight: 900"
                        class="w-100"
                        i18n
                        >Change Password</b
                    >
                </div>
                <div
                    class="d-flex"
                    *ngIf="selectedMenu === 'password'"
                    (click)="togglePassword()"
                >
                    <span *ngIf="type === 'password'">
                        <i class="fa fa-eye" style="font-size: 12px"></i>
                    </span>
                    <span *ngIf="type === 'text'">
                        <i class="fa fa-eye-slash" style="font-size: 12px"></i>
                    </span>
                </div>

                <div (click)="selectSettingMenu('password')">
                    <span
                        [ngClass]="{ 'd-none': selectedMenu !== 'password' }"
                        *ngIf="selectedMenu === 'password'"
                    >
                        <i class="fa fa-chevron-up"></i>
                    </span>
                    <span
                        [ngClass]="{ 'd-none': selectedMenu === 'password' }"
                        *ngIf="selectedMenu !== 'password'"
                    >
                        <i class="fa fa-chevron-down"></i>
                    </span>
                </div>
            </div>
            <hr
                class="m-0 mt-1 bg-dark text-dark"
                *ngIf="selectedMenu === 'password'"
            />
            <div
                class="h-100 w-100 d-flex justify-content-center"
                *ngIf="selectedMenu === 'password'"
            >
                <form [formGroup]="changePasswordForm" (ngSubmit)="changePin()">
                    <div class="coolinput">
                        <div class="form-floating">
                            <input
                                type="{{ type }}"
                                placeholder="Enter your old password"
                                name="oldPassword"
                                formControlName="oldPassword"
                                class="form-control"
                            />
                            <label for="oldPin" class="text-dark" i18n
                                >Old password</label
                            >
                        </div>
                        <small
                            class="text-danger"
                            *ngIf="
                                changePasswordForm.controls['oldPassword']
                                    .errors?.required &&
                                changePasswordForm.value.newPassword.length !==
                                    0
                            "
                            >Old password is required</small
                        >
                    </div>
                    <div class="coolinput mt-2">
                        <div class="form-floating">
                            <input
                                type="{{ type }}"
                                placeholder="Enter the new password"
                                name="newPassword"
                                class="form-control"
                                formControlName="newPassword"
                            />
                            <label for="newPin" class="text-dark" i18n
                                >New password</label
                            >
                        </div>
                        <small
                            class="text-danger"
                            *ngIf="
                                changePasswordForm.controls['newPassword']
                                    .errors?.required &&
                                changePasswordForm.value.newPassword.length !==
                                    0
                            "
                            >New password is required</small
                        >

                        <small
                            class="text-danger"
                            *ngIf="
                                changePasswordForm.controls['newPassword']
                                    .errors?.pattern
                            "
                        >
                            <span i18n
                                >The password must meet the following
                                criteria:</span
                            ><br />
                            <small i18n>At least one lowercase</small><br />
                            <small i18n>At least one uppercase</small><br />
                            <small i18n>At least one digit</small><br />
                            <small i18n>At least eight characters long</small>
                        </small>
                    </div>

                    <div class="coolinput mt-2">
                        <div class="form-floating">
                            <input
                                type="{{ type }}"
                                placeholder="Repeat the new password"
                                name="newPassword2"
                                class="form-control input border border-dark"
                                formControlName="newPassword2"
                            />
                            <label for="newPassword2" class="text-dark" i18n
                                >Confirm password</label
                            >
                        </div>
                        <small
                            class="text-danger"
                            *ngIf="
                                changePasswordForm.value.newPassword !==
                                    changePasswordForm.value.newPassword2 &&
                                changePasswordForm.value.newPassword2.length !==
                                    0
                            "
                            >Passwords do not match</small
                        >
                    </div>

                    <div class="w-100 d-flex justify-content-center mb-1 mt-2">
                        <button
                            title="Update merchant information"
                            class="btn btn-sm text-white bg-primary is-small is-rounded px-2 fs-6"
                            type="submit"
                            [disabled]="!changePasswordForm.valid"
                        >
                            <small
                                [ngClass]="{ 'd-none': isLoading }"
                                class="fs-6 text-light"
                                i18n
                                >Change</small
                            >
                            <span *ngIf="isLoading" class="">
                                <i
                                    class="fa-solid fs-6 fa-spin fa-circle-notch fs-4 bg-priamry"
                                ></i>
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div class="p-1 rounded mt-4 h-100 level-2 w-100 mb-2">
            <div
                class="w-100 d-flex justify-content-between text-dark align-items-center"
                role="button"
            >
                <div (click)="selectSettingMenu('pin')">
                    <b
                        style="font-size: 12px; font-weight: 900"
                        class="w-100"
                        i18n
                        >Change Pin</b
                    >
                </div>
                <div
                    class="d-flex"
                    style="width: 20px"
                    *ngIf="selectedMenu === 'pin'"
                    (click)="togglePassword()"
                >
                    <span *ngIf="type === 'password'">
                        <i class="fa fa-eye" style="font-size: 12px"></i>
                    </span>
                    <span *ngIf="type === 'text'">
                        <i class="fa fa-eye-slash" style="font-size: 12px"></i>
                    </span>
                </div>

                <div (click)="selectSettingMenu('pin')">
                    <span
                        [ngClass]="{ 'd-none': selectedMenu !== 'pin' }"
                        *ngIf="selectedMenu === 'pin'"
                    >
                        <i class="fa fa-chevron-up"></i>
                    </span>
                    <span
                        [ngClass]="{ 'd-none': selectedMenu === 'pin' }"
                        *ngIf="selectedMenu !== 'pin'"
                    >
                        <i class="fa fa-chevron-down"></i>
                    </span>
                </div>
            </div>
            <hr
                class="m-0 mt-1 bg-dark text-dark"
                *ngIf="selectedMenu === 'pin'"
            />
            <div
                class="h-100 w-100 d-flex justify-content-center"
                *ngIf="selectedMenu === 'pin'"
            >
                <form [formGroup]="changePinForm" (ngSubmit)="changePin()">
                    <div class="coolinput">
                        <div class="form-floating">
                            <input
                                type="{{ type }}"
                                placeholder="Enter the old pin"
                                name="oldPin"
                                formControlName="oldPin"
                                class="form-control input border border-dark"
                                maxlength="4"
                            />
                            <label for="oldPin" class="text-dark" i18n
                                >Old pin</label
                            >
                        </div>
                        <small
                            class="text-danger"
                            *ngIf="
                                changePinForm.controls['oldPin'].errors?.pattern
                            "
                            i18n
                            >Pin must be 4 digits</small
                        >
                    </div>
                    <div class="coolinput mt-2">
                        <div class="form-floating">
                            <input
                                type="{{ type }}"
                                placeholder="Enter the new pin"
                                name="newPin"
                                maxlength="4"
                                class="form-control input border border-dark"
                                formControlName="newPin"
                            />
                            <label for="newPin" class="text-dark" i18n
                                >New pin</label
                            >
                        </div>
                        <small
                            class="text-danger"
                            *ngIf="
                                changePinForm.controls['newPin'].errors?.pattern
                            "
                            i18n
                            >Pin must be 4 digits</small
                        >
                    </div>
                    <div class="coolinput mt-2">
                        <div class="form-floating">
                            <input
                                type="{{ type }}"
                                placeholder="Repeat the new pin"
                                name="newPin2"
                                maxlength="4"
                                class="form-control input border border-dark"
                                formControlName="newPin2"
                            />
                            <label for="newPin2" class="text-dark" i18n
                                >Confirm pin</label
                            >
                        </div>
                        <small
                            class="text-danger"
                            *ngIf="
                                changePinForm.value.newPin !==
                                    changePinForm.value.newPin2 &&
                                changePinForm.value.newPin2.length !== 0
                            "
                            i18n
                        >
                            Pins do not match
                        </small>
                    </div>

                    <div
                        class="w-100 d-flex justify-content-center align-items-center mb-1 mt-2"
                    >
                        <button
                            title="Update merchant information"
                            class="btn btn-sm text-white bg-primary is-small is-rounded px-2 fs-6"
                            type="submit"
                            [disabled]="!changePinForm.valid"
                        >
                            <small
                                [ngClass]="{ 'd-none': isLoading }"
                                class="fs-6 text-light"
                                i18n
                                >Change</small
                            >
                            <span *ngIf="isLoading" class="">
                                <i
                                    class="fa-solid fs-6 fa-spin fa-circle-notch fs-4 bg-primary"
                                ></i>
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>

        <b
            role="button"
            class="ms-1 mt-5"
            style="font-size: 13px"
            (click)="logout()"
            title="Logout"
            i18n
            ><i class="fa-solid fa-right-from-bracket"></i> Logout</b
        >
    </div>

    <!-- <app-confirm-dialog *ngIf="dialog && dialog.isActive"></app-confirm-dialog> -->
    <!-- <div
        class="modal fade"
        id="myModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">
                        
                    </h5>
                    <button
                        type="button"
                        class="close btn btn-primary btn-sm float-right"
                        data-dismiss="modal"
                        aria-label="Close"
                        (click)="saveSomeThing()"
                        
                    >
                        <i class="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <p style="font-weight: 900;">This action will disconnect you in iHela Credit Union <br/> Are you sure you want to Logout ?</p>
                    <div class="text-center mt-3">
                        <button type="button" class="btn btn-primary btn-sm me-3" (click)="saveSomeThing()">Yes</button> 

                        <button type="button" class="btn btn-dark btn-sm" (click)="saveSomeThing()">No</button>
                        
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>
