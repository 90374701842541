<div class="container">
    <div class="row justify-content-md-center">
        <div class="col-lg-8 mt-5">
            <b class="is-size-4 fs-4"><strong>iHelá Ryanje</strong></b
            ><br />
            <b class="is-size-6"><small>CBS</small></b
            ><br />
            <b class="is-size-6"
                ><small i18n
                    >A single accounting and management platform for banks,
                    microfinances and businesses</small
                ></b
            ><br />

            <div class="row mt-2 mb-3">
                <div class="col-lg-3">
                    <div class="bg-white rounded p-3" role="button">
                        <div
                            class=""
                            style="width: 40%; margin: auto; padding: auto"
                        >
                            <div
                                class="bg-secondary"
                                style="border-radius: 50%"
                            >
                                <p class="text-center text-lg fs-2 text-white">
                                    <i
                                        class="fa-solid is-size-6 fa-building-columns text-center"
                                    ></i>
                                </p>
                            </div>
                        </div>
                        <p class="text-center" i18n>Banks</p>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="bg-white rounded p-3" role="button">
                        <div class="" style="width: 40%; margin: auto">
                            <div
                                class="bg-primary h-100"
                                style="border-radius: 50%; width: 100%"
                            >
                                <p class="text-lg fs-2 text-white text-center">
                                    <i
                                        class="fa-solid fa-rectangle-list is-size-6"
                                    ></i>
                                </p>
                            </div>
                        </div>
                        <p class="text-center" i18n>Microfinances</p>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="bg-white rounded p-3" role="button">
                        <div
                            class=""
                            style="width: 40%; margin: auto; padding: auto"
                        >
                            <div class="bg-warning" style="border-radius: 50%">
                                <p class="text-center text-lg fs-2 text-white">
                                    <i
                                        class="fa-solid is-size-6 fa-users text-center text-secondary"
                                    ></i>
                                </p>
                            </div>
                        </div>
                        <p class="text-center" i18n>Cooperatives</p>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="bg-white rounded p-3" role="button">
                        <div
                            class=""
                            style="width: 40%; margin: auto; padding: auto"
                        >
                            <div class="bg-info" style="border-radius: 50%">
                                <p class="text-center text-lg fs-2 text-white">
                                    <i
                                        class="fa-solid is-size-6 fa-book text-center"
                                    ></i>
                                </p>
                            </div>
                        </div>
                        <p class="text-center" i18n>Business</p>
                    </div>
                </div>
            </div>
            <p class="text-center">
                <small i18n>Powered by iHela Credit Union</small>
            </p>
            <small
                ><i i18n>A product of magis - &copy; 2018-{{ year }}</i></small
            >
        </div>
        <div class="col-lg-4 mt-5">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
