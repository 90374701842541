import {
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
} from '@angular/core';
import { debounceTime, from } from 'rxjs';
import { TarifService } from 'src/app/core';

@Component({
    selector: 'app-simulation-fees',
    templateUrl: './simulation-fees.component.html',
    styleUrls: ['./simulation-fees.component.scss'],
})
export class SimulationFeesComponent {
    @Input() amount: any;
    @Input() typeCode: any;
    @Input() bankId: any;

    @Output() feesEvent = new EventEmitter<any>();

    isLoading = false;
    fees: any;

    constructor(private tarifService: TarifService) {}

    getFees() {
        this.isLoading = true;
        this.tarifService
            .getSimulate(this.typeCode, this.amount, this.bankId)
            .subscribe({
                next: (response) => {
                    this.isLoading = false;
                    this.fees = response.objects[0];
                    this.feesEvent.emit(this.fees);
                },
                error: (err) => {
                    this.isLoading = false;
                },
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.fees = null;
        for (const propName in changes) {
            console.log('propo', propName);
            if (
                propName === 'amount' ||
                propName === 'typeCode' ||
                propName === 'bankId'
            ) {
                from(this.amount)
                    .pipe(debounceTime(300))
                    .subscribe({
                        next: (amount: any) => {
                            console.log('oooook', amount);
                            if (amount && this.typeCode && this.bankId) {
                                this.getFees();
                            }
                        },
                    });
            }
        }
    }
}
