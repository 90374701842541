import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';

import { Observable, map } from 'rxjs';

import { Store } from '@ngxs/store';

import { AuthState } from '../../shared/states/auth';
import { OpenDialog } from '../../shared';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    isAuthenticated$: Observable<boolean>;
    isAuthenticated: any;
    token$: Observable<any>;
    token: any;

    constructor(private store: Store, private router: Router) {
        // this.isAuthenticated$ = false;
        this.isAuthenticated$ = this.store.select(AuthState.isAuthenticated);
        this.token$ = this.store.select(AuthState.token);
    }

    ngOnInit() {
        this.token$.subscribe((token) => {
            this.token = token;
        });
    }

    canActivate(): // next: ActivatedRouteSnapshot,
    // state: RouterStateSnapshot

    | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.store.select(AuthState.isAuthenticated).pipe(
            map((isAuthenticated: boolean) => {
                this.isAuthenticated = isAuthenticated;
                if (!this.isAuthenticated) {
                    this.store.dispatch(
                        new OpenDialog({
                            message: $localize` You Must Log In`,
                            title: '',
                            type: 'info',
                        })
                    );
                    return this.router.navigate(['/']);
                } else {
                    return this.isAuthenticated;
                }
            })
        );
        // return this.isAuthenticated
    }
}
