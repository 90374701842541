import {
    Component,
    Input,
    OnInit,
    SimpleChanges,
    inject,
    ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    UntypedFormControl,
    Validators,
    FormControl,
    FormBuilder,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { concat } from 'rxjs';

import {
    MerchantService,
    VariableService,
    GeneralService,
} from '../../../../core';
import { MyAccountsComponent } from 'src/app/shared/components/my-accounts/my-accounts.component';
import {
    OpenActionDialog,
    BankState,
    DialogState,
    OpenDialog,
    CloseDialog,
    SwitchThemeState,
    AuthState,
} from '../../../../shared';
import { MerchantBillComponent } from 'src/app/modules/global-components/bills-format/merchant-bill/merchant-bill.component';
import { MerchantBillModel } from 'src/app/shared/states/bill/bill.models';
import {
    OpenMerchantBillPopup,
    OpenLandscapeBillPopup,
} from 'src/app/shared/states/bill/bill.actions';
import { MultiplePaymentAndAccountSectionComponent } from './multiple-payment_and_account-section/multiple-payment_and_account-section.component';

@Component({
    selector: 'app-billers-merchants',
    templateUrl: './billers-merchants.component.html',
    styleUrls: ['./billers-merchants.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        NgxSkeletonLoaderModule,
        FormsModule,
        ReactiveFormsModule,
        MyAccountsComponent,
        MerchantBillComponent,
        MultiplePaymentAndAccountSectionComponent,
    ],
})
export class BillersMerchantsComponent {
    private onDestroy$: Subject<void> = new Subject<void>();

    private variableService = inject(VariableService);
    @ViewChild('closeModal') closeModal!: { nativeElement: HTMLElement };

    @Input() category: any = {};
    @Input() product: any = {};
    @Input() biller: any = {};

    selectedSection = 'first';
    closeFactor: any;
    merchants: any = [];
    selectedMerchant: any;
    products: any;
    lookupMerchantForm!: UntypedFormGroup;
    lookupMerchantField: any = {};
    selectedProduct: any;
    selectedProductField = false;
    lookupData: any;
    return_data: any;
    productArticle: any;
    type = '';
    bundleSelected: any;
    bundleKey: any;
    choice: any = [];
    lookup_extra_data: any;
    nextStep = false;
    return_lookup_data: any;
    selectedBankId$: any;
    selectedBankId: any;
    dialog$: Observable<any>;
    dialog: any;
    isLoading = false;
    isProductLoading = false;
    theme$: Observable<any>;
    theme: any;
    // closeModal: any;

    /* PaymentForm */

    productPaymentForm!: FormGroup;
    fieldsLoop: any = [];
    paymentFields: any = {};

    /* Below variables are for debit account information */

    debitAccount: any;
    selectedDebitAccountType = '';
    selectedCreditAccountType = '';
    selectedInstitutionType = '';
    creditAccount: any;
    selectedBank$: Observable<any>;
    selectedBank: any;
    selectedInstitution: any;
    debitWallet: any;
    // merchant: any;
    productAdding = false;
    // paymentConfirming = false;
    allproductsToPay: any = [];
    productsToDisplay: any = [];
    field: any;
    response_data: any;
    debit: any;
    selectedDebitAccount: any;

    successMessage: any;
    userName: any;
    clientInfo$: Observable<any>;
    merchantCode: any;
    account: any;

    test: any[] = [];
    typeSelectedName = '';
    logo_url: any;

    constructor(
        private merchantService: MerchantService,
        private generalService: GeneralService,
        private fb: FormBuilder,
        private store: Store
    ) {
        this.selectedBankId$ = this.store.select(
            BankState.GetSelectedClientBankId
        );
        this.dialog$ = this.store.select(DialogState.GetDialog);
        this.theme$ = this.store.select(SwitchThemeState.GetTheme);
        this.selectedBank$ = this.store.select(BankState.GetSelectedClientBank);
        this.clientInfo$ = this.store.select(AuthState.GetClientInfo);
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            const chng = changes[propName];
            if (propName === 'category') {
                this.closeFactor = propName;
                this.typeSelectedName = 'category';
                if (chng.currentValue) {
                    this.initMerchantAndProduct();
                    if (this.category.category) {
                        this.merchantService
                            .getMerchantsByCategory(this.category.category.id)
                            .subscribe({
                                next: (merchants) => {
                                    this.merchants = merchants;
                                },
                            });
                    }
                }
            } else if (propName === 'product') {
                this.closeFactor = propName;
                this.typeSelectedName = 'product';
                if (chng.currentValue) {
                    // this.selectedProduct = chng.currentValue;
                    this.initMerchantAndProduct();
                    this.isProductLoading = true;

                    this.merchantService
                        .getMerchantsProductsDetails(chng.currentValue['id'])
                        .subscribe({
                            next: (product) => {
                                this.selectedMerchant = product.object.merchant;
                                this.logo_url = this.product.lookup_image;

                                this.isProductLoading = false;
                                this.selectProduct(product.object);
                            },
                        });
                }
            } else if (propName === 'biller') {
                this.closeFactor = propName;
                this.typeSelectedName = 'biller';
                if (chng.currentValue) {
                    // this.selectedProduct = chng.currentValue;
                    this.initMerchantAndProduct();
                    this.isProductLoading = true;
                    // this.selectMerchant(this.merchant);
                    this.merchantCode = this.biller.lookup_subtitle;

                    this.merchantService
                        .getProductsByMerchant(this.biller.id)
                        .subscribe({
                            next: (products) => {
                                this.products = products;
                                this.selectedMerchant = this.biller;
                                this.logo_url = this.biller.lookup_image;

                                this.isProductLoading = false;
                            },
                        });
                }
            }
        }
    }

    ngOnInit() {
        this.dialog$.pipe(takeUntil(this.onDestroy$)).subscribe({
            next: (dialog: any) => {
                if (dialog) {
                    this.dialog = dialog;
                    if (this.dialog && this.dialog.response) {
                        if (
                            this.dialog.response === 'pin submitted' &&
                            this.dialog.title === 'Biller Merchant Payment'
                        ) {
                            this.submitProductPayment();
                        }
                    }
                }
            },
        });

        this.theme$.pipe(takeUntil(this.onDestroy$)).subscribe({
            next: (theme: any) => {
                this.theme = theme;
            },
        });

        this.selectedBank$.pipe(takeUntil(this.onDestroy$)).subscribe({
            next: (bank: any) => {
                this.selectedBank = bank;
            },
        });

        this.clientInfo$.pipe(takeUntil(this.onDestroy$)).subscribe({
            next: (info) => {
                this.userName = info.clientFullName;
            },
        });
    }

    initLookupForm() {
        this.lookupMerchantForm = this.fb.group(this.lookupMerchantField);
    }

    initPaymentProductForm() {
        this.productPaymentForm = new UntypedFormGroup({
            payment_data: new UntypedFormGroup(this.paymentFields),
        });
    }

    initMerchantAndProduct() {
        this.selectedMerchant = null;
        this.selectedProduct = null;
        this.merchants = null;
        this.paymentFields = null;
        this.fieldsLoop = null;
        this.selectedProductField = false;
    }

    selectMerchant(merchant: any) {
        this.selectedMerchant = merchant;
        if (this.typeSelectedName === 'category') {
            this.logo_url = this.selectedMerchant.merchant_logo;
        }
        this.merchantCode = this.selectedMerchant.merchant_code;
        this.generalService
            .pingMerchant(this.selectedMerchant.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (data) => {
                    // console.log('ping 3333333 ***', data);
                },
            });
        this.products = null;
        this.getProducts();
    }

    getProducts() {
        this.isProductLoading = true;
        this.merchantService
            .getProductsByMerchant(this.selectedMerchant.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (product) => {
                    this.products = product;
                    this.isProductLoading = false;
                },
            });
    }

    selectProduct(product: any) {
        this.selectedProduct = product;
        if (product.lookup_first) {
            // console.log(
            //     '**** Looooookuuuuuuppsssss 3333 55555 66666',
            //     this.selectedProduct
            // );
            this.doFirstMerchantLookup();
        }
        this.fieldsLoop = [];

        for (const field of product.lookup_metadata) {
            if (field.widget_attrs.required) {
                this.lookupMerchantField[field.field_name] = [
                    '',
                    Validators.required,
                ];
            } else {
                this.lookupMerchantField[field.field_name] = [''];
            }
        }

        this.paymentFields = {};

        for (const field of product.metadata) {
            let choice_field: any = {};
            const loop_field: any = {};
            choice_field = field.widget_attrs.values;
            if (field.widget_attrs.required) {
                this.paymentFields![field.field_name] = new UntypedFormControl(
                    '',
                    Validators.required
                );
                loop_field['name'] = field.field_name;
                loop_field['placeholder'] = field.name;
                loop_field['type'] = field.field_type;
                loop_field['meta_type'] = field.meta_type;
                loop_field['choice_field'] = choice_field;
            } else {
                this.paymentFields![field.field_name] = new UntypedFormControl(
                    '',
                    Validators.required
                );
                loop_field['name'] = field.field_name;
                loop_field['placeholder'] = field.name;
                loop_field['type'] = field.field_type;
                loop_field['meta_type'] = field.meta_type;
                loop_field['choice_field'] = choice_field;
            }

            this.fieldsLoop.push(loop_field);
        }

        // console.log('fields loop complete',this.fieldsLoop);
        this.initLookupForm();
        this.initPaymentProductForm();
    }

    /* This function is for product with first_lookup true like OBR's taxes, 
    At the end of the day this function will be replaced by getMerchantLookup, Thank you ! */

    doFirstMerchantLookup() {
        this.isLoading = true;
        this.lookupData = {
            merchant_product_id: this.selectedProduct.id,
            lookup_data: {},
            lookup_extra_data: {},
        };
        this.choice = [];
        this.merchantService
            .getMerchantProductLookup(this.lookupData)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
                (data) => {
                    if (data.object.success === true) {
                        this.isLoading = false;
                        this.productPaymentForm.reset();
                        // this.isLookupProduct = false;
                        // this.productArticle = data.object.response_data.lookup;
                        this.productArticle = data.object.response_data.lookup;
                        this.type = typeof this.productArticle;
                        this.return_data =
                            data.object.response_data.lookup_data;
                        this.choice = data.object.response_data.lookup_choice;
                        this.nextStep =
                            data.object.response_data.lookup_next_step;
                        this.return_lookup_data = data.object.response_data;
                        this.lookupMerchantForm.reset();
                        this.initPaymentProductForm();
                    } else if (data.object.success === false) {
                        this.isLoading = false;
                        const notification = {
                            title: '',
                            type: 'failed',
                            message: data.object.response_message,
                        };

                        this.store.dispatch(new OpenDialog(notification));
                    }
                },
                (err) => {
                    this.isLoading = false;

                    const notification = {
                        title: '',
                        type: 'failed',
                        message: 'Something went wrong, please retry again',
                    };
                    this.store.dispatch(new OpenDialog(notification));
                }
            );
    }

    getMerchantLookup(bundleKey?: any) {
        this.isLoading = true;
        if (bundleKey && this.lookupData && this.lookupData.lookup_extra_data) {
            // console.log('lll 11');
            this.lookupData.lookup_extra_data[bundleKey.key] = bundleKey.value;
        } else {
            // console.log('lll 22');
            this.lookupData = {
                merchant_product_id: this.selectedProduct.id,
                lookup_data: {
                    ...this.lookupMerchantForm.value,
                },
                lookup_extra_data: {},
            };
        }
        this.lookupMerchantForm.disable();
        this.choice = [];

        // console.log('lll 333', this.lookupData);

        this.merchantService
            .getMerchantProductLookup(this.lookupData)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
                (data) => {
                    this.lookupMerchantForm.enable();
                    this.productPaymentForm.reset();
                    if (data.object.success === true) {
                        this.isLoading = false;
                        // this.isLookupProduct = false;
                        this.productArticle = data.object.response_data.lookup;
                        this.type = typeof this.productArticle;
                        this.return_data =
                            data.object.response_data.lookup_data;
                        if (data.object.response_data.lookup_choice) {
                            this.choice =
                                data.object.response_data.lookup_choice;
                        }
                        this.nextStep =
                            data.object.response_data.lookup_next_step;
                        this.return_lookup_data = data.object.response_data;
                        this.lookupMerchantForm.reset();
                        this.initPaymentProductForm();
                    } else if (data.object.success === false) {
                        this.isLoading = false;
                        const notification = {
                            title: '',
                            type: 'failed',
                            message: data.object.response_message,
                        };

                        this.store.dispatch(new OpenDialog(notification));
                    }
                },
                (err) => {
                    this.isLoading = false;
                    this.lookupMerchantForm.enable();
                    const notification = {
                        title: '',
                        type: 'failed',
                        message: 'Something went wrong, please retry again',
                    };
                    this.store.dispatch(new OpenDialog(notification));
                }
            );
    }

    selectBundle(bundle: any) {
        for (const bundleKey of this.choice) {
            this.bundleKey = { key: bundleKey.key };
        }

        if (this.nextStep) {
            this.choice = [];
            this.bundleKey = { ...this.bundleKey, value: bundle.value };
            // console.log('1xxxx', bundle, this.bundleKey);
            this.getMerchantLookup(this.bundleKey);
        } else {
            if (this.bundleKey.key === 'add_to_lookup') {
                // console.log('2xxxx', bundle, this.bundleKey);
                this.bundleSelected = bundle;
                this.return_data = {
                    ...this.return_data,
                    ...bundle.value,
                };
            } else {
                // console.log('3xxxx', bundle, this.bundleKey);
                if (this.lookupData && this.lookupData.lookup_extra_data) {
                    this.lookup_extra_data = this.lookupData.lookup_extra_data;
                } else {
                    this.lookup_extra_data = {};
                }

                this.lookup_extra_data[this.bundleKey.key] = bundle.value;

                if (this.lookupData && this.lookupData.lookup_data) {
                    this.return_data = {
                        ...this.return_data,
                        ...this.lookupData.lookup_data,
                        ...this.lookup_extra_data,
                    };
                } else {
                    this.return_data = {
                        ...this.return_data,
                        ...this.lookup_extra_data,
                    };
                }

                this.bundleSelected = bundle;
                console.log('boommm 44', this.return_data);
            }
        }
        this.productPaymentForm.reset();
    }

    exit() {
        this.bundleSelected = null;
        this.productArticle = null;
        if (!this.selectedProduct.lookup_first) {
            this.choice = [];
        }
    }

    getDebitOptions(event: any) {
        this.debit = event;
        if (event.account) {
            this.account = event.account.acc_short_number;
        } else if (event.wallet) {
            this.account = event.wallet.code;
        }
        this.debitAccount = this.debit.account;
        this.debitWallet = this.debit.wallet;

        if (this.debitAccount) {
            this.selectedDebitAccount = this.debitAccount.acc_short_number;
            // this.selectedBank = this.debitAccount.acc_bank_id
        }
        if (this.debitWallet) {
            this.selectedDebitAccount = this.debitWallet.code;
            // this.selectedBank = this.debitWallet.bank_id;
        }
        this.selectedDebitAccountType = event.selectedDebitOption;

        this.selectedCreditAccountType = event.creditAccountType;
        if (!this.selectedCreditAccountType) {
            this.selectedInstitution = '';
            this.selectedInstitutionType = '';
            this.creditAccount = undefined;
        }
    }

    enterPin() {
        let merchant;
        if (this.selectedMerchant.merchant_title) {
            merchant = this.selectedMerchant.merchant_title;
        } else if (this.biller.lookup_title) {
            merchant = this.biller.lookup_title;
        }
        if (
            this.category &&
            this.category.category &&
            this.category.category.name
        ) {
            const data = {
                title: 'Biller Merchant Payment',
                type: 'pin',
                message: ` Pay service <b>${this.category.category.name}</b> merchant
               <b>${merchant}</b>
                with your account
              <b>${this.selectedDebitAccount} </b> `,
                action: 'confirm merchant payment',
            };

            this.store.dispatch(new OpenActionDialog(data));
        } else {
            const data = {
                title: 'Biller Merchant Payment',
                type: 'pin',
                message: ` Pay merchant
               <b>${merchant}</b>
                with your account
              <b>${this.selectedDebitAccount} </b> `,
                action: 'confirm biller merchant payment',
            };

            this.store.dispatch(new OpenActionDialog(data));
        }
    }

    submitProductPayment() {
        let payment_data = {};
        for (const payment of this.allproductsToPay) {
            payment_data = payment.payment_data;
        }
        let data: any = {
            debit_bank: this.selectedBank.id,
            merchant_product_id: this.selectedProduct.id,
            debit_type: this.selectedDebitAccountType,
            pin_code: this.variableService.pin,
            debit_account: this.selectedDebitAccount,
        };

        if (
            this.allproductsToPay.length > 1 &&
            this.selectedProduct &&
            this.selectedProduct.accepts_multiple_payment
        ) {
            data = {
                ...data,
                is_multiple_payment: true,
                payment_details: this.allproductsToPay,
            };
            // data;
            // data['is_multiple_payment'] = true;
            // data['payment_details'] = this.allproductsToPay;
        } else {
            data = {
                ...data,
                payment_data,
                lookup_data: this.return_data,
            };
            // data = this.productPaymentForm?.value;
            // data['lookup_data'] = this.return_data;
        }

        // data['debit_bank'] = this.selectedBank.id;
        // data['merchant_product_id'] = this.selectedProduct.id;
        // data['debit_type'] = this.selectedDebitAccountType;
        // data['pin_code'] = this.variableService.pin;
        // data['debit_account'] = this.selectedDebitAccount;

        const response = {
            title: '',
            type: 'loading',
            message: '',
        };
        this.store.dispatch(new OpenDialog(response));

        this.merchantService
            .payMerchant(data)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (data) => {
                    this.store.dispatch(new CloseDialog({ response: 'close' }));
                    if (
                        data.object['success'] !== undefined &&
                        !data.object.success
                    ) {
                        const notification = {
                            title: '',
                            type: 'failed',
                            message: data.object.response_message,
                        };
                        this.store.dispatch(new OpenDialog(notification));
                        return;
                    } else if (data.object.success) {
                        this.allproductsToPay = [];
                        this.productsToDisplay = [];
                        this.productPaymentForm.reset();
                    }

                    const response = data.object.response_data;

                    this.successMessage = {
                        data: {
                            debit_account: this.account,
                            name: this.userName,
                            merchantName: this.selectedMerchant?.merchant_title,
                            date: response.date,
                            printable_text: response.orders[0].printable_text,
                            amount: response.amount,
                            code: response.bill,
                            product: {
                                name: this.selectedProduct?.name,
                                value: '',
                            },
                            description: '',
                            adress: '',
                            receipt_date: response.date,
                            credit_account: this.merchantCode,
                            logo_url: this.logo_url,
                        },
                    };

                    if (this.selectedProduct.voucher_type === 'L') {
                        this.store.dispatch(
                            new OpenLandscapeBillPopup(this.successMessage.data)
                        );
                    } else {
                        this.store.dispatch(
                            new OpenMerchantBillPopup(this.successMessage.data)
                        );
                    }

                    this.closeModal.nativeElement.click();
                    this.selectedSection = 'first';

                    // if (data.object.success === true) {
                    //
                    // } else if (data.object.success === false) {
                    //     const notification = {
                    //         title: '',
                    //         type: 'failed',
                    //         message: data.object.response_message,
                    //     };
                    //     this.store.dispatch(new OpenDialog(notification));
                    // }
                },
                error: (msg) => {
                    this.store.dispatch(new CloseDialog({ response: 'close' }));
                    let message;
                    if (msg.object.response_message) {
                        message = msg.object.response_message;
                    } else {
                        message = 'An error occurred please try again later';
                    }
                    const notification = {
                        title: '',
                        type: 'failed',
                        message: message,
                    };
                    this.store.dispatch(new OpenDialog(notification));
                },
            });
    }
    close() {
        if (this.closeFactor === 'category') {
            this.selectedMerchant = null;
        }
        if (this.closeFactor === 'product') {
            this.productArticle = null;
        }
        if (this.closeFactor === 'biller') {
            this.selectedProduct = null;
        }
        this.bundleSelected = null;
        this.productArticle = null;
        this.return_lookup_data = null;
        this.choice = [];
        this.allproductsToPay = [];
        this.productAdding = false;
        this.selectedSection = 'first';
    }

    goBack() {
        if (this.closeFactor === 'category') {
            if (
                this.selectedMerchant &&
                !this.productArticle &&
                !this.selectedProduct
            ) {
                this.selectedMerchant = null;
                this.selectedSection = 'first';
                this.productAdding = false;
            } else if (this.selectedProduct && !this.return_lookup_data) {
                this.selectedProduct = null;
                this.productAdding = false;
                this.choice = [];
            } else if (this.productArticle && !this.selectedProduct) {
                this.productArticle = null;
                this.return_data = null;
                this.productAdding = false;
            } else if (this.bundleSelected) {
                this.bundleSelected = null;
                this.productAdding = false;
            } else if (this.choice && !this.bundleSelected) {
                this.exit();
                this.return_lookup_data = null;
                this.lookupMerchantForm.reset();
                this.productAdding = false;
            } else if (
                !this.choice &&
                !this.bundleSelected &&
                this.return_lookup_data &&
                !this.productAdding
            ) {
                this.exit();
                this.return_lookup_data = null;
                this.lookupMerchantForm.reset();
                this.allproductsToPay = [];
                this.productAdding = false;
            } else if (this.productAdding) {
                this.productAdding = false;
            }
        }

        if (this.closeFactor === 'product') {
            if (this.productArticle && !this.selectedProduct) {
                this.productArticle = null;
                this.selectedSection = 'first';
                this.productAdding = false;
            } else if (this.bundleSelected) {
                this.bundleSelected = null;
                this.productAdding = false;
            } else if (this.choice && !this.bundleSelected) {
                this.exit();
                this.return_lookup_data = null;
                this.lookupMerchantForm.reset();
                this.productAdding = false;
            } else if (
                !this.choice &&
                !this.bundleSelected &&
                this.return_lookup_data &&
                !this.productAdding
            ) {
                this.exit();
                this.return_lookup_data = null;
                this.lookupMerchantForm.reset();
                this.allproductsToPay = [];
                this.productAdding = false;
            } else if (this.productAdding) {
                this.productAdding = false;
            }
        }

        if (this.closeFactor === 'biller') {
            if (this.selectedProduct && !this.return_lookup_data) {
                this.selectedProduct = null;
                this.choice = [];
                this.selectedSection = 'first';
                this.productAdding = false;
            } else if (this.productArticle && !this.selectedProduct) {
                this.productArticle = null;
            } else if (this.bundleSelected) {
                this.bundleSelected = null;
                this.productAdding = false;
            } else if (this.choice && !this.bundleSelected) {
                this.exit();
                this.return_lookup_data = null;
                this.lookupMerchantForm.reset();
                this.productAdding = false;
            } else if (
                !this.choice &&
                !this.bundleSelected &&
                this.return_lookup_data &&
                !this.productAdding
            ) {
                this.exit();
                this.return_lookup_data = null;
                this.lookupMerchantForm.reset();
                this.allproductsToPay = [];
                this.productAdding = false;
            } else if (this.productAdding) {
                this.productAdding = false;
            }
        }
        if (this.selectedSection === 'second') {
            this.selectedSection = 'first';
        }
    }

    showDebitSection() {
        let canShowSection = false;

        if (this.selectedProduct) {
            if (this.selectedProduct.lookup_first) {
                if (this.bundleSelected) {
                    canShowSection = true;
                }
            } else if (!this.selectedProduct.lookup_first) {
                if (this.selectedProduct.lookup_metadata.length === 0) {
                    canShowSection = true;
                } else if (
                    this.return_data &&
                    !this.return_data.lookup_next_step &&
                    this.choice &&
                    this.choice?.length === 0
                ) {
                    canShowSection = true;
                } else if (
                    this.return_data &&
                    !this.return_data.lookup_next_step &&
                    this.choice?.length >= 0 &&
                    this.bundleSelected
                ) {
                    canShowSection = true;
                } else if (
                    this.return_data &&
                    !this.return_data.lookup_next_step &&
                    !this.choice
                ) {
                    canShowSection = true;
                }
            } else if (this.selectedProduct.price) {
                canShowSection = true;
                console.log('Price selected');
            }
        }

        return canShowSection;
    }
    addProductToPayment(article: any, bundle: any) {
        this.productAdding = true;
        // let field = this.return_data;
        const lookup_data = this.return_data;
        const payment_data = this.productPaymentForm.value.payment_data;
        this.type = typeof article;
        // field = this.fieldsLoop;
        const product = { payment_data, lookup_data };
        const productDisplay = { payment_data, lookup_data, bundle, article };

        this.allproductsToPay.push(product);
        this.productsToDisplay.push(productDisplay);
        this.exit();
        console.log('Product added', this.allproductsToPay);
    }
    removeProduct(event: any) {
        const index = this.productsToDisplay.indexOf(event);

        if (index > -1) {
            this.allproductsToPay.splice(index, 1);
            this.productsToDisplay.splice(index, 1);
        }
        this.allproductsToPay;
        console.log('Product removed', this.allproductsToPay);
    }
    confirmPayment() {
        this.selectedSection = 'second';
        if (!this.selectedProduct.accepts_multiple_payment) {
            this.allproductsToPay.push(this.productPaymentForm?.value);
        }
    }

    ngOnDestroy(): void {
        this.selectedSection = 'first';
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}

// body:
// {
//     "merchant_product_id": 30,
//     "debit_bank": 1,
//     "debit_account": "0000000016-01",
//     "debit_type": "account",
//     "pin_code": "4321",
//     "is_multiple_payment": true,
//     "payment_details":[
// 	{
// 		"payment_data": {
// 			"number":1
// 	    	},
// 	    	"lookup_data":{
//     		"subscriber_id" :100875968,
//     		"service_code":"02722604216",
//     		"customer_name":"ARAKA",
//     		"mobile":"111111",
//     		"contact_address":"Afracia",
//     		"subscriber_status":"VALID",
//     		"expiration_date":"2030-08-04",
//     		"basic_offer_display_name":"Chinese",
//     		"basic_offer_business_class":"DTH",
//     		"other_info":"N/A",
//     		"package_code":null,
//     		"account":"02722604216",
//     		"amount":60000
//     		}
//     	},
//     	{
// 		"payment_data": {
// 			"number":1
// 	    	},
// 	    	"lookup_data":{
//     		"subscriber_id" :100875968,
//     		"service_code":"02722604216",
//     		"customer_name":"ARAKA",
//     		"mobile":"111111",
//     		"contact_address":"Afracia",
//     		"subscriber_status":"VALID",
//     		"expiration_date":"2030-08-04",
//     		"basic_offer_display_name":"Chinese",
//     		"basic_offer_business_class":"DTH",
//     		"other_info":"N/A",
//     		"package_code":null,
//     		"account":"02722604216",
//     		"amount":60000
//     		}
//     	}
// ]
// }
