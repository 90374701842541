import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';

import { Observable } from 'rxjs';
import { MenuService } from 'src/app/core';
import { SelectMenuGroup } from 'src/app/shared/states/menu';

@Component({
    selector: 'app-w-aside-menu',
    templateUrl: './w-aside-menu.component.html',
    styleUrls: ['./w-aside-menu.component.scss'],
})
export class WAsideMenuComponent implements OnInit {
    selectedGroup = '';

    constructor(
        private router: Router,
        private menuService: MenuService,
        private store: Store
    ) {
        //
    }

    ngOnInit(): void {
        // comment
    }

    selectGroup(group: string) {
        this.selectedGroup = group;
        // this.menuService.changeMenuGroup(true)
        this.store.dispatch(new SelectMenuGroup({ menuGroup: null }));
    }
}
