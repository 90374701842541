import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Store, Select } from '@ngxs/store';

// import { AuthState } from 'app/shared/states/auth';
// import { AuthState } from '../../shared/states/auth';
// import { UserService } from '../services';
import { Logout, AuthState } from '../../shared';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    // @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<any>;
    // isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);
    isAuthenticated = true;
    isAuthenticated$: Observable<any>;
    constructor(private router: Router, private store: Store) {
        this.isAuthenticated$ = this.store.select(AuthState.isAuthenticated);
    }

    ngOnInit() {
        this.isAuthenticated$.subscribe({
            next: (isAuthenticated) => {
                this.isAuthenticated = isAuthenticated;
            },
        });
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            // retry(1),
            catchError((error: HttpErrorResponse) => {
                let errorMessage = '';
                if (error.error instanceof ErrorEvent) {
                    // client-side error
                    errorMessage = `Error: ${error.error.message}`;
                } else {
                    // server-side error
                    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                }

                // if (error.status === 401 || error.status === 403) {
                //     console.log('WRONG RESPONSE: LOGGING OUT USER... ');
                //     if (this.isAuthenticated) {
                //         this.store.dispatch(new Logout());
                //         this.router.navigate(['/']);
                //     } else {
                //         this.router.navigate(['/']);
                //     }
                // }

                console.error(errorMessage, '\nError Object:', error.error);
                return throwError(error);
            })
        );
    }
}
