import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmountWordsComponent } from './amount-words/amount-words.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SimulationFeesComponent } from './simulation-fees/simulation-fees.component';

import { AmountVisibilityComponent } from './amount-visibility/amount-visibility.component';

@NgModule({
    declarations: [
        AmountWordsComponent,
        SimulationFeesComponent,
        AmountVisibilityComponent,
    ],
    imports: [CommonModule, ReactiveFormsModule],
    exports: [
        AmountWordsComponent,
        SimulationFeesComponent,
        AmountVisibilityComponent,
    ],
})
export class CustomerFieldModule {}
