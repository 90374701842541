import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { FetchStatistic } from './statistic.actions';
import { Statistic } from './statistic.model';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { Injectable, Injector } from '@angular/core';

@State<Statistic>({
    name: 'statistic',
    defaults: {
        stats: [],
    },
})
@Injectable()
export class StatisticState {
    dashboardService: any;
    constructor(private injector: Injector) {
        this.dashboardService = this.injector.get(DashboardService);
    }

    @Selector()
    static selectStats(state: Statistic): any {
        return state.stats;
    }

    @Action(FetchStatistic)
    fetchStatistic(ctx: StateContext<Statistic>, _action: FetchStatistic) {
        return this.dashboardService.getWorkstationStats().pipe(
            tap((stats: any) => {
                ctx.patchState({ stats: stats });
            })
        );
    }
}
