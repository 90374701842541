<app-confirm-dialog
    *ngIf="dialog"
    class="mt-2"
    style="
        position: absolute;
        justify-content: center;
        width: 100%;
        display: flex;
        z-index: 200;
    "
></app-confirm-dialog>
<div class="">
    <!-- header -->
    <div>
        <app-header [onLoginPage]="true"></app-header>
    </div>

    <div
        class="d-flex flex-column position-relative level-2"
        style="height: calc(100vh - 50px)"
    >
        <div
            class="global-bg position-absolute"
            style="
                inset: 0;
                background-image: url(/assets/images/corporate-auth-bar-bg.svg);
                background-repeat: no-repeat;
                background-position-y: 50%;
            "
        ></div>
        <!-- main content -->
        <main class="flex-grow-1" style="z-index: 10">
            <section>
                <!-- sub-header -->
                <div
                    class="row align-items-center col-md- mx-auto justify-content-center"
                >
                    <div
                        class="col-2 col-md-4 overflow-hidden"
                        style="max-width: 200px; max-height: 130px"
                    >
                        <img
                            src="../../../assets/images/magis.png"
                            class="image"
                        />
                    </div>

                    <div class="d-flex col-md-7 justify-content-center">
                        <div
                            class="text-primary-light fs-5 pointer text-center me-md-5 me-3"
                        >
                            <b i18n>Online Banking</b>
                        </div>
                        <div
                            class="text-primary-light fs-5 pointer text-center me-md-5 me-3"
                        >
                            <b i18n>Magis ERP</b>
                        </div>
                        <div
                            class="text-primary-light fs-5 pointer text-center me-md-5 me-3"
                        >
                            <b i18n>Payroll and RH</b>
                        </div>
                        <div
                            class="text-primary-light fs-5 pointer text-center me-md-5 me-3"
                        >
                            <b i18n>Utilities</b>
                        </div>
                    </div>
                </div>

                <section class="row justify-content-around col-lg-10 mx-auto">
                    <!-- organizations and invitations section     -->
                    <div class="col-md-6">
                        <div>
                            <div class="mb-3 mt-2 fs-3 text-dark">
                                <b i18n>Your Organizations</b>
                            </div>

                            <!-- skeleton loader for organization -->
                            <div
                                class="row mb-3 flex-wrap"
                                style="gap: 2rem"
                                *ngIf="isPopulatingOperator || organization"
                            >
                                <div class="col-5 col-sm-4 col-md-3">
                                    <ngx-skeleton-loader
                                        [theme]="{
                                            'width.%': 100,
                                            'height.px': 80
                                        }"
                                    ></ngx-skeleton-loader>
                                </div>
                                <div class="col-5 col-sm-4 col-md-3">
                                    <ngx-skeleton-loader
                                        [theme]="{
                                            'width.%': 100,
                                            'height.%': 80
                                        }"
                                    ></ngx-skeleton-loader>
                                </div>
                                <div class="col-5 col-sm-4 col-md-3">
                                    <ngx-skeleton-loader
                                        [theme]="{
                                            'width.%': 100,
                                            'height.%': 80
                                        }"
                                    ></ngx-skeleton-loader>
                                </div>
                            </div>

                            <div
                                *ngIf="!isPopulatingOperator && !organization"
                                class="row flex-wrap"
                                style="gap: 2rem"
                                [ngClass]="{
                                    'col-md-5 ':
                                        !corporates || corporates.length === 0
                                }"
                            >
                                <!-- organizations -->
                                <div
                                    class="d-flex flex-column align-items-center col-5 col-sm-4 col-md-3"
                                    *ngFor="let corporate of corporates"
                                    (click)="selectCorporate(corporate)"
                                >
                                    <div
                                        class="sm-radius organization-logo d-flex justify-content-center align-items-center p-2"
                                        style="
                                            background-color: rgba(
                                                229,
                                                242,
                                                255,
                                                1
                                            );
                                        "
                                    >
                                        <img
                                            [src]="
                                                corporate.organization
                                                    .institution_client.picture
                                            "
                                            class="image"
                                            alt="organization logo"
                                        />
                                    </div>
                                    <div class="mt-1 text-center">
                                        <b class="text-dark" i18n>{{
                                            corporate.organization
                                                .institution_client
                                                .client_full_name
                                        }}</b>
                                    </div>
                                </div>

                                <!-- add organization -->
                                <div
                                    class="d-flex flex-column align-items-center"
                                    data-bs-toggle="modal"
                                    data-bs-target="#notFound"
                                    [ngClass]="{
                                        'col-md-auto':
                                            !corporates ||
                                            corporates.length === 0,
                                        'col-5 col-sm-4 col-md-3':
                                            corporates.length !== 0
                                    }"
                                >
                                    <div
                                        class="sm-radius organization-logo d-flex justify-content-center align-items-center"
                                        style="
                                            background-color: rgba(
                                                229,
                                                242,
                                                255,
                                                1
                                            );
                                        "
                                    >
                                        <span class="text-primary-light"
                                            ><i class="fa-solid fa-plus"></i
                                        ></span>
                                    </div>
                                    <div class="mt-1 text-center">
                                        <b class="text-dark" i18n
                                            >Add Organization</b
                                        >
                                    </div>
                                </div>
                                <div class="col-5 col-sm-4 col-md-3"></div>
                                <div class="col-5 col-sm-4 col-md-3"></div>
                            </div>
                        </div>

                        <div
                            *ngIf="
                                !loadingInvitations && invitations.length !== 0
                            "
                            class="level-2 px-4 py-2mt-5 col-11 mx-auto lg-radius mt-3"
                            style="
                                border: 1px solid rgba(32, 112, 184, 1);
                                max-height: 300px;
                                overflow: auto;
                            "
                        >
                            <!-- invitations -->
                            <section
                                *ngIf="invitations && invitations.lenght !== 0"
                            >
                                <div class="mb-3">
                                    <b class="text-dark fs-5" i18n
                                        >Invitations</b
                                    >
                                </div>
                                <div
                                    *ngFor="let item of invitations"
                                    class="invitations-card lg-radius d-flex align-items-center overflow-hidden mb-3 bg-primary-3"
                                    style="height: 48px"
                                >
                                    <div
                                        class="col-2 d-flex justify-content-center align-items-center border-end border-dark"
                                    >
                                        <img
                                            [src]="
                                                item.organization
                                                    .institution_client.picture
                                            "
                                            alt="{{
                                                item.organization
                                                    .institution_client
                                                    .client_full_name
                                            }}"
                                            class="image h-75 w-75"
                                        />
                                    </div>
                                    <div
                                        class="col ms-2 border-end border-dark"
                                    >
                                        <small
                                            ><b i18n>You have been invited</b>
                                            to become an operator with
                                            {{
                                                item.organization
                                                    .institution_client
                                                    .client_full_name
                                            }}</small
                                        >
                                    </div>
                                    <div
                                        class="col-2 d-flex justify-content-around align-items-center"
                                    >
                                        <div
                                            class="d-flex flex-column align-items-center text-primary-light pointer"
                                            (click)="openModal('accept', item)"
                                        >
                                            <span class="">
                                                <i
                                                    class="fa-regular fa-circle-check"
                                                ></i>
                                            </span>
                                            <span style="font-size: 8px" i18n
                                                >Accept</span
                                            >
                                        </div>
                                        <div
                                            (click)="openModal('decline', item)"
                                            class="d-flex flex-column align-items-center pointer text-secondary"
                                        >
                                            <span class="">
                                                <i
                                                    class="fa-regular fa-circle-xmark"
                                                ></i>
                                            </span>
                                            <span style="font-size: 8px" i18n
                                                >Decline</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <!-- requests -->

                            <!-- <section>
                                <div class="mb-3 mt-4">
                                    <b class="text-dark fs-5" i18n>Requests</b>
                                </div>
                                <div
                                    class="invitations-card lg-radius d-flex align-items-center overflow-hidden mb-3"
                                    style="
                                        background-color: rgba(
                                            229,
                                            242,
                                            255,
                                            1
                                        );
                                        height: 48px;
                                    "
                                >
                                    <div
                                        class="col-2 d-flex justify-content-center align-items-center border-end border-dark"
                                    >
                                        <img
                                            src="../../../../assets/images/ihela3.png"
                                            alt="organization logo"
                                            class="image h-75 w-75"
                                        />
                                    </div>
                                    <div
                                        class="col ms-2 text-dark border-end border-dark"
                                    >
                                        <small
                                            ><b>You requested</b> to add
                                            organisation ihela</small
                                        >
                                    </div>
                                    <div
                                        class="col-2 d-flex justify-content-around align-items-center"
                                    >
                                        <div
                                            class="d-flex flex-column align-items-center text-primary-light pointer"
                                        >
                                            <span
                                                class="pending-status p-1 px-2"
                                                style="font-size: 8px"
                                                i18n
                                                >Pending ...</span
                                            >
                                        </div>
                                        <div
                                            class="d-flex flex-column align-items-center pointer"
                                            style="color: rgba(65, 65, 65, 1)"
                                        >
                                            <span class="">
                                                <i
                                                    class="fa-regular fa-circle-xmark"
                                                ></i>
                                            </span>
                                            <span style="font-size: 8px" i18n
                                                >Decline</span
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="invitations-card lg-radius d-flex align-items-center overflow-hidden mb-3"
                                    style="
                                        background-color: rgba(
                                            229,
                                            242,
                                            255,
                                            1
                                        );
                                        height: 48px;
                                    "
                                >
                                    <div
                                        class="col-2 d-flex justify-content-center align-items-center border-end border-dark"
                                    >
                                        <img
                                            src="../../../../assets/images/ihela3.png"
                                            alt="organization logo"
                                            class="image h-75 w-75"
                                        />
                                    </div>
                                    <div
                                        class="col ms-2 text-dark border-end border-dark"
                                    >
                                        <small
                                            ><b>You requested</b> to add
                                            organisation ihela</small
                                        >
                                    </div>
                                    <div
                                        class="col-2 d-flex justify-content-around align-items-center"
                                    >
                                        <div
                                            class="d-flex flex-column align-items-center text-primary-light pointer"
                                        >
                                            <span
                                                class="approved-status p-1 px-2"
                                                style="font-size: 8px"
                                                i18n
                                                >Approved.</span
                                            >
                                        </div>
                                        <div></div>
                                    </div>
                                </div>
                                <div
                                    class="invitations-card lg-radius d-flex align-items-center overflow-hidden mb-3"
                                    style="
                                        background-color: rgba(
                                            229,
                                            242,
                                            255,
                                            1
                                        );
                                        height: 48px;
                                    "
                                >
                                    <div
                                        class="col-2 d-flex justify-content-center align-items-center border-end border-dark"
                                    >
                                        <img
                                            src="../../../../assets/images/ihela3.png"
                                            alt="organization logo"
                                            class="image h-75 w-75"
                                        />
                                    </div>
                                    <div
                                        class="col ms-2 text-dark border-end border-dark"
                                    >
                                        <small
                                            ><b>You requested</b> to add
                                            organisation ihela</small
                                        >
                                    </div>
                                    <div
                                        class="col-2 d-flex justify-content-around align-items-center"
                                    >
                                        <div
                                            class="d-flex flex-column align-items-center text-primary-light pointer"
                                        >
                                            <span
                                                class="denied-status p-1 px-2"
                                                style="font-size: 8px"
                                                i18n
                                                >Denied.</span
                                            >
                                        </div>
                                        <div></div>
                                    </div>
                                </div>
                            </section> -->
                        </div>
                    </div>
                    <!-- informations card -->
                    <div
                        class="col-md-4 position-relative"
                        [ngClass]="{
                            'information-card-dark': theme === 'magis-dark',
                            'information-card-light': theme === 'magis-light'
                        }"
                        style="
                            height: 60vh;

                            border-radius: 22px;
                            max-width: 445px;
                        "
                    >
                        <div
                            class="position-absolute"
                            style="
                                inset: 0;
                                background-image: url(/assets/images/corporate-auth-bar-card.svg);
                                background-repeat: no-repeat;
                                background-position-y: 50%;
                            "
                        ></div>
                    </div>
                </section>
            </section>
        </main>

        <!-- footer -->
        <footer
            style="z-index: 10"
            class="d-flex justify-content-center align-items-center mt-5 mb-1"
        >
            <div class="text-secondary">
                <span class="fs-5" i18n>
                    <a
                        class="text-secondary"
                        href="https://about.ihela.bi/"
                        target="_blank"
                        >About us</a
                    >
                </span>
                <span class="mx-3">.</span>
                <span class="fs-5" i18n>Privacy</span>
                <span class="mx-3">.</span>
                <span class="fs-5">Terms and conditions</span>
                <span class="mx-3">.</span>
                <span class="fs-5" i18n>Jobs</span>
                <span class="mx-3">.</span>
                <span class="fs-5" i18n>
                    <a
                        class="text-secondary"
                        href="http://bankingdocs.ihela.bi/"
                        target="_blank"
                        >API</a
                    >
                </span>
            </div>
        </footer>
    </div>
</div>

<!-- modal -->

<div class="myModal" *ngIf="showModal">
    <div class="modal-overlay" (click)="closeModal()"></div>
    <div
        class="modal-content d-flex flex-column align-items-center justify-content-center p-4 level-2"
        style="border-radius: 19px; width: 425px"
    >
        <div
            class="text-center"
            style="height: 128px; min-width: 166px; max-width: 60%"
        >
            <img [src]="popupOrganizationLogo" class="image sm-radius" />
        </div>
        <div
            class="mt-2 mb-3 col-10 text-dark"
            style="font-size: 15px; color: rgba(65, 65, 65, 1)"
        >
            <!-- new organization -->
            <span *ngIf="popupAction === 'newOrganization'" i18n
                >Enter your Password to add a new organization</span
            >

            <!-- accept demand -->
            <!-- <span i18n *ngIf="popupAction === 'accept'">
                Enter your Pin to accept the invitation to become an operator
                with
                {{
                    selectedInvitation.organization.institution_client
                        .client_full_name
                }}
            </span> -->

            <!-- decline demand -->
            <!-- <span i18n *ngIf="popupAction === 'decline'">
                Enter your Pin to decline the invitation to become an operator
                with
                {{
                    selectedInvitation.organization.institution_client
                        .client_full_name
                }}
            </span> -->

            <!-- login -->
            <span i18n *ngIf="popupAction === 'login'">
                Enter your password to access the
                {{
                    selectedCorporate.organization.institution_client
                        .client_full_name
                }}
            </span>
        </div>

        <div class="row justify-content-center w-100">
            <form
                class="col-10 p-0"
                [formGroup]="popupForm"
                (ngSubmit)="submitOnPopup()"
            >
                <!-- password input -->
                <div
                    class="position-relative mt-1"
                    *ngIf="
                        popupAction === 'newOrganization' ||
                        popupAction === 'login'
                    "
                >
                    <div class="form-floating">
                        <input
                            [type]="passwordType"
                            id="password"
                            placeholder="0000"
                            class="form-control"
                            formControlName="password"
                            style="padding-right: 10%"
                        />
                        <label
                            for="password"
                            class="text-dark d-flex align-items-center"
                        >
                            <span class="me-2 mt-1" style="font-size: 14px"
                                ><i class="fa-solid fa-lock"></i
                            ></span>
                            <span style="font-size: 15px"
                                >Password
                                <code class="text-danger">*</code></span
                            >
                        </label>
                    </div>
                    <span
                        class="position-absolute"
                        style="top: 35%; right: 5%"
                        *ngIf="popupForm.controls.password.value"
                    >
                        <span
                            class="pointer text-dark"
                            (click)="changePasswordType('password')"
                            *ngIf="passwordType === 'password'"
                            ><i class="fa-regular fa-eye"></i
                        ></span>
                        <span
                            class="pointer text-dark"
                            (click)="changePasswordType('password')"
                            *ngIf="passwordType === 'text'"
                            ><i class="fa-regular fa-eye-slash"></i
                        ></span>
                    </span>
                </div>

                <!-- pin input -->
                <div
                    class="position-relative"
                    *ngIf="
                        popupAction === 'accept' || popupAction === 'decline'
                    "
                >
                    <div class="form-floating">
                        <input
                            [type]="pinType"
                            id="pin"
                            placeholder="0000"
                            class="form-control"
                            minlength="4"
                            maxlength="4"
                            style="padding-right: 10%"
                            formControlName="pin"
                        />
                        <label
                            for="password"
                            class="text-dark d-flex align-items-center"
                        >
                            <span class="me-2 mt-1" style="font-size: 14px"
                                ><i class="fa-solid fa-lock"></i
                            ></span>
                            <span style="font-size: 15px"
                                >Pin <code class="text-danger">*</code></span
                            >
                        </label>
                    </div>
                    <span
                        class="position-absolute"
                        style="top: 35%; right: 5%"
                        *ngIf="popupForm.controls.pin.value"
                    >
                        <span
                            class="pointer text-dark"
                            (click)="changePasswordType('pin')"
                            *ngIf="pinType === 'password'"
                            ><i class="fa-regular fa-eye"></i
                        ></span>
                        <span
                            class="pointer text-dark"
                            (click)="changePasswordType('pin')"
                            *ngIf="pinType === 'text'"
                            ><i class="fa-regular fa-eye-slash"></i
                        ></span>
                    </span>
                </div>

                <div class="mt-4 text-center">
                    <b
                        class="me-3 pointer text-dark"
                        title="close Modal"
                        (click)="closeModal()"
                        ><small>Cancel</small></b
                    >
                    <button
                        class="btn primary-light text-light px-3"
                        type="submit"
                        i18n
                        [disabled]="!popupForm.valid"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- not found popup -->
<div class="modal" id="notFound" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content p-3 py-5">
            <app-not-found-page></app-not-found-page>
        </div>
    </div>
</div>
