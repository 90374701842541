import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class VisaService {
    constructor(private http: HttpClient, private apiService: ApiService) {}

    lookupCheck(data: any): Observable<any> {
        return this.apiService.post('/checks/check/verification/', data).pipe(
            map((data) => {
                return data;
            })
        );
    }

    treatCheck(treatment: any) {
        return this.apiService.post('/checks/check/treatment/', treatment).pipe(
            map((data) => {
                return data;
            })
        );
    }
}
