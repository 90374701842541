import { Component, OnInit, SimpleChanges, inject } from '@angular/core';
import {
    trigger,
    state,
    style,
    animate,
    transition,
} from '@angular/animations';
import { Output, Input, EventEmitter } from '@angular/core';
import {
    FormControl,
    Validators,
    FormGroup,
    FormBuilder,
} from '@angular/forms';
import { HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { Select, Store } from '@ngxs/store';
import { Observable, fromEvent, Subject } from 'rxjs';
import { debounceTime, delay, take, takeUntil } from 'rxjs/operators';

import { AuthService, VariableService } from '../../../core/services';
import {
    AuthState,
    Logout,
    PopulateOperate,
    DialogState,
    OpenDialog,
    CloseDialog,
    SwitchThemeState,
    PopulateUser,
    OpenActionDialog,
} from 'src/app/shared';
import { ComponentCanDeactivate } from '../../../core/guards';

@Component({
    selector: 'app-auth-corporate',
    templateUrl: './auth-corporate.component.html',
    styleUrls: ['./auth-corporate.component.scss'],
    animations: [
        trigger('myInsertRemoveTrigger', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('300ms', style({ opacity: 20 })),
            ]),
            transition(':leave', [animate('300ms', style({ opacity: 20 }))]),
        ]),
    ],
})
export class AuthCorporateComponent implements OnInit {
    private onDestroy$: Subject<void> = new Subject<void>();
    selectedCorporate: any;
    // corporate = { name: "iHela Credit Union" };
    corporates$: Observable<any>;
    corporates: any;
    isAuthenticated$: Observable<any>;
    isAuthenticated: any;
    page = 'start';
    isOpen = true;
    password = '';
    @Input() showPassword = true;
    @Input() refresh = false;
    source$: Observable<Event> = new Observable<Event>();

    password2 = '';
    passwordType = 'password';
    pinType = 'password';
    isLoading = false;
    showMenu = false;
    organization$: Observable<any>;
    organization: any;
    dialog$: Observable<any>;
    dialog: any;
    private variableService = inject(VariableService);
    isPopulatingOperator = false;

    showModal = false;
    popupAction: any;
    popupOrganizationLogo = '';
    loadingInvitations = true;
    invitations: any;
    selectedInvitation: any;

    clientId$: Observable<any>;
    clientId: any;

    theme$: Observable<any>;
    theme: any;
    popupForm = this.fb.group({ password: [''], pin: [''] });
    pin: any;

    // @HostListener allows us to also guard against browser refresh, close, etc.
    @HostListener('window:beforeunload')
    canDeactivate(): Observable<boolean> | boolean {
        // insert logic to check if there are pending changes here;
        // returning true will navigate without confirmation
        // returning false will show a confirm dialog before navigating away
        return true;
    }

    constructor(
        private authService: AuthService,
        private store: Store,
        private router: Router,
        private fb: FormBuilder
    ) {
        this.corporates$ = this.store.select(AuthState.corporates);
        this.isAuthenticated$ = this.store.select(AuthState.isAuthenticated);
        this.organization$ = this.store.select(AuthState.GetOrganization);
        this.password = this.variableService.password;
        this.dialog$ = this.store.select(DialogState.GetDialog);
        this.clientId$ = this.store.select(AuthState.GetClientId);
        this.theme$ = this.store.select(SwitchThemeState.GetTheme);
    }

    ngOnInit(): void {
        setTimeout(this.cleanPassword, 900000);
        this.corporates$.subscribe((data) => {
            this.corporates = data;
            if (this.corporates) {
                this.isPopulatingOperator = false;
            }
        });
        this.isAuthenticated$.subscribe((isAuthenticated) => {
            this.isAuthenticated = isAuthenticated;
        });
        this.organization$.subscribe({
            next: (organization) => {
                this.organization = organization;
                if (organization !== null) {
                    this.router.navigate(['/w']);
                } else {
                    this.variableService.isPopulatingOperator.subscribe({
                        next: (isPopulating) => {
                            this.isPopulatingOperator = isPopulating;
                        },
                    });
                }
            },
        });
        this.dialog$.subscribe({
            next: (dialog) => {
                this.dialog = dialog;
                if (
                    this.dialog &&
                    this.dialog.response === 'pin submitted' &&
                    this.dialog.action == 'GetPin'
                ) {
                    this.pin = this.variableService.pin;
                    this.submitOnPopup();
                }
            },
        });
        this.clientId$.pipe(takeUntil(this.onDestroy$)).subscribe({
            next: (clientId) => {
                this.clientId = clientId;
                if (this.clientId) {
                    this.getOperatorInvitations();
                }
            },
        });

        this.theme$.pipe(takeUntil(this.onDestroy$)).subscribe({
            next: (theme) => {
                this.theme = theme;
            },
        });
    }

    // ngOnChanges(changes: SimpleChanges) {
    //     for (const propName in changes) {
    //         const chng = changes[propName];
    //         if (propName === 'showPassword') {
    //             console.log('');
    //             if (chng.currentValue) {
    //                 this.showPassword = chng.currentValue;
    //             }
    //         } else if (propName === 'password') {
    //             this.password = chng.currentValue;
    //         } else if (propName === 'refresh') {
    //             if (chng.currentValue) {
    //                 this.isAuthenticated$.subscribe((isAuthenticated) => {
    //                     this.isAuthenticated = isAuthenticated;
    //                 });

    //                 // if (!localStorage.getItem('foo')) {
    //                 //     localStorage.setItem('foo', 'no reload');
    //                 //     location.reload();
    //                 // } else {
    //                 //     localStorage.removeItem('foo');
    //                 // }
    //             }
    //         }
    //     }
    // }

    submitOnPopup() {
        switch (this.popupAction) {
            case 'login':
                this.loginCorporate();
                break;
            case 'accept':
                this.submitInvitationStatus();
                break;
            case 'decline':
                this.submitInvitationStatus();
                break;
        }
    }

    selectCorporate(corporate: any) {
        this.openModal('login');
        this.selectedCorporate = corporate;
        if (this.selectedCorporate) {
            this.popupOrganizationLogo =
                this.selectedCorporate.organization.institution_client.picture;
        }
    }

    deselectCorporate() {
        this.selectedCorporate = null;
        this.page = 'start';
    }

    onChangePage(page: any) {
        this.page = page;
    }

    logout() {
        this.store.dispatch(new Logout());
        // if (!localStorage.getItem('foo2')) {
        //     localStorage.setItem('foo2', 'no reload');
        //     location.reload();
        // } else {
        //     localStorage.removeItem('foo2');
        // }
    }

    loginCorporate() {
        this.isLoading = true;
        this.closeModal();
        const data = {
            title: '',
            type: 'loading',
            message: '',
        };
        this.store.dispatch(new OpenDialog(data));
        if (this.password) {
            const login_data = {
                password: this.popupForm.value.password,
                organization_id: this.selectedCorporate.organization.id,
            };
            if (this.organization === null) {
                this.authService
                    .loginCorporate(login_data)
                    .pipe(takeUntil(this.onDestroy$))
                    .subscribe({
                        next: (data) => {
                            this.password = '';
                            this.showPassword = false;
                            this.popupForm.reset();
                            this.isLoading = false;
                            // console.log("XXXX 3333", data)
                            if (data.object.success === true) {
                                this.store.dispatch(
                                    new CloseDialog({ response: 'close' })
                                );
                                this.store.dispatch(new PopulateOperate());
                                this.router.navigate(['/w']);
                            } else if (data.object.response_code === '07') {
                                this.router.navigate(['/w']);
                            } else if (data.object.success === false) {
                                this.store.dispatch(
                                    new CloseDialog({ response: 'close' })
                                );
                                const response_msg = {
                                    title: '',
                                    type: 'failed',
                                    message: data.object.response_message,
                                };
                                this.store.dispatch(
                                    new OpenDialog(response_msg)
                                );
                                this.selectedCorporate = null;
                            }
                        },
                        error: (err) => {
                            this.password = '';
                            this.showPassword = true;
                            this.isLoading = false;
                            this.popupForm.reset();
                            this.store.dispatch(
                                new CloseDialog({ response: 'close' })
                            );
                            const data = {
                                title: '',
                                type: 'failed',
                                message:
                                    err.object.response_message ??
                                    'Could not Login',
                            };
                            this.store.dispatch(new OpenDialog(data));
                            this.selectedCorporate = null;
                        },
                    });
            } else {
                this.router.navigate(['/w']);
            }
        } else {
            const login_data = {
                password: this.popupForm.value.password,
                organization_id: this.selectedCorporate.organization.id,
            };
            this.authService
                .loginCorporate(login_data)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe({
                    next: (data) => {
                        this.password = '';
                        this.showPassword = false;
                        this.isLoading = false;
                        this.popupForm.reset();
                        if (data.object.success === true) {
                            this.store.dispatch(
                                new CloseDialog({ response: 'close' })
                            );
                            this.store.dispatch(new PopulateOperate());
                            this.router.navigate(['/w']);
                        } else if (data.object.response_code === '07') {
                            this.router.navigate(['/w']);
                        } else if (data.object.success === false) {
                            this.store.dispatch(
                                new CloseDialog({ response: 'close' })
                            );
                            const response_msg = {
                                title: '',
                                type: 'failed',
                                message: data.object.response_message,
                            };
                            this.store.dispatch(new OpenDialog(response_msg));
                        }
                    },
                    error: (err) => {
                        this.password = '';
                        this.showPassword = true;
                        this.isLoading = false;
                        this.popupForm.reset();
                        this.store.dispatch(
                            new CloseDialog({ response: 'close' })
                        );
                        const data = {
                            title: '',
                            type: 'failed',
                            message:
                                err.object.response_message ??
                                'Could not Login',
                        };
                        this.store.dispatch(new OpenDialog(data));
                    },
                });
        }
    }

    getOperatorInvitations() {
        this.authService
            .getOperatorInvitations(this.clientId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (response) => {
                    this.invitations = response.objects;
                    this.loadingInvitations = false;
                },
                error: (err) => {
                    this.isLoading = false;
                },
            });
    }

    submitInvitationStatus() {
        const body = {
            status:
                this.popupAction === 'accept'
                    ? 'C'
                    : this.popupAction === 'decline'
                    ? 'R'
                    : '',
            operator: this.selectedInvitation.id,
            pin_code: this.pin,
        };
        this.closeModal();
        this.store.dispatch(
            new OpenDialog({ title: '', message: '', type: 'loading' })
        );
        this.authService
            .submitInvitationStatus(body)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (response) => {
                    this.popupForm.reset();
                    this.store.dispatch(new CloseDialog({ response: 'close' }));
                    if (
                        response.object['success'] !== undefined &&
                        !response.object.success
                    ) {
                        this.store.dispatch(
                            new OpenDialog({
                                title: '',
                                message: response.object.response_message,
                                type: 'failed',
                            })
                        );
                        return;
                    }
                    this.store.dispatch(
                        new OpenDialog({
                            title: '',
                            message: 'Success',
                            type: 'success',
                        })
                    );
                    this.getOperatorInvitations();
                    this.store.dispatch(new PopulateUser());
                },
                error: (err) => {
                    this.popupForm.reset();
                    this.store.dispatch(new CloseDialog({ response: 'close' }));
                    this.store.dispatch(
                        new OpenDialog({
                            title: '',
                            message:
                                'Something went wrong, please retry again!',
                            type: 'failed',
                        })
                    );
                },
            });
    }

    closeModal() {
        this.showModal = false;
        this.selectedInvitation = null;
        // this.selectedCorporate = null;
    }

    openModal(action: string, operator?: any) {
        this.popupAction = action;
        if (action === 'login' || action === 'newOrganization') {
            this.showModal = true;
            this.popupForm.controls.pin.removeValidators(Validators.required);
            this.popupForm.controls.password.setValidators(Validators.required);
        } else if (action === 'accept' || action === 'decline') {
            // this.popupForm.controls.password.removeValidators(
            //     Validators.required
            // );
            // this.popupForm.controls.pin.setValidators(Validators.required);
            if (operator) {
                this.selectedInvitation = operator;
                this.popupOrganizationLogo =
                    this.selectedInvitation.organization.institution_client.picture;
            }
            this.store.dispatch(
                new OpenActionDialog({
                    action: 'GetPin',
                    message: '',
                    title: '',
                    type: 'pin',
                })
            );
        }
    }

    cleanPassword() {
        this.password = '';
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
    changePasswordType(type: string) {
        if (type === 'password') {
            this.passwordType =
                this.passwordType === 'text'
                    ? 'password'
                    : this.passwordType === 'password'
                    ? 'text'
                    : this.passwordType;
        } else if (type === 'pin') {
            this.pinType =
                this.pinType === 'text'
                    ? 'password'
                    : this.pinType === 'password'
                    ? 'text'
                    : this.pinType;
        }
    }
}
