export class OpenDialog {
    static readonly type = '[Dialog] open Modal';
    constructor(
        public payload: {
            title: string;
            message: string;
            type: string;
        }
    ) {}
}

export class OpenConfirmDialog {
    static readonly type = '[Dialog] open Modal';
    constructor(
        public payload: {
            title: string;
            message: string;
            type: string;
            action: string;
        }
    ) {}
}

export class OpenActionDialog {
    static readonly type = '[Dialog] open Modal';
    constructor(
        public payload: {
            title: string;
            message: string;
            type: string;
            action: string;
        }
    ) {}
}

export class CloseDialog {
    static readonly type = '[Dialog] close Modal';
    constructor(
        public payload: {
            response: string;
        }
    ) {}
}
