import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Store } from '@ngxs/store';
import { AmountState } from 'src/app/shared';

@Component({
    selector: 'app-amount-visibility',
    templateUrl: './amount-visibility.component.html',
    styleUrls: ['./amount-visibility.component.scss'],
})
export class AmountVisibilityComponent implements OnInit, OnDestroy {
    @Input() amount: any; // Input property to receive the amount value
    @Input() currency: any = ' '; // Input property to receive the currency value
    @Input() customClasses: string | undefined; // Input property to receive custom CSS classes

    showAmounts: boolean | undefined; // Variable to store the visibility state of amounts
    showAmounts$: Observable<boolean>; // Observable for the visibility state
    private onDestroy$: Subject<void> = new Subject<void>(); // Subject to trigger the component destruction

    constructor(private store: Store) {
        this.showAmounts$ = this.store.select(AmountState.isShowed); // Select the visibility state from the store
    }

    ngOnInit() {
        // Subscribe to the visibility state to update the showAmounts variable
        this.showAmounts$.pipe(takeUntil(this.onDestroy$)).subscribe({
            next: (showAmount) => {
                this.showAmounts = showAmount;
            },
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next(); // Emit the next value to trigger the component destruction
        this.onDestroy$.complete(); // Complete the subject to clean up resources
    }
}
