<!-- <div
    class="d-flex"
    
>
    <b class="text-secondary" style="font-size: 20px">Overview</b>
    <img
        class="image-fluid arrow-width ms-auto"
        src="{{ overviewOption.image_url }}"
        role="button"
        (click)="openOverview()"
        title="{{ overviewOption.title }}"
    />
</div> -->

<div class="modal fade" data-mdb-backdrop="false" id="myModal">
    <div
        class="modal-dialog w-100 lg-radius d-flex justify-content-center h-100 align-items-center overflow-auto"
        style="min-width: 50%"
    >
        <div class="modal-content w-resp">
            <div class="d-flex justify-content-end">
                <span
                    data-bs-toggle="modal"
                    data-bs-target="#myModal"
                    role="button"
                >
                    <i class="fa fa-xmark text-dark fs-4 mt-2 me-2"></i>
                </span>
            </div>
            <div class="px-4 pb-4">
                <div class="w-100 d-grid">
                    <span class="text-dark fs-3">Overview</span>
                    <hr class="text-dark mt-1" />
                </div>
                <div class="w-100 d-flex justify-content-center">
                    <div
                        class="w-100 d-flex justify-content-center"
                        *ngIf="overviewUrl !== ''"
                    >
                        <div
                            class="row rounded"
                            *ngIf="overViewData && overViewData.length > 0"
                            style="width: 85%"
                        >
                            <div
                                class="col-md-3 col-lg-6 d-flex"
                                *ngFor="
                                    let data of overViewData.flat();
                                    let i = index
                                "
                                [ngClass]="
                                    i + (1 % 2) === 0
                                        ? 'justify-content-end'
                                        : ''
                                "
                            >
                                <div class="mb-4">
                                    <span class="text-dark">
                                        <small>
                                            <i
                                                class="fa-solid text-primary-light fa-xs fa-circle"
                                            ></i>
                                        </small>
                                        <small class="ms-2 text-sm">{{
                                            data.label.toUpperCase()
                                        }}</small> </span
                                    ><br />
                                    <b
                                        class="text-secondary ms-4"
                                        style="font-size: 18px"
                                    >
                                        {{ data.value_data.currency }}
                                        {{ data.value | currency : ' ' }}
                                    </b>
                                </div>
                            </div>
                        </div>
                        <div class="" *ngIf="!overViewData">
                            <span class="my-4">
                                <i class="spinner-border"></i>
                            </span>
                        </div>
                    </div>

                    <div class="mt-2" *ngIf="overviewUrl === ''">
                        <span class="text-secondary"
                            >This list doesn't have any Overview Data.</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row rounded p-2 mt-3" style="border-radius: 10px">
    <div
        class="col-lg d-flex align-items-center align-self-start"
        *ngIf="data_list"
    >
        <b class="text-primary" style="font-size: 20px">{{ title }}</b>
        <span *ngIf="showBalaceStatusEye" role="button" (click)="showAmounts()"
            ><span
                [ngClass]="{
                'text-primary-light': plateform==='workStation',
                'text-primary': plateform!=='workStation',

            }"
                *ngIf="!showAmount"
                title="Show balances"
            >
                <i class="fa-solid fa-eye mt-1 ms-3"></i
            ></span>
            <span
                [ngClass]="{
                'text-primary-light': plateform==='workStation',
                'text-primary': plateform!=='workStation',

            }"
                *ngIf="showAmount"
                title="Hide balances"
            >
                <i class="fa-solid fa-eye-slash mt-1 ms-3"></i></span
        ></span>
    </div>
    <div
        class="col-lg align-self-end"
        style="width: 100%; position: relative"
        *ngIf="data_list"
    >
        <div class="">
            <div class="row">
                <div class="col-lg-6">
                    <div style="height: 30px" *ngIf="show_searchBar">
                        <input
                            class="form-control form-control-sm ps-5"
                            type="text"
                            style="
                                border-radius: 5px;

                                height: 35px;
                                width: 200px;
                                border: 1px #838383 solid;
                            "
                            placeholder="Search"
                            (keydown.enter)="search()"
                            [formControl]="searchName"
                        />

                        <button
                            style="bottom: 15px"
                            class="btn bg-transparent text-dark position-relative translate-middle-y"
                            type="submit"
                            *ngIf="!isLoading"
                            (click)="search()"
                        >
                            <i class="fa-solid fa-search fs-7"></i>
                        </button>
                        <span
                            style="bottom: 15px"
                            *ngIf="isLoading"
                            class="btn bg-transparent position-relative translate-middle-y text-dark"
                            ><i class="fas fa-spinner fa-pulse"></i
                        ></span>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div
                        role="button"
                        class="text-dark d-flex justify-content-around align-items-center"
                    >
                        <div
                            (click)="displayFilters()"
                            class="sm-radius justify-content-center align-items-center"
                            [ngClass]="
                                theme === 'dark-mode' || 'magis-dark'
                                    ? 'text-dark'
                                    : 'text-secondary'
                            "
                            style="
                                border: 1px #414141 solid;
                                height: 32px;
                                width: 81px;
                                display: inline-flex;
                            "
                        >
                            <div class="center1 m-auto">
                                Filter
                                <small
                                    class="ms-3 fa-solid fa-filter text-small"
                                ></small>
                            </div>
                        </div>

                        <div
                            style="
                                border: 1px #414141 solid;
                                height: 32px;
                                width: 50px;
                                display: inline-flex;
                            "
                            class="justify-content-center mx-3 sm-radius justify-content-center align-items-center"
                        >
                            <!-- <p> -->
                            <span
                                class="text-center"
                                role="button"
                                title="Refresh"
                                (click)="getData()"
                                *ngIf="!isLoading"
                            >
                                <i class="fa-solid fa-arrows-rotate"></i>
                            </span>
                            <span *ngIf="isLoading"
                                ><i class="fas fa-spinner fa-pulse"></i
                            ></span>
                            <!-- </p>     -->
                        </div>
                        <button
                            class="btn ms-auto bg-primary text-all-white"
                            data-bs-toggle="modal"
                            data-bs-target="#myModal"
                        >
                            <b>Overview</b>
                        </button>
                    </div>
                </div>
                <app-filters
                    *ngIf="showFilters"
                    (selectedPeriod)="getSelectedPeriod($event)"
                ></app-filters>
            </div>
        </div>
    </div>
</div>
<!-- <div class="level-2 mt-2 rounded" style="height: 70vh; overflow-y: auto"> -->

<div class="d-flex" style="margin-top: 30px; width: 100%" *ngIf="todayDate">
    <div class="horizontal-line"></div>
    <span class="ms-auto text-primary px-2" style="margin-top: -1%">Today</span>
    <div class="horizontal-line ms-auto"></div>
</div>
<div
    class="mt-2 rounded"
    [ngClass]="
        overviewUrl !== '' && !overviewOption.hidden
            ? 'shortHeight'
            : 'longHeight'
    "
    style="width: 100%"
>
    <div
        class="row ps-2 pe-2 rounded"
        [ngClass]="{
            tableHeaderLight: theme === 'magis-light',
            tableHeaderDark: theme === 'magis-dark'
        }"
        *ngIf="data_list && data_list.length > 0"
    >
        <div
            [ngClass]="
                header.canBeDisplayed
                    ? header.size !== ''
                        ? 'col-lg-' + header.size
                        : 'col-lg'
                    : ''
            "
            *ngFor="let header of headers"
            role="button"
            style="margin: auto; padding: auto"
        >
            <div *ngIf="header.canBeDisplayed">
                <small
                    class="text-dark"
                    *ngIf="data_list && data_list.length > 0"
                    ><strong>{{ header.name }} </strong>
                    <!-- <i class="ms-1 fa-solid fa-sort"></i> -->
                </small>
            </div>
        </div>
    </div>
    <!-- <div class="lineHeader"></div> -->

    <div *ngIf="!response_data" class="d-grid px-2 pt-3">
        <span class="w-100 mb-2">
            <ngx-skeleton-loader
                [theme]="{
                'height.px': 78,
                'width.%': 100,
            }"
            ></ngx-skeleton-loader
        ></span>
        <span class="w-100 mb-2">
            <ngx-skeleton-loader
                [theme]="{
                'height.px': 78,
                'width.%': 100,
            }"
            ></ngx-skeleton-loader
        ></span>
        <span class="w-100 mb-2">
            <ngx-skeleton-loader
                [theme]="{
                'height.px': 78,
                'width.%': 100,
            }"
            ></ngx-skeleton-loader
        ></span>
        <span class="w-100 mb-2">
            <ngx-skeleton-loader
                [theme]="{
                'height.px': 78,
                'width.%': 100,
            }"
            ></ngx-skeleton-loader
        ></span>
        <span class="w-100 mb-2">
            <ngx-skeleton-loader
                [theme]="{
                'height.px': 78,
                'width.%': 100,
            }"
            ></ngx-skeleton-loader
        ></span>
    </div>

    <div
        *ngIf="response_data && data_list && data_list.length > 0"
        class="mt-2"
        style="position: relative"
        [ngClass]="
            theme === 'light-mode' || 'dark-mode' ? 'bankingPadding' : ''
        "
    >
        <div
            class="row ps-2 pe-2 mb-2 rounded justify-content-center"
            *ngFor="let line of data_list"
            [ngClass]="{
                'table-dark lineDark': theme === 'magis-dark',
                'table-light lineLight': theme === 'magis-light',
                'border level-1': theme === 'light-mode',
                'border-dark level-2': theme === 'dark-mode'
            }"
        >
            <div
                [ngClass]="
                    data.canBeDisplayed
                        ? data.size !== ''
                            ? 'col-lg-' + data.size
                            : 'col-lg'
                        : ''
                "
                *ngFor="let data of line; index as i"
                class="align-self-center"
            >
                <div
                    [ngClass]="
                        data.detail !== '' ? 'p-0 is-hoverable clickable' : ''
                    "
                    [routerLink]="data.detail !== '' ? data.detail : []"
                    *ngIf="data.canBeDisplayed"
                >
                    <small>
                        <!-- [ngClass]="data.css !== '' ? data.css : ''" -->
                        <div
                            *ngIf="!data.boolean"
                            [ngClass]="[
                                i === 0 ? 'text-primary' : 'text-dark',
                                data.class !== ''
                                    ? 'badge-design ' + data.class
                                    : ''
                            ]"
                        >
                            <i
                                class="me-2"
                                [ngClass]="
                                    data.icon !== ''
                                        ? 'fa-solid fa-' + data.icon
                                        : ''
                                "
                                *ngIf="data.icon !== ''"
                            ></i>
                            <span *ngIf="!data.format || data.format === ''">{{
                                data.value
                            }}</span>
                            <span
                                *ngIf="data.format === 'date'"
                                class="text-dark"
                                >{{ data.value | date : 'medium' }}</span
                            >
                            <span
                                *ngIf="data.format === 'currency'"
                                class="text-dark"
                            >
                                <span *ngIf="showAmount">{{
                                    data.value | currency : 'BIF '
                                }}</span>
                                <span *ngIf="!showAmount">BIF *** ***</span>
                            </span>
                        </div>
                        <div
                            *ngIf="
                                data.boolean &&
                                !data.option1 &&
                                !data.option2 &&
                                !data.value1 &&
                                !data.value2
                            "
                        >
                            <div *ngIf="data.value">
                                <small
                                    ><i
                                        class="fa-solid fa-circle-check fs-6 text-success"
                                    ></i
                                ></small>
                            </div>
                            <div *ngIf="!data.value">
                                <small
                                    ><i
                                        class="fa-solid fa-circle-xmark fs-6"
                                        style="color: #e5ccd1"
                                    ></i
                                ></small>
                            </div>
                        </div>
                        <!-- to display icon from option1 and option2 put manually -->
                        <div
                            *ngIf="data.boolean && data.option1 && data.option2"
                        >
                            <div *ngIf="data.value">
                                <small
                                    ><i
                                        class="{{
                                            data.option1
                                        }} fs-3 text-success"
                                    ></i
                                ></small>
                            </div>
                            <div *ngIf="!data.value">
                                <small
                                    ><i
                                        class="{{
                                            data.option2
                                        }} fs-3 text-primary"
                                    ></i
                                ></small>
                            </div>
                        </div>
                        <!-- to display data from value1 and value2 put manually -->
                        <div *ngIf="data.boolean && data.value1 && data.value2">
                            <div *ngIf="data.value">
                                <span
                                    class="badge-design badge bg-success text-success"
                                >
                                    {{ data.value1 }}
                                </span>
                            </div>
                            <div *ngIf="!data.value">
                                <span class="badge-design badge bg-primary">
                                    {{ data.value2 }}
                                </span>
                            </div>
                        </div>
                    </small>
                </div>
            </div>
            <!-- <div class="line"></div> -->
        </div>
    </div>

    <div
        class="row justify-content-md-center"
        *ngIf="response_data && data_list && data_list.length === 0"
    >
        <div class="col-auto">
            <div class="text-danger" i18n>
                <span class="fs-6 me-1">
                    <i class="fa-solid fa-ban"></i>
                </span>
                There is no {{ title }}
            </div>
        </div>
    </div>

    <div
        class="d-flex"
        *ngIf="response_data && data_list && data_list.length > 0"
        style="bottom: 90px; position: inherit"
    >
        <div class="ms-auto">
            <div class="d-flex" style="position: relative">
                <div>
                    <b class="text-dark">Page size</b>
                </div>
                <div
                    class="ps-2"
                    style="position: absolute; bottom: 0px; margin-left: 75px"
                >
                    <div
                        *ngIf="
                            displayPaginationLimit &&
                            clientPagination.filters.limit
                        "
                        id="pagination"
                        class="text-dark"
                    >
                        <div *ngFor="let limit of paginationsLimit">
                            <div
                                role="button"
                                class="w-100"
                                (click)="selectPagintationLimit(limit)"
                                [ngClass]="
                                    theme === 'magis-light'
                                        ? limit !==
                                          clientPagination.filters.limit
                                            ? canMoveNext(limit) === true
                                                ? 'disabled selectedLimitLightBg'
                                                : 'selectedLimitLightBg'
                                            : 'sortLightBg'
                                        : theme === 'magis-dark'
                                        ? limit !==
                                          clientPagination.filters.limit
                                            ? canMoveNext(limit) === true
                                                ? 'disabled selectedLimitDarkBg'
                                                : 'selectedLimitDarkBg'
                                            : 'sortDarkBg'
                                        : theme === 'light-mode'
                                        ? limit !==
                                          clientPagination.filters.limit
                                            ? canMoveNext(limit) === true
                                                ? 'disabled level-2'
                                                : 'level-2'
                                            : 'level-1'
                                        : theme === 'dark-mode'
                                        ? limit !==
                                          clientPagination.filters.limit
                                            ? canMoveNext(limit) === true
                                                ? 'disabled level-3'
                                                : 'level-3'
                                            : 'level-2'
                                        : ''
                                "
                            >
                                <b class="center1">{{ limit }}</b>
                            </div>
                        </div>
                    </div>
                    <div
                        class="d-flex text-dark"
                        [ngClass]="
                            theme === 'magis-dark'
                                ? 'selectedLimitDarkBg'
                                : theme === 'dark-mode'
                                ? 'bg-light'
                                : 'selectedLimitLightBg'
                        "
                        style="border-radius: 2px"
                        role="button"
                        (click)="openPagination()"
                    >
                        <div>
                            <span class="m-1">{{
                                clientPagination.filters.limit
                            }}</span>
                        </div>
                        <div
                            class="ps-2 pe-2 ms-2"
                            [ngClass]="
                                theme === 'magis-dark'
                                    ? 'sortDarkBg'
                                    : theme === 'dark-mode'
                                    ? 'level-3'
                                    : 'sortLightBg'
                            "
                            style="
                                border-top-right-radius: 2px;
                                border-bottom-right-radius: 2px;
                            "
                        >
                            <span>
                                <i class="fa-solid fa-sort-down"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <!-- <div class="d-flex">
                    <span class=""></span>
                    <div><i class="fa-solid fa-sort-down"></i> </div>
                </div> -->
            </div>
        </div>
        <div class="ms-auto">
            <button
                class="btn btn-sm btn-light rounded me-2"
                [disabled]="currentPage === 0"
                (click)="doListMove('prev')"
            >
                <i class="fa-solid fa-angle-left"></i></button
            ><button
                class="btn btn-sm btn-light rounded me-2"
                [disabled]="
                    response_data.count <
                    (currentPage + 1) * clientPagination.filters.limit
                "
                (click)="doListMove('next')"
                *ngIf="clientPagination.filters.limit"
            >
                <i class="fa-solid fa-angle-right"></i>
            </button>
            <small
                ><i class="text-dark"
                    >Page {{ currentPage + 1 }} of {{ pages + 1 }}</i
                ></small
            >
        </div>
        <div class="ms-auto">
            <div class="" *ngIf="!showNotification">
                <b
                    class="text-primary"
                    role="button"
                    (click)="doExcelExport()"
                    *ngIf="!isExporting"
                    >Export Excel</b
                >
                <b *ngIf="isExporting"
                    >exporting excel ... {{ exportingState }} %
                    <span class="ms-2" title="Refresh" (click)="refreshExport()"
                        ><i class="fa-solid fa-arrows-rotate"></i></span
                ></b>
            </div>

            <div
                class="p-2 ps-4 pe-4 notification-success"
                style="max-width: 300px"
                *ngIf="showNotification"
            >
                <div class="d-flex">
                    <div style="margin-top: auto; margin-bottom: auto">
                        <span>
                            <i
                                class="fa-solid fa-circle-check me-2 text-success fs-5"
                            ></i
                        ></span>
                    </div>
                    <div>
                        <b style="font-size: 14px">Successfully Exported</b>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
