<div
    class="rounded p-2 w-100 d-grid text-dark"
    [ngClass]="
    {
        'level-1': plateform==='workStation' || theme==='dark-mode',
    }
    "
>
    <!-- If there is no bank selected -->

    <div
        *ngIf="!selectedBank.id && plateform !== 'workStation'"
        class="expand-collapse-section"
    >
        <div>
            <i
                class="fa fa-circle fa-xs me-2"
                [ngClass]="{
                    'text-primary-light': plateform === 'workStation',
                    'text-primary': plateform !== 'workStation'
                }"
            ></i>
            <b class="text-dark">Select a bank :</b>
        </div>

        <div>
            <div class="p-2 row" style="gap: 2rem 0" *ngIf="clientBanks">
                <div
                    *ngFor="let bank of clientBanks"
                    (click)="selectBank(bank)"
                    role="button"
                    class="d-grid col-6 col-sm-4 col-md-3 justify-content-center"
                >
                    <div class="d-flex w-100 mb-2 justify-content-center">
                        <div
                            class="d-grid justify-content-center align-items-center img-container border lg-radius"
                        >
                            <img
                                class="bank-image"
                                [src]="bank?.company?.image"
                            />
                        </div>
                    </div>

                    <div class="text-center">
                        <span>{{ bank?.company?.fullname.toUpperCase() }}</span>
                    </div>
                </div>
            </div>
            <div *ngIf="!clientBanks" class="d-flex pt-3">
                <div *ngFor="let _ of [1, 2, 3]" class="d-grid mt-1 me-5">
                    <span class="text-center">
                        <ngx-skeleton-loader
                            [theme]="{
                                'height.px': 60,
                                'width.px': 60,
                                opacity: 0.7
                            }"
                            appearance="line"
                        ></ngx-skeleton-loader
                    ></span>
                    <span>
                        <ngx-skeleton-loader
                            [theme]="{
                                'height.px': 7,
                                'width.px': 100,
                                opacity: 0.7
                            }"
                            appearance="line"
                        ></ngx-skeleton-loader>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <!-- this section is showed if we have selectedBank or wallet is showed -->
    <!--Title description-->
    <div
        class="d-flex align-items-center justify-content-between"
        *ngIf="(isWalletShown && !selectedBank.id) || selectedBank.id"
    >
        <div class="" *ngIf="title !== ''">
            <i class="fa fa-circle fa-xs me-2 text-primary"></i>
            <b class="text-dark">{{ title }}</b>
        </div>
    </div>

    <!-- this section is showed if we have selectedBank or wallet is showed -->
    <div
        class="d-flex align-items-start"
        *ngIf="(isWalletShown && !selectedBank.id) || selectedBank.id"
        [ngClass]="{
            'overflow-scroll': plateform === 'workStation',
        }"
    >
        <div
            class="d-grid mt-2"
            [ngClass]="{
                'w-100': plateform === 'workStation'
            }"
        >
            <div
                class="ms-4 mb-3"
                style="height: 50px"
                *ngIf="
                    plateform !== 'workStation' &&
                    selectedBank &&
                    selectedBank.id
                "
            >
                <app-switch-bank
                    (bankOptions)="getSwitchBankOptions($event)"
                ></app-switch-bank>
            </div>

            <!-- *Debit accounts type  -->
            <div
                style="height: 50px"
                [ngClass]="{
                    ' px-2': plateform !== 'workStation',
                    'w-75': plateform === 'workStation' && !isTermDeposit,
                }"
                class="d-flex"
            >
                <div
                    class="d-flex me-4 p-1 justify-content-between rounded align-items-center"
                    [ngClass]="{
                        'level-2': plateform === 'workStation' || isGrey,
                        'level-1':
                            plateform !== 'workStation' &&
                            theme === 'light-mode',
                        'level-3':
                            plateform !== 'workStation' &&
                            theme === 'dark-mode',
                        'w-auto': isTermDeposit,

                        'w-100': plateform === 'workStation'
                    }"
                >
                    <div
                        *ngIf="
                            plateform === 'workStation' &&
                            !isTermDeposit &&
                            !isOperation
                        "
                        style="transition: all 0.2s ease-in-out"
                        role="button"
                        class="text-center text-dark width"
                        (click)="selectDebitAccountType('cash')"
                        [ngClass]="{
                            'primary-light text-white rounded p-1':
                                selectedDebitAccountType === 'cash',
                            'not-clickable':
                                plateform === 'workStation' && isTermDeposit
                        }"
                    >
                        <b i18n>Cash</b>
                    </div>
                    <div
                        *ngIf="isAccountShown"
                        role="button"
                        (click)="selectDebitAccountType('account')"
                        style="transition: all 0.2s ease-in-out"
                        [ngClass]="{
                            'bg-primary  text-white rounded':
                                plateform !== 'workStation' &&
                                selectedDebitAccountType === 'account',
                            'primary-light text-white rounded':
                                (plateform === 'workStation' &&
                                    selectedDebitAccountType === 'account') ||
                                isTermDeposit,

                            'd-none':
                                selectedBank &&
                                !selectedBank.id &&
                                plateform !== 'workStation'
                        }"
                        class="text-white text-center px-1 py-2 ms-1 width"
                    >
                        <!-- 'not-clickable': (!isWalletShown && plateform !==
                        'workStation') || isTermDeposit, -->
                        <b class="" *ngIf="plateform === 'workStation'" i18n
                            >Client account</b
                        >
                        <b
                            class=""
                            *ngIf="
                                plateform !== 'workStation' &&
                                selectedBank &&
                                selectedBank.id
                            "
                            i18n
                            >Account</b
                        >
                    </div>
                    <div
                        *ngIf="isWalletShown"
                        role="button"
                        (click)="selectDebitAccountType('wallet')"
                        style="transition: all 0.2s ease-in-out"
                        [ngClass]="{
                            'bg-primary  text-white rounded px-1 py-2 me-1':
                                plateform !== 'workStation' &&
                                selectedDebitAccountType === 'wallet',
                            'primary-light text-white rounded p-1':
                                plateform === 'workStation' &&
                                selectedDebitAccountType === 'wallet',
                            'not-clickable':
                                plateform === 'workStation' && isTermDeposit
                        }"
                        class="text-center text-dark pe-3 width"
                    >
                        <b i18n>Wallet</b>
                    </div>
                    <div
                        *ngIf="plateform === 'workStation' && !isTermDeposit"
                        style="transition: all 0.2s ease-in-out"
                        role="button"
                        class="text-center text-dark width"
                        (click)="selectDebitAccountType('internal')"
                        [ngClass]="{
                            'primary-light text-white rounded p-1':
                                selectedDebitAccountType === 'internal',
                            'not-clickable':
                                plateform === 'workStation' && isTermDeposit
                        }"
                    >
                        <b i18n>Internal account</b>
                    </div>
                    <div
                        style="transition: all 0.2s ease-in-out"
                        *ngIf="plateform === 'workStation' && !isTermDeposit"
                        role="button"
                        class="text-center text-dark width"
                        (click)="selectDebitAccountType('treasury')"
                        [ngClass]="{
                            'primary-light text-white rounded p-1':
                                selectedDebitAccountType === 'treasury',
                            'not-clickable':
                                plateform === 'workStation' && isTermDeposit
                        }"
                    >
                        <b i18n>Alias</b>
                    </div>
                    <div
                        style="transition: all 0.2s ease-in-out"
                        *ngIf="plateform === 'workStation' && !isTermDeposit"
                        role="button"
                        class="text-center width text-dark"
                        (click)="selectDebitAccountType('agent')"
                        [ngClass]="{
                            'primary-light text-white rounded p-1':
                                selectedDebitAccountType === 'agent',
                            'not-clickable':
                                plateform === 'workStation' && isTermDeposit
                        }"
                    >
                        <b i18n>Agent</b>
                    </div>
                    <div
                        style="transition: all 0.2s ease-in-out"
                        *ngIf="plateform === 'workStation' && !isTermDeposit"
                        role="button"
                        class="text-center text-dark width"
                        (click)="selectDebitAccountType('merchant')"
                        [ngClass]="{
                            'primary-light text-white rounded p-1':
                                selectedDebitAccountType === 'merchant',
                            'not-clickable':
                                plateform === 'workStation' && isTermDeposit
                        }"
                    >
                        <b class="" i18n>Merchant</b>
                    </div>
                </div>
                <div (click)="toggleBalance()" role="button" class="mb-2">
                    <span *ngIf="!isBalanceShown" title="Show balance">
                        <i class="fa-solid fa-eye mt-3"></i
                    ></span>
                    <span *ngIf="isBalanceShown" title="Hide balance">
                        <i class="fa-solid fa-eye-slash mt-3"></i
                    ></span>
                </div>
            </div>

            <!-- *END OF DEBIT ACCOUNTS TYPES-->

            <!-- *Accounts list container-->
            <div
                *ngIf="
                    plateform !== 'workStation' &&
                    selectedDebitAccountType === 'account' &&
                    !debitAccount &&
                    selectedBank &&
                    selectedBank.id
                "
                [ngClass]="{
                    'level-2': plateform === 'workStation' || isGrey,
                    'level-1':
                        plateform !== 'workStation' && theme === 'light-mode',
                    'level-3':
                        plateform !== 'workStation' && theme === 'dark-mode'
                }"
                class="customized-border-secondary b-right mt-2 p-3 ms-2 lg-radius overflow-y-auto"
            >
                <!--* spinner when there are no accounts-->
                <span
                    *ngIf="!accounts"
                    class="text-center w-100 h-100 align-items-center d-flex justify-content-center text-dark"
                    title="Loading accounts..."
                >
                    <i class="spinner-border"></i>
                </span>
                <!--*END OF SPINNER-->

                <!--*No accounts message-->
                <span
                    *ngIf="accounts && accounts.length == 0"
                    class="text-center w-100 h-100 align-items-center d-flex justify-content-center text-dark"
                >
                    <span class="text-secondary">
                        <i class="fa fa-ban me-1"></i>
                        <b>You don't have any account</b>
                    </span>
                </span>

                <!-- *Accounts list loop-->
                <div *ngIf="accounts && accounts.length > 0" class="">
                    <div
                        *ngFor="let account of accounts; let i = index"
                        [ngClass]="{
                            'level-2': plateform === 'workStation' || isGrey,
                            'level-1':
                                plateform !== 'workStation' &&
                                theme === 'light-mode',
                            'level-3':
                                plateform !== 'workStation' &&
                                theme === 'dark-mode'
                        }"
                        class="p-2 rounded"
                    >
                        <div
                            (click)="selectDebitAccount(i)"
                            class="d-grid ps-3 py-0 account b-left rounded"
                            [ngClass]="{
                                'not-clickable b-gray':
                                    account?.acc_status.status_code === 'B' ||
                                    account?.acc_status.status_code === 'C'
                            }"
                            role="button"
                        >
                            <b style="font-weight: 900">{{
                                account.acc_get_title
                            }}</b>
                            <b style="font-weight: 400">{{
                                account.acc_holder
                            }}</b>
                            <b style="font-weight: 400">{{
                                account.acc_short_number
                            }}</b>
                            <b
                                style="font-weight: 400"
                                [ngClass]="{
                                    't-suc': theme === 'dark-mode',
                                    'text-success': theme === 'light-mode'
                                }"
                            >
                                <span *ngIf="isBalanceShown">{{
                                    account.acc_available_balance
                                        | currency : ' '
                                }}</span>
                                <span *ngIf="!isBalanceShown">*** ***</span>
                                {{ account.acc_currency }}</b
                            >
                        </div>
                        <hr class="mx-4 my-1" />
                    </div>
                </div>
            </div>

            <!--* ___END OF ACCOUNTS LIST CONTAINER___-->

            <!--* wallets list container-->
            <div
                *ngIf="
                    plateform !== 'workStation' &&
                    selectedDebitAccountType === 'wallet' &&
                    !debitWallet
                "
                [ngClass]="{
                    'level-2': plateform === 'workStation' || isGrey,
                    'level-1':
                        plateform !== 'workStation' && theme !== 'dark-mode',
                    'level-3':
                        plateform !== 'workStation' && theme === 'dark-mode'
                }"
                class="border border-secondary b-right mt-2 ms-2 p-3 lg-radius overflow-y-auto"
            >
                <!--* spinner when there are no wallets yet-->
                <span
                    *ngIf="!wallets"
                    class="text-center w-100 h-100 align-items-center d-flex justify-content-center text-dark"
                    title="Loading wallets..."
                >
                    <i class="spinner-border"></i>
                </span>

                <!--* No wallets found message-->
                <span
                    *ngIf="wallets && wallets.length == 0"
                    class="text-center w-100 h-100 align-items-center d-flex justify-content-center text-dark"
                >
                    <span class="text-secondary">
                        <i class="fa fa-ban me-1"></i>
                        <b>You don't have any wallet</b>
                    </span>
                </span>

                <!--* wallets list loop-->
                <div *ngIf="wallets && wallets.length > 0" class="">
                    <div *ngFor="let wallet of wallets; let i = index">
                        <div
                            (click)="selectDebitWallet(i)"
                            class="d-grid ps-3 py-0 w-100 account b-left rounded"
                            role="button"
                        >
                            <b style="font-weight: 900">{{ wallet.title }}</b>
                            <b style="font-weight: 400">{{
                                wallet.account.account_holder
                            }}</b>
                            <b
                                style="font-weight: 400"
                                [ngClass]="{
                                    't-suc': theme === 'dark-mode',
                                    'text-success': theme === 'light-mode'
                                }"
                            >
                                <span *ngIf="isBalanceShown">{{
                                    wallet.available_balance | currency : ' '
                                }}</span>
                                <span *ngIf="!isBalanceShown">*** ***</span>
                                {{ wallet.currency }}</b
                            >
                        </div>
                        <hr class="mx-4" />
                    </div>
                </div>

                <!--* __END OF WALLETS LIST LOOP-->
            </div>

            <!--* END OF WALLETS LIST CONTAINER-->

            <!--* selected account container-->
            <div
                *ngIf="
                    debitAccount &&
                    plateform !== 'workStation' &&
                    selectedDebitAccountType === 'account' &&
                    !isAmountChanging &&
                    selectedBank &&
                    selectedBank.id
                "
                class="mt-3 w-100 lg-radius"
            >
                <div
                    class="p-2 ms-2 lg-radius"
                    [ngClass]="
        {
         'level-2': plateform==='workStation' || isGrey,
         'level-1': plateform!=='workStation' && theme !=='dark-mode',
         'level-3': plateform!=='workStation' && theme==='dark-mode',

         }
            "
                >
                    <div
                        class="d-flex align-items-start justify-content-between ps-2 py-0 border-left border-4 border-primary"
                    >
                        <div class="d-grid">
                            <b style="font-weight: 900">{{
                                debitAccount.acc_get_title
                            }}</b>
                            <b style="font-weight: 400">{{
                                debitAccount.acc_holder
                            }}</b>
                            <b style="font-weight: 400">{{
                                debitAccount.acc_short_number
                            }}</b>
                            <b
                                style="font-weight: 400"
                                [ngClass]="{
                                    't-suc': theme === 'dark-mode',
                                    'text-success': theme === 'light-mode'
                                }"
                            >
                                <span *ngIf="isBalanceShown">{{
                                    debitAccount.acc_available_balance
                                        | currency : ' '
                                }}</span>
                                <span *ngIf="!isBalanceShown">*** ***</span>
                                {{ debitAccount.acc_currency }}</b
                            >
                        </div>

                        <div>
                            <span
                                role="button"
                                (click)="deselectDebitAccount()"
                                class="bg-danger text-white ms-5 pt-1 px-1"
                                style="border-radius: 50%"
                            >
                                <i class="fa fa-xmark"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <span
                class="mt-3"
                *ngIf="
                    debitAccount &&
                    plateform !== 'workStation' &&
                    selectedDebitAccountType === 'account' &&
                    isAmountChanging
                "
            >
                <ngx-skeleton-loader
                    [theme]="{
                        'height.px': 100,
                        'width.px': 364,
                    }"
                ></ngx-skeleton-loader>
            </span>

            <!-- * __END OF SELECTED ACCOUNT__ -->

            <!--*selected wallet container-->
            <div
                *ngIf="
                    debitWallet &&
                    plateform !== 'workStation' &&
                    selectedDebitAccountType === 'wallet' &&
                    !isAmountChanging
                "
                class="mt-3 w-100"
            >
                <div
                    class="p-2 ms-2 lg-radius"
                    [ngClass]="
        {
         'level-2': plateform==='workStation' || isGrey,
         'level-1': plateform!=='workStation' && theme==='light-mode',
         'level-3': plateform!=='workStation' && theme==='dark-mode',

         }
            "
                >
                    <div
                        class="d-flex align-items-start justify-content-between ps-2 py-0"
                        style="border-left: 4px solid #810019"
                    >
                        <div class="d-grid">
                            <b style="font-weight: 900">{{
                                debitWallet.title
                            }}</b>
                            <b style="font-weight: 400">{{
                                debitWallet.account.account_holder
                            }}</b>
                            <b
                                style="font-weight: 400"
                                [ngClass]="{
                                    't-suc': theme === 'dark-mode',
                                    'text-success': theme === 'light-mode'
                                }"
                            >
                                <span *ngIf="isBalanceShown">{{
                                    debitWallet.available_balance
                                        | currency : ' '
                                }}</span>
                                <span *ngIf="!isBalanceShown">*** ***</span>

                                {{ debitWallet.currency }}</b
                            >
                        </div>

                        <div>
                            <span
                                role="button"
                                (click)="deselectDebitAccount()"
                                class="bg-danger text-white ms-5 pt-1 px-1"
                                style="border-radius: 50%"
                            >
                                <i class="fa fa-xmark"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <span
                class="mt-3"
                *ngIf="
                    debitWallet &&
                    plateform !== 'workStation' &&
                    selectedDebitAccountType === 'wallet' &&
                    isAmountChanging
                "
            >
                <ngx-skeleton-loader
                    [theme]="{
                        'height.px': 100,
                        'width.px': 364,
                    }"
                ></ngx-skeleton-loader>
            </span>

            <!-- *END OF SELECTED WALLET CONTAINER-->
        </div>

        <!--* switch bank component-->

        <!--* END OF switch bank component-->
    </div>

    <div class="ms-4" *ngIf="plateform === 'workStation'">
        <div
            class="pt-3"
            *ngIf="selectedDebitAccountType === 'account' || isTermDeposit"
        >
            <app-lookup-individual
                (individualInfo)="getIndividualClient($event)"
                (closeInfo)="closeAccountIndividual($event)"
            ></app-lookup-individual>
        </div>

        <div style="width: 400px" *ngIf="selectedDebitAccountType === 'cash'">
            <app-ticketing (amount)="getAmountOptions($event)"></app-ticketing>
        </div>
        <div
            *ngIf="
                selectedDebitAccountType !== 'cash' &&
                selectedDebitAccountType !== 'account'
            "
        >
            <!-- <div *ngIf="!isTermDeposit && selectedDebitAccountType">
                <label for="creditAccount" class="text-dark" i18n
                    >Account number</label
                >
            </div> -->

            <div class="w-35 mt-3">
                <div *ngIf="selectedDebitAccountType === 'wallet'">
                    <span></span>
                    <!-- <app-lookup
                        class="w-100"
                        [option]="lookupType"
                        (selectedItemEvent)="getClient($event)"
                        [url]="lookupDebitAccountUrl"
                        lookupLabel="Search wallets"
                        itemsLabel="Wallets"
                    ></app-lookup> -->
                    <div class="d-flex" *ngIf="!walletName">
                        <input
                            type="text"
                            class="lookup form-control pe-5 ps-2 level-1 lookup-transfer-input"
                            required
                            [formControl]="lookupWallet"
                            (keydown.enter)="lookupAccount()"
                            placeholder="search wallet..."
                        />

                        <button
                            (click)="lookupAccount()"
                            role="button"
                            class="btn bg-none text-dark position-relative"
                            style="right: 14%"
                            [ngClass]="
                                plateform === 'workStation'
                                    ? 'look-w'
                                    : 'look-b'
                            "
                        >
                            <span *ngIf="!walletSearching">
                                <i class="fa fa-search fs-5"></i>
                            </span>
                            <span *ngIf="walletSearching">
                                <i
                                    class="fa fs-5 fa-pulse fa-circle-notch text-dark"
                                ></i>
                            </span>
                        </button>
                    </div>
                    <div *ngIf="walletName">
                        <div
                            class="d-flex align-items-start justify-content-between ps-2 py-0"
                            style="border-left: 4px solid #2070b8"
                            role="button"
                        >
                            <div class="d-grid text-dark">
                                <b style="font-weight: 900">{{
                                    walletNumber
                                }}</b>
                                <b style="font-weight: 400">{{ walletName }}</b>
                            </div>

                            <div>
                                <span
                                    (click)="deselectCreditAccount()"
                                    class="bg-danger text-white ms-5 pt-1 px-1"
                                    style="border-radius: 50%"
                                >
                                    <i class="fa fa-xmark"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectedDebitAccountType === 'internal'">
                    <app-lookup
                        class="w-100"
                        [option]="lookupType"
                        (selectedItemEvent)="getClient($event)"
                        [url]="lookupDebitAccountUrl"
                        itemsLabel="Internal accounts"
                        lookupLabel="Internal accounts"
                    ></app-lookup>
                </div>
                <div *ngIf="selectedDebitAccountType === 'agent'">
                    <app-lookup
                        class="w-100"
                        [option]="lookupType"
                        (selectedItemEvent)="getClient($event)"
                        [url]="lookupDebitAccountUrl"
                        lookupLabel="Agent account"
                        itemsLabel="Agent accounts"
                    ></app-lookup>
                </div>
                <div *ngIf="selectedDebitAccountType === 'treasury'">
                    <app-lookup
                        class="w-100"
                        [option]="lookupType"
                        (selectedItemEvent)="getClient($event)"
                        [url]="lookupDebitAccountUrl"
                        lookupLabel="Treasury account"
                        itemsLabel="Treasury accounts"
                    ></app-lookup>
                </div>
                <div *ngIf="selectedDebitAccountType === 'merchant'">
                    <app-lookup
                        class="w-100"
                        [option]="lookupType"
                        (selectedItemEvent)="getClient($event)"
                        [url]="lookupDebitAccountUrl"
                        lookupLabel="Merchant Account"
                        itemsLabel="Merchant accounts"
                    ></app-lookup>
                </div>
            </div>
        </div>

        <div class="ms-2 d-flex align-items-center mt-1" *ngIf="debitAccount">
            <i class="fa fa-play text-primary-light me-2"></i>
            <b class="text-primary-light">{{ debitAccount.lookup_title }}</b>
        </div>
    </div>
</div>
