<!-- * The center section that contains the icons that help us to switch platform-->
<div class="mt-3">
    <!--* The icons to display in online banking plateform-->
    <div class="" *ngIf="plateform !== 'workStation' && plateform !== 'onamob'">
        <div class="d-flex justify-content-around justify-content-lg-start">
            <span
                *ngFor="let plateF of variableService.plateforms"
                role="button"
                title="{{ plateF.title }}"
                class="margin-right"
                (click)="switchPlateform(plateF.plateform)"
            >
                <i
                    *ngIf="plateF.icon"
                    class="{{ plateF.icon }} fs-3"
                    [ngClass]="
                        plateF.plateform === plateform
                            ? 'selectedIcon-b'
                            : 'unselectedIcon-b'
                    "
                ></i>
                <img
                    *ngIf="plateF.image && !plateF.is_selected"
                    [ngClass]="
                        plateF.plateform === plateform
                            ? 'selectedIcon-b'
                            : 'unselectedIcon-b'
                    "
                    src="{{ plateF.image }}"
                    style="width: 30px; margin-top: -15%"
                />
                <img
                    *ngIf="plateF.image && plateF.is_selected"
                    [ngClass]="
                        plateF.plateform === plateform
                            ? 'selectedIcon-b'
                            : 'unselectedIcon-b'
                    "
                    src="/assets/images/ihela-b-light.svg"
                    style="width: 31px; margin-top: -19%"
                />
            </span>
        </div>
    </div>
    <!--* The icons to display in workstation plateform-->

    <div class="" *ngIf="plateform === 'workStation'">
        <div class="d-flex justify-content-around justify-content-lg-start">
            <span
                role="button"
                title="Switch on Home"
                class="margin-right"
                (click)="switchPlateform('home')"
            >
                <i
                    class="fa-solid fa-house-chimney-user fs-3 unselectedIcon-w"
                ></i>
            </span>
            <span
                role="button"
                title="Switch on Banking plateform"
                class="margin-right"
                (click)="switchPlateform('onlineBanking')"
            >
                <!-- <i  
                        class="fa-solid fa-building-columns fs-3 unselectedIcon-w"
                    ></i> -->
                <img
                    src="/assets/images/ihela-w-light.svg"
                    class="fs-3 unselectedIcon-w"
                    style="width: 30px; margin-top: -15%"
                />
            </span>
            <span
                role="button"
                class="margin-right"
                title="Switch on onamob platform"
                (click)="switchPlateform('onamob')"
            >
                <i
                    class="fa-solid fa-mobile-screen-button fs-3 unselectedIcon-w"
                ></i>
            </span>
            <span
                role="button"
                title="Switch on Market plateform"
                class="margin-right"
                (click)="switchPlateform('market'); toggleMarket()"
            >
                <i class="fa-solid fa-cart-shopping fs-3 unselectedIcon-w"></i>
            </span>
            <span
                role="button"
                title="Switch on Workstation plateform"
                (click)="switchPlateform('workStation')"
            >
                <i
                    class="fa-solid fa-desktop fs-3"
                    [ngClass]="
                    {
                        'unselectedIcon-w':plateform !=='workStation',
                        'selectedIcon-w':plateform ==='workStation',


                }"
                ></i>
            </span>
        </div>
    </div>

    <!--* The icons to display in onamob plateform-->

    <div class="" *ngIf="plateform === 'onamob'">
        <div class="d-flex justify-content-around justify-content-lg-start">
            <span
                role="button"
                title="Switch on Home"
                class="margin-right"
                (click)="switchPlateform('home')"
            >
                <i
                    class="fa-solid fa-house-chimney-user fs-3 unselectedIcon-o"
                ></i>
            </span>
            <span
                role="button"
                title="Switch on Banking plateform"
                class="margin-right"
                (click)="switchPlateform('onlineBanking')"
            >
                <!-- <i  
                            class="fa-solid fa-building-columns fs-3 unselectedIcon-w"
                        ></i> -->
                <img
                    src="/assets/images/ihela-o.svg"
                    class="fs-3 unselectedIcon-o"
                    style="width: 30px; margin-top: -15%"
                />
            </span>
            <span
                role="button"
                class="margin-right"
                title="Switch on onamob platform"
                (click)="switchPlateform('onamob')"
            >
                <i
                    class="fa-solid fa-mobile-screen-button fs-3 selectedIcon-o"
                ></i>
            </span>
            <span
                role="button"
                title="Switch on Market plateform"
                class="margin-right"
                (click)="switchPlateform('market'); toggleMarket()"
            >
                <i class="fa-solid fa-cart-shopping fs-3 unselectedIcon-o"></i>
            </span>
            <span
                role="button"
                title="Switch on Workstation plateform"
                (click)="switchPlateform('workStation')"
            >
                <i class="fa-solid fa-desktop fs-3 unselectedIcon-o"></i>
            </span>
        </div>
    </div>
</div>
