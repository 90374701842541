<div class="level-1 p-4 rounded">
    <!-- <b
        class="text-primary float-end"
        role="button"
        title="clients List"
        (click)="redirectToClientsList()"
        >Clients</b
    > -->

    <div class="row">
        <div class="col-lg-2">
            <img
                *ngIf="selectedClient && !selectedClient.picture_url"
                src="assets/images/userprofile.png"
                class="img-fluid"
                style="width: 80%; border-radius: 50%"
            />
            <img
                *ngIf="selectedClient && selectedClient.picture_url"
                [src]="selectedClient.picture_url"
                class="img-fluid"
                style="
                    width: 70%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                "
            />
        </div>

        <div class="col-lg-4" *ngIf="selectedClient">
            <div class="mb-2">
                <b
                    *ngIf="selectedClient"
                    class="fs-4 text-dark"
                    (click)="showUpdateDetails()"
                    ><strong
                        >{{ selectedClient.client_full_name }}
                        <span *ngIf="updateOption">
                            <i
                                class="fa-sharp fa-pen-clip"
                                title="Modify client's details"
                                style="font-size: 15px"
                                role="button"
                            ></i>
                        </span> </strong
                ></b>
                <br />
                <b class="text-dark">{{ selectedClient.client_code }}</b
                ><br /><br />
                <b
                    class="{{
                        selectedClient.client_classification.css
                    }} text-dark"
                    >{{ selectedClient.client_classification.title }}</b
                ><br />
            </div>
            <!-- <div
                role="button"
                (click)="redirectToDetails()"
                *ngIf="canRedirectToDetails"
            >
                <b
                    class="text-primary"
                    style="font-size: 13px"
                    title="You'll be redirected on client details page"
                    ><i class="fa-solid fa-circle-plus"></i> More Details</b
                >
            </div> -->
        </div>

        <div class="col-lg-4" *ngIf="selectedAccount">
            <div class="mb-2">
                <b *ngIf="selectedAccount" class="fs-4 text-dark"
                    ><strong
                        >{{ selectedAccount.acc_holder }}
                        <span *ngIf="updateOption">
                            <i
                                class="fa-sharp fa-pen-clip"
                                title="Modify client's details"
                                style="font-size: 15px"
                                role="button"
                            ></i>
                        </span> </strong
                ></b>
                <br />
                <b class="text-dark">{{ selectedAccount.acc_get_title }}</b
                ><br /><br />
                <b
                    class=" fa-{{
                        selectedAccount.acc_status.status_icon
                    }} text-dark fa fs-6"
                ></b>

                <br />
            </div>
            <!-- <div
                role="button"
                (click)="redirectToDetails()"
                *ngIf="canRedirectToDetails"
            >
                <b
                    class="text-primary"
                    style="font-size: 13px"
                    title="You'll be redirected on client details page"
                    ><i class="fa-solid fa-circle-plus"></i> More Details</b
                >
            </div> -->
        </div>

        <div class="col-lg" *ngIf="selectedClient">
            <b class="fs-5 text-dark">
                <strong
                    ><i
                        class="fa-regular fa-user"
                        *ngIf="
                            selectedClient.client_category_type.title ===
                            'INDIVIDUEL'
                        "
                    ></i>
                    <i
                        class="fa-solid fa-business-time"
                        *ngIf="
                            selectedClient.client_category_type.title !==
                            'INDIVIDUEL'
                        "
                    ></i>
                    {{ selectedClient.client_category_type.title }}</strong
                > </b
            ><br />
            <b class="text-dark">{{ selectedClient.client_category.title }}</b
            ><br />
            <b class="text-dark">
                {{ selectedClient.client_category_type.client_type.title }}</b
            >
        </div>
        <div class="col-lg" *ngIf="selectedAccount">
            <b class="fs-5 text-dark">
                <strong
                    ><i class=""></i>
                    <i></i>
                    {{ selectedAccount.acc_currency }}</strong
                > </b
            ><br />
            <b class="text-dark">{{ selectedAccount.acc_number }}</b
            ><br />

            <div class="col-lg" *ngIf="!accountId && !clientId">
                <span class="spinner-border text-dark"></span>
            </div>
        </div>

        <div class="col-lg" *ngIf="!selectedClient && !selectedAccount">
            <span class="spinner-border text-dark"></span>
        </div>

        <div class="col-lg-2 me-3">
            <div class="d-flex">
                <div *ngIf="selectedClient" class="me-4 d-grid">
                    <b class="text-dark d-flex align-items-center">
                        <i class="fa-solid fa-user"></i>
                        <b class="ms-1">Agent</b>
                    </b>
                    <b class="text-dark d-flex align-items-center"
                        ><i class="fa-solid fa-cart-shopping"></i>
                        <b class="ms-1">Merchant </b>
                    </b>
                    <b class="text-dark d-flex align-items-center"
                        ><i class="fa-solid fa-hands-helping"></i>
                        <b class="ms-1">Partner</b>
                    </b>
                </div>
                <div class="ms-2 d-grid" *ngIf="selectedClient">
                    <b>
                        <i
                            class="fa-solid fa-circle-check text-success"
                            *ngIf="selectedClient.is_agent"
                        ></i>
                        <i
                            class="fa-solid fa-circle-xmark text-danger"
                            *ngIf="!selectedClient.is_agent"
                        ></i> </b
                    ><br />
                    <b>
                        <i
                            class="fa-solid fa-circle-check text-success"
                            *ngIf="selectedClient.is_merchant"
                        ></i>
                        <i
                            class="fa-solid fa-circle-xmark text-danger"
                            *ngIf="!selectedClient.is_merchant"
                        ></i>
                    </b>
                    <b>
                        <i
                            class="fa-solid fa-circle-check text-success"
                            *ngIf="selectedClient.is_partner_bank"
                        ></i>
                        <i
                            class="fa-solid fa-circle-xmark text-danger"
                            *ngIf="!selectedClient.is_partner_bank"
                        ></i>
                    </b>
                </div>
            </div>
        </div>
        <!-- <div class="col-lg-1" *ngIf="selectedClient">
            <div class="dropdown float-end">
                <span
                    role="button"
                    class="fs-4"
                    class="btn text-dark"
                    type="button"
                    id="dropdownMenuButton2"
                    data-bs-toggle="dropdown"
                    aria-expanded="true"
                    style="border: none"
                >
                    <i class="fa fa-th-list float-end ms-4 fs-4"></i>
                </span>
                <ul
                    class="dropdown-menu dropdown-menu-white"
                    aria-labelledby="dropdownMenuButton2"
                >
                    <li role="button">
                        <b
                            class="dropdown-item"
                            [ngClass]="{
                                active: selectedFilt === 'accounts'
                            }"
                            (click)="selectFilt('accounts')"
                            >Accounts</b
                        >
                    </li>
                    <li role="button">
                        <b
                            class="dropdown-item"
                            [ngClass]="{
                                active: selectedFilt === 'signature'
                            }"
                            (click)="selectFilt('signature')"
                            >Signature</b
                        >
                    </li>
                </ul>
                <div></div>
            </div>
        </div> -->
    </div>
</div>
