<div class="">
    <div
        class=""
        [ngClass]="{
            'form-floating': isFormFloating
        }"
    >
        <input
            type="text"
            class="form-control field"
            [ngStyle]="{ 'background-color': background }"
            [formControl]="amountInput"
            (input)="newAmount()"
            (change)="getFees()"
            id="amount"
            placeholder="Amount"
            min="1"
        />
        <label for="amount" class="form-label fs-6" *ngIf="isFormFloating" i18n
            >Amount <code>*</code></label
        >
    </div>
    <div
        class="text-center py-1 d-flex align-items-center ms-3 mt-2"
        *ngIf="showAmountwords"
    >
        <small class="text-dark me-3" *ngIf="amountWords"
            ><i class="fa-solid fa-play"></i
        ></small>
        <small class="text-dark text-capitalize fw-bold" *ngIf="amountWords">
            {{ amountWords }}
        </small>
    </div>
    <div *ngIf="showFees && showAmountwords" class="ms-3 my-2">
        <div class="text-dark d-flex align-items-center">
            <span class="text-dark"><i class="fa-solid fa-play"></i></span>
            <small class="">
                <b i18n class="ms-3" i18n>Fees : </b>
                <b *ngIf="!isLoading && !fees">----</b>
                <b *ngIf="!isLoading && fees">{{
                    (fees?.commissions | currency : 'BIF ') ?? ''
                }}</b>
                <b *ngIf="!isLoading && fees"
                    >(
                    <span class="text-primary"
                        >Total : {{ totalWithFees | currency : 'BIF ' }}</span
                    >
                    )</b
                >
            </small>
            <span *ngIf="isLoading" class="text-sm ms-2">
                <i class="fas fa-spinner fa-pulse"></i>
            </span>
        </div>
    </div>
</div>
