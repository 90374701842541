import { Injectable } from '@angular/core';

import {
    State,
    StateToken,
    Selector,
    Action,
    StateContext,
    Store,
} from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PlateformModel } from './switch.models';
import { Switch } from './switch.actions';

// import { AuthService } from '../../../core';

// import { StateReset } from 'ngxs-reset-plugin';

const SWITCH_MODEL = new StateToken<PlateformModel[]>('plateform');

@State<PlateformModel>({
    name: SWITCH_MODEL,
    defaults: {
        plateform: 'home',
    },
})
@Injectable()
export class SwitchState {
    constructor() {
        //comment
    }

    @Selector()
    static GetPlateform(state: PlateformModel): any {
        if (state.plateform) {
            return state.plateform;
        } else {
            return null;
        }
    }

    @Action(Switch)
    switchPlateform(ctx: StateContext<PlateformModel>, action: Switch) {
        ctx.patchState({ plateform: action.payload.plateform });
    }
}
