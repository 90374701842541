import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class CorporateService {
    constructor(private apiService: ApiService) {}

    getAccounts() {
        return this.apiService.get('/ledger/').pipe(
            map((data) => {
                return data;
            })
        );
    }

    getAccountDetails(account_id: any) {
        return this.apiService.get('/ledger/' + account_id + '/').pipe(
            map((data) => {
                return data;
            })
        );
    }

    updateAccount(data: any, account_id: any) {
        return this.apiService.patch('/ledger/' + account_id + '/', data).pipe(
            map((data) => {
                return data;
            })
        );
    }
    createLedger(body: any) {
        const url = '/ledger/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }
    getTreasuryDetails(treasury_id: any) {
        return this.apiService
            .get('/treasury/institutions/manage/' + treasury_id)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    getTreasuriesList() {
        return this.apiService.get('/treasury/institutions/manage/?').pipe(
            map((data) => {
                return data;
            })
        );
    }

    doInstitutionTransaction(data: any) {
        return this.apiService
            .post('/treasury/institution-transaction/', data)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
