import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LayoutComponent } from './shared/components/layout-container/layout.component';
import { AuthCorporateComponent } from './modules/authentication/auth-corporate/auth-corporate.component';
import {
    AuthGuard,
    WorkstationGuard,
    BankingGuard,
    NetworkAwarePreloadService,
} from './core';

const routes: Routes = [
    {
        path: ':plateform',
        component: LayoutComponent,
        children: [
            {
                path: 'onlineBanking',
                loadChildren: () =>
                    import(
                        './modules/online-banking/online-banking.module'
                    ).then((m) => m.OnlineBankingModule),
                canActivate: [AuthGuard, BankingGuard],
            },
            {
                path: 'settings',
                loadChildren: () =>
                    import(
                        './modules/online-banking/settings/settings.module'
                    ).then((m) => m.SettingsModule),
                canActivate: [AuthGuard, BankingGuard],
            },

            {
                path: 'workstation',
                loadChildren: () =>
                    import('./modules/workstation/workstation.module').then(
                        (m) => m.WorkstationModule
                    ),
                canActivate: [AuthGuard, WorkstationGuard],
            },
            {
                path: 'market',
                loadChildren: () =>
                    import('./modules/market/market.module').then(
                        (m) => m.MarketModule
                    ),
                canActivate: [AuthGuard, BankingGuard],
            },
            {
                path: 'home',
                loadChildren: () =>
                    import('./modules/home/home.module').then(
                        (m) => m.HomeModule
                    ),
                canActivate: [AuthGuard, BankingGuard],
            },
            {
                path: 'onamob',
                loadChildren: () =>
                    import('./modules/onamob/onamob.module').then(
                        (m) => m.OnamobModule
                    ),
                canActivate: [AuthGuard, BankingGuard],
            },
            {
                path: '',
                loadChildren: () =>
                    import('./modules/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule
                    ),
                canActivate: [AuthGuard],
            },
        ],
        canActivate: [AuthGuard],
    },
    // {
    //     path: '',
    //     component: AuthComponent,
    // },

    {
        path: 'auth/corporate',
        component: AuthCorporateComponent,
        canActivate: [AuthGuard],
    },

    {
        path: '',
        loadChildren: () =>
            import('./modules/authentication/authentication.module').then(
                (m) => m.AuthenticationModule
            ),
    },

    /* {
        path: 'saving',
        loadChildren: () => import('./modules/saving/saving.module').then(
            (m)=> m.SavingModule)
      }*/
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: NetworkAwarePreloadService,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
