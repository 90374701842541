import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map, retry } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DashboardService {
    constructor(private apiService: ApiService) {
        //
    }
    getWorkstationStats() {
        const url =
            '/dbs/general/stats/?stats_type=agents_number,merchants_number,clients_created,counters_number,operations_done,cashpoints_number&filter_merchant=';
        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getDefaultWallet() {
        const url = '/dbs/wallet/default/';
        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getRecentTransactions() {
        const url = '/operations/pending/logic/?filter_for_client=true/';
        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
}
