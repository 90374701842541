<!-- <div class="row justify-content-md-center mt-2">
    <div class="col-lg-4 align-self-center">
        
    </div>
</div>
 -->
<section class="searchMenu w-100 position-relative">
    <div class="d-flex level-3 rounded p-2 w-100" *ngIf="selectedItem">
        <div style="line-height: 18px me-2">
            <div class="d-flex">
                <div
                    style="width: 50px; height: 50px"
                    *ngIf="selectedItem.lookup_image && showProfile"
                >
                    <img
                        [src]="selectedItem.lookup_image"
                        class="image"
                        style="border-radius: 50%"
                    />
                </div>
                <div class="text-dark center ms-2">
                    <span>{{ selectedItem.lookup_title }}</span
                    ><br />
                    <span class="subtitle"
                        >{{ selectedItem.lookup_subtitle }}
                    </span>
                </div>
            </div>
        </div>
        <div class="ps-2">
            <span
                class="text-danger"
                style="right: 0 !important; position: absolute"
                role="button"
                (click)="deselect()"
                ><i class="fa-solid fa-circle-xmark me-2"></i>
            </span>
        </div>
    </div>

    <!-- <label for="validationTooltipUsername">Username</label> -->
    <div *ngIf="option === 'lookup' && !selectedItem">
        <input
            type="text"
            class="form-control lg-radius input text-dark global-border-input"
            id="validationTooltipUsername"
            placeholder="{{ lookupLabel }}"
            aria-describedby="validationTooltipUsernamePrepend"
            [disabled]="isLoading"
            required
            [formControl]="lookup"
            (keydown.enter)="DoLookup()"
            style="background-color: transparent !important; height: 45px"
        />
        <span
            role="button"
            (click)="DoLookup()"
            *ngIf="!isLoading"
            class="bg-transparent position-absolute search-btn text-dark"
            id="validationTooltipUsernamePrepend"
            type="submit"
        >
            <i class="fa-solid fa-search fs-7"></i>
        </span>
        <span
            *ngIf="isLoading"
            class="bg-transparent position-absolute search-btn text-dark"
            id="validationTooltipUsernamePrepend"
        >
            <i class="fa-solid fa-pulse fa-circle-notch"></i>
        </span>
    </div>

    <div *ngIf="option === 'autocomplete'">
        <div class="form-floating" *ngIf="!selectedItem">
            <input
                id="menu_group"
                class="input form-control autocomplete"
                placeholder="client"
                (focus)="initAutocomplete()"
                (keyup)="initAutocomplete()"
                (click)="showAutoComplete = true"
                [formControl]="search"
                (input)="DoAutocomplete()"
                autocomplete="false"
            />
            <label for="menu_group" class="form-label mx-3">{{
                itemsLabel
            }}</label>
        </div>
        <div
            class="autoCompleteItems level-2 shadow rounded level-0 w-100 shadow py-2 mt-2"
            *ngIf="showAutoComplete"
        >
            <div class="list mx-1">
                <div class="d-flex">
                    <b class="text-secondary">{{ itemsLabel }} List</b>
                    <b
                        style="right: 0 !important; position: absolute"
                        role="button"
                        class="me-2"
                        (click)="close()"
                        ><span class="ms-1">
                            <i
                                class="fa-solid fa-circle-xmark text-danger"
                            ></i> </span
                    ></b>
                </div>

                <span class="text-dark ms-5" *ngIf="isLoading"
                    ><i class="fa-solid fa-pulse fa-circle-notch"></i>
                </span>

                <!-- not found message -->
                <small
                    class="py-3 text-center"
                    *ngIf="!isLoading && items.objects.length === 0"
                >
                    <b class="text-secondary" i18n>Data Not Found</b>
                </small>
                <div *ngIf="!isLoading && items">
                    <div
                        class="item py-1 px-3 rounded"
                        *ngFor="let item of items.objects"
                        role="button"
                    >
                        <div (click)="selectItem(item)">
                            <div class="d-flex text-dark">
                                <div
                                    *ngIf="showProfile"
                                    style="width: 50px; height: 50px"
                                >
                                    <img
                                        [src]="item.lookup_image"
                                        class="image"
                                        style="border-radius: 50%"
                                    />
                                </div>
                                <div class="center ms-2">
                                    <span>{{ item.lookup_title }}</span
                                    ><br />
                                    <span class="subtitle">{{
                                        item.lookup_subtitle
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
