import { Injectable } from '@angular/core';
import {
    MerchantBillModel,
    ObrBillModel,
    TransferBillModel,
    LandscapeBillModel,
} from './bill.models';
import {
    Action,
    Select,
    Selector,
    State,
    StateContext,
    StateToken,
    Store,
} from '@ngxs/store';
import {
    CloseBillPopup,
    OpenMerchantBillPopup,
    OpenObrBillPopup,
    OpenTransfertBillPopup,
    OpenLandscapeBillPopup,
} from './bill.actions';

const BILL_TOKEN = new StateToken<TransferBillModel>('Bill');
const LANDSCAPE_BILL = new StateToken<LandscapeBillModel>('landscape');

@State<ObrBillModel>({
    name: BILL_TOKEN,
    defaults: {
        data: {
            agence: '',
            amount: '',
            company_name: '',
            declarant_code: '',
            declarant_name: '',
            deliver_to: '',
            name: '',
            nif: '',
            office: '',
            receipt_date: '',
            receipt_num: '',
            ref: '',
            type: '',
        },
        active: {
            isActive: false,
            type: '',
        },
    },
})
@State<MerchantBillModel>({
    name: BILL_TOKEN,
    defaults: {
        data: {
            adress: '',
            amount: '',
            code: '',
            credit_account: '',
            date: '',
            debit_account: '',
            description: '',
            merchantName: '',
            name: '',
            printable_text: '',
            product: {
                name: '',
                value: '',
            },
        },
        active: {
            isActive: false,
            type: '',
        },
    },
})
@State<TransferBillModel>({
    name: BILL_TOKEN,
    defaults: {
        data: {
            amount: '',
            bank_reference: '',
            bill_date: '',
            credit_account_holder: '',
            credit_account: '',
            credit_bank: '',
            debit_account_holder: '',
            debit_account: '',
            debit_bank: '',
            reference: '',
            transfer_fees: '',
            description: '',
        },
        active: {
            isActive: false,
            type: '',
        },
    },
})
@State<LandscapeBillModel>({
    name: LANDSCAPE_BILL,
    defaults: {
        data: {
            printable_text: '',
            receipt_date: '',
            logo_url: '',
        },
        active: {
            isActive: false,
            type: '',
        },
    },
})
@Injectable()
export class BillState {
    constructor(private store: Store) {
        //code
    }

    // selectors
    @Selector()
    static GetBillData(
        state:
            | TransferBillModel
            | MerchantBillModel
            | ObrBillModel
            | LandscapeBillModel
    ): any {
        return state.data;
    }

    @Selector()
    static GetActiveBillInfo(
        state:
            | TransferBillModel
            | MerchantBillModel
            | ObrBillModel
            | LandscapeBillModel
    ) {
        return state.active;
    }

    // actions
    @Action(OpenTransfertBillPopup)
    openTransferBillPopup(
        ctx: StateContext<TransferBillModel>,
        action: OpenTransfertBillPopup
    ) {
        ctx.patchState({
            data: {
                amount: action.payload.amount,
                bank_reference: action.payload.bank_reference,
                bill_date: action.payload.bill_date,
                credit_account_holder: action.payload.credit_account_holder,
                credit_account: action.payload.credit_account,
                credit_bank: action.payload.credit_bank,
                debit_account_holder: action.payload.debit_account_holder,
                debit_account: action.payload.debit_account,
                debit_bank: action.payload.debit_bank,
                reference: action.payload.reference,
                transfer_fees: action.payload.transfer_fees,
                description: action.payload.description,
            },
            active: {
                isActive: true,
                type: 'transferBill',
            },
        });
    }

    @Action(OpenMerchantBillPopup)
    openMerchantBillPopup(
        ctx: StateContext<MerchantBillModel>,
        action: OpenMerchantBillPopup
    ) {
        ctx.patchState({
            data: {
                adress: action.payload.adress,
                amount: action.payload.amount,
                code: action.payload.code,
                credit_account: action.payload.credit_account,
                date: action.payload.date,
                debit_account: action.payload.debit_account,
                description: action.payload.description,
                merchantName: action.payload.merchantName,
                name: action.payload.name,
                printable_text: action.payload.printable_text,
                product: {
                    name: action.payload.product?.name ?? '',
                    value: action.payload.product?.value ?? '',
                },
            },
            active: {
                isActive: true,
                type: 'merchantBill',
            },
        });
    }

    @Action(OpenObrBillPopup)
    openObrBillPopup(
        ctx: StateContext<ObrBillModel>,
        action: OpenObrBillPopup
    ) {
        ctx.patchState({
            data: {
                receipt_num: action.payload.receipt_num,
                office: action.payload.office,
                deliver_to: action.payload.deliver_to,
                receipt_date: action.payload.receipt_date,
                nif: action.payload.nif,
                company_name: action.payload.company_name,
                declarant_code: action.payload.declarant_code,
                declarant_name: action.payload.declarant_name,
                agence: action.payload.agence,
                ref: action.payload.ref,
                amount: action.payload.amount,
                name: action.payload.name,
                type: action.payload.type,
            },
            active: {
                isActive: true,
                type: 'obrBill',
            },
        });
    }

    @Action(OpenLandscapeBillPopup)
    openlandscapeBillPopup(
        ctx: StateContext<LandscapeBillModel>,
        action: OpenLandscapeBillPopup
    ) {
        ctx.patchState({
            data: {
                printable_text: action.payload.printable_text,
                receipt_date: action.payload.receipt_date,
                logo_url: '',
            },
            active: {
                isActive: true,
                type: 'landscapeBill',
            },
        });
    }

    @Action(CloseBillPopup)
    closeBillPopup(
        ctx: StateContext<
            | TransferBillModel
            | MerchantBillModel
            | ObrBillModel
            | LandscapeBillModel
        >,
        action: CloseBillPopup
    ) {
        ctx.patchState({
            active: {
                isActive: false,
                type: '',
            },
        });
    }
}
