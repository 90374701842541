<div class="card lg-radius overflow-hidden border-0" style="width: 800px">
    <div
        class="card-header bg-primary border-bottom-0 py-2 px-5 d-flex justify-content-between align-items-center"
    >
        <div class="" style="height: 80px; width: 157px">
            <img
                class="img-fluid"
                src="/assets/images/ihela3.png"
                alt="IHELA Logo"
            />
        </div>
        <div
            class=""
            *ngIf="successMessage.logo_url"
            style="height: 80px; width: 157px"
        >
            <img class="image" [src]="successMessage.logo_url" />
        </div>
    </div>
    <div class="card-body py-1 level-1 position-relative">
        <span
            class="position-absolute d-flex justify-content-center align-items-center"
            style="inset: 0; opacity: 0.1"
        >
            <img
                style="height: 53%"
                src="/assets/images/ihela-black.svg"
                alt=""
            />
        </span>
        <section id="bill-content" class="text-dark">
            <!-- content injected in TS -->
        </section>
    </div>
    <div
        class="card-footer border-top-0 bg-primary py-2 px-5 d-flex justify-content-between align-items-center"
    >
        <div
            class=""
            style="height: 50px; width: 50px; opacity: 0; pointer-events: none"
        >
            <i class="fa-solid fa-qrcode h-100 w-100"></i>
        </div>
        <div
            class="d-flex flex-column align-items-end"
            [ngClass]="{
                'text-light': theme === 'light-mode',
                'text-dark': theme === 'dark-mode'
            }"
        >
            <b
                ><small>{{ successMessage.receipt_date }}</small></b
            >
            <b class="d-flex justify-content-end"
                ><small class="me-3">ihela.bi</small><small>76434444</small></b
            >
            <b><small>Q. Asiatique Bujumbura </small></b>
        </div>
    </div>
</div>
