import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { BehaviorSubject, Observable, map, switchMap, takeUntil } from 'rxjs';
import { AnonymousSubject, Subject } from 'rxjs/internal/Subject';

@Injectable({
    providedIn: 'root',
})
export class TarifService {
    private cancelPreviousRequest$ = new Subject<void>();
    private inputChanged$ = new Subject<string>();

    constructor(private apiService: ApiService) {
        //
    }

    getBanks() {
        const url = '/banks/list/?commission_type=I';
        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getTarifType(bank_id: string) {
        const url = '/dbs/tariff-table/?bank=' + bank_id;
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }
    getAllTarifType() {
        const url = '/dbs/tariff-table/';
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getTarifFees(tarif_id: string) {
        const url = '/dbs/tariff-fees/?tarif_table=' + tarif_id;
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }

    TarifFees(tarif_id: string) {
        const url = '/dbs/tariff-fees/?tarif_table=' + tarif_id;
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getSimulateWithTarifTable(tarifTable: any, amount: string) {
        const url = `/dbs/tariff-simulation/?tarif_table=${tarifTable}&amount=${amount}`;
        return this.apiService.get(url).pipe(map((data) => data));
    }

    getSimulate(type_code: any, amount: any, bank: any) {
        this.inputChanged$.next(amount);
        return this.inputChanged$.pipe(
            switchMap((newAmount) => {
                this.cancelPreviousRequest$.next();
                const url = `/dbs/tariff-simulation/?type_code=${type_code}&amount=${newAmount}&bank=${bank}`;
                return this.apiService
                    .get(url)
                    .pipe(takeUntil(this.cancelPreviousRequest$));
            })
        );
    }
    addTarif(body: any) {
        const url = '/dbs/tariff-type/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }
    addTarifToTable(body: any) {
        const url = '/dbs/tariff-table/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }
    addFees(body: any) {
        const url = '/dbs/tariff-fees/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }
    modifyFees(feeId: string, body: any) {
        const url = '/dbs/tariff-fees/' + feeId + '/';
        return this.apiService
            .patch(url, body)
            .pipe(map((response) => response));
    }
    deleteFees(feeId: number) {
        const url = '/dbs/tariff-fees/' + feeId + '/';
        return this.apiService.delete(url).pipe(map((response) => response));
    }
}
