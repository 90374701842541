import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';

import { Store } from '@ngxs/store';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClientService } from 'src/app/core';
import { BankService } from 'src/app/core/services/bank.service';
import { TransferService } from 'src/app/core/services/transfer.service';

import {
    SwitchThemeState,
    AuthState,
    BankState,
    SwitchState,
    AmountState,
} from 'src/app/shared/';

@Component({
    selector: 'app-right-side-banking',
    templateUrl: './right-side-banking.component.html',
    styleUrls: ['./right-side-banking.component.scss'],
    standalone: true,
    imports: [RouterModule, CommonModule, NgxSkeletonLoaderModule],
})
export class RighSideBankingComponent implements OnInit {
    plateform: any;
    plateform$!: Observable<any>;
    private onDestroy$: Subject<void> = new Subject<void>();
    recentTransactions: any;
    isTransactionDone = false;
    lastTransfers: any;
    isTransfer = false;
    isBaking = false;
    favoriteBeneficiaries: any;
    theme = '';
    theme$: Observable<string>;
    clientId$: Observable<any>;
    clientId!: string;
    selectedBankId$: Observable<any>;
    clientVerified = '&filter_for_client=true';
    isBalanceShown = false;
    isBalanceShown$: Observable<boolean>;

    clientDetails: any;
    loadingClientDetails = true;

    constructor(
        private store: Store,
        private transferService: TransferService,
        private bankService: BankService,
        private clientService: ClientService
    ) {
        this.plateform$ = this.store.select(SwitchState.GetPlateform);
        this.theme$ = this.store.select(SwitchThemeState.GetTheme);
        this.clientId$ = this.store.select(AuthState.GetClientId);
        this.selectedBankId$ = this.store.select(
            BankState.GetSelectedClientBankId
        );

        this.isBalanceShown$ = this.store.select(AmountState.isShowed);
    }

    ngOnInit() {
        this.plateform$.pipe(takeUntil(this.onDestroy$)).subscribe({
            next: (response: any) => {
                this.plateform = response;

                this.theme$.subscribe((theme: string) => {
                    this.theme = theme;
                });

                if (this.plateform === 'onlineBanking') {
                    this.clientId$.subscribe({
                        next: (clientId) => {
                            if (clientId) {
                                this.clientId = clientId;
                                // this.getClientDetails();

                                //this mehod get bankId and get last transactions etc...
                                this.getSelectedBankId();
                            }
                        },
                    });
                }
            },
        });

        this.theme$.subscribe({
            next: (data) => {
                this.theme = data;
            },
        });

        this.isBalanceShown$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((isShowed: boolean) => {
                this.isBalanceShown = isShowed;
            });

        this.handleBanking();
    }

    handleTransfer() {
        this.transferService.isTransfer$.subscribe((data) => {
            this.isTransfer = data;

            this.getFavoriteBeneficiaries();
            this.getLastTransfers();
        });
    }

    getSelectedBankId() {
        this.selectedBankId$.subscribe({
            next: (bankId) => {
                if (bankId) {
                    this.getRecentTransactions();
                    this.handleTransfer();

                    this.bankService.isTransactionDone$.subscribe(
                        (isTransactionDone: boolean) => {
                            this.isTransactionDone = isTransactionDone;

                            if (isTransactionDone) {
                                this.getRecentTransactions();
                                this.getLastTransfers();
                                this.getFavoriteBeneficiaries();
                            }
                        }
                    );
                }
            },
        });
    }

    handleBanking() {
        this.bankService._isBankingAndServicesSelected$.subscribe((data) => {
            this.isBaking = data;
        });
    }

    getRecentTransactions() {
        this.bankService.updateTransaction(false);
        this.isTransactionDone = false;
        this.recentTransactions = undefined;

        const period = {
            start_date: '',
            end_date: '',
        };

        this.transferService
            .getRecentTransactions('', period, this.clientVerified)
            .subscribe((transfers) => {
                this.recentTransactions = transfers.objects;
                this.bankService.updateTransaction(false);
            });
    }

    getLastTransfers() {
        this.bankService.updateTransaction(false);
        this.isTransactionDone = false;
        this.lastTransfers = undefined;
        this.transferService.getTransfersList().subscribe((transfers) => {
            this.lastTransfers = transfers.objects;
            this.bankService.updateTransaction(false);
        });
    }

    getFavoriteBeneficiaries() {
        this.bankService.updateTransaction(false);
        this.isTransactionDone = false;
        this.favoriteBeneficiaries = undefined;
        this.transferService.getLastBeneficiary().subscribe((beneficiaries) => {
            this.favoriteBeneficiaries = beneficiaries.objects;
            this.bankService.updateTransaction(false);
        });
    }

    goToLastTransaction(i: number) {
        const transaction = this.lastTransfers[i];
        this.transferService.goToLastTransaction(transaction);
    }

    getClientDetails() {
        this.loadingClientDetails = true;
        this.clientService.getClientDetails(this.clientId).subscribe({
            next: (response) => {
                this.clientDetails = response.object;
                this.loadingClientDetails = false;
            },
            error: (err) => {
                this.loadingClientDetails = false;
            },
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
