import { Injectable } from '@angular/core';

import { GetMerchantInfo } from './merchant-info.action';
import { MerchantInfoModels } from './merchant-info.models';

import {
    State,
    StateToken,
    Selector,
    Action,
    StateContext,
    Store,
} from '@ngxs/store';

const MERCHANT_MODEL = new StateToken<MerchantInfoModels[]>('merchant');

@State<MerchantInfoModels>({
    name: MERCHANT_MODEL,
    defaults: {
        merchant: {
            id: null,
            is_teller_admin: false,
        },
    },
})
@Injectable()
export class MerchantState {
    constructor() {
        // comment
    }
    @Selector()
    static GetMerchantInfo(state: MerchantInfoModels): any {
        return state.merchant;
    }
    @Action(GetMerchantInfo)
    getMerchantInfo(
        ctx: StateContext<MerchantInfoModels>,
        action: GetMerchantInfo
    ) {
        ctx.patchState({
            merchant: {
                id: action.payload.id,
                is_teller_admin: action.payload.is_teller_admin,
            },
        });
    }
}
