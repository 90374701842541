import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from '@angular/router';

import { Observable, Subject } from 'rxjs';
import { Store, StateContext, Select } from '@ngxs/store';
import { map } from 'rxjs/operators';

import { AuthState } from '../../shared';

@Injectable({
    providedIn: 'root',
})
export class TreasurerGuard implements CanActivate {
    constructor(private store: Store, private router: Router) {}

    canActivate():
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.store.select(AuthState.isTreasurer).pipe(
            map((isTreasurer) => {
                if (isTreasurer === false) {
                    return this.router.navigate(['/w']);
                }
                return isTreasurer;
            })
        );
    }
}
