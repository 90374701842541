<head>
    <title *ngIf="plateform === 'workStation'">MAGIS ERP</title>
    <title *ngIf="plateform !== 'workStation'">iHel&aacute; Rya&#771;nje</title>
    <link
        rel="icon"
        type="image/x-icon"
        href="/src/assets/images/favicon.ico"
    />
    <!-- <link rel="shortcut icon" > -->
</head>
<div
    class="h-100 w-100 overflow-hidden position-relative"
    [ngClass]="
        plateform !== 'workStation'
            ? theme === 'light-mode'
                ? 'level-1'
                : 'level-2'
            : 'level-2'
    "
    style="
        background-image: url('../assets/images/Background texture.svg');
        background-size: cover;
    "
>
    <div *ngIf="showSplashScreen">
        <app-splash-screen></app-splash-screen>
    </div>
    <router-outlet></router-outlet>
</div>
