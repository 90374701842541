import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { CoreRoutingModule } from './core-routing.module';
import * as Services from './services';
import * as Guards from './guards';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
    HttpErrorInterceptor,
    HttpTokenInterceptor,
    ClientsInfoInterceptorInterceptor,
} from './interceptors';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
    declarations: [],
    imports: [CommonModule, CoreRoutingModule, HttpClientModule],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpTokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ClientsInfoInterceptorInterceptor,
            multi: true,
        },
        Services.ApiService,
        Services.AuthService,
        Services.ThemeService,
        Services.MenuService,
        Services.ClientService,
        Services.GeneralService,
        Services.AdminService,
        Services.CorporateService,
        Services.ExcelService,
        Services.CheckService,
        Services.CounterService,
        Services.VisaService,
        Services.CreditService,
        Services.HomeService,
        Services.MarketService,
        Services.MerchantService,
        Services.TransferService,
        Services.BankService,
        Services.TarifService,
        Services.AccessService,
        Services.CustomerBaseService,
        Guards.AuthGuard,
        Guards.PendingChangesGuard,
        Guards.TreasurerGuard,
        Guards.WorkstationGuard,
        Guards.BankingGuard,
        DatePipe,
    ],
})
export class CoreModule {}
