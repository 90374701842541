<link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/leaflet.css"
/>
<!-- <script src="https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/leaflet.js"></script> -->

<div class="map-container">
    <div class="map-frame">
        <div id="map"></div>
    </div>

    <div class="legend" *ngIf="lat && lng">
        <div>
            <b i18n>Latitude :</b>
            <span i18n>{{ lat }}</span>
        </div>
        <div>
            <b i18n>Longitude :</b>
            <span i18n>{{ lng }}</span>
        </div>
    </div>
</div>

<div *ngIf="!lat && !lng">Getting your current location ...</div>

<div
    class="spinner-border text-dark"
    style="margin-top: 250px"
    role="status"
    *ngIf="!lat && !lng"
>
    <span class="visually-hidden" i18n>Loading...</span>
</div>
<!-- <div
    *ngIf="showButton"
    style="position: absolute; bottom: 10px; left: 30px; z-index: 20"
>
    <button class="btn btn-info" (click)="setLocation()" i18n>
        <b *ngIf="!isLoading">Set the location</b>
        <b *ngIf="isLoading">
            <i class="fa-solid fa-spin fa-circle-notch text-white"></i>
        </b>
    </button>
</div> -->
<div
    *ngIf="noLocationMessage"
    class="d-flex justify-content-end w-100"
    style="position: absolute; bottom: 10px; right: 20px"
>
    <div>
        <span class="float-end text-dark" i18n
            >Using your location as the merchant's location is unavailable.
            Please provide the merchant's location.</span
        >
    </div>
</div>

<!-- smallIcon = new L.Icon({
    iconUrl:
        'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-icon.png',

    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl:
        'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    shadowSize: [41, 41],
});
merchantLat:any
merchantLng:any

private initMap(): void {
    this.map = L.map('map', {
        center: [this.lat, this.lng],
        zoom: 20,
    });

    const tiles = L.tileLayer(
        'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        {
            maxZoom: 18,
            minZoom: 3,
            attribution:
                '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        }
    );

    tiles.addTo(this.map);
    if (this.lat && this.lng) {
        this.addMarker(
            this.lat,
            this.lng,
            this.description,
            this.popupOptions.open
        );
    }
}

getLocation() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position: any) => {
                if (position) {
                    console.log(
                        'Latitude: ' +
                            position.coords.latitude +
                            'Longitude: ' +
                            position.coords.longitude
                    );
                    this.lat = position.coords.latitude;
                    this.lng = position.coords.longitude;
                    this.initMap();
                }
            },
            (error: any) => console.log(error)
        );
    } else {
        alert('Geolocation is not supported by this browser.');
    }
}

// onMapClick(event: any) {
//     console.log(event.latlng);
//     this.latlng = event.latlng;
//     this.lat = event.latlng.lat;
//     this.lng = event.latlng.lng;
// }


merchantIcon = new L.Icon({
    iconUrl: 'https://www.svgrepo.com/show/80543/shopping-cart-outline.svg',
    iconSize: [38, 75],

    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

ngOnInit(): void {
    //
}
markerOptions: any = {
    icon: this.merchantIcon,
    draggable: true,
};
ngAfterViewInit(): void {
this.getLocation();

if(this.map){
var popup = L.popup();

const onMapClick = (e:any) => {
    popup
        .setLatLng(e.latlng)
        .setContent("You clicked the map at " + e.latlng.toString())
        .openOn(this.map);
}
this.map.on('click', onMapClick);
if(this.map){
    this.map.on('click', onMapClick);

}  
}


}



addMarker(lat: any, long: any, text: any, open: any) {
    const marker = L.marker([lat, long], { icon: this.merchantIcon });
    marker.addTo(this.map);
    if (open) {
        marker.addTo(this.map).bindPopup(text).openPopup();
    } else {
        marker.addTo(this.map).bindPopup(text);
    }
}
markMerchant(lat:any, lng:any){
    const marker = L.marker([lat, lng], { icon: this.merchantIcon });
    marker.addTo(this.map)


} -->
