import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SettingService {
    //
    private _isSetting: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    get isSetting$(): Observable<boolean> {
        return this._isSetting.asObservable();
    } //

    private _selectedSubMenu: BehaviorSubject<string> =
        new BehaviorSubject<string>('');

    get selectedSubMenu$(): Observable<string> {
        return this._selectedSubMenu.asObservable();
    } //

    constructor(private apiService: ApiService) {
        //
    }

    handleTransfer(arg: boolean) {
        this._isSetting.next(arg);
    }

    selectSubMenu(menu: string) {
        this._selectedSubMenu.next(menu);
    }

    changePin(body: any) {
        const url = '/client/change-pin/';
        return this.apiService.post(url, body).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    changePassword(body: any) {
        const url = '/client/change-password/';
        return this.apiService.post(url, body).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getClientContact(clientId: any, contactType: string) {
        let url = '';
        if (contactType === 'phoneNumber') {
            url = `/client/extid/?client=${clientId}&id_type=P`;
        } else if (contactType === 'email') {
            url = `/client/extid/?client=${clientId}&id_type=E`;
        }
        return this.apiService.get(url).pipe(map((data) => data));
    }

    makeNumberOrEmailPrimary(id: number, primary: any) {
        return this.apiService.patch('/client/extid/' + id + '/', primary).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
}
