import { Injectable, SimpleChanges } from '@angular/core';

import { Observable, of, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { AuthState } from '../../shared';

@Injectable({
    providedIn: 'root',
})
export class VariableService {
    // password: Observable<string> = new Observable<string>;
    password: any;
    clientId = '';
    selectedClient: any;
    selectedFilt = '';
    accountId = '';
    selectedAccount: any;
    pin = '';
    menuGroups = [
        { name: 'Reporting', value: 'R' },
        { name: 'Desk', value: 'W' },
        { name: 'Intranet', value: 'I' },
    ];
    plateforms = [
        {
            plateform: 'home',
            title: 'Switch On Home',
            image: '',
            icon: 'fa-solid fa-house-chimney-user',
            is_selected: false,
        },
        {
            plateform: 'onlineBanking',
            title: 'Switch On Banking',
            icon: '',
            image: '/assets/images/ihela-b.svg',
            is_selected: false,
        },
        {
            plateform: 'market',
            title: 'Switch On My market',
            image: '',
            icon: 'fa-solid fa-cart-shopping',
            is_selected: false,
        },
        {
            plateform: 'workStation',
            title: 'Switch On WorkStation',
            image: '',
            icon: 'fa-solid fa-desktop',
            is_selected: false,
        },
        {
            plateform: 'onamob',
            title: 'Switch On Onamob',
            image: '',
            icon: 'fa-solid fa-mobile-screen-button',
            is_selected: false,
        },
    ];

    selectedCrumb: BehaviorSubject<string> = new BehaviorSubject('');

    // search: Observable<string>;
    search: BehaviorSubject<string> = new BehaviorSubject('');
    isPopulatingOperator: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(private apiService: ApiService) {
        // this.search = of('');
        // this.search.next(of(''));
    }

    initPlateforms() {
        this.plateforms = [
            {
                plateform: 'home',
                title: 'Switch On Home',
                image: '',
                icon: 'fa-solid fa-house-chimney-user',
                is_selected: false,
            },
            {
                plateform: 'onlineBanking',
                title: 'Switch On Banking',
                icon: '',
                image: '/assets/images/ihela-b.svg',
                is_selected: false,
            },
            {
                plateform: 'onamob',
                title: 'Switch On Onamob',
                image: '',
                icon: 'fa-solid fa-mobile-screen-button',
                is_selected: false,
            },
            {
                plateform: 'market',
                title: 'Switch On My market',
                image: '',
                icon: 'fa-solid fa-cart-shopping',
                is_selected: false,
            },
            {
                plateform: 'workStation',
                title: 'Switch On WorkStation',
                image: '',
                icon: 'fa-solid fa-desktop',
                is_selected: false,
            },
        ];
    }

    setSelectedCrumb(value: string) {
        this.selectedCrumb.next(value);
    }
}
