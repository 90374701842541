import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';

import { ClientService, VariableService } from '../../../../core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-general-client-details',
    templateUrl: './general-client-details.component.html',
    styleUrls: ['./general-client-details.component.scss'],
})
export class GeneralClientDetailsComponent implements OnInit {
    @Input() clientId: any;

    selectedAccount: any;
    @Input() accountId: any;
    @Input() updateOption = false;
    @Input() canRedirectToDetails = false;
    selectedClient: any;
    selectedFilt: any;
    clientId2 = '';
    state = false;
    @Output() selectedClientEvent = new EventEmitter<any>();
    @Output() selectedAccountEvent = new EventEmitter<any>();

    @Output() showUpdates = new EventEmitter<any>();

    constructor(
        private clientService: ClientService,
        private variableService: VariableService,
        private router: Router
    ) {
        // comment
    }

    // I discovered that ngIf in parent component recall child component and call api anytime to child is show by a condition,
    // so I used a global variable and lifecycle to fix that.
    // I think there is an easier way, Please if you find it before me lemme know.
    // .. Dorian

    ngOnInit(): void {
        if (this.variableService.clientId === '' && this.clientId) {
            this.variableService.clientId = this.clientId;
            this.getClientDetails();
        } else if (
            (this.variableService.clientId === '') !== this.clientId &&
            this.clientId
        ) {
            this.variableService.clientId = this.clientId;
            this.getClientDetails();
        } else {
            this.selectedClient = this.variableService.selectedClient;
        }

        if (this.variableService.accountId === '' && this.accountId) {
            this.variableService.accountId = this.accountId;
            this.getAccountDetails();
        } else if (
            (this.variableService.accountId === '') !== this.accountId &&
            this.accountId
        ) {
            this.variableService.accountId = this.accountId;
            this.getAccountDetails();
        } else {
            this.selectedAccount = this.variableService.selectedAccount;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            const chng = changes[propName];
            console.log(propName);
            if (propName === 'clientId') {
                this.clientId = chng.currentValue;
            }
        }
    }

    getClientDetails() {
        this.selectedClient = null;
        this.clientService.getClientDetails(this.clientId).subscribe({
            next: (client) => {
                this.variableService.selectedClient = client.object;
                this.selectedClientEvent.emit(client.object);
                this.selectedClient = client.object;
            },
        });
    }
    getAccountDetails() {
        this.selectedAccount = null;
        this.clientService.getClientAccountDetails(this.accountId).subscribe({
            next: (account) => {
                this.variableService.selectedAccount = account.object;
                this.selectedAccount = account.object;
            },
        });
    }

    selectFilt(filt: string) {
        this.selectedFilt = filt;
    }

    redirectToDetails() {
        const url = '/w/workstation/desk/client/details/' + this.clientId;
        this.router.navigate([url]);
    }

    redirectToClientsList() {
        this.router.navigate(['/w/workstation/desk/client/list']);
    }

    showUpdateDetails() {
        if (this.state) {
            this.state = false;
            this.showUpdates.emit(false);
        } else {
            this.showUpdates.emit(true);
            this.state = true;
        }
        // this.showUpdates.emit(true);
    }
}
