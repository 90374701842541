import { Injectable } from '@angular/core';

import {
    State,
    StateToken,
    Selector,
    Action,
    StateContext,
    Store,
} from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DialogModel } from './dialog.models';
import {
    OpenDialog,
    OpenConfirmDialog,
    OpenActionDialog,
    CloseDialog,
} from './dialog.actions';

const DIALOG_MODEL = new StateToken<DialogModel>('modal');

@State<DialogModel>({
    name: DIALOG_MODEL,
    defaults: {
        modal: {
            title: null,
            type: null,
            message: null,
            isActive: false,
            response: null,
            action: null,
        },
    },
})
@Injectable()
export class DialogState {
    constructor() {
        //comment
    }

    @Selector()
    static GetDialog(state: DialogModel): any {
        return state.modal;
    }

    @Selector()
    static isActive(state: DialogModel): any {
        return state.modal.isActive;
    }

    @Action(OpenDialog)
    openDialog(ctx: StateContext<DialogModel>, action: OpenDialog) {
        ctx.patchState({
            modal: {
                type: action.payload.type,
                title: action.payload.title,
                message: action.payload.message,
                isActive: true,
                response: null,
                action: null,
            },
        });
    }

    @Action(OpenConfirmDialog)
    openConfirmDialog(
        ctx: StateContext<DialogModel>,
        action: OpenConfirmDialog
    ) {
        ctx.patchState({
            modal: {
                type: action.payload.type,
                title: action.payload.title,
                message: action.payload.message,
                isActive: true,
                response: null,
                action: action.payload.action,
            },
        });
    }

    @Action(OpenActionDialog)
    openActionDialog(ctx: StateContext<DialogModel>, action: OpenActionDialog) {
        ctx.patchState({
            modal: {
                type: action.payload.type,
                title: action.payload.title,
                message: action.payload.message,
                isActive: true,
                response: null,
                action: action.payload.action,
            },
        });
    }

    @Action(CloseDialog)
    closeDialog(ctx: StateContext<DialogModel>, action: CloseDialog) {
        const state = ctx.getState();
        ctx.patchState({
            modal: {
                isActive: false,
                type: null,
                title: state.modal.title,
                message: null,
                response: action.payload.response,
                action: state.modal.action,
            },
        });
        console.log('dialog closed', state);
    }
}
