import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Store } from '@ngxs/store';
import { BankService } from 'src/app/core/services/bank.service';
import { AuthState } from '../../states/auth';
import { BankState, SelectClientBank } from '../../states/bank';
import { Observable, Subject, takeUntil } from 'rxjs';
import { LoanService } from 'src/app/core/services/loan.service';
import { GeneralModule } from 'src/app/shared/components/general';
import { SwitchTheme, SwitchThemeState } from '../../states/theme';

@Component({
    selector: 'app-switch-bank',
    templateUrl: './switch-bank.component.html',
    styleUrls: ['./switch-bank.component.scss'],
    standalone: true,
    imports: [CommonModule, GeneralModule],
})
export class SwitchBankComponent {
    banks: any;
    clientId$: any;
    clientId = '';
    defaultBank: any;
    selectedBank: any;
    selectedBank$: Observable<any>;
    loans: any;
    isModalShown = false;
    theme = '';
    theme$: Observable<string>;

    @Output() bankOptions: EventEmitter<{
        selectedDebitAccountType: any;
        debitAccount: any;
        debitWallet: any;
        banks: any;
        creditAccountType: any;
        accounts: any;
        wallets: any;
    }> = new EventEmitter();

    private onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private bankService: BankService,
        private store: Store,
        private loanService: LoanService
    ) {
        this.clientId$ = this.store.select(AuthState.GetClientId);
        this.selectedBank$ = this.store.select(BankState.GetSelectedClientBank);
        this.theme$ = this.store.select(SwitchThemeState.GetTheme);
    }

    ngOnInit() {
        // this.selectedBank$.subscribe(bank => {
        //     this.selectedBank=bank
        // })
        this.theme$.subscribe((theme) => {
            this.theme = theme;
        });
        this.clientId$.subscribe((clientId: string) => {
            this.clientId = clientId;
            this.selectedBank$.subscribe((bank: any) => {
                this.selectedBank = bank;
            });

            this.bankService
                .getBanksList()
                .pipe(takeUntil(this.onDestroy$))
                .subscribe((banks: any) => {
                    this.banks = banks.objects;
                    const options = {
                        selectedDebitAccountType: null,
                        debitAccount: null,
                        debitWallet: null,
                        banks: this.banks,
                        creditAccountType: null,
                        accounts: null,
                        wallets: null,
                    };

                    this.bankOptions.emit(options);

                    this.defaultBank = banks.objects.find(
                        (bank: any) => bank.is_default === true
                    );

                    // this.store.dispatch(
                    //     new SelectClientBank({
                    //         id: this.selectedBank.id,
                    //         name: this.selectedBank.name,
                    //         slug: this.selectedBank.slug,
                    //         bank_type: this.selectedBank.bank_type,
                    //         bank_code: this.selectedBank.bank_code,
                    //         is_active: this.selectedBank.is_active,
                    //         is_default: this.selectedBank.is_default,
                    //         company: this.selectedBank.company,
                    //     })
                    // );
                });
        });
    }

    switchBank(index: any) {
        this.loanService
            .getLoansList()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((loans: any) => {
                this.loans = loans.object.response_data;
                // console.log('loans', this.loans.length);
                this.loanService.getLoansLength(this.loans.length);
            });
        const options = {
            selectedDebitAccountType: null,
            debitAccount: null,
            debitWallet: null,
            banks: this.banks,
            creditAccountType: null,
            accounts: undefined,
            wallets: undefined,
        };

        this.bankOptions.emit(options);
        this.selectedBank = this.banks[index];

        this.store.dispatch(
            new SelectClientBank({
                id: this.selectedBank.id,
                name: this.selectedBank.name,
                slug: this.selectedBank.slug,
                bank_type: this.selectedBank.bank_type,
                bank_code: this.selectedBank.bank_code,
                is_active: this.selectedBank.is_active,
                is_default: this.selectedBank.is_default,
                company: this.selectedBank.company,
            })
        );
        this.isModalShown = false;
    }

    // ngOnDestroy() {
    //     this.onDestroy$.next();
    //     this.onDestroy$.complete();
    //     this.store.dispatch(
    //         new SelectClientBank({
    //             id: '',
    //             name: '',
    //             slug: '',
    //             bank_type: '',
    //             bank_code: '',
    //             is_active: false,
    //             is_default: false,
    //             company: undefined,
    //         })
    //     );
    // }

    closeModal(event: MouseEvent): void {
        if (!(event.target as HTMLElement).closest('.inner-container')) {
            this.isModalShown = false;
        }
    }

    showModal() {
        this.isModalShown = true;
    }
}
