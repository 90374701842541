import { Component, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';

import {
    Switch,
    SwitchState,
    AuthState,
    MenuState,
    SwitchThemeState,
} from '../../../../';
import { VariableService } from '../../../../../core/services';

@Component({
    selector: 'app-sub-header',
    templateUrl: './sub-header.component.html',
    styleUrls: ['./sub-header.component.scss'],
})
export class SubHeaderComponent implements OnInit {
    private variableService = inject(VariableService);
    text = { title: '', icon: '', link: '' };
    plateform$: Observable<any>;
    plateform: any;
    userInfo$: Observable<any>;
    userInfo: any;
    selectedMarket: any = 'market';
    selectedMarket$: Observable<any>;
    dayMoment = 'Morning';
    search = new FormControl('');

    clientInfo$: Observable<any>;

    theme: any;
    theme$: Observable<any>;

    constructor(private store: Store) {
        this.plateform$ = this.store.select(SwitchState.GetPlateform);
        this.userInfo$ = this.store.select(AuthState.GetUserInfo);
        this.clientInfo$ = this.store.select(AuthState.GetClientInfo);
        this.selectedMarket$ = this.store.select(MenuState.GetSelectedMarket);
        this.theme$ = this.store.select(SwitchThemeState.GetTheme);
    }

    ngOnInit() {
        this.plateform$.subscribe({
            next: (plateform: any) => {
                this.plateform = plateform;
                this.text = {
                    title: '',
                    icon: '../../../../../assets/images/ihela-fav.ico',
                    link: '',
                };
                if (plateform === 'home') {
                    this.userInfo$.subscribe({
                        next: (userInfo) => {
                            this.userInfo = userInfo;
                            this.dayMoment = this.timeOfDay();
                        },
                    });
                    this.text['link'] = '/h';
                } else if (this.plateform === 'onlineBanking') {
                    this.text['title'] = 'Digital Financial Services';
                    this.text['link'] = '/b';
                } else if (this.plateform === 'onamob') {
                    this.text['title'] = 'Onamob';
                    this.text['link'] = '/o/onamob';
                    this.text['icon'] =
                        '../../../../../assets/images/onamob-dashboard.svg';
                } else if (this.plateform === 'market') {
                    this.text['link'] = '/m';
                    this.selectedMarket$.subscribe({
                        next: (market) => {
                            this.selectedMarket = market;
                            if (this.selectedMarket === 'myMarket') {
                                this.text['title'] = 'My Market';
                            } else {
                                this.text['title'] = 'Market Place';
                            }
                        },
                    });
                }
            },
        });

        this.theme$.subscribe({
            next: (theme) => {
                this.theme = theme;
            },
        });

        setInterval(() => {
            this.dayMoment = this.timeOfDay();
        }, 60000);
    }

    timeOfDay() {
        let moment = '';
        const hour = new Date().getHours();
        if (hour >= 4 && hour <= 11) {
            moment = 'Morning';
        } else if (hour >= 12 && hour <= 16) {
            moment = 'Afternoon';
        } else if (hour >= 17 || hour <= 3) {
            moment = 'Evening';
        }
        return moment;
    }

    searchFor() {
        if (this.search.value) {
            this.variableService.search.next(this.search.value);
        }
    }
}
