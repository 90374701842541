<!-- <div>
    <div class="modal-content">
        <div class="modal-body m-0 p-0">
            <div class="container-fluid">
                <div class="row" *ngIf="product">
                    <div class="col-6 m-0 p-0">
                        <img
                            [src]="product.icon"
                            *ngIf="product.icon"
                            class="img-fluid"
                            style="
                                height: 600px;
                                border-top-left-radius: 5px;
                                border-bottom-left-radius: 5px;
                            "
                        />
                        <img
                            src="./assets/images/illistrator2.png"
                            *ngIf="!product.icon"
                            width="499"
                            height="600"
                            style="
                                height: 600px;
                                border-top-left-radius: 5px;
                                border-bottom-left-radius: 5px;
                            "
                        />
                    </div>
                    <div class="col-6" style="background-color: #e4ebf1">
                        <div class="row ms-2">
                            <div
                                class="row m-0 p-0 mt-3 justify-content-between"
                            >
                                <div class="col-6">
                                    {{ product.merchant.merchant_title }}
                                </div>
                                <div class="col-5">
                                    <button
                                        type="button"
                                        class="btn-close float-end mx-2"
                                         data-bs-dismiss="modal"
                                        style="border: none"
                                        (click)="emptyQuant()"
                                    ></button>
                                </div>
                            </div>
                            <div class="row">
                                <hr
                                    class="ms-2"
                                    style="width: 45px; color: black"
                                />
                            </div>
                            <div class="row justify-content-between">
                                <div class="col-7">
                                    <h3 class="mb-3">
                                        <b>{{ product.name }}</b>
                                    </h3>

                                    <p>
                                        <b>{{ product.price }}</b>
                                    </p>
                                </div>
                                <div class="col">
                                    <div class="row float-end mt-3">
                                        <span class="float-end">
                                            <span class="text-success"
                                                ><i
                                                    class="fa-solid fa-star"
                                                ></i></span
                                            ><br />
                                            <span class="text-success"
                                                ><i
                                                    class="fa-solid fa-star"
                                                ></i></span
                                            ><br />
                                            <span class="text-success"
                                                ><i
                                                    class="fa-solid fa-star"
                                                ></i></span
                                            ><br />
                                            <span
                                                ><i
                                                    class="fa-regular fa-star"
                                                ></i></span
                                            ><br />
                                            <span
                                                ><i
                                                    class="fa-regular fa-star"
                                                ></i></span
                                            ><br />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <hr
                                    class="ms-2"
                                    style="width: 45px; color: black"
                                />
                            </div>
                            <div class="row">
                                <span i18n>Quantity</span>
                            </div>
                            <div class="row m-0 p-0">

                                <div class="col-3 mx-2">

                                    <div class="row">
                                        <div
                                            class="col"
                                            style="
                                                background-color: #d7eaf9;
                                                width: 70px;
                                            "
                                        >
                                            <span style="color: black">
                                                <span
                                                    class="float-start"
                                                    type="button"
                                                    (click)="
                                                        removeItemQuantity()
                                                    "
                                                    [attr.disabled]="
                                                        itemQuantity != 1
                                                    "
                                                >
                                                    <i
                                                        class="fa-solid fa-minus"
                                                    ></i>
                                                </span>
                                                <span class="ms-4">
                                                    {{ itemQuantity }}
                                                </span>
                                                <span
                                                    class="float-end"
                                                    type="button"
                                                    (click)="addItemQuantity()"
                                                    ><i
                                                        class="fa-solid fa-plus"
                                                    ></i
                                                ></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-5 mx-3">

                                    <div class="row text-center">
                                        <div
                                            (click)="addToCart(product)"
                                            class="col"
                                            type="button"
                                            style="
                                                background-color: #d7eaf9;
                                                width: 70px;
                                            "
                                        >
                                            <span style="color: black" i18n
                                                ><b>Add to cart</b></span
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="col-2 m-0 p-0 float-end"
                                    type="button"
                                >

                                    <span class="float-end">
                                        <span
                                            class="position-relative icon fs-4 text-gray"
                                            type="button"
                                        >
                                            <i
                                                class="fa-solid fa-cart-shopping"
                                            ></i>

                                            <span
                                                style="
                                                    padding: 0px 4px;
                                                    font-size: 13px;
                                                    left: 30px;
                                                    top: -3px;
                                                "
                                                class="shadow position-absolute translate-middle rounded-circle text-light badge bg-dark fw-bold"
                                            >
                                                {{ cartAdding }}
                                            </span>
                                        </span>
                                    </span>
                                    <span
                                        class="float-end text-primary position-relative"
                                        style="
                                            font-size: 16px;
                                            top: 73%;
                                            right: -33%;
                                        "
                                    >
                                        <button
                                            type="button"
                                            class="shaker"
                                            (click)="emptyCart()"
                                        >
                                            Pay
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <span class="float-end" *ngIf="!totalAmount"
                                >Total: 0</span
                            >
                            <span class="float-end" *ngIf="totalAmount"
                                >Total: {{ totalAmount }}</span
                            >
                        </div>
                        <div
                            *ngFor="let cart of cartItemList"
                            style="
                                position: relative;
                                top: 30%;
                                overflow-y: auto;
                            "
                        >
                            <div
                                class="row"
                                style="border: none; background-color: #d7eaf9"
                            >
                                <div class="row justify-content-between">
                                    <div class="col-6">
                                        <span class="float-start"
                                            >{{ cart.name
                                            }}<strong *ngIf="cart.price">{{
                                                cart.price
                                            }}</strong></span
                                        >
                                    </div>
                                    <div class="col-6 m-0 p-0">
                                        <span class="float-end"
                                            ><strong>{{
                                                cart.Quantity
                                            }}</strong></span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 -->

<div class="modal-content">
    <div class="modal-body m-0 p-0">
        <div
            class="container-fluid row center1"
            style="min-width: 742px; height: 300px"
        >
            <app-not-found-page></app-not-found-page>
        </div>
    </div>
</div>
