<!-- <div
    title="You can move this part"
    class="float-start bankMenu shadow p-0 bg-white"
    style="z-index: 5"
    cdkDrag
    (click)="moved = true"
    [ngClass]="{ bankMenu: moved === false, moved: moved === true }"
>
    <p class="text-center mt-2" i18n>
        <i class="fas fa-building-columns fa-2x"></i><br /><span class="small"
            >Bank</span
        >
    </p> -->

<!-- <p class="text-center">
        <small i18n></small><br />
        <i class="fas fa-circle-notch fa-spin fa-2x"></i>
    </p> -->
<!-- <div>
        <div>
            <div
                class="float-start rounded-small p-3 clickable"
                style="border-radius: 50%; margin-left: -50%"
            >
                <img
                    src="../../../assets/images/userprofile.png"
                    class="app-logo"
                    style="width: 100%; border-radius: 50%"
                />
            </div>
        </div>
    </div>
</div> -->

<main>
    <div *ngIf="selectedBank" class="d-flex justify-content-center">
        <img
            class="me-2 rounded is-hoverable selected"
            role="button"
            (click)="showModal()"
            [src]="selectedBank.company.image"
            [alt]="selectedBank.company.name"
            [title]="selectedBank.company.name"
        />
    </div>

    <section
        *ngIf="isModalShown"
        class="modal-overlay"
        (click)="closeModal($event)"
    >
        <div
            class="level-1 w-lg-25 inner-container mx-1 h-50 lg-radius"
            style="min-width: 25%"
        >
            <div class="pe-2 pt-2 mb-3">
                <span
                    class="float-end"
                    role="button"
                    (click)="isModalShown = false"
                >
                    <i class="fa fa-circle-xmark text-danger fs-4"></i>
                </span>
            </div>

            <div class="m-3 mt-5">
                <div class="mt-1 px-5">
                    <div class="w-100 d-flex justify-content-between">
                        <b>Banks</b>
                        <span
                            *ngIf="banks"
                            style="
                                background-color: rgb(209, 206, 206);
                                color: #000;
                            "
                            class="px-2 rounded-circle"
                        >
                            <b>{{ banks.length }}</b>
                        </span>
                    </div>
                </div>
                <hr class="mx-4" />
                <div
                    *ngIf="banks"
                    class="px-1 mt-5 align-items-center row justify-content-between"
                >
                    <div
                        class="d-grid col-auto mb-4 me-2 p-2 is-hoverable rounded align-items-center"
                        *ngFor="let bank of banks; let i = index"
                        (click)="switchBank(i)"
                        role="button"
                    >
                        <div class="w-100 d-flex justify-content-center">
                            <img
                                class="me-2 rounded bank"
                                [title]="'switch to ' + bank.company.name"
                                [src]="bank.company.image"
                                [alt]="bank.company.name"
                            />
                        </div>

                        <span class="mt-1">{{ bank.company.name }}</span>
                    </div>
                </div>
                <div
                    *ngIf="!banks"
                    class="d-flex align-items-center m-3 justify-content-center"
                >
                    <span class="fs-5 me-2">
                        <i class="fa-solid fa-pulse fa-circle-notch"></i>
                    </span>
                    <span
                        class="fs-6 text-secondary"
                        [ngClass]="{
                    'text-secondary':theme==='light-mode',
                    'text-dark':theme==='dark-mode',
        
                    
                }"
                        >Loading bank...</span
                    >
                </div>
            </div>
        </div>
    </section>
</main>
