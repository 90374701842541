import { Component, OnInit } from '@angular/core';

import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TransferService } from 'src/app/core/services/transfer.service';

import { SwitchState, AuthState, BankState } from 'src/app/shared';

@Component({
    selector: 'app-setting-online-banking',
    templateUrl: './setting-online-banking.component.html',
    styleUrls: ['./setting-online-banking.component.scss'],
})
export class SettingOnlineBankingComponent {
    plateform: any;
    plateform$!: Observable<any>;
    private onDestroy$: Subject<void> = new Subject<void>();
    recentTransactions: any;
    bankId$: Observable<any>;
    clientId$: Observable<any>;
    clientVerified = '&filter_for_client=true';

    constructor(
        private store: Store,
        private transferService: TransferService
    ) {
        this.plateform$ = this.store.select(SwitchState.GetPlateform);
        this.bankId$ = this.store.select(BankState.GetSelectedClientBankId);
        this.clientId$ = this.store.select(AuthState.GetClientId);
    }

    ngOnInit() {
        this.plateform$.pipe(takeUntil(this.onDestroy$)).subscribe({
            next: (response: any) => {
                this.plateform = response;
            },
        });

        const period = {
            start_date: '',
            end_date: '',
        };

        this.clientId$.subscribe({
            next: (clientId) => {
                if (clientId) {
                    this.bankId$.subscribe({
                        next: (bankId) => {
                            if (bankId) {
                                this.transferService
                                    .getRecentTransactions(
                                        '',
                                        period,
                                        this.clientVerified
                                    )
                                    .subscribe((transfers) => {
                                        this.recentTransactions =
                                            transfers.objects;
                                    });
                            }
                        },
                    });
                }
            },
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
