import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';

import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { AuthState, BankState, SwitchState } from '../../shared';
import { environment } from 'src/environments/environment';

@Injectable()
export class ClientsInfoInterceptorInterceptor implements HttpInterceptor {
    constructor(
        private store: Store,
        @Inject(LOCALE_ID) protected localeId: string
    ) {}

    intercept(
        req: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        const headersConfig: any = {
            'X-iHela-Access-Client-Id': '',
            'X-iHela-Access-Bank-Id': '',
            'X-iHela-AppSubject': '',
        };

        const clientId = this.store.selectSnapshot(AuthState.GetClientId);
        const clientBankId = this.store.selectSnapshot(
            BankState.GetSelectedClientBankId
        );
        const plateform = this.store.selectSnapshot(SwitchState.GetPlateform);
        headersConfig['X-iHela-AppInfo'] = environment.appInfo;

        if (clientId) {
            headersConfig['X-iHela-Access-Client-Id'] = `${clientId}`;
        }

        if (clientBankId) {
            headersConfig['X-iHela-Access-Bank-Id'] = `${clientBankId}`;
        }

        if (plateform) {
            headersConfig['X-iHela-AppSubject'] =
                environment.plateformsUuid.find(
                    (plateformEnv) => plateformEnv.name === plateform
                )!.uuid;
        }

        const request = req.clone({ setHeaders: headersConfig });
        return next.handle(request);
    }
}
