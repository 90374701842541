<dialog
    id="favDialog"
    class="rounded level-2"
    style="border: none; max-width: 500px"
>
    <div *ngIf="dialog.type === 'confirm'">
        <p class="text-center" style="color: #f27474; font-size: 60px">
            <i class="fa-regular fa-circle-question"></i>
        </p>
        <p
            style="font-weight: 900; font-size: 20px"
            *ngIf="dialog"
            class="text-center text-dark"
            [innerHTML]="dialog.message"
        ></p>
        <div class="text-center mt-3">
            <button
                type="button"
                class="btn btn-primary btn-sm me-3"
                (click)="respondModal('Yes')"
            >
                Yes
            </button>

            <button
                type="button"
                class="btn btn-dark btn-sm"
                (click)="respondModal('No')"
            >
                No
            </button>
        </div>
    </div>

    <div *ngIf="dialog.type === 'password'">
        <p
            style="font-size: 20px"
            *ngIf="dialog"
            class="text-center text-dark"
            [innerHTML]="dialog.message"
        ></p>
        <form
            [formGroup]="passwordForm"
            (ngSubmit)="submitPassword()"
            class="text-center mt-2"
        >
            <div class="form-floating mt-2">
                <input
                    type="password"
                    class="form-control input level-3"
                    name="password"
                    placeholder="Password"
                    style="border: none"
                    formControlName="password"
                />
                <label class="fs-6" for="id_password"
                    ><small
                        ><i class="fa-solid fa-lock text-secondary"></i>
                        Password</small
                    >
                    <code>*</code></label
                >
            </div>
            <div class="text-center mt-4">
                <!-- <span *ngIf="isLoading">
                    <i
                        class="fa-solid fa-pulse fa-circle-notch fs-4"
                    ></i>
                </span> -->
                <button
                    class="btn btn-sm bg-primary is-small is-rounded"
                    type="submit"
                    [disabled]="!passwordForm.valid"
                >
                    <span style="color: #fff">Submit</span>
                </button>
                <b
                    role="button"
                    class="ms-4 text-dark"
                    (click)="respondModal('cancel')"
                    >Cancel</b
                >
            </div>
        </form>
    </div>

    <div *ngIf="dialog.type === 'pin'">
        <div *ngIf="clientInfo && !clientInfo.hasPin">
            <p style="font-size: 20px" class="text-center text-dark" i18n>
                You do not have a pin yet, Please create your pin before
                performing your action!
            </p>
            <form
                [formGroup]="changePinForm"
                (ngSubmit)="submitPinCreation()"
                class="text-center mt-2"
            >
                <div class="form-floating mt-2">
                    <input
                        type="{{ pinType }}"
                        class="form-control input level-3"
                        name="pin"
                        style="border: none"
                        formControlName="new_pin"
                        placeholder="Enter your pin"
                        maxlength="4"
                        (keyup)="checkPinSimulality()"
                    />
                    <label class="fs-6" for="id_pin"
                        ><small
                            ><i class="fa-solid fa-key text-secondary me-3"></i>
                            Enter your pin</small
                        >
                    </label>
                    <span (click)="changePinType()" *ngIf="showPin">
                        <i
                            class="fa-regular fa-eye text-dark"
                            style="position: absolute; top: 19px; right: 10px"
                        ></i>
                    </span>
                    <span (click)="changePinType()" *ngIf="!showPin">
                        <i
                            class="fa-regular fa-eye-slash text-dark"
                            style="position: absolute; top: 19px; right: 10px"
                        ></i>
                    </span>
                </div>
                <div class="form-floating mt-2">
                    <input
                        type="{{ pinType }}"
                        class="form-control input level-3"
                        name="pin"
                        style="border: none"
                        formControlName="new_pin2"
                        placeholder="Confirm your pin"
                        maxlength="4"
                        (keyup)="checkPinSimulality()"
                    />
                    <label class="fs-6" for="id_pin"
                        ><small
                            ><i class="fa-solid fa-key text-secondary me-3"></i>
                            Confirm your pin</small
                        >
                    </label>
                    <span (click)="changePinType()" *ngIf="showPin">
                        <i
                            class="fa-regular fa-eye text-dark"
                            style="position: absolute; top: 19px; right: 10px"
                        ></i>
                    </span>
                    <span (click)="changePinType()" *ngIf="!showPin">
                        <i
                            class="fa-regular fa-eye-slash text-dark"
                            style="position: absolute; top: 19px; right: 10px"
                        ></i>
                    </span>
                </div>
                <div
                    *ngIf="
                        changePinForm.value.new_pin !== '' &&
                        changePinForm.value.new_pin2 !== ''
                    "
                >
                    <small class="text-danger" *ngIf="!arePinsMatch"
                        >Pins doesn't match</small
                    >
                </div>
                <div class="form-floating mt-2">
                    <input
                        type="{{ pinType }}"
                        class="form-control input level-3"
                        name="pin"
                        style="border: none"
                        formControlName="old_pin"
                        placeholder="Enter your password"
                    />
                    <label class="fs-6" for="id_password"
                        ><small
                            ><i
                                class="fa-solid fa-lock text-secondary me-3"
                            ></i>
                            Enter your password</small
                        >
                    </label>
                    <span (click)="changePinType()" *ngIf="showPin">
                        <i
                            class="fa-regular fa-eye text-dark"
                            style="position: absolute; top: 19px; right: 10px"
                        ></i>
                    </span>
                    <span (click)="changePinType()" *ngIf="!showPin">
                        <i
                            class="fa-regular fa-eye-slash text-dark"
                            style="position: absolute; top: 19px; right: 10px"
                        ></i>
                    </span>
                </div>

                <div class="text-center mt-4">
                    <!-- <span *ngIf="isLoading">
                        <i
                            class="fa-solid fa-pulse fa-circle-notch fs-4"
                        ></i>
                    </span> -->
                    <button
                        class="btn btn-sm bg-primary is-small is-rounded"
                        type="submit"
                        [disabled]="!changePinForm.valid"
                        style="min-width: 60px"
                    >
                        <span style="color: #fff" *ngIf="!isCreatingPin"
                            >Create</span
                        >
                        <span *ngIf="isCreatingPin" style="color: #fff"
                            ><i
                                class="fa-solid fa-pulse fa-circle-notch fs-4"
                            ></i
                        ></span>
                    </button>
                    <b
                        role="button"
                        class="ms-4 text-dark"
                        (click)="respondModal('cancel')"
                        *ngIf="!isCreatingPin"
                        >Cancel</b
                    >
                </div>
            </form>
        </div>
        <div *ngIf="clientInfo && clientInfo.hasPin">
            <p
                style="font-size: 20px"
                *ngIf="dialog"
                class="text-center text-dark"
                [innerHTML]="dialog.message"
            ></p>
            <form
                [formGroup]="pinForm"
                (ngSubmit)="submitPin()"
                class="text-center mt-2"
            >
                <div class="form-floating mt-2">
                    <input
                        type="password"
                        class="form-control input level-3"
                        name="pin"
                        placeholder="Pin"
                        style="border: none"
                        formControlName="pin"
                        maxlength="4"
                    />
                    <label class="fs-6" for="id_password"
                        ><small
                            ><i class="fa-solid fa-lock text-secondary"></i>
                            Pin</small
                        >
                        <code>*</code></label
                    >
                </div>
                <div class="text-center mt-4">
                    <!-- <span *ngIf="isLoading">
                        <i
                            class="fa-solid fa-pulse fa-circle-notch fs-4"
                        ></i>
                    </span> -->
                    <button
                        class="btn btn-sm bg-primary is-small is-rounded"
                        type="submit"
                        [disabled]="!pinForm.valid"
                    >
                        <span style="color: #fff">Submit</span>
                    </button>
                    <b
                        role="button"
                        class="ms-4 text-dark"
                        (click)="respondModal('cancel')"
                        >Cancel</b
                    >
                </div>
            </form>
        </div>
    </div>

    <div
        *ngIf="dialog.type === 'loading'"
        style="
            min-height: 100px;
            min-width: 150px;
            display: flex;
            justify-content: center;
            padding-top: 25%;
            padding-bottom: 25%;
        "
    >
        <!-- <p
            style="font-size: 20px; font-weight: 900"
            *ngIf="dialog"
            class="text-center"
        >
            {{ dialog.message }}
        </p> -->
        <div class="spinner-border text-dark" role="status"></div>
    </div>
</dialog>

<div
    *ngIf="
        dialog.type === 'success' ||
        dialog.type === 'failed' ||
        dialog.type === 'info'
    "
>
    <div
        [ngClass]="{
            'notification-success': dialog.type === 'success',
            'notification-failed': dialog.type === 'failed',
            'bg-info': dialog.type === 'info'
        }"
        *ngIf="showNotification"
        class="p-2 ps-4 pe-4 sm-radius"
        style="max-width: 300px"
    >
        <div class="d-flex">
            <div style="margin-top: auto; margin-bottom: auto">
                <span *ngIf="dialog.type === 'success'"
                    ><i
                        class="fa-solid fa-circle-check me-2 text-success fs-5"
                    ></i
                ></span>
                <span *ngIf="dialog.type === 'failed'"
                    ><i
                        class="fa-solid fa-exclamation-triangle me-2 text-danger fs-5"
                    ></i
                ></span>
                <span class="text-info" *ngIf="dialog.type === 'info'">
                    <i class="fa-solid fa-circle-info me-2 fs-5"></i>
                </span>
            </div>
            <div>
                <b
                    style="font-size: 14px"
                    [ngClass]="{
                        'text-white':
                            (theme === 'dark-mode' || theme === 'magis-dark') &&
                            dialog.type !== 'info',
                        'text-info': dialog.type === 'info'
                    }"
                    >{{ dialog.message }}</b
                >
            </div>
        </div>
    </div>
</div>
