import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.sentryDsn) {
    Sentry.init({
        dsn: environment.sentryDsn,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
            Sentry.dedupeIntegration(),
            Sentry.metrics.metricsAggregatorIntegration(),
        ],
        tracesSampleRate: environment.sentryTraceRate,
        tracePropagationTargets: environment.sentryTargetUrls,
        // replaysSessionSampleRate: 0.1,
        // replaysOnErrorSampleRate: 1.0,
        profilesSampleRate: environment.sentryTraceRate,
        beforeSend(event, hint) {
            if (
                event.request?.url?.includes('localhost') ||
                event.request?.url?.includes('127.0.0.1')
            ) {
                return null;
            }
            return event;
        },
    });
}

if (environment.production) {
    enableProdMode();

    // disable some console on production
    console.log = () => {
        // void
    };
    console.info = () => {
        //void
    };
}
platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((success) => {
        //code
    })
    .catch((err) => console.error(err));
