<div class="col-4" *ngIf="closing">
    <form [formGroup]="searchForm" (ngSubmit)="getClientInfo()">
        <!-- *ngIf="!closing" -->
        <div>
            <input
                class="form-control form-control-sm ps-5 text-dark field"
                type="text"
                style="border-radius: 15px; border: none; width: 200px"
                placeholder="{{ placeholder }}"
                formControlName="clientId"
            />
        </div>

        <div>
            <button
                style="bottom: 15px"
                class="text-dark btn bg-transparent position-relative translate-middle-y"
                type="submit"
                *ngIf="!dataCheck"
            >
                <i class="fa-solid fa-search fs-7"></i>
            </button>
            <span
                style="bottom: 15px"
                *ngIf="dataCheck"
                class="btn bg-transparent position-relative text-dark translate-middle-y"
                ><i class="fas fa-spinner fa-pulse"></i
            ></span>
        </div>
    </form>
</div>
<div
    class="row"
    *ngIf="clientId && !closing"
    [ngClass]="{
        'd-flex justify-content-center': menuSelected === 'merchant_code'
    }"
>
    <div
        class="{{ cardBackground }} lg-radius text-dark"
        style="min-height: 320px; width: 248px"
    >
        <span
            type="button"
            (click)="closeInformation()"
            class="float-end mt-1 text-secondary fs-5"
            ><i class="fa-solid fa-circle-xmark"></i
        ></span>
        <div class="p-3">
            <span i18n class="d-flex justify-content-center"
                ><strong *ngIf="menuSelected !== 'merchant_code'"
                    >Client Info</strong
                >
                <strong *ngIf="menuSelected === 'merchant_code'"
                    >Merchant Info</strong
                ></span
            >
            <div class="d-flex justify-content-center">
                <div
                    *ngIf="clientId.acc_picture"
                    class="lg-radius"
                    style="width: 100px; height: 100px"
                >
                    <img
                        [src]="clientId.acc_picture"
                        *ngIf="clientId.acc_picture"
                        class="img-fluid float-end image lg-radius"
                    />
                </div>
                <img
                    *ngIf="!clientId.acc_picture"
                    src="./assets/images/userprofile.png"
                    class="img-fluid float-start"
                    style="
                        position: relative;
                        width: 120px;
                        height: 120px;
                        border-radius: 50%;
                    "
                />
            </div>
            <div class="">
                <span
                    class="d-flex justify-content-center mt-1"
                    style="flex-wrap: wrap"
                    ><strong class="text-center">{{
                        clientId.acc_holder
                    }}</strong></span
                >
                <span class="d-flex justify-content-center mt-1"
                    ><strong class="text-center">{{
                        clientId.acc_number
                    }}</strong></span
                >
                <span
                    class="d-flex justify-content-center mt-1"
                    style="flex-wrap: wrap"
                    ><strong class="text-center">{{
                        clientId.acc_account_type
                    }}</strong></span
                >
                <span
                    class="d-flex justify-content-center mt-1"
                    i18n
                    class="text-{{ clientId.acc_status.status_css }}"
                    ><span
                        ><i
                            class="fa-solid fa-{{
                                clientId.acc_status.status_icon
                            }}"
                        ></i></span
                    ><strong
                        *ngIf="clientId.acc_status.reason !== ''"
                        class="ms-1"
                    >
                        {{ clientId.acc_status.reason }}</strong
                    >
                    <strong
                        *ngIf="clientId.acc_status.reason === ''"
                        class="ms-1"
                    >
                        {{ clientId.acc_status.reason_explained }}</strong
                    ></span
                >
                <span
                    i18n
                    *ngIf="signatureDisplay"
                    (click)="showSignatures()"
                    class="text-primary d-flex justify-content-center mt-1"
                    style="cursor: pointer; text-decoration: underline"
                    ><strong><a>Show Signatures</a></strong></span
                >
                <span
                    i18n
                    (click)="hideSignatures()"
                    *ngIf="!signatureDisplay"
                    class="text-primary d-flex justify-content-center mt-1"
                    style="text-decoration: underline; cursor: pointer"
                    ><strong>Hide Signature</strong></span
                >
            </div>
            <div class="row mt-3" *ngIf="!signatureDisplay">
                <!-- Signature picture -->
                <span *ngIf="signatureloader" class="mb-3">
                    <ngx-skeleton-loader
                        [theme]="{
                            'height.px': 85,
                            'width.%': 100,
                        }"
                    ></ngx-skeleton-loader>
                </span>
                <span
                    *ngIf="signature && signature.signature"
                    class="mb-3"
                    style="height: 85px"
                >
                    <img
                        [src]="signature.signature"
                        class="img-fluid image sm-radius"
                    />
                </span>
                <br *ngIf="signature && signature.signature" />
                <span
                    *ngIf="signature && signature.signature2"
                    class="mb-3"
                    style="height: 85px"
                >
                    <img
                        [src]="signature.signature2"
                        class="img-fluid image sm-radius"
                    />
                </span>
                <span *ngIf="signatureloader" class="mb-3">
                    <ngx-skeleton-loader
                        [theme]="{
                            'height.px': 85,
                            'width.%': 100,
                        }"
                    ></ngx-skeleton-loader>
                </span>
                <span
                    *ngIf="
                        (!signatureloader && !signature) ||
                        (signature &&
                            !signature.signature &&
                            !signature.signature2)
                    "
                >
                    <span
                        class="empty-state-icon d-flex justify-content-center"
                        style="font-size: 40px"
                    >
                        <i class="fa-solid fa-face-meh"></i>
                    </span>
                    <br />
                    <b
                        class="empty-state-icon text-md d-flex justify-content-center"
                        i18n
                    >
                        No Signature to display
                    </b>
                </span>
            </div>
            <!-- <div
                class="row ms-4 mt-4"
                *ngIf="
                    !signatureDisplay &&
                    menuSelected === 'withdrawal' &&
                    clientId.acc_signatories
                "
            >
                <span i18n style="text-decoration: underline">
                    <strong>Procuration</strong>
                </span>
            </div>
            <div
                class="row ms-3 mt-1"
                *ngIf="!signatureDisplay && menuSelected === 'withdrawal'"
            >
                <div class="col-auto">
                    <img
                        src="./assets/images/userprofile.png"
                        class="img-fluid float-start"
                        style="
                            position: relative;
                            width: 71px;
                            border-radius: 50%;
                        "
                    />
                </div>
                <div class="col ms-2 p-0">
                    <span><strong>MAYUGI Jessica</strong></span
                    ><br />
                    <span>Id Card: 3567/850/09</span><br />
                    <span
                        (click)="seeDetails()"
                        i18n
                        style="
                            cursor: pointer;
                            text-decoration: underline;
                            color: #2070b8;
                        "
                    >
                        See Details</span
                    >
                </div>
            </div> -->
        </div>
    </div>
    <!-- <div class="mt-3" *ngIf="clientId && !details && !signatureDisplay">
        <div class="row">
            <div class="col">
                <div class="row">
                    <span
                        ><strong>{{ clientId.acc_holder }} </strong>
                        <span i18n> gives procuration to </span>
                        <strong>MAYUGI Jessica</strong>
                    </span>
                </div>
                <div class="row ms-3 mt-1">
                    <div class="col-auto">
                        <img
                            src="./assets/images/userprofile.png"
                            class="img-fluid float-start"
                            style="
                                position: relative;
                                width: 163px;
                                border-radius: 50%;
                            "
                        />
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-auto">
                        <span i18n>Name and Surname:</span><br />
                        <span><strong>MAYUGI Jessica</strong></span
                        ><br />
                        <span i18n>Id Card:</span><br />
                        <span><strong>3567/850/09</strong></span
                        ><br />
                        <span i18n>Date and place of delivery:</span>
                        <span> <strong>04/05/1997, Buhiga</strong></span>
                    </div>
                </div>
                <div class="row mt-3 ms-4">
                    <span i18n><strong>Signature</strong></span>
                </div>
                <div class="row mt-2 ms-4">
                    <img
                        [src]="clientId.acc_self_signature"
                        *ngIf="clientId.acc_self_signature"
                        class="img-fluid float-end;"
                        style="
                            position: relative;
                            width: 185px;
                            border-radius: 100px;
                        "
                    />
                </div>
            </div>
        </div>
    </div> -->
</div>
