export class GetMenuGroup {
    static readonly type = '[Menu Group] Get Menu Group';
}

export class SelectMenuGroup {
    static readonly type = '[Selected Menu] Select Menu';

    constructor(public payload: { menuGroup: any }) {}
}

export class SelectMarket {
    static readonly type = '[Selected Market] Select Market';

    constructor(public payload: { marketName: any }) {}
}

export class SetSubMenus {
    static readonly type = '[SubMenu] Set Sub Menus';

    constructor(public payload: { subMenus: any }) {}
}

export class SetSelectedMenuAccess {
    static readonly type = '[SelectedMenu Access] SelectedMenu Acceses';

    constructor(public payload: { access: any }) {}
}
