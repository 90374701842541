import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngxs/store';
import { SwitchThemeState } from 'src/app/shared';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-obr-bill',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './obr-bill.component.html',
    styleUrls: ['./obr-bill.component.scss'],
})
export class ObrBillComponent {
    @Input() successMessage!: {
        receipt_num: string;
        office: string;
        deliver_to: string;
        receipt_date: string | Date;
        nif: string;
        company: string;
        declarant_code: string;
        declarant_name: string;
        agence: string;
        ref: string;
        amount: string | number;
        name: string;
        type: string;
    };

    theme$: Observable<any>;

    theme: any;

    constructor(private store: Store) {
        this.theme$ = this.store.select(SwitchThemeState.GetTheme);
    }

    ngOnInit() {
        this.theme$.subscribe({
            next: (theme) => {
                this.theme = theme;
            },
        });
    }
}
