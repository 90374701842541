import { Injectable } from '@angular/core';

import { map } from 'rxjs';

import { ApiService } from './api.service';
import { GeneralService } from '..';

@Injectable({
    providedIn: 'root',
})
export class HomeService {
    constructor(
        private apiService: ApiService,
        private generalService: GeneralService
    ) {}

    getClientProducts(id: any, search = '') {
        const url = `/dbs/merchant-product/objects_autocomplete/?limit=4&search=`;
        return this.generalService
            .DoAutocomplete(url, search)
            .pipe(map((data) => data));
    }

    getBillers() {
        const url = `/dbs/merchant/manage/objects_autocomplete/?is_biller=true`;
        return this.apiService.get(url).pipe(map((data) => data));
    }

    getPublicationType() {
        const url = '/socialnetwork/publication-type/';
        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
    getPublication() {
        const url = '/socialnetwork/publication/';
        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getPublicationCategory() {
        const url = '/socialnetwork/publication-category/';
        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
    PublicationComments() {
        const url = '/socialnetwork/publication-comments/';
        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    publicationDetails() {
        const url = '/socialnetwork/publication-details/';
        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
}
