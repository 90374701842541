export class OpenTransfertBillPopup {
    static readonly type = '[popup] transfer bill';
    constructor(
        public payload: {
            debit_account_holder: string;
            debit_account: string;
            debit_bank: string;
            credit_account_holder: string;
            credit_account: string;
            credit_bank: string;
            reference: string;
            bank_reference: string;
            amount: string | number;
            transfer_fees: string | number;
            bill_date: string | number | Date;
            description: string;
        }
    ) {}
}

export class OpenMerchantBillPopup {
    static readonly type = '[popup] merchant bill';
    constructor(
        public payload: {
            name: string;
            debit_account: string;
            date: string;
            printable_text: string;
            merchantName: string;
            amount: string;
            code: string;
            product?: {
                name: string;
                value: string;
            };
            description: string;
            adress: string;
            credit_account: string;
        }
    ) {}
}

export class OpenObrBillPopup {
    static readonly type = '[popup] obr bill';
    constructor(
        public payload: {
            receipt_num: string;
            office: string;
            deliver_to: string;
            receipt_date: string;
            nif: string;
            company_name: string;
            declarant_code: string;
            declarant_name: string;
            agence: string;
            ref: string;
            amount: string;
            name: string;
            type: string;
        }
    ) {}
}

export class OpenLandscapeBillPopup {
    static readonly type = '[popup] landscape bill';
    constructor(
        public payload: {
            printable_text: string;
            receipt_date: string;
            logo_url: string;
        }
    ) {}
}

export class CloseBillPopup {
    static readonly type = '[Popup] close bill';
}
