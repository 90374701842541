export class GetMerchantInfo {
    constructor(
        public payload: {
            id: number;
            is_teller_admin: boolean;
        }
    ) {}

    static readonly type = '[Merchant] Get Merchant Info';
}
