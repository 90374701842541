export class GetClientBankList {
    static readonly type = '[bank] Get Bank';
    // constructor(public payload: { clientId: any }) {}
}

export class SelectClientBank {
    static readonly type = '[bank] Select Bank';

    constructor(
        public payload: {
            id: string;
            slug: string;
            name: string;
            bank_type: string;
            bank_code: string;
            is_active: boolean;
            is_default: boolean;
            company: any;
        }
    ) {}
}
