import { Injectable } from '@angular/core';

import {
    State,
    Store,
    StateToken,
    Action,
    StateContext,
    Selector,
} from '@ngxs/store';
import { tap } from 'rxjs/operators';

import { GetClientBankList, SelectClientBank } from './bank.actions';
import { ClientBankModel, BankModel } from './bank.models';

import { ClientService } from '../../../core';

const BANK_MODEL = new StateToken<ClientBankModel[]>('bank');
const SELECTED_BANK_MODEL = new StateToken<BankModel>('selectedBank');

@State<ClientBankModel>({
    name: BANK_MODEL,
    defaults: {
        banks: [],
    },
})
@State<BankModel>({
    name: SELECTED_BANK_MODEL,
    defaults: {
        selectedBank: {
            id: null,
            slug: null,
            name: null,
            bank_type: null,
            bank_code: null,
            is_active: false,
            is_default: false,
            company: null,
        },
    },
})
@Injectable()
export class BankState {
    constructor(private clientService: ClientService, private store: Store) {}

    @Selector()
    static GetClientBanks(state: ClientBankModel): any {
        return state.banks;
    }

    @Selector()
    static GetSelectedClientBank(state: BankModel): any {
        return state.selectedBank;
    }

    @Selector()
    static GetSelectedClientBankId(state: BankModel): any {
        if (state.selectedBank) {
            return state.selectedBank.id;
        } else {
            return null;
        }
    }

    @Action(GetClientBankList)
    getClientBankList(
        ctx: StateContext<ClientBankModel>,
        action: GetClientBankList
    ) {
        return this.clientService.getClientBankList().pipe(
            tap(
                (result: {
                    objects: {
                        banks: [];
                    };
                }) => {
                    ctx.patchState({
                        banks: result.objects,
                    });
                }
            )
        );
    }

    @Action(SelectClientBank)
    selectClientBank(ctx: StateContext<BankModel>, action: SelectClientBank) {
        ctx.patchState({
            selectedBank: {
                id: action.payload.id,
                name: action.payload.name,
                slug: action.payload.slug,
                bank_type: action.payload.bank_type,
                bank_code: action.payload.bank_code,
                is_active: action.payload.is_active,
                is_default: action.payload.is_default,
                company: action?.payload.company,
            },
        });
    }
}
