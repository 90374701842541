import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeService } from 'src/app/core';
import { Subject, takeUntil } from 'rxjs';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
    selector: 'app-home-right-sidebar',
    standalone: true,
    imports: [CommonModule, NgxSkeletonLoaderModule],
    templateUrl: './home-right-sidebar.component.html',
    styleUrls: ['./home-right-sidebar.component.scss'],
})
export class HomeRightSidebarComponent {
    private onDestroy$: Subject<void> = new Subject<void>();
    maxRating = 5;
    currentRating = 1;

    stars = Array.from({ length: this.maxRating }, (_, index) => ({
        icon:
            index < this.currentRating
                ? 'fa fa-star text-success'
                : 'fa fa-star',
    }));
    billers: any;
    billersLoading = true;

    constructor(private homeService: HomeService) {}

    ngOnInit() {
        this.getBillers();
    }

    updateRating(newRating: number) {
        this.currentRating = newRating;

        this.stars = this.stars.map((star, index) => ({
            icon: index < newRating ? 'fa fa-star text-success' : 'far fa-star',
        }));
    }

    getBillers() {
        this.homeService
            .getBillers()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (response) => {
                    this.billers = response.objects;
                    this.billersLoading = false;
                },
                error: (error) => {
                    this.billersLoading = false;
                },
            });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
