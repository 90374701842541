import { Injectable } from '@angular/core';
// import { Observable, of as ObservableOf } from 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private apiService: ApiService) {}

    login(login_data: { username: string; password: string }): Observable<any> {
        const data = { user: login_data };
        return this.apiService.post('/users/login/', data).pipe(
            map((data) => {
                return data;
            })
        );
    }

    populate() {
        return this.apiService
            .get('/hr/access/operator/organizations/?populate=true')
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    getConnectedOperator() {
        return this.apiService.get('/hr/connected/operator/').pipe(
            map((data) => {
                return data;
            })
        );
    }

    loginCorporate(login_data: { organization_id: any; password: any }) {
        return this.apiService.post('/hr/organization/login/', login_data).pipe(
            map((data) => {
                return data;
            })
        );
    }

    logoutCorporate() {
        return this.apiService.get('/hr/organization/logout/').pipe(
            map((data) => {
                return data;
            })
        );
    }

    logout() {
        return this.apiService.post('/users/logout/').pipe(
            map((data) => {
                return data;
            })
        );
    }

    populateClient() {
        return this.apiService.get('/client/user/populate/').pipe(
            map((data) => {
                return data;
            })
        );
    }

    createAccount(body: any) {
        const url = '/client/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }

    requestOTP(body: any) {
        const url = '/otp/request/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }
    OTPverification(body: any) {
        const url = '/otp/verification/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }

    getOperatorInvitations(clientId: any) {
        const url = `/hr/operator/organizations/manage/?list_type=invitations&access_bank_id=${clientId}`;
        return this.apiService.get(url).pipe(map((data) => data));
    }
    submitInvitationStatus(body: any) {
        const url = '/hr/administration/operator/organization/status/';
        return this.apiService.post(url, body).pipe(map((data) => data));
    }
    getBanksList() {
        const url = '/banks/list/?externel_request=true&bank_type=MFI';
        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    verifyEmail(email: any) {
        const apiUrl = `/extid/verification/?externel_request=true&type=email&value=${email}`;
        return this.apiService.get(apiUrl).pipe(map((data) => data));
    }

    verifyPhoneNumber(tel: any) {
        const apiUrl = `/extid/verification/?externel_request=true&type=phone_number&value=${tel}`;
        return this.apiService.get(apiUrl).pipe(map((data) => data));
    }
}
