import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    FormGroup,
    // FormControl,
    FormBuilder,
    Validators,
    // Validators,
} from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { Subject, Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import {
    OpenActionDialog,
    OpenConfirmDialog,
    DialogState,
    OpenDialog,
    CloseDialog,
    BankState,
    AuthState,
} from 'src/app/shared';

import { CounterService } from 'src/app/core/services/counter.service';
import { ClientService } from 'src/app/core';
import {
    Signature,
    ClientInfo,
    ClientInfoObject,
} from 'src/app/core/models/signature-display.model';

@Component({
    selector: 'app-lookup-individual',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxSkeletonLoaderModule,
    ],
    templateUrl: './lookup-individual.component.html',
    styleUrls: ['./lookup-individual.component.scss'],
})
export class LookupIndividualComponent implements OnInit {
    private onDestroy$: Subject<void> = new Subject<void>();
    @Input() bankId = '';
    @Input() menuSelected = '';
    @Input() cardBackground = 'level-2';
    @Input() close_option = false;
    @Output() individualInfo = new EventEmitter<ClientInfo>();
    @Output() closeInfo = new EventEmitter<boolean>();
    closing = true;

    searchForm: FormGroup;

    dialog$: Observable<string>;
    // dialog: string = '';

    clientId!: ClientInfo | null | undefined;
    clientLookUp!: string;
    placeholder = 'Search Client';
    client_id!: string;

    dataCheck = false;
    signatureDisplay = true;
    signature!: Signature['object'];
    signatureloader = false;
    bankId$: Observable<string>;
    organizationId = '';
    organization$: Observable<string>;

    constructor(
        private counterService: CounterService,
        private fb: FormBuilder,
        private store: Store,
        private clientService: ClientService
    ) {
        this.dialog$ = this.store.select(DialogState.GetDialog);
        this.searchForm = this.fb.group({
            clientId: [''],
        });

        this.bankId$ = this.store.select(BankState.GetSelectedClientBankId);
        this.organization$ = this.store.select(AuthState.GetOrganization);
    }
    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            const chng = changes[propName];
            if (propName === 'menuSelected') {
                if (this.menuSelected === 'merchant_code') {
                    this.placeholder = 'Merchant Code';
                } else if (this.menuSelected !== 'merchant_code') {
                    this.placeholder = 'Search Client';
                }
            }
        }
    }
    ngOnInit(): void {
        this.organization$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((organization: any) => {
                this.organizationId = organization.id;
            });
    }

    getClientSignatures() {
        this.clientService
            .getIndividualClientDetails(this.client_id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (signature: Signature) => {
                    this.signature = signature.object;
                    this.signatureloader = false;
                },
                error: (data) => {
                    this.signatureloader = false;
                    let message;
                    if (data.object.detail) {
                        message = data.object.detail;
                    } else {
                        message =
                            'Error occured when displaying signature information';
                    }
                    const notification = {
                        title: '',
                        type: 'failed',
                        message: message,
                    };
                    this.store.dispatch(new OpenDialog(notification));
                },
            });
    }
    getCorporateSignatures() {
        this.clientService
            .getCorporateClientDetails(this.client_id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (signature: Signature) => {
                    this.signature = signature.object;
                    this.signatureloader = false;
                },
                error: (data) => {
                    this.signatureloader = false;
                    let message;
                    if (data.object.detail) {
                        message = data.object.detail;
                    } else {
                        message =
                            'Error occured when displaying signature information';
                    }
                    const notification = {
                        title: '',
                        type: 'failed',
                        message: message,
                    };
                    this.store.dispatch(new OpenDialog(notification));
                },
            });
    }
    getClientInfo() {
        const clientId = this.searchForm.value.clientId;
        let organizationId;
        if (this.bankId !== '') {
            organizationId = this.bankId;
        } else if (this.bankId === '') {
            organizationId = this.organizationId;
        }
        if (this.menuSelected === 'merchant_code') {
            this.clientLookUp =
                'organization_pk=' +
                organizationId +
                '&account_id=' +
                clientId +
                '&account_type=merchant';
        } else {
            this.clientLookUp =
                'organization_pk=' + organizationId + '&account_id=' + clientId;
        }
        this.dataCheck = true;
        this.searchForm.disable();
        this.counterService
            .getClientDetails(this.clientLookUp)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (clientId: ClientInfoObject) => {
                    this.dataCheck = false;
                    this.closing = false;
                    this.searchForm.reset();
                    this.searchForm.enable();
                    this.clientId = clientId.object;
                    this.client_id = this.clientId.acc_client_id;
                    this.individualInfo.emit(this.clientId);
                    if (this.clientId.acc_client_type === 'I') {
                        this.signatureloader = true;
                        this.getClientSignatures();
                    }
                    // else if(this.clientId.acc_client_type === 'C'){
                    //     this.getCorporateSignatures();
                    // }

                    if (this.clientId.acc_status.reason === '') {
                        console.log(
                            'Client ID is not',
                            this.clientId.acc_status.reason
                        );
                    }
                },
                error: (data) => {
                    this.dataCheck = false;
                    this.searchForm.enable();
                    let message;
                    if (data.object.response_message) {
                        message = data.object.response_message;
                    } else {
                        message = 'Error occured';
                    }
                    const notification = {
                        title: '',
                        type: 'failed',
                        message: message,
                    };
                    this.store.dispatch(new OpenDialog(notification));
                },
            });
    }

    showSignatures() {
        this.signatureDisplay = false;
    }
    closeInformation() {
        this.closing = true;
        this.closeInfo.emit(this.closing);
        this.clientId = null;
        this.signatureDisplay = true;
        this.signature.signature = '';
        this.signature.signature2 = '';
        if (!this.clientId) {
            this.individualInfo.emit(undefined);
        }
    }
    hideSignatures() {
        this.signatureDisplay = true;
    }
    // seeDetails() {
    //     this.details = false;
    // }
}
