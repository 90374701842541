import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import {
    PopulateOperate,
    AuthState,
    SwitchThemeState,
    SwitchTheme,
    SwitchState,
    GetMenuGroup,
    PopulateClient,
    GetClientBankList,
    SelectMarket,
    MenuState,
    PopulateUser,
} from './shared';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'ng-vizion-erp';
    organization$: Observable<any>;
    organization: any;
    populateUser$: Observable<any>;
    theme$: Observable<any>;
    theme: any;
    plateform$: Observable<any>;
    plateform: any;
    isAuthenticated$: Observable<any>;
    operator$: Observable<any>;
    selectedMarket$: Observable<any>;
    clientId$: Observable<any>;

    showSplashScreen = true;

    constructor(private store: Store, private router: Router) {
        this.organization$ = this.store.select(AuthState.GetOrganization);
        this.populateUser$ = this.store.select(AuthState.GetPopulateUser);
        this.theme$ = this.store.select(SwitchThemeState.GetTheme);
        this.plateform$ = this.store.select(SwitchState.GetPlateform);
        this.isAuthenticated$ = this.store.select(AuthState.isAuthenticated);
        this.operator$ = this.store.select(AuthState.GetOperator);
        this.selectedMarket$ = this.store.select(MenuState.GetSelectedMarket);
        this.clientId$ = this.store.select(AuthState.GetClientId);
    }

    ngOnInit() {
        this.plateform$.subscribe({
            next: (plateform: any) => {
                this.plateform = plateform;
                const faviconLink: any =
                    document.querySelector('link[rel="icon"]');

                if (plateform === 'workStation') {
                    faviconLink.href = '../assets/images/favicon.ico';
                } else {
                    faviconLink.href = '../assets/images/ihela-fav.ico';
                }
            },
        });

        this.populateUser$.subscribe({
            next: (populate: any) => {
                if (
                    !populate.organization &&
                    populate['operator'] !== undefined &&
                    !populate.operator.id &&
                    this.plateform === 'workStation'
                ) {
                    this.router.navigate(['/auth/corporate']);
                    if (this.theme === 'light-mode') {
                        this.store.dispatch(
                            new SwitchTheme({ theme: 'magis-light' })
                        );
                    } else if (this.theme === 'dark-mode') {
                        this.store.dispatch(
                            new SwitchTheme({ theme: 'magis-dark' })
                        );
                    }
                }
            },
        });

        this.isAuthenticated$.subscribe({
            next: (isAuthenticated) => {
                if (isAuthenticated) {
                    this.plateform$.subscribe({
                        next: (plateform) => {
                            this.plateform = plateform;
                            if (
                                this.plateform &&
                                this.plateform === 'workStation'
                            ) {
                                this.store.dispatch(new PopulateUser());
                                this.store.dispatch(new PopulateOperate());
                                this.organization$.subscribe({
                                    next: (organization) => {
                                        if (organization) {
                                            this.store.dispatch(
                                                new GetMenuGroup()
                                            );
                                            this.store.dispatch(
                                                new GetClientBankList()
                                            );
                                            // this.clientService.getDefaultBank().subscribe({ next: (data) => {  } })
                                        }
                                    },
                                });
                            }
                        },
                    });
                    this.store.dispatch(new PopulateClient());

                    // this.clientId$.subscribe((clientId: string) => {
                    //     if (clientId) {
                    //         this.plateform$.subscribe({
                    //             next: (plateform) => {
                    //                 if (plateform !== 'workStation') {
                    //                     this.selectDefaultBank();
                    //                 }
                    //             },
                    //         });
                    //     }
                    // });
                } else {
                    // this.router.navigate(['/']);
                }
            },
        });

        this.theme$.subscribe({
            next: (theme) => {
                this.theme = theme;
                this.store.dispatch(new SwitchTheme({ theme: theme }));
            },
        });

        this.operator$.subscribe({
            next: (operator) => {
                if (operator) {
                    // console.log('ssss 8888', operator.isTreasurer);
                }
            },
        });

        this.selectedMarket$.subscribe({
            next: (response) => {
                if (!response) {
                    this.store.dispatch(
                        new SelectMarket({ marketName: 'market' })
                    );
                } else {
                    this.store.dispatch(
                        new SelectMarket({ marketName: response })
                    );
                }
            },
        });

        // this.changeLanguage();

        this.getBrowserInfo();
    }

    getBrowserInfo() {
        const userAgent = navigator.userAgent;
        const platform = navigator.platform;
        let browserInfo = '';

        const getBrowserName = () => {
            const userAgentLower = userAgent.toLowerCase();
            if (userAgentLower.includes('firefox')) {
                return 'Firefox';
            } else if (userAgentLower.includes('chrome')) {
                return 'Chrome';
            } else if (
                userAgentLower.includes('safari') &&
                !userAgentLower.includes('chrome')
            ) {
                return 'Safari';
            } else if (
                userAgentLower.includes('opera') ||
                userAgentLower.includes('opr')
            ) {
                return 'Opera';
            } else if (userAgentLower.includes('edge')) {
                return 'Edge';
            } else if (userAgentLower.includes('msie')) {
                return 'Internet Explorer';
            } else {
                return 'Unknown Browser';
            }
        };

        const browserName = getBrowserName();

        browserInfo = `${browserName} on ${platform}`;

        // console.log('Browser Info:', browserInfo);

        return browserInfo;
    }

    // selectDefaultBank() {
    //     this.clientService.getDefaultBank().subscribe({
    //         next: (bank) => {
    //             const defaultBank = {
    //                 id: bank.object.id,
    //                 name: bank.object.name,
    //                 slug: bank.object.slug,
    //                 bank_type: bank.object.bank_type,
    //                 bank_code: bank.object.bank_code,
    //                 is_active: bank.object.is_active,
    //                 is_default: bank.object.is_default,
    //                 company: bank.object.company,
    //             };
    //             this.store.dispatch(new SelectClientBank(defaultBank));
    //         },
    //     });
    // }

    // changeLanguage(url: string ='/w', langCode: string="fr") {
    //     var a = document.createElement("a"); // create an a element
    //     a.href = url; // set its href to the URL
    //     var paths = a.pathname.split("/"); // split the pathname by /
    //     paths.shift(); // remove the first empty element
    //     if (paths[0].length == 2) {
    //     // if the first element is a language code
    //     paths[0] = langCode; // replace it with the new one
    //     } else {
    //     // otherwise
    //     paths.unshift(langCode); // add the new one at the beginning
    //     console.log("Naaahaaaaaa 33 ####");
    //     }
    //     const new_url =
    //     a.protocol +
    //     "//" +
    //     a.host +
    //     "/" +
    //     paths.join("/") +
    //     (a.search != "" ? a.search : "") +
    //     (a.hash != "" ? a.hash : "")

    //     console.log("***1111 new url ***** 3333", new_url)
    // }

    ngAfterViewInit() {
        setTimeout(() => {
            this.showSplashScreen = false;
        }, 2000);
    }
}
