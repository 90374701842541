import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerFieldModule } from '../customer-field/customer-field.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import { NgxSliderModule } from 'ngx-slider-v2';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { ListComponent } from './list/list.component';
import { FiltersComponent } from './filters/filters.component';

@NgModule({
    declarations: [ListComponent, FiltersComponent],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CustomerFieldModule,
        NgxSkeletonLoaderModule,
        // NgxSliderModule
    ],
    exports: [ListComponent, FiltersComponent],
})
export class ReusableModule {
    //comment
}
