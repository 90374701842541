<div class="d-flex align-items-center">
    <div class="text-primary fs-5">
        <i class="fa-solid fa-play"></i>
    </div>
    <div class="ms-3 text-dark">
        <span i18n class="me-3">Fees</span>
        <b *ngIf="!isLoading">{{ fees?.commissions ?? '' }}</b>
        <span *ngIf="isLoading" class="text-sm">
            <i class="fas fa-spinner fa-pulse"></i>
        </span>
    </div>
</div>
