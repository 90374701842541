import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    Input,
    SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    FormGroup,
    FormControl,
    FormBuilder,
    Validators,
} from '@angular/forms';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CounterService } from 'src/app/core/services/counter.service';
import { VariableService } from 'src/app/core';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AmountWordsComponent } from '../../customer-field/amount-words/amount-words.component';
import { CustomerFieldModule } from '../../customer-field/customer-field.module';
import { Store } from '@ngxs/store';
import {
    OpenActionDialog,
    OpenConfirmDialog,
    DialogState,
    OpenDialog,
    CloseDialog,
} from 'src/app/shared';

@Component({
    selector: 'app-ticketing',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CustomerFieldModule,
    ],
    templateUrl: './ticketing.component.html',
    styleUrls: ['./ticketing.component.scss'],
})
export class TicketingComponent implements OnInit {
    private onDestroy$: Subject<void> = new Subject<void>();

    @Input() operation = '';
    @Input() description: any;
    @Input() clientId = '';
    @Input() summaryClose: any;
    @Input() isDark = false;
    @Input() formReset = false;
    @Input() phoneRequired = false;
    @Input() boxTransfer = false;
    @Output() amount = new EventEmitter<any>();
    @Output() noteBill = new EventEmitter<any>();
    @Output() confirming = new EventEmitter<any>();
    @Output() mandator = new EventEmitter<any>();
    @Output() mandatorPhone = new EventEmitter<any>();
    @Output() noteMovement = new EventEmitter<any>();

    dialog$: Observable<any>;
    dialog: any;

    numberForm: FormGroup;
    detailsForm: FormGroup;
    passwordForm: FormGroup;
    summary = true;
    confirmation = false;
    bankNote: any;
    noteValue: any;
    number: any;
    amountWritten = 0;
    generalTotal: any;
    checking: any;
    selectedNotes: any = [];

    bankBill: any = {};

    constructor(
        private counterService: CounterService,
        private variableService: VariableService,
        private fb: FormBuilder,
        private store: Store
    ) {
        this.dialog$ = this.store.select(DialogState.GetDialog);
        this.passwordForm = this.fb.group({
            password: ['', Validators.required],
        });
        this.numberForm = this.fb.group({});
        this.detailsForm = this.fb.group({
            amount: [''],
            mandator: ['', Validators.required],
            mandator_phone_number: [''],
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            const chng = changes[propName];
            if (propName === 'formReset') {
                if (this.formReset) {
                    this.numberForm.reset();
                    this.detailsForm.reset();
                }
            }
        }
    }

    ngOnInit(): void {
        this.counterService
            .pickUpBankNote()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (note) => {
                    this.bankNote = note.objects;
                    this.noteBill.emit(this.bankNote);
                    for (const note of this.bankNote) {
                        this.bankBill[note.bank_note_value] = [''];
                    }
                    this.numberForm = this.fb.group(this.bankBill);
                },
            });
    }
    inputAmount(event: any) {
        this.amountWritten = event.amount;
        this.amount.emit(this.amountWritten);
    }
    getAllDetails() {
        if (!this.amountWritten) {
            const notification = {
                title: '',
                type: 'failed',
                message: 'Fill the amount field',
            };
            this.store.dispatch(new OpenDialog(notification));
        } else if (!this.detailsForm.value.mandator) {
            const notification = {
                title: '',
                type: 'failed',
                message: 'Fill the done by field',
            };
            this.store.dispatch(new OpenDialog(notification));
        } else {
            this.generateSummary();
        }
    }
    generateSummary() {
        this.summary = false;
        this.confirming.emit(this.summary);
    }
    inputDetails() {
        this.mandator.emit(this.detailsForm.value.mandator);
        this.mandatorPhone.emit(this.detailsForm.value.mandator_phone_number);
    }
    getNumber(event: any) {
        this.number = event;
    }
    //  working on how to use ticketing for mif down below

    // movement: any = [];
    getNoteMovement(note: any, number: any) {
        //     let data;
        //     if(this.movement.length > 0) {
        //         for (let noteEntered of this.movement) {
        //             if (note === noteEntered.bank_note && noteEntered.number !== '') {
        //                 const index = this.movement.indexOf(noteEntered.bank_note);
        //                 console.log('first condition checked', index);
        //                 // if(index > -1){
        //                     this.movement.splice(index, 1);
        //                     console.log('noteEntered note from first condition', this.movement)
        //                     // this.noteMovement.emit(this.movement);
        //                 // }
        //             } else if (note === noteEntered.bank_note && number ==='' ){
        //                 const index = this.movement.indexOf(note);
        //                 console.log('first condition on number checked', index);
        //                 // if(index > -1){
        //                     this.movement.splice(index, 1);
        //                     console.log('movement number from first condition', this.movement)
        //                     // this.noteMovement.emit(this.movement);
        //                 // }
        //             } else if(note === noteEntered.bank_note || note !== noteEntered.bank_note) {
        //                 data = {
        //                     bank_note: note,
        //                     number: number,
        //                 };
        //                 this.movement.push(data);
        //                 console.log('movement nothing', this.movement)
        //             }
        //         }
        //     } else {
        //         data = {
        //             bank_note: note,
        //             number: number,
        //         };
        //         this.movement.push(data);
        //         console.log('Note moved', this.movement);
        //     }
        //     // if (
        //     //     this.movement.length > 0 &&
        //     //     this.movement.filter((bill: any) => bill.note === note)
        //     //         .length > 0
        //     // ) {
        //     //     this.movement.find((bill: any) => bill.note === note)!.number =
        //     //         number;
        //     // } else {
        //     //     data = { bank_note: note, number: number };
        //     //     this.movement.push(data);
        //     // }
        //     // for (const movement of this.movementEntered) {
        //     // }
        //     // console.log('Note moved', this.movement);
        //     this.noteMovement.emit(this.movement);
    }

    // end of the function up

    calculateTotalAmount(note: any, number: any) {
        this.generalTotal = 0;
        if (
            this.selectedNotes.length > 0 &&
            this.selectedNotes.filter((bill: any) => bill.note === note)
                .length > 0
        ) {
            this.selectedNotes.find((bill: any) => bill.note === note)!.number =
                number;
        } else {
            const data = { note: note, number: number };
            this.selectedNotes.push(data);
        }
        for (const currency of this.selectedNotes) {
            this.generalTotal += currency.note * currency.number;
        }
        if (this.generalTotal > 0) {
            if (this.amountWritten == this.generalTotal) {
                this.confirmation = true;
            }
        }
    }
}
