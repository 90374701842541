import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SwitchState } from 'src/app/shared/states/switch';

@Component({
    selector: 'app-splash-screen',
    templateUrl: './splash-screen.component.html',
    styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent {
    // @Input() showStatus = true;
    // showStatusAfter: any = true;

    plateform$: Observable<any>;
    plateform: any;

    imageUrl = 'assets/images/ihela3.png';

    constructor(private store: Store) {
        this.plateform$ = this.store.select(SwitchState.GetPlateform);
    }

    ngOnInit() {
        this.plateform$.subscribe({
            next: (plateform) => {
                this.plateform = plateform;
                if (plateform === 'workStation') {
                    this.imageUrl = 'assets/images/magis.png';
                }
            },
        });
    }

    // ngDoCheck() {
    //     if (!this.showStatus) {
    //         setTimeout(() => {
    //             this.showStatusAfter = false;
    //         }, 100);
    //     }
    // }
}
