<section class="unavailable-contaier">
    <div class="icon-container">
        <i class="fa fa-gears text-primary"></i>
    </div>
    <div class="unavailable-message text-dark">
        <span class="large-text">This page is unavailable at this</span><br />
        <span class="large-text">moment.</span>
    </div>

    <div class="error-message text-dark">
        <span>This may occur due to a technical error that we</span><br />
        <span>are currently resolving.</span><br />
        <button
            *ngIf="action === 'Close'"
            class="btn bg-primary mt-5 text-white"
            (click)="closePage()"
        >
            {{ action }}
        </button>
    </div>
</section>
