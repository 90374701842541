import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngxs/store';
import { SwitchThemeState } from 'src/app/shared';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-landscape-bill',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './landscape-bill.component.html',
    styleUrls: ['./landscape-bill.component.scss'],
})
export class LandscapeBillComponent {
    @Input() successMessage!: {
        printable_text: string;
        receipt_date: string | Date;
        logo_url: string;
    };

    // @Input() merchant: any;
    // @Input() : any;
    billContent!: HTMLElement;

    theme$: Observable<any>;

    theme: any;

    constructor(private store: Store) {
        this.theme$ = this.store.select(SwitchThemeState.GetTheme);
    }

    ngOnInit() {
        this.theme$.subscribe({
            next: (theme) => {
                this.theme = theme;
            },
        });
    }

    ngAfterViewInit() {
        this.billContent = document.getElementById(
            'bill-content'
        ) as HTMLElement;

        if (this.successMessage.printable_text) {
            this.billContent.innerHTML = this.successMessage.printable_text;
        }
    }
}
