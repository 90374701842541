export class GetClientList {
    constructor(public payload: { offset: any }) {}

    static readonly type = '[Client] Get Client User';
}

export class GetWalletsList {
    constructor(public client_id: any) {}

    static readonly type = '[Client] Get wallets';
}
