import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Subject, Observable } from 'rxjs';
import { Store } from '@ngxs/store';

import {
    SwitchTheme,
    AuthState,
    SwitchState,
    SwitchThemeState,
    Switch,
} from '../../shared';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    constructor(private store: Store, private router: Router) {}

    private _darkTheme = new Subject<boolean>();
    isDarkTheme = this._darkTheme.asObservable();

    setDarkTheme(isDarkTheme: boolean): void {
        this._darkTheme.next(isDarkTheme);
    }

    switchPlateform(plateform: string, theme: string, organization: string) {
        console.log('parmas', plateform, theme, organization);
        this.store.dispatch(new Switch({ plateform: plateform }));

        if (plateform === 'onlineBanking') {
            this.router.navigate(['/b']);
            if (theme === 'magis-light') {
                this.store.dispatch(new SwitchTheme({ theme: 'light-mode' }));
            } else if (theme === 'magis-dark') {
                this.store.dispatch(new SwitchTheme({ theme: 'dark-mode' }));
            }
        } else if (plateform === 'market') {
            this.router.navigate(['/m']);
            if (theme === 'magis-light') {
                this.store.dispatch(new SwitchTheme({ theme: 'light-mode' }));
            } else if (theme === 'magis-dark') {
                this.store.dispatch(new SwitchTheme({ theme: 'dark-mode' }));
            }
        } else if (plateform === 'onamob') {
            this.router.navigate(['/o/onamob']);
            this.store.dispatch(new SwitchTheme({ theme: theme }));
        } else if (plateform === 'home') {
            this.router.navigate(['/h']);
            if (theme === 'magis-light') {
                this.store.dispatch(new SwitchTheme({ theme: 'light-mode' }));
            } else if (theme === 'magis-dark') {
                this.store.dispatch(new SwitchTheme({ theme: 'dark-mode' }));
            }
        } else {
            if (organization === null) {
                this.router.navigate(['/auth/corporate']);
                if (theme === 'light-mode') {
                    this.store.dispatch(
                        new SwitchTheme({ theme: 'magis-light' })
                    );
                } else if (theme === 'dark-mode') {
                    this.store.dispatch(
                        new SwitchTheme({ theme: 'magis-dark' })
                    );
                }
            } else {
                this.router.navigate(['/w']);
                if (theme === 'light-mode') {
                    this.store.dispatch(
                        new SwitchTheme({ theme: 'magis-light' })
                    );
                } else if (theme === 'dark-mode') {
                    this.store.dispatch(
                        new SwitchTheme({ theme: 'magis-dark' })
                    );
                }
            }
        }
        // else {
        //     this.showMenu === false;
        // }
    }
}
