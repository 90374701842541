import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { map, tileLayer, marker, Icon, Marker } from 'leaflet';
import { MerchantService } from 'src/app/core';

@Component({
    selector: 'app-global-map',
    templateUrl: './global-map.component.html',
    styleUrls: ['./global-map.component.scss'],
})
export class GlobalMapComponent {
    mapCenter = { lat: -3.3816576, lng: 29.360128 };
    mapZoom = 15;
    coords: any;

    markerOptions: google.maps.MarkerOptions = {
        icon: {
            url: '../../../../../assets/images/merchant-marker.svg',
            scaledSize: new google.maps.Size(26, 50),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(12, 41),
        },
    };

    constructor(private merchantService: MerchantService) {
        //
    }

    ngOnInit(): void {
        // this.getLocation()
        this.getMerchantsLocation();

        this.merchantService.coords$.subscribe((coords: any) => {
            if (coords) {
                this.mapCenter = {
                    lat: coords.latitude,
                    lng: coords.longitude,
                };
            }
            // console.log('coords x', coords);
        });
    }

    // ngDoCheck() {
    //     console.log('wdwiiii', window.innerWidth);
    // }

    markerClick(event: google.maps.MapMouseEvent, coord: any): void {
        // Handle marker click event
        console.log('Marker clicked:', coord);
    }

    getMerchantsLocation() {
        this.merchantService.getMerchantsLocation().subscribe((data) => {
            this.coords = data.objects.features;
        });
    }
}
