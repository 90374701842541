import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { PaginationConfig } from '../models';

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    private _asideMenuStatus: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    get getAsideMenuStatus$(): Observable<boolean> {
        return this._asideMenuStatus.asObservable();
    }

    constructor(private apiService: ApiService) {}

    toggleAsideMenu(ForHidden?: boolean) {
        if (ForHidden === true) {
            this._asideMenuStatus.next(false);
        } else {
            this._asideMenuStatus.next(!this._asideMenuStatus.value);
        }
    }

    getMenu(group: string) {
        return this.apiService.get('/menugroup/list/?group_type=' + group).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getMenuGroup(menu_id: string) {
        return this.apiService.get('/menu/list/?menu_group=' + menu_id).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getAllMenu() {
        return this.apiService.get('/menugroup/list/').pipe(
            map((data) => {
                return data;
            })
        );
    }
    getMetadata(search = '', pagination?: PaginationConfig) {
        const url = `/metadata/?search=${search}&limit=${pagination?.filters.limit}&offset=${pagination?.filters.offset}`;
        return this.apiService.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }
    createMetadata(body: any) {
        const url = '/metadata/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }
}
