<section class="d-none d-lg-block">
    <div class="ms-3 mt-5 fw-bold" style="font-size: 28px; color: #c98986">
        <span>Settings</span>
    </div>

    <div class="mb-3 ms-3" style="margin-top: 100px">
        <div
            class="p-2 rounded-top"
            [ngClass]="{
                'level-2': theme === 'light-mode' && isGeneralSubMensShown,
                'level-3': theme === 'dark-mode' && isGeneralSubMensShown
            }"
            style="width: 256px"
        >
            <div
                (click)="
                    isGeneralSubMensShown = !isGeneralSubMensShown;
                    isSecurityMenusShown = false;
                    isSessionMenusShown = false;
                    selectSubMenu('general', 'account')
                "
                role="button"
                class="d-flex align-items-center justify-content-between"
            >
                <div class="d-flex align-items-center">
                    <i
                        class="fa-solid fa-gear"
                        style="color: #9d9d9d; height: 19px; width: 20px"
                    >
                    </i>
                    <b
                        class="fw-bold text-dark text-md"
                        style="
                            color: #414141;

                            margin-left: 10px;
                        "
                        >General
                    </b>
                </div>
                <div class="text-dark">
                    <span *ngIf="!isGeneralSubMensShown">
                        <i class="fa fa-chevron-right"></i>
                    </span>
                    <span *ngIf="isGeneralSubMensShown">
                        <i class="fa fa-chevron-down"></i>
                    </span>
                </div>
            </div>
            <div *ngIf="isGeneralSubMensShown">
                <div class="d-grid ms-5">
                    <span
                        class="mb-1 colors fw-bold text-md-sm"
                        role="button"
                        (click)="selectSubMenu('general', 'account')"
                        [ngClass]="{
              'text': selectedSubMenu=== 'account',
              
          }"
                        >Account</span
                    >

                    <span
                        class="mb-1 colors fw-bold text-md-sm"
                        role="button"
                        (click)="selectSubMenu('general', 'customization')"
                        [ngClass]="{
              'text': selectedSubMenu=== 'customization',
              
          }"
                    >
                        Customization</span
                    >
                </div>
            </div>
        </div>
    </div>

    <div class="mb-3 ms-3 mt-2">
        <div
            class="p-2 rounded-top"
            [ngClass]="{
                'level-2': theme === 'light-mode' && isSecurityMenusShown,
                'level-3': theme === 'dark-mode' && isSecurityMenusShown
            }"
            style="width: 256px"
        >
            <div
                (click)="
                    isSecurityMenusShown = !isSecurityMenusShown;
                    isGeneralSubMensShown = false;
                    isSessionMenusShown = false;
                    selectSubMenu('security', 'pin')
                "
                role="button"
                class="d-flex align-items-center justify-content-between"
            >
                <div class="d-flex align-items-center">
                    <i
                        class="fa-solid fa-shield-halved"
                        style="color: #9d9d9d; height: 19px; width: 20px"
                    >
                    </i>
                    <b
                        class="fw-bold text-dark text-md"
                        style="color: #414141; margin-left: 10px"
                        i18n
                        >Security</b
                    >
                </div>
                <div class="text-dark">
                    <span *ngIf="!isSecurityMenusShown">
                        <i class="fa fa-chevron-right"></i>
                    </span>
                    <span *ngIf="isSecurityMenusShown">
                        <i class="fa fa-chevron-down"></i>
                    </span>
                </div>
            </div>
            <div *ngIf="isSecurityMenusShown">
                <div class="d-grid ms-5">
                    <span
                        class="mb-1 colors fw-bold text-md-sm"
                        role="button"
                        (click)="selectSubMenu('security', 'pin')"
                        [ngClass]="{
              'text': selectedSubMenu=== 'pin',
              
          }"
                        >Pin</span
                    >

                    <span
                        class="mb-1 colors fw-bold text-md-sm"
                        role="button"
                        (click)="selectSubMenu('security', 'password')"
                        [ngClass]="{
              'text': selectedSubMenu=== 'password',

          }"
                    >
                        Password</span
                    >
                </div>
            </div>
        </div>
    </div>

    <div class="mb-3 ms-3 mt-2">
        <div
            class="p-2 rounded-top"
            [ngClass]="{
                'level-2': theme === 'light-mode' && isSessionMenusShown,
                'level-3': theme === 'dark-mode' && isSessionMenusShown
            }"
            style="width: 256px"
        >
            <div
                (click)="
                    isSessionMenusShown = !isSessionMenusShown;
                    isGeneralSubMensShown = false;
                    isSecurityMenusShown = false;
                    selectSubMenu('session', 'session')
                "
                role="button"
                class="d-flex align-items-center justify-content-between"
            >
                <div class="d-flex align-items-center">
                    <i
                        class="fa-solid fa-business-time"
                        style="color: #9d9d9d; height: 19px; width: 20px"
                    ></i>
                    <b
                        class="fw-bold text-dark text-md"
                        style="color: #414141; margin-left: 10px"
                        i18n
                        >Sessions</b
                    >
                </div>
                <div class="text-dark">
                    <span>
                        <i class="fa fa-chevron-right"></i>
                    </span>
                    <!-- <span *ngIf="!isSessionMenusShown ">
                        <i class="fa fa-chevron-right"></i>
                    </span> -->
                </div>
            </div>
            <!-- <div *ngIf="isSessionMenusShown ">
                <div class="d-grid ms-5">
                    <span
                        class="mb-1 colors fw-bold text-md-sm"
                        role="button"
                        (click)="selectSubMenu('session', 'session')"
                        [ngClass]="{
              'text': selectedSubMenu=== 'session',
              
          }"
                        >session</span
                    >

                </div>
            </div> -->
        </div>
    </div>
</section>

<section class="d-lg-none level-2 pt-3 h-100" style="width: 50px">
    <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="fs-4 mb-3 position-relative">
            <span
                role="button"
                (click)="toggleMenuPopup('general')"
                style="color: #9d9d9d"
                ><i class="fa-solid fa-gear"></i
            ></span>

            <div
                class="position-absolute d-flex flex-column text-center level-2 p-2 menuPopup"
                *ngIf="activePopupMenu === 'general'"
                style="top: -50%; left: 50px"
            >
                <span
                    role="button"
                    class="border-bottom"
                    (click)="selectSubMenu('general', 'account')"
                    [ngClass]="{
                    'fw-bold': selectedSubMenu=== 'account',
                    
                }"
                    ><small class="">Account</small></span
                >
                <span
                    role="button"
                    (click)="selectSubMenu('general', 'customization')"
                    [ngClass]="{
                    'fw-bold': selectedSubMenu=== 'customization',
                    
                }"
                    ><small>Customization</small></span
                >
            </div>
        </div>
        <div class="fs-4 mb-3 position-relative">
            <span
                role="button"
                (click)="toggleMenuPopup('security')"
                style="color: #9d9d9d"
                ><i class="fa-solid fa-shield-halved"></i
            ></span>

            <div
                *ngIf="activePopupMenu === 'security'"
                class="position-absolute d-flex flex-column text-center level-2 p-2 menuPopup"
                style="top: -50%; left: 50px"
            >
                <span
                    class="border-bottom"
                    role="button"
                    (click)="selectSubMenu('security', 'pin')"
                    [ngClass]="{
                    'fw-bold': selectedSubMenu=== 'pin',
                    
                }"
                    ><small>Pin</small></span
                >
                <span
                    role="button"
                    (click)="selectSubMenu('security', 'password')"
                    [ngClass]="{
                    'fw-bold': selectedSubMenu=== 'password',
      
                }"
                    ><small>Password</small></span
                >
            </div>
        </div>
    </div>
</section>
