import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Observable, fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import {
    MerchantService,
    GeneralService,
    VariableService,
} from 'src/app/core/services';
import { PaymerchantComponent } from '../paymerchant/paymerchant.component';
import { BillersMerchantsComponent } from '../paymerchant/billers-merchants/billers-merchants.component';

@Component({
    selector: 'app-market-right-sidebar',
    standalone: true,
    imports: [
        CommonModule,
        NgxSkeletonLoaderModule,
        PaymerchantComponent,
        BillersMerchantsComponent,
    ],
    templateUrl: './market-right-sidebar.component.html',
    styleUrls: ['./market-right-sidebar.component.scss'],
})
export class MarketRightSidebarComponent implements OnInit {
    private onDestroy$: Subject<void> = new Subject<void>();
    private variableService = inject(VariableService);

    products: any;
    product: any;
    loader: any;
    first3: any;
    display = true;

    countProductLoader = [1, 2, 3, 4, 5, 6, 7, 8];
    // search = ''

    constructor(
        private merchantService: MerchantService,
        private generalService: GeneralService
    ) {
        // comment
    }
    ngOnInit(): void {
        // comment
        // this.getAllProducts('');

        this.variableService.search.pipe(takeUntil(this.onDestroy$)).subscribe({
            next: (search: any) => {
                this.getAllProducts(search);
            },
        });
    }
    // getAllProducts(search: any) {
    //     // this.merchantService
    //     //     .getMerchantsProducts()
    //     //     .pipe(takeUntil(this.onDestroy$))
    //     //     .subscribe({
    //     //         next: (data) => {
    //     //             this.products = data.objects;
    //     //             this.loader = this.products;
    //     //         },
    //     //     });

    //     const url = '/dbs/merchant-product/objects_autocomplete'
    //     this.generalService.DoAutocomplete(url, search).pipe(takeUntil(this.onDestroy$)).subscribe({
    //         next: (products) => {
    //             this.products = products
    //             this.loader = this.products
    //         }
    //     })
    // }

    getAllProducts(search: string) {
        this.merchantService
            .searchProduct(search)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (data) => {
                    this.products = data.objects;
                    this.first3 = this.products.slice(0, 3);
                    this.loader = this.products;
                },
            });
    }

    selectProduct(event: any) {
        console.log('PRoducts', event);
        this.product = event;
    }
    // displayNextProduct() {
    //     this.display = false;
    // }
}
