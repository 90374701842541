import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
    SwitchState,
    DialogState,
    Switch,
    SwitchThemeState,
    AuthState,
} from '../../';
import { ThemeService, MenuService } from '../../../core';
import { SettingService } from 'src/app/core/services/setting.service';
import { BillState } from '../../states/bill/bill.state';
import { CloseBillPopup } from '../../states/bill/bill.actions';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
    private onDestroy$: Subject<void> = new Subject<void>();
    private themeService = inject(ThemeService);
    plateform$: any;
    plateform: any;
    showSettingMenu = false;
    dialog$: Observable<any>;
    dialog: any;
    modal$: Observable<any>;
    moved = true;
    theme$: Observable<any>;
    theme: any;
    clearPin = false;
    isSettings = false;

    billInfo$: Observable<any>;
    billInfo: any;
    activeBill$!: Observable<any>;
    activeBill: any;

    billSuccessMessage: any;

    organization$: Observable<any>;
    organization: any;

    corporates$: Observable<any>;
    corporates: any[] = [];

    constructor(
        private store: Store,
        private menuService: MenuService,
        private settingService: SettingService
    ) {
        this.plateform$ = this.store.select(SwitchState.GetPlateform);
        this.dialog$ = this.store.select(DialogState.isActive);
        this.modal$ = this.store.select(DialogState.GetDialog);
        this.theme$ = this.store.select(SwitchThemeState.GetTheme);
        this.billInfo$ = this.store.select(BillState.GetBillData);
        this.activeBill$ = this.store.select(BillState.GetActiveBillInfo);
        this.organization$ = this.store.select(AuthState.GetOrganization);
        this.corporates$ = this.store.select(AuthState.corporates);
    }

    ngOnInit(): void {
        // this.bankService.getBanksList(1).subscribe()
        this.plateform$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((plateform: string) => {
                this.plateform = plateform;
            });

        this.dialog$.pipe(takeUntil(this.onDestroy$)).subscribe({
            next: (dialog) => {
                this.dialog = dialog;
            },
        });

        this.theme$.pipe(takeUntil(this.onDestroy$)).subscribe({
            next: (theme) => {
                this.theme = theme;
            },
        });
        this.modal$.pipe(takeUntil(this.onDestroy$)).subscribe({
            next: (dialog) => {
                if (
                    dialog.response === 'Yes' &&
                    dialog.action === 'loginCorporate'
                ) {
                    this.themeService.switchPlateform(
                        'workStation',
                        this.theme,
                        this.plateform
                    );
                } else if (
                    dialog.response === 'Yes' &&
                    dialog.action === 'loginIHelaPlateform'
                ) {
                    this.themeService.switchPlateform(
                        'home',
                        this.theme,
                        this.plateform
                    );
                } else if (dialog.response === 'clearPin') {
                    this.clearPin = true;
                }
            },
        });

        this.organization$.pipe(takeUntil(this.onDestroy$)).subscribe({
            next: (organization) => {
                this.corporates$.pipe(takeUntil(this.onDestroy$)).subscribe({
                    next: (corporates) => {
                        this.organization = organization;

                        if (this.organization) {
                            for (let i = 0; i < corporates.length; i++) {
                                if (
                                    corporates[i].organization
                                        .institution_client.client_full_name !==
                                    this.organization.institution_client
                                        .client_full_name
                                ) {
                                    this.corporates.push(corporates[i]);
                                }
                            }
                        }
                    },
                });
            },
        });

        this.settingService.isSetting$.subscribe((data: any) => {
            this.isSettings = data;
        });

        this.billInfo$.subscribe({
            next: (response) => {
                this.billSuccessMessage = response;
            },
        });
        this.activeBill$.subscribe({
            next: (response) => {
                this.activeBill = response;
            },
        });
        // this.adminService.getAdminMenu().subscribe({ next: (data) => {  } })
    }
    ngAfterViewInit() {
        this.menuService.getAsideMenuStatus$.subscribe((value) => {
            if (this.plateform !== 'workStation') {
                const containerAsideMenu = document.getElementById(
                    'container-aside-menu'
                ) as HTMLElement;
                const asideMenuOverlay = document.getElementById(
                    'aside-menu-overlay'
                ) as HTMLElement;
                asideMenuOverlay.addEventListener('click', () => {
                    containerAsideMenu.classList.remove('active');
                    this.menuService.toggleAsideMenu(true);
                });
                if (value) {
                    containerAsideMenu.classList.add('active');
                } else {
                    containerAsideMenu.classList.remove('active');
                }
            }
        });
    }

    closeBillPopup() {
        this.store.dispatch(new CloseBillPopup());
    }
    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    // logoutCorporate() {
    //     this.authService.logoutCorporate().subscribe({
    //         next: (data) => {
    //             console.log('LOgouuttt ssss', data);
    //         },
    //     });
    // }
    hideNotification() {
        setTimeout(() => {
            this.dialog = null;
        }, 5000);
    }
}
