import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    SimpleChanges,
} from '@angular/core';
import {
    trigger,
    state,
    style,
    animate,
    transition,
} from '@angular/animations';
import {
    FormControl,
    FormGroup,
    FormBuilder,
    Validators,
} from '@angular/forms';

import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';

import {
    DialogState,
    CloseDialog,
    AuthState,
    OpenDialog,
    ConfirmPinPossession,
    SwitchThemeState,
    SwitchState,
} from '../../..';
import { VariableService, SettingService } from '../../../../core';

// declare let window: any;
// declare const outputBox = document.querySelector('output');
// declare const selectEl = favDialog.querySelector('select');
// declare const confirmBtn = favDialog.querySelector('#confirmBtn');

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
    modal: any;
    dialog$: Observable<any>;
    dialog: any;
    favDialog: any;
    showNotification = false;
    password: any = new FormControl('');
    passwordForm: FormGroup;
    pinForm: FormGroup;
    @Input() clearPin = false;
    clientInfo$: Observable<any>;
    clientInfo: any;
    changePinForm: FormGroup;
    isCreatingPin = false;
    arePinsMatch = false;
    pinType = 'password';
    showPin = false;

    theme: any;
    theme$: Observable<any>;
    plateform: any;
    plateform$: Observable<any>;

    constructor(
        private store: Store,
        private variableService: VariableService,
        private fb: FormBuilder,
        private settingService: SettingService
    ) {
        this.theme$ = this.store.select(SwitchThemeState.GetTheme);
        this.plateform$ = this.store.select(SwitchState.GetPlateform);
        this.dialog$ = this.store.select(DialogState.GetDialog);
        this.passwordForm = this.fb.group({
            password: ['', Validators.required],
        });

        this.pinForm = this.fb.group({
            pin: ['', Validators.required],
        });
        this.clientInfo$ = this.store.select(AuthState.GetClientInfo);
        this.changePinForm = this.fb.group({
            old_pin: ['', Validators.required],
            new_pin: ['', Validators.required],
            new_pin2: ['', Validators.required],
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            const chng = changes[propName];
            if (propName === 'clearPin') {
                if (chng.currentValue) {
                    this.variableService.pin = '';
                    this.pinForm.controls['pin'].setValue('');
                }
            }
        }
    }

    ngOnInit(): void {
        this.dialog$.subscribe({
            next: (dialog) => {
                this.dialog = dialog;
                if (
                    (this.dialog &&
                        this.dialog.isActive &&
                        this.dialog.type === 'confirm') ||
                    this.dialog.type === 'password' ||
                    this.dialog.type === 'loading' ||
                    this.dialog.type === 'pin'
                ) {
                    this.openModal();
                } else if (this.dialog && !this.dialog.isActive) {
                    this.favDialog = document.getElementById('favDialog');
                    if (this.favDialog) {
                        this.favDialog.close('animalNotChosen');
                    }
                } else if (
                    this.dialog.type === 'success' ||
                    this.dialog.type === 'failed' ||
                    this.dialog.type === 'info'
                ) {
                    this.showNotification = true;
                    setTimeout(() => {
                        this.showNotification = false;
                    }, 5000);
                }
            },
        });

        this.theme$.subscribe({
            next: (theme) => {
                this.theme = theme;
            },
        });
        this.plateform$.subscribe({
            next: (plateform) => {
                this.plateform = plateform;
            },
        });
        this.clientInfo$.subscribe({
            next: (clientInfo) => {
                this.clientInfo = clientInfo;
            },
        });
    }

    openModal() {
        // this.modal.show();
        this.favDialog = document.getElementById('favDialog');
        if (this.favDialog) {
            this.favDialog.showModal();
        }
    }

    // closeModal() {
    //   this.store.dispatch(new CloseDialog());
    // }

    respondModal(response: string) {
        this.store.dispatch(new CloseDialog({ response: response }));
    }

    submitPassword() {
        if (this.passwordForm.value['password'] !== '') {
            this.variableService.password = this.passwordForm.value['password'];
            this.store.dispatch(
                new CloseDialog({ response: 'password submitted' })
            );
            this.passwordForm.value['password'] = '';
            this.passwordForm.controls['password'].setValue('');
            this.passwordForm.reset();
        } else {
            this.store.dispatch(new CloseDialog({ response: 'canceled' }));
        }
    }

    submitPin() {
        if (this.pinForm.value['pin'] !== '') {
            this.variableService.pin = this.pinForm.value['pin'];
            this.store.dispatch(new CloseDialog({ response: 'pin submitted' }));
            console.log('eooooo');
            this.pinForm.value['pin'] = '';
            this.pinForm.reset();
        } else {
            this.store.dispatch(new CloseDialog({ response: 'canceled' }));
        }
    }

    submitPinCreation() {
        this.isCreatingPin = true;
        this.settingService.changePin(this.changePinForm.value).subscribe({
            next: (response_data) => {
                this.isCreatingPin = false;
                if (response_data.object.success === true) {
                    this.variableService.pin =
                        this.changePinForm.value['new_pin'];
                    this.store.dispatch(
                        new CloseDialog({ response: 'pin submitted' })
                    );
                    this.store.dispatch(new ConfirmPinPossession());
                } else if (response_data.object.success === false) {
                    const data = {
                        title: '',
                        type: 'failed',
                        message: response_data.object.response_message,
                    };
                    this.store.dispatch(new OpenDialog(data));
                }
            },
            error: (err: any) => {
                this.isCreatingPin = false;
                const data = {
                    title: '',
                    type: 'failed',
                    message: 'Something went, please retry again!',
                };
                this.store.dispatch(new OpenDialog(data));
            },
        });
    }

    checkPinSimulality() {
        if (
            this.changePinForm.value.new_pin !==
            this.changePinForm.value.new_pin2
        ) {
            this.arePinsMatch = false;
        } else {
            this.arePinsMatch = true;
        }
    }

    changePinType() {
        if (this.pinType === 'password') {
            this.pinType = 'text';
            this.showPin = true;
        } else {
            this.pinType = 'password';
            this.showPin = false;
        }
    }
}
