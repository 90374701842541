import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { DetailedMapComponent } from './components/map/detailed-map/detailed-map.component';
import { GlobalMapComponent } from './components/map/global-map/global-map.component';
import { PieChartComponent } from './components/charts/pie-chart/pie-chart.component';
import { GeneralModule } from './components';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
    declarations: [GlobalMapComponent, DetailedMapComponent, PieChartComponent],
    imports: [
        CommonModule,
        SharedRoutingModule,
        GeneralModule,
        GoogleMapsModule,
    ],
    exports: [GlobalMapComponent, DetailedMapComponent, PieChartComponent],
})
export class SharedModule {}
