import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';

import {
    State,
    StateToken,
    Selector,
    Action,
    StateContext,
    Store,
} from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
    AuthStateModel,
    PopulateModel,
    OperatorUserModel,
} from './auth.models';
import {
    Login,
    PopulateUser,
    Logout,
    PopulateOperate,
    LogoutCorporate,
    PopulateClient,
    ConnectUser,
    ClearCorporates,
    ConfirmPinPossession,
} from './auth.actions';

import { AuthService, VariableService } from '../../../core';
import { OpenDialog, CloseDialog, SwitchState, Switch } from '../../';

// import { StateReset } from 'ngxs-reset-plugin';

const AUTH_STATE_TOKEN = new StateToken<AuthStateModel[]>('auth');
const POPULATE_MODEL = new StateToken<PopulateModel[]>('auth');
const Operator_User_Model = new StateToken<OperatorUserModel>('operator');

@State<AuthStateModel>({
    name: AUTH_STATE_TOKEN,
    defaults: {
        user: {
            token: null,
            username: null,
            email: null,
        },
    },
})
@State<PopulateModel>({
    name: POPULATE_MODEL,
    defaults: {
        corporates: [],
    },
})
@State<OperatorUserModel>({
    name: Operator_User_Model,
    defaults: {
        user: {
            username: null,
            email: null,
            token: null,
        },
        client: {
            id: null,
            clientId: null,
            clientCode: null,
            clientFullName: null,
            isAgent: false,
            isMerchant: false,
            picture: null,
            language: null,
            hasPin: false,
        },
        operator: {
            id: null,
            isTeller: false,
            isTreasurer: false,
        },
        organization: null,
    },
})
@Injectable()
export class AuthState {
    private variableService = inject(VariableService);

    constructor(
        private authService: AuthService,
        private store: Store,
        private router: Router
    ) {}

    @Selector()
    static token(state: AuthStateModel): string | null {
        return state.user.token;
    }

    @Selector()
    static corporates(state: PopulateModel): [] {
        if (state.corporates) {
            return state.corporates;
        } else {
            return [];
        }
    }

    @Selector()
    static GetOrganization(state: OperatorUserModel): any {
        if (state.organization) {
            return state.organization;
        } else {
            return null;
        }
    }

    @Selector()
    static GetPopulateUser(state: OperatorUserModel): any {
        return state;
    }

    @Selector()
    static isAuthenticated(state: OperatorUserModel): boolean {
        if (state) {
            let hasToken = !!state.user.token;

            if (hasToken) {
                const isValid = true;
                hasToken = hasToken && isValid;
            }

            return hasToken;
        }
        return false;
    }

    @Selector()
    static GetOperator(state: OperatorUserModel): any {
        return state.operator;
    }

    @Selector()
    static GetUserInfo(state: OperatorUserModel): any {
        if (state.user) {
            return state.user;
        } else {
            return null;
        }
    }

    @Selector()
    static isAuthenticatedInCorporate(state: OperatorUserModel): any {
        if (state.organization && state.operator) {
            return true;
        } else {
            return false;
        }
    }

    @Selector()
    static isTreasurer(state: OperatorUserModel): any {
        if (state.operator) {
            return state.operator.isTreasurer;
        }
    }

    @Selector()
    static GetClientId(state: OperatorUserModel): any {
        if (state.client) {
            return state.client.clientId;
        }
    }

    @Selector()
    static GetClientInfo(state: OperatorUserModel): any {
        if (state.client) {
            return state.client;
        }
    }

    @Action(Login)
    login(ctx: StateContext<OperatorUserModel>, action: Login) {
        return this.authService.login(action.payload).pipe(
            tap((result: any) => {
                ctx.patchState({
                    user: {
                        token: result.user.token,
                        username: result.user.username,
                        email: result.user.email,
                    },
                });

                this.store.dispatch(new PopulateUser());
            })
        );

        // return ctx.patchState({
        //     username: action.payload.username,
        //     email: action.payload.password,
        // });
    }

    @Action(ConnectUser)
    connectUser(ctx: StateContext<OperatorUserModel>, action: ConnectUser) {
        ctx.patchState({
            user: {
                username: action.payload.username,
                email: action.payload.email,
                token: action.payload.token,
            },
        });

        this.store.dispatch(new PopulateClient());
    }

    @Action(PopulateUser)
    populateUser(ctx: StateContext<PopulateModel>, action: PopulateUser) {
        return this.authService.populate().pipe(
            tap(
                (result: {
                    objects: {
                        corporates: [];
                    };
                    corporates: [];
                }) => {
                    ctx.patchState({
                        // operator: {
                        //     corporates: result.objects,
                        // },
                        corporates: result.objects,
                    });
                }
            )
        );
    }

    @Action(ClearCorporates)
    clearCorporates(ctx: StateContext<PopulateModel>, action: ClearCorporates) {
        ctx.patchState({ corporates: [] });
    }

    @Action(LogoutCorporate)
    logoutCorporate(
        ctx: StateContext<OperatorUserModel>,
        action: LogoutCorporate
    ) {
        const state = ctx.getState();

        // if (action.payload.corporateOnly === true) {
        const data = {
            title: '',
            type: 'loading',
            message: '',
        };
        this.store.dispatch(new OpenDialog(data));
        this.authService.logoutCorporate().subscribe({
            next: (response) => {
                if (
                    response.object['success'] !== undefined &&
                    !response.object.success
                ) {
                    this.store.dispatch(new CloseDialog({ response: 'close' }));
                    this.store.dispatch(
                        new OpenDialog({
                            message: response.object.response_message,
                            title: '',
                            type: 'failed',
                        })
                    );
                    return;
                }
                this.store.dispatch(new ClearCorporates());
                ctx.patchState({
                    user: {
                        username: state.user.username,
                        email: state.user.email,
                        token: state.user.token,
                    },
                });
                this.router.navigate(['/auth/corporate']);
                // window.location.href = '/auth/corporate';
                this.store.dispatch(new CloseDialog({ response: 'close' }));
            },
        });
        // } else {
        //     const data = {
        //         title: '',
        //         type: 'loading',
        //         message: '',
        //     };
        //     this.store.dispatch(new OpenDialog(data));
        //     this.authService.logoutCorporate().subscribe({
        //         next: (data) => {
        //             this.store.dispatch(new CloseDialog({ response: 'close' }));
        //             if (data.object.success === true) {
        //                 this.store.dispatch(new Logout());
        //                 window.location.href = '/';
        //                 ctx.patchState({
        //                     user: {
        //                         username: state.user.username,
        //                         email: state.user.email,
        //                         token: state.user.token,
        //                     },
        //                 });
        //             }
        //         },
        //     });
        // }
    }

    @Action(ConfirmPinPossession)
    confirmPinPossession(
        ctx: StateContext<OperatorUserModel>,
        action: ConfirmPinPossession
    ) {
        const state = ctx.getState();
        const client = state.client;
        client['hasPin'] = true;

        ctx.patchState({
            client: client,
            user: state.user,
            operator: state.operator,
            organization: state.organization,
        });
    }

    @Action(Logout)
    logout(ctx: StateContext<AuthStateModel>, action: Logout) {
        const state = ctx.getState();
        const data = {
            title: '',
            type: 'loading',
            message: '',
        };
        this.store.dispatch(new OpenDialog(data));
        this.authService.logout().subscribe({
            next: (response) => {
                if (
                    response.object['success'] !== undefined &&
                    !response.object.success
                ) {
                    this.store.dispatch(new CloseDialog({ response: 'close' }));
                    this.store.dispatch(
                        new OpenDialog({
                            message: response.object.response_message,
                            title: '',
                            type: 'failed',
                        })
                    );
                    return;
                }
                this.store.dispatch(new ClearCorporates());
                ctx.setState({
                    user: {
                        token: null,
                        username: null,
                        email: null,
                    },
                });
                // window.location.href = '/';
                this.router.navigate(['/']);
                this.store.dispatch(new CloseDialog({ response: 'close' }));
                this.store.dispatch(new Switch({ plateform: 'home' }));
            },
            error: (err) => {
                this.store.dispatch(new CloseDialog({ response: 'close' }));
                this.store.dispatch(
                    new OpenDialog({
                        message:
                            err?.object?.response_message ??
                            $localize`Something went wrong, please retry again!`,
                        title: '',
                        type: 'failed',
                    })
                );
            },
        });
        // this.store.dispatch(new LogoutCorporate());
    }

    @Action(PopulateClient)
    populateClient(
        ctx: StateContext<OperatorUserModel>,
        action: PopulateClient
    ) {
        const state = ctx.getState();
        return this.authService.populateClient().pipe(
            tap(
                (result: {
                    object: {
                        client: {
                            id: string;
                            client_id: string;
                            client_code: string;
                            client_full_name: string;
                            is_agent: boolean;
                            is_merchant: boolean;
                            picture_url: string;
                            prefered_language: string;
                            has_pin: boolean;
                        };
                        user: {
                            username: string;
                            email: string;
                        };
                    };
                }) => {
                    if (result.object.client) {
                        ctx.patchState({
                            client: {
                                id: result.object.client.id,
                                clientId: result.object.client.client_id,
                                clientCode: result.object.client.client_code,
                                clientFullName:
                                    result.object.client.client_full_name,
                                isAgent: result.object.client.is_agent,
                                isMerchant: result.object.client.is_merchant,
                                picture: result.object.client.picture_url,
                                language:
                                    result.object.client.prefered_language,
                                hasPin: result.object.client.has_pin,
                            },
                            user: {
                                username: result.object.user.username,
                                email: result.object.user.email,
                                token: state.user.token,
                            },
                        });
                    } else {
                        ctx.patchState({
                            client: {
                                id: null,
                                clientId: null,
                                clientCode: null,
                                clientFullName: null,
                                isAgent: false,
                                isMerchant: false,
                                picture: null,
                                language: null,
                                hasPin: false,
                            },
                            user: {
                                username: null,
                                email: null,
                                token: null,
                            },
                        });
                    }
                }
            )
        );
    }

    @Action(PopulateOperate)
    populateOperator(
        ctx: StateContext<OperatorUserModel>,
        action: PopulateOperate
    ) {
        const state = ctx.getState();
        this.variableService.isPopulatingOperator.next(true);
        return this.authService.getConnectedOperator().pipe(
            tap(
                (result: {
                    object: {
                        response_data: {
                            user: {
                                token: string;
                            };
                            object: {
                                operator: {
                                    employee_client: {
                                        client_code: string;
                                        client_full_name: string;
                                        is_agent: boolean;
                                        is_merchant: boolean;
                                        picture: string;
                                        prefered_language: string;
                                    };
                                };
                                organization: any;
                                id: any;
                                is_teller: boolean;
                                is_treasurer: boolean;
                            };
                        };
                    };
                }) => {
                    ctx.patchState({
                        user: {
                            token: result.object.response_data.user.token,
                            username: state.user.username,
                            email: state.user.email,
                        },
                    });

                    this.variableService.isPopulatingOperator.next(false);

                    if (result.object.response_data.object) {
                        if (result.object.response_data.object.operator) {
                            ctx.patchState({
                                operator: {
                                    id: result.object.response_data.object.id,
                                    isTeller:
                                        result.object.response_data.object
                                            .is_teller,
                                    isTreasurer:
                                        result.object.response_data.object
                                            .is_treasurer,
                                },
                            });
                        }
                        if (result.object.response_data.object.organization) {
                            ctx.patchState({
                                organization:
                                    result.object.response_data.object
                                        .organization,
                            });
                        }
                    } else {
                        ctx.patchState({
                            operator: {
                                id: null,
                                isTeller: false,
                                isTreasurer: false,
                            },
                            organization: null,
                        });
                    }

                    // this.store.dispatch(new PopulateUser());
                }
            )
        );
    }
}
