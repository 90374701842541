import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { SwitchThemeState } from 'src/app/shared';

@Component({
    selector: 'app-transfer-bill',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './transfer-bill.component.html',
    styleUrls: ['./transfer-bill.component.scss'],
})
export class TransferBillComponent {
    @Input() successMessage: any;

    // {
    //     debit_account: string;
    //     debit_bank: string;
    //     credit_account: string;
    //     credit_bank: string;
    //     reference: string;
    //     bank_reference: string;
    //     amount: string | number;
    //     transfer_fees: string | number;
    //     bill_date: string | number | Date;
    // };

    theme$: Observable<any>;
    theme: any;

    constructor(private store: Store) {
        this.theme$ = this.store.select(SwitchThemeState.GetTheme);
    }

    ngOnInit() {
        this.theme$.subscribe({
            next: (theme) => {
                this.theme = theme;
            },
        });
    }
}
