import { CommonModule, Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-not-found-page',
    templateUrl: './not-found-page.component.html',
    styleUrls: ['./not-found-page.component.scss'],
    imports: [CommonModule],
    standalone: true,
})
export class NotFoundPageComponent {
    @Input() action = 'Return';
    @Output() close = new EventEmitter();

    constructor(private _location: Location) {}

    goBack() {
        if (this.action === 'Return') {
            // this._location.back();
        } else if (this.action === 'Close') {
            this.closePage();
        }
    }

    closePage() {
        this.close.emit(false);
    }
}
