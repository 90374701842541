import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class BankService {
    constructor(
        private apiService: ApiService,
        private httpClient: HttpClient
    ) {}

    private _isTransactionDone: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    get isTransactionDone$(): Observable<boolean> {
        return this._isTransactionDone.asObservable();
    }

    updateTransaction(bool: boolean) {
        this._isTransactionDone.next(bool);
    }

    private _isBankingAndServicesSelected: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    get _isBankingAndServicesSelected$(): Observable<boolean> {
        return this._isBankingAndServicesSelected.asObservable();
    }

    handleBanking(arg: boolean) {
        this._isBankingAndServicesSelected.next(arg);
    }
    getBanksList() {
        const url = '/banks/clientlist/?';
        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
    getBanksListAll() {
        const url = '/banks/list/?bank_type=MFI&list_for_add_bank=true';

        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
    addBank(body: any) {
        const url = '/client/clientorganization/';
        return this.apiService
            .post(url, body)
            .pipe(map((response) => response));
    }
    withdrawFromAgent(withdraw: any) {
        return this.apiService.post('/dbs/agent/withdrawal/', withdraw).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getAccountStatements(
        accountId: any,
        dateFrom: { year: string; month: string; day: string },
        dateEnd: { year: string; month: string; day: string }
    ) {
        const url = `/operations/statement/?client_acc_id=${accountId}&year=${dateFrom.year}&year_to=${dateEnd.year}&month_from=${dateFrom.month}&day_from=${dateEnd.month}&month_to=${dateFrom.day}&day_to=${dateEnd.day}&limit=50&offset=0`;
        return this.apiService.get(url).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    getBankStatusPing(body: any) {
        const url = `${environment.websocketUrl}ws/dbsapp/partners-ping/`;

        return this.httpClient.post(url, body).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
}
