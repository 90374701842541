import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { map, tileLayer, marker, Icon, popup, Map, LatLng } from 'leaflet';

@Component({
    selector: 'app-detailed-map',
    templateUrl: './detailed-map.component.html',
    styleUrls: ['./detailed-map.component.scss'],
})
export class DetailedMapComponent implements AfterViewInit {
    @Input() lat: any = -3.3816576;
    @Input() lng: any = 29.3568512;
    @Input() isLoading = false;
    myLat: any;
    myLong: any;
    showButton = false;
    @Output() coords: EventEmitter<{ lat: number; lng: number }> =
        new EventEmitter();

    merchantIcon = new Icon({
        iconUrl: 'https://www.svgrepo.com/show/80543/shopping-cart-outline.svg',
        iconSize: [38, 75],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });

    smallIcon = new Icon({
        iconUrl:
            'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-icon.png',
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowUrl:
            'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        shadowSize: [41, 41],
    });

    noLocationMessage: boolean | undefined;

    constructor() {
        //
    }

    ngOnInit() {
        this.initMap();
    }

    ngAfterViewInit(): void {
        //
    }

    initMap() {
        const customMap = map('map').setView([this.lat, this.lng], 20);

        const tiles = tileLayer(
            'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
            {
                maxZoom: 19,
                minZoom: 3,
                attribution: '',
            }
        ).addTo(customMap);

        if (!this.lat && !this.lng) {
            const customMarker = marker([-3.3816576, 29.3568512], {
                icon: this.smallIcon,
                draggable: true,
            }).addTo(customMap);
            this.noLocationMessage = true;
        } else {
            const customMarker = marker([this.lat, this.lng], {
                icon: this.smallIcon,
                draggable: true,
            }).addTo(customMap);
        }

        const customPopup = popup();

        const onMapClick = (e: any) => {
            // this.getLocation();

            customPopup
                .setLatLng(e.latlng)
                .setContent(`(${e.latlng.lat}, ${e.latlng.lng})`)
                .openOn(customMap);
            this.lat = e.latlng.lat;
            this.lng = e.latlng.lng;
            console.log(this.lat, this.lng);
            this.showButton = true;

            const coordinates = { lat: this.lat, lng: this.lng };

            this.coords.emit(coordinates);

            console.log(this.lat, this.lng);
        };

        customMap.on('click', onMapClick);
    }

    getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position: any) => {
                    if (position) {
                        console.log(
                            'Latitude: ' +
                                position.coords.latitude +
                                ' Longitude: ' +
                                position.coords.longitude
                        );
                        this.lat = position.coords.latitude;
                        this.lng = position.coords.longitude;
                    }
                },
                (error: any) => console.log(error)
            );
        } else {
            alert('Geolocation is not supported by this browser.');
        }
    }

    setLocation() {
        const coordinates = { lat: this.lat, lng: this.lng };

        this.coords.emit(coordinates);
        // alert('The current location is on :' + this.lat + ' and ' + this.lng);
        console.log(this.lat, this.lng);
    }
}
