export interface AuthStateModel {
    user: {
        username: string | null;
        email: string | null;
        token: string | null;
    };
}

export interface PopulateModel {
    corporates: any;
}

export interface OperatorUserModel {
    user: {
        username: string | null;
        email: string | null;
        token: string | null;
    };
    client: {
        id: string | null;
        clientId: string | null;
        clientCode: string | null;
        clientFullName: string | null;
        isAgent: boolean;
        isMerchant: boolean;
        picture: string | null;
        language: string | null;
        hasPin: boolean;
    };
    operator: {
        id: string | null;
        isTeller: boolean;
        isTreasurer: boolean;
    };
    organization: any;
}

// export interface AuthStateModel {
//     username: string | null;
//     email: string | null;
//     token: string | null;
// }

// export interface PopulateModel {
//     corporates: any;
// }
