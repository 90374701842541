import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FormatDateService {
    // constructor() { }

    dissectDate(date: any) {
        const dissectedDate = {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate(),
        };

        return dissectedDate;
    }
}
