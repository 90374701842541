import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CartService {
    public cartItemList: any = [];
    public productList = new BehaviorSubject<any>([]);
    //   constructor(){}
    getProducts() {
        return this.productList.asObservable();
    }
    setProduct(product: any) {
        this.cartItemList.push(...product);
        this.productList.next(product);
    }
    addToCart(product: any) {
        this.cartItemList.push(product);
        this.productList.next(this.cartItemList);
        // this.getTotalAmount();
        console.log(this.cartItemList);
    }

    removeCartItem(product: any) {
        this.cartItemList.map((item: any, index: any) => {
            if (product.id === item.id) {
                this.cartItemList.splice(index, 1);
            }
        });
    }
    EmptyCart() {
        this.cartItemList = [];
        this.productList.next(this.cartItemList);
    }
    retrieveCart() {
        return this.cartItemList;
    }
}
