<div
    class="d-none d-lg-flex justify-content-center justify-content-lg-start"
    style="height: 66px"
>
    <div class="d-none d-lg-flex mt-3" style="width: 17vw">
        <img
            [src]="text.icon"
            style="height: 30px"
            class="img-fluid clickable"
            [routerLink]="text.link"
        />
        <span
            class="text-capitalize"
            [ngClass]="{
                'ms-2' : text.icon !=='',
                'sub-header-title-o':plateform==='onamob',
                'sub-header-title-b':plateform !=='onamob',

            }"
        >
            <span *ngIf="plateform === 'home'"
                >Good {{ dayMoment }}, {{ userInfo.username ?? '' }}</span
            >
            <span *ngIf="plateform !== 'home'">{{ text.title }}</span>
        </span>
    </div>
</div>
