// import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

import { VERSION } from './utils';

export const environment = {
    production: false,
    apiUrl: 'https://assets.ihela.bi/testcbs/api/v1',
    websocketUrl: 'ws://assets.ihela.bi/testcbs/',

    plateformsUuid: [
        { name: 'home', uuid: '44793f5f-1bd5-4569-9592-ec8a7c81d022' },
        { name: 'onlineBanking', uuid: '65231163-2099-45e3-95de-0bdfa5882088' },
        { name: 'market', uuid: '28544962-9f7a-4768-adad-66f336251822' },
        { name: 'workStation', uuid: '1e35b443-1076-4971-ae04-85a9272d6ec9' },
    ],
    appInfo: '39a31c07-633e-429e-a602-09500f8d56d2',
    bigBangId: 1,
    publicServicesMerchantCategoryId: 3,

    sentryDsn: 'https://4808bd0c8a72e687fe9af699ab0a05d7@turabe.ihela.online/6',
    sentryTargetUrls: [
        /https:\/\/test\.ihela\.bi/,
        /https:\/\/assets\.ihela\.bi\/testcbs\/api\/v1/,
    ],
    sentryTraceRate: 1.0,
};
