import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TarifService } from 'src/app/core';

import { ToWords } from 'to-words';
import { ToWordsOptions } from 'to-words/dist/types';

@Component({
    selector: 'app-amount-words',
    templateUrl: './amount-words.component.html',
    styleUrls: ['./amount-words.component.scss'],
})
export class AmountWordsComponent {
    toWords!: ToWords;

    amount!: number | null;
    amountWords!: string;
    amountInput = new FormControl('');
    fees: any;
    isLoading = false;

    @Input() language = '';
    @Output() amountEvent = new EventEmitter<any>();
    @Input() background = '';
    @Input() activeBorder = false;
    @Input() isFormFloating = true;

    // input for fees
    @Input() showFees = false;
    @Input() showAmountwords = true;
    @Input() typeCode: any;
    @Input() bankId: any;
    @Output() feesEvent = new EventEmitter<any>();
    totalWithFees: any;

    constructor(private tarifService: TarifService) {}

    ngOnInit() {
        this.toWords = new ToWords({
            localeCode: this.language ? this.language : 'en-GB',
            currency: true,
        } as ToWordsOptions);
    }

    newAmount() {
        if (this.amountInput.value) {
            const inputValue = this.amountInput.value.toString();
            const cleanedAmount = inputValue.replace(/[^0-9.]/g, '');
            const parsedAmount = parseFloat(cleanedAmount);

            if (!isNaN(parsedAmount)) {
                this.amount = parsedAmount;
                const formattedAmount = parsedAmount.toLocaleString();
                this.amountInput.setValue(formattedAmount);
                this.amountEvent.emit({ amount: this.amount });
                this.convertAmountToWords(this.amount);
            } else {
                this.amountWords = '';
                this.amountInput.setValue('');
                this.amountEvent.emit({ amount: null });
            }
        } else {
            this.amountEvent.emit({ amount: null });
            this.amountWords = '';
        }
    }

    getFees() {
        this.isLoading = true;
        if (this.typeCode && this.amountInput.value && this.bankId) {
            alert('asdlad get fees executed');
            this.tarifService
                .getSimulate(this.typeCode, this.amount, this.bankId)
                .subscribe({
                    next: (response) => {
                        this.isLoading = false;
                        this.fees = response.objects[0];
                        this.feesEvent.emit(this.fees);
                        this.totalWithFees = this.fees + this.amount;
                    },
                    error: (err) => {
                        this.isLoading = false;
                    },
                });
        }
    }

    private convertAmountToWords(amount: number) {
        if (amount) {
            this.amountWords = this.toWords.convert(this.amount as number, {
                currency: true,
                currencyOptions: {
                    name: 'BIF',
                    plural: 'BIF',
                    symbol: '',
                    fractionalUnit: {
                        name: '',
                        plural: '',
                        symbol: '',
                    },
                },
            });
        }
    }
}
