import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-institution-list',
    templateUrl: './institution-list.component.html',
    styleUrls: ['./institution-list.component.scss'],
})
export class InstitutionListComponent implements OnInit {
    year: any;

    constructor() {
        // comment
    }

    ngOnInit(): void {
        this.year = new Date().getFullYear();
    }
}
