import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SwitchThemeState } from 'src/app/shared/states/theme';

// import { Options } from 'ngx-slider-v2';

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
    theme: any;
    theme$: Observable<any>;

    name = '';
    title = '';
    // value = '';
    type_field = '';
    @Input() filters: any = [
        // { name: 'Period', title: 'period', value: 'p' },
        {
            name: 'Date',
            title: 'date',
            value: [
                { title: 'To day', value: 'toDay', type_field: 'radio' },
                { title: 'This week', value: 'thisWeek', type_field: 'radio' },
                { title: 'This year', value: 'thisYear', type_field: 'radio' },
                {
                    title: 'Select Date',
                    value: 'Select a Date',
                    type_field: 'select_date',
                }, // This Field was added just to make Select Date button in loop
                { title: 'Date', value: 'date', type_field: 'date' },
                { title: 'Period', value: 'period', type_field: 'date' },
            ],
        },
        {
            name: 'Status',
            title: 'status',
            value: [
                { title: 'Activate', value: 'A', type_field: 'radio' },
                { title: 'Deactivate', value: 'D', type_field: 'radio' },
            ],
        },
        {
            name: 'General',
            title: 'general',
            value: [
                { title: 'Ordinary', value: 'O', type_field: 'checkbox' },
                { title: 'Entreprise', value: 'E', type_field: 'checkbox' },
            ],
        },
    ];
    @Output() selectedFilters = new EventEmitter<any>();
    selectedFiltGroup: any;
    showDateForm = false;
    selectedEmitFilters: any;

    years = ['2019', '2020', '2021', '2022'];
    months: any = [
        { title: 'Jan', value: 1, name: $localize`January` },
        { title: 'Feb', value: 2, name: $localize`February` },
        { title: 'Mar', value: 3, name: $localize`March` },
        { title: 'Apr', value: 4, name: $localize`April` },
        { title: 'May', value: 5, name: $localize`May` },
        { title: 'Jun', value: 6, name: $localize`June` },
        { title: 'Jul', value: 7, name: $localize`July` },
        { title: 'Aug', value: 8, name: $localize`August` },
        { title: 'Sep', value: 9, name: $localize`September` },
        { title: 'Oct', value: 10, name: $localize`October` },
        { title: 'Nov', value: 11, name: $localize`November` },
        { title: 'Dec', value: 12, name: $localize`December` },
    ];
    selectedYear = '';
    selectedMonth: any;
    showYears = false;
    showMonths = false;
    startDate = new FormControl('');
    endDate = new FormControl('');
    // @ViewChild('exampleSlider') exampleSlider!: ElementRef;
    // exSlider: any;
    // Slider: any;

    lowerValue = 25;
    upperValue = 75;

    value = 100;
    // options: Options = {
    //   floor: 0,
    //   ceil: 200
    // };

    @Output() selectedPeriod = new EventEmitter<any>();

    constructor(private store: Store) {
        this.theme$ = this.store.select(SwitchThemeState.GetTheme);
    }

    ngOnInit(): void {
        this.name = $localize`name`;
        // this.value = $localize`value`;
        this.title = $localize`title`;
        this.type_field = $localize`type_field`;

        // this.exSlider = new this.Slider(this.exampleSlider.nativeElement);

        this.theme$.subscribe({
            next: (theme) => {
                this.theme = theme;
            },
        });
    }

    selectFiltGroup(filt: string) {
        if (this.selectedFiltGroup !== filt) {
            this.selectedFiltGroup = filt;
        } else {
            this.selectedFiltGroup = null;
        }
    }

    displayDate() {
        if (this.showDateForm === true) {
            this.showDateForm = false;
        } else {
            this.showDateForm = true;
        }
    }

    displayYears() {
        if (this.showYears) {
            this.showYears = false;
        } else {
            this.showYears = true;
        }
    }

    displayMonths() {
        if (this.showMonths) {
            this.showMonths = false;
        } else {
            this.showMonths = true;
        }
    }

    selectMonth(month: any) {
        this.selectedMonth = month;
        this.showMonths = false;
    }

    selectYear(year: string) {
        this.selectedYear = year;
        this.showYears = false;
    }

    emitPeriod() {
        if (this.startDate.value && this.endDate.value) {
            const period = {
                startDate: this.startDate.value,
                endDate: this.endDate.value,
            };
            this.selectedPeriod.emit(period);
        } else if (this.startDate) {
            const period = {
                startDate: this.startDate.value,
                endDate: this.startDate.value,
            };
            this.selectedPeriod.emit(period);
        } else {
            const period = {
                startDate: this.endDate.value,
                endDate: this.endDate.value,
            };
            this.selectedPeriod.emit(period);
        }
    }

    // selectFilt(filt: string) {
    //     if (filt.type_field === 'radio') {
    //         this.selectedFilters.emit({ filt.value:  })
    //     }
    // }
}
