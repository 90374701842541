import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    @Input() showCopyRight = true;
    year: any;

    ngOnInit() {
        this.year = new Date(Date.now()).getFullYear();
    }
}
