import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { SettingService } from 'src/app/core/services/setting.service';
import { SwitchThemeState } from 'src/app/shared/states/theme';

@Component({
    selector: 'app-setting-aside-menu',
    templateUrl: './setting-aside-menu.component.html',
    styleUrls: ['./setting-aside-menu.component.scss'],
})
export class SettingAsideMenuComponent implements OnInit {
    private onDestroy$: Subject<void> = new Subject<void>();
    text: any;
    isSecurityMenusShown = false;
    isSessionMenusShown = false;
    isGeneralSubMensShown = false;
    selectedMenu = '';
    selectedSubMenu = '';
    activePopupMenu: any;
    theme: any;
    theme$: Observable<any>;

    constructor(
        private settingService: SettingService,
        private router: Router,
        private store: Store
    ) {
        this.theme$ = this.store.select(SwitchThemeState.GetTheme);
    }
    ngOnInit() {
        if (this.router.url === '/b/settings/security') {
            this.selectedMenu = 'security';
            this.isSecurityMenusShown = true;
            this.selectedSubMenu = 'pin';
        } else if (this.router.url === '/b/settings/general') {
            this.selectedMenu = 'general';
            this.selectedSubMenu = 'account';
            this.isGeneralSubMensShown = true;
        } else if (this.router.url === '/b/settings/session') {
            this.selectedMenu = 'session';
            this.isSessionMenusShown = true;
        } else {
            this.selectedMenu = 'general';
            this.selectedSubMenu = 'account';
            this.isGeneralSubMensShown = true;
        }
        this.selectSubMenu(this.selectedMenu, this.selectedSubMenu);
        this.toggleMenuPopup(this.activePopupMenu);
        this.theme$.pipe(takeUntil(this.onDestroy$)).subscribe({
            next: (theme) => {
                this.theme = theme;
            },
        });
    }
    toggleMenuPopup(activePopupMenu: string) {
        if (this.activePopupMenu === activePopupMenu) {
            this.activePopupMenu = null;
        } else {
            this.activePopupMenu = activePopupMenu;
        }
    }

    selectSubMenu(selectedMenu: string, selectedSubMenu: string) {
        this.settingService.selectSubMenu(selectedSubMenu);
        this.selectedSubMenu = selectedSubMenu;

        if (selectedMenu === 'security') {
            this.router.navigateByUrl('b/settings/security');
        }

        if (selectedMenu === 'general') {
            this.router.navigateByUrl('b/settings/general');
        }
        if (selectedMenu === 'session') {
            this.router.navigateByUrl('b/settings/session');
        }
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
