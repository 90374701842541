import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngxs/store';

import { AuthService } from '../../../../core';
import {
    AuthState,
    OpenDialog,
    DialogState,
    PopulateOperate,
    Logout,
    LogoutCorporate,
    OpenConfirmDialog,
    SwitchThemeState,
    SwitchState,
} from '../../../../shared';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingService } from 'src/app/core/services/setting.service';

declare let window: any;

@Component({
    selector: 'app-setting',
    templateUrl: './setting.component.html',
    styleUrls: ['./setting.component.scss'],
})
export class SettingComponent implements OnInit {
    private onDestroy$: Subject<void> = new Subject<void>();
    showSettingMenu = false;
    organization$: Observable<any>;
    organization: any;
    modal: any;
    dialog$: Observable<any>;
    dialog: any;
    isLoading = false;
    selectedMenu: any;
    connectedOperator: any;
    type = 'password';
    plateform$: any;
    plateform = '';

    changePinForm: any = new FormGroup({
        oldPin: new FormControl('', [
            Validators.required,
            Validators.pattern(/^\d{4}$/),
        ]),
        newPin: new FormControl('', [
            Validators.required,
            Validators.pattern(/^\d{4}$/),
        ]),
        newPin2: new FormControl('', [
            Validators.required,
            Validators.pattern(/^\d{4}$/),
        ]),
    });
    changePasswordForm: any = new FormGroup({
        oldPassword: new FormControl('', Validators.required),
        newPassword: new FormControl('', [
            Validators.required,
            Validators.pattern(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
            ),
        ]),
        newPassword2: new FormControl('', Validators.required),
    });
    theme$: Observable<any>;
    theme: any;

    constructor(
        private store: Store,
        private authService: AuthService,
        private router: Router,
        private settingService: SettingService
    ) {
        this.organization$ = this.store.select(AuthState.GetOrganization);
        this.dialog$ = this.store.select(DialogState.GetDialog);
        this.theme$ = this.store.select(SwitchThemeState.GetTheme);
        this.plateform$ = this.store.select(SwitchState.GetPlateform);
    }

    ngOnInit(): void {
        this.plateform$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((plateform: string) => {
                this.plateform = plateform;
            });

        this.authService.getConnectedOperator().subscribe((operator: any) => {
            this.connectedOperator =
                operator.object.response_data.object.operator;
        });
        this.organization$.subscribe({
            next: (organization) => {
                this.organization = organization;
            },
        });

        this.theme$.subscribe({
            next: (theme) => {
                this.theme = theme;
            },
        });

        this.dialog$.pipe(takeUntil(this.onDestroy$)).subscribe({
            next: (dialog) => {
                this.dialog = dialog;
                if (this.dialog && this.dialog.response) {
                    if (
                        this.dialog.response === 'Yes' &&
                        this.dialog.action === 'logoutCorporateSettings'
                    ) {
                        // this.authService.logoutCorporate().subscribe({
                        //     next: (data) => {
                        //         if (data.success === true) {
                        //             this.store.dispatch(new PopulateOperate());
                        //             this.router.navigate(['/auth/corporate']);
                        //             const data = {
                        //                 title: '',
                        //                 type: '',
                        //                 message: '',
                        //                 reponse: 'done',
                        //             };
                        //             this.store.dispatch(new OpenDialog(data));
                        //         }
                        //     },
                        // });
                        this.store.dispatch(new LogoutCorporate());
                        // this.store.dispatch))
                    } else if (
                        this.dialog.respone === 'No' &&
                        this.dialog.action === 'logoutCorporateSettings'
                    ) {
                        // console.log('Mugumize aho');
                        this.showSettingMenu = false;
                    }
                }
            },
        });
    }

    togglePassword() {
        if (this.type === 'password') {
            this.type = 'text';
        } else if (this.type === 'text') {
            this.type = 'password';
        }
    }
    selectSettingMenu(menu: string) {
        this.type = 'password';

        if (this.selectedMenu === menu) {
            this.selectedMenu = null;
        } else {
            this.selectedMenu = menu;
        }

        if (this.selectedMenu !== menu) {
            this.changePinForm.patchValue({
                oldPin: '',
                newPin: '',
                newPin2: '',
            });
            this.changePasswordForm.patchValue({
                oldPassword: '',
                newPassword: '',
                newPassword2: '',
            });
        }
    }

    changePin() {
        this.isLoading = true;
        const body = {
            old_pin: this.changePinForm.value.oldPin,
            new_pin: this.changePinForm.value.newPin,
            new_pin2: this.changePinForm.value.newPin2,
        };
        // this.changePinForm.value=''
        this.changePinForm.patchValue({
            oldPin: '',
            newPin: '',
            newPin2: '',
        });

        this.settingService
            .changePin(body)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (response) => {
                    if (response.object.success === true) {
                        const data = {
                            title: '',
                            type: 'success',
                            message: 'Pin changed successfully',
                        };
                        this.store.dispatch(new OpenDialog(data));
                    } else if (response.object.success === false) {
                        const data = {
                            title: '',
                            type: 'failed',
                            message: 'failed to change the pin',
                        };
                        this.store.dispatch(new OpenDialog(data));
                    } else {
                        const data = {
                            title: '',
                            type: 'success',
                            message: 'Pin changed successfully',
                        };
                        this.store.dispatch(new OpenDialog(data));
                    }

                    this.isLoading = false;
                },
                error: (data) => {
                    this.changePinForm.value = '';
                    this.isLoading = false;
                    const failure = {
                        title: 'failure',
                        type: 'failed',
                        message: 'Failed to change the pin ',
                    };
                    this.store.dispatch(new OpenDialog(failure));
                },
            });
    }

    openModal() {
        const data = {
            title: '',
            type: 'confirm',
            message:
                'This action will disconnect you in ' +
                this.organization.institution_client.client_full_name +
                '  Are you sure you want to Logout ?',
            action: 'logoutCorporateSettings',
        };
        this.store.dispatch(new OpenConfirmDialog(data));
        this.showSettingMenu = true;
    }

    logout() {
        this.store.dispatch(new Logout());
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
