<!-- <div
    class="level-2 rounded p-2 shadow mt-1"
    style="position: absolute; min-width: 250px; z-index: 1; right: 0"
>
    <div *ngFor="let filt of filters">
        <span
            class="mb-1 ms-3 d-flex"
            role="button"
            (click)="selectFiltGroup(filt)"
            *ngIf="!selectedFiltGroup || selectedFiltGroup !== filt"
        >
            <b class="text-dark">{{ filt.name }}</b>
            <i class="fa-solid fa-sort-down ms-2 text-dark" role="button"></i>
        </span>

        <span
            class="mb-1 ms-3 d-flex"
            role="button"
            (click)="selectFiltGroup(filt)"
            *ngIf="selectedFiltGroup && selectedFiltGroup === filt"
        >
            <b class="text-dark">{{ filt.name }}</b>
            <i class="fa-solid fa-sort-up ms-2 text-dark" role="button"></i>
        </span>

        <div
            class=""
            *ngIf="selectedFiltGroup"
            [ngClass]="{
                'mb-1 ms-4 level-2 rounded p-2': selectedFiltGroup === filt
            }"
        >
            <div *ngFor="let value of filt.value">
                <div
                    *ngIf="
                        value.type_field === 'radio' &&
                        selectedFiltGroup.title === 'date' &&
                        filt.title === 'date'
                    "
                >
                    <span role="button" class="text-dark">
                        <input
                            type="{{ value.type_field }}"
                            id="active"
                            name="active"
                            class="form-check-input input me-2"
                            role="button"
                        />
                        <b class="text-dark">{{ value.title }}</b
                        ><br />
                    </span>
                </div>

                <div
                    class="mt-3"
                    *ngIf="
                        value.type_field === 'select_date' &&
                        selectedFiltGroup.title === 'date'
                    "
                    title="You can filt by a date or choose a certain period"
                >
                    <span
                        style="justify-content: center; display: flex"
                        *ngIf="!showDateForm"
                        (click)="displayDate()"
                        role="button"
                        class="text-secondary"
                    >
                        <b class="fs-5 text-dark" i18n
                            >Select Date
                            <i
                                class="fa-solid fs-5 ms-2 fa-sort-down text-dark"
                            ></i
                        ></b>
                    </span>
                    <span
                        style="justify-content: center; display: flex"
                        *ngIf="showDateForm"
                        (click)="displayDate()"
                        role="button"
                        class="text-secondary"
                    >
                        <b class="fs-5 text-dark" i18n
                            >Select Date
                            <i
                                class="fa-solid fa-sort-up fs-5 ms-2 text-dark"
                            ></i
                        ></b>
                    </span>
                </div>

                <div
                    role="button"
                    class="text-dark mt-2"
                    *ngIf="
                        value.type_field === 'date' &&
                        selectedFiltGroup.title === 'date' &&
                        showDateForm
                    "
                >
                    <div *ngIf="value.value !== 'period'">
                        <b class="text-dark">{{ value.title }}</b
                        ><br />
                        <input
                            type="{{ value.type_field }}"
                            id="active"
                            name="active"
                            class="ihela_radio form-control me-2"
                            role="button"
                        />
                    </div>

                    <div
                        *ngIf="value.value === 'period'"
                        class="mt-3 justify-content-center"
                    >
                        <span style="justify-content: center; display: flex">
                            <b class="fs-5 text-dark"
                                ><i
                                    class="fa-solid fa-recycle fs-5 me-2 text-dark"
                                ></i
                                >{{ value.title }}</b
                            >
                        </span>

                        <div class="row">
                            <div class="col-lg-6 col-sm-auto">
                                <b class="text-dark" i18n>Start</b><br />
                                <input
                                    type="{{ value.type_field }}"
                                    id="active"
                                    name="active"
                                    class="form-control me-2"
                                />
                            </div>
                            <div class="col-lg-6 col-sm-auto">
                                <b class="text-dark" i18n>End</b><br />
                                <input
                                    type="{{ value.type_field }}"
                                    id="active"
                                    name="active"
                                    class="form-control me-2"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <span
                    role="button"
                    class="text-dark"
                    *ngIf="
                        value.type_field === 'radio' &&
                        selectedFiltGroup.title === 'status' &&
                        filt.title === 'status'
                    "
                >
                    <input
                        type="{{ value.type_field }}"
                        id="active"
                        name="active"
                        class="form-check-input input me-2"
                    />
                    <b class="text-dark">{{ value.title }}</b
                    ><br />
                </span>

                <span
                    role="button"
                    class="text-dark"
                    *ngIf="
                        value.type_field === 'checkbox' &&
                        selectedFiltGroup.title === 'general'
                    "
                >
                    <b class="text-dark">{{ value.title }}</b>
                    <input
                        type="{{ value.type_field }}"
                        id="active"
                        name="active"
                        class="form-check-input input float-end"
                    />
                    <br />
                </span>
            </div>
        </div>
    </div>
</div> -->

<div
    class="level-1 rounded p-2 shadow mt-1"
    style="position: absolute; min-width: 400px; z-index: 1; right: 0"
>
    <div class="p-3">
        <div class="d-flex">
            <div class="" style="width: 130px; position: relative">
                <div
                    class="level-2 p-2 rounded d-flex"
                    role="button"
                    (click)="displayYears()"
                >
                    <b *ngIf="!selectedYear" class="text-dark">Year</b
                    ><b *ngIf="selectedYear" class="text-dark">{{
                        selectedYear
                    }}</b>
                    <span class="ms-auto"
                        ><i
                            class="fa-solid fa-chevron-down fs-5 ms-2 text-dark"
                        ></i
                    ></span>
                </div>

                <div
                    class="level-1 shadow rounded p-2 mt-2"
                    *ngIf="showYears"
                    style="position: absolute; z-index: 10; width: 100%"
                >
                    <div
                        *ngFor="let year of years"
                        role="button"
                        class="is-hoverable"
                        (click)="selectYear(year)"
                        [ngClass]="
                            selectedYear === year
                                ? 'text-primary underline-for-selected'
                                : 'text-dark'
                        "
                    >
                        <p class="text-center">{{ year }}</p>
                    </div>
                </div>
            </div>
            <div class="ms-auto" style="width: 130px; position: relative">
                <div
                    class="level-2 p-2 rounded d-flex"
                    (click)="displayMonths()"
                >
                    <b *ngIf="!selectedMonth" class="text-dark">Month</b
                    ><b *ngIf="selectedMonth" class="text-dark">{{
                        selectedMonth.name
                    }}</b>
                    <span class="ms-auto"
                        ><i
                            class="fa-solid fa-chevron-down fs-5 ms-2 text-dark"
                        ></i
                    ></span>
                </div>

                <div
                    class="level-1 shadow rounded p-2 row mt-2 p-0"
                    *ngIf="showMonths"
                    style="
                        position: absolute;
                        z-index: 10;
                        width: 100%;
                        margin-left: 3px;
                    "
                >
                    <div
                        *ngFor="let month of months"
                        role="button"
                        class="is-hoverable col-6 justify-content-center"
                        (click)="selectMonth(month)"
                        [ngClass]="
                            selectedMonth === month
                                ? 'text-primary underline-for-selected'
                                : 'text-dark'
                        "
                    >
                        <p>{{ month.title }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex w-100 mt-5" style="margin-bottom: 40px">
            <b class="me-2 text-center text-dark">Period</b>
            <input
                type="date"
                id="active"
                name="active"
                class="form-control me-2"
                [formControl]="startDate"
            />
            <input
                type="date"
                id="active"
                name="active"
                class="form-control"
                [formControl]="endDate"
            />
        </div>
        <hr />

        <div class="level-2 p-2 rounded d-flex" role="button">
            <b class="text-dark">Status</b>
            <span class="ms-auto"
                ><i class="fa-solid fa-chevron-down fs-5 ms-2 text-dark"></i
            ></span>
        </div>

        <!-- <input type="range" multiple name="range" data-slider-min="0" data-slider-max="100" data-slider-step="10" data-slider-value="[20, 80]" data-slider-tooltip="show"> -->
        <!-- <input type="range" multiple value="50,150" min="0" max="200"  id="exampleSlider"/> -->

        <!-- <input type="range" id="exampleSlider" data-slider-min="0" data-slider-max="100" data-slider-step="10" data-slider-value="[20, 80]" data-slider-tooltip="show" /> -->
        <!-- <input type="range" multiple id="exampleSlider" data-slider-min="0" data-slider-max="100" data-slider-step="10" data-slider-value="[20, 80]" data-slider-tooltip="show" /> -->
        <!-- <ngx-slider [(value)]="value" [options]="options"></ngx-slider> -->
        <!-- <double-slider min="100" max="1000"></double-slider> -->

        <div class="text-center">
            <button
                class="btn bg-primary"
                [ngClass]="{
                    'text-dark': theme === 'magis-dark' || theme === 'dark-mode',
                    'text-light': theme === 'magis-light' || theme === 'light-mode',
                }"
                style="margin-top: 30px; width: 100px"
                [disabled]="!startDate.value && !endDate.value"
                (click)="emitPeriod()"
            >
                Apply
            </button>
        </div>
    </div>
</div>
