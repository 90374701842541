<div style="width: 100%">
    <div>
        <div class="myModal" *ngIf="activeBill.isActive">
            <div class="modal-overlay" (click)="closeBillPopup()"></div>
            <div class="modal-content">
                <app-merchant-bill
                    *ngIf="
                        activeBill.type === 'merchantBill' && billSuccessMessage
                    "
                    [successMessage]="billSuccessMessage"
                ></app-merchant-bill>

                <app-transfer-bill
                    *ngIf="
                        activeBill.type === 'transferBill' && billSuccessMessage
                    "
                    [successMessage]="billSuccessMessage"
                ></app-transfer-bill>

                <app-obr-bill
                    *ngIf="activeBill.type === 'obrBill' && billSuccessMessage"
                    [successMessage]="billSuccessMessage"
                ></app-obr-bill>
                <app-landscape-bill
                    *ngIf="
                        activeBill.type === 'landscapeBill' &&
                        billSuccessMessage
                    "
                    [successMessage]="billSuccessMessage"
                ></app-landscape-bill>
            </div>
        </div>

        <app-confirm-dialog
            [clearPin]="clearPin"
            *ngIf="dialog"
            style="
                position: fixed;
                justify-content: center;
                width: 100%;
                display: flex;
                margin-top: 5vh;
                z-index: 1060;
            "
        ></app-confirm-dialog>

        <!-- other plateform layout -->
        <div
            class="d-flex flex-column position-relative"
            style="height: 100dvh"
            *ngIf="plateform && plateform !== 'workStation'"
        >
            <section>
                <app-header></app-header>
            </section>

            <!-- main content -->
            <section
                id="mainContent-container"
                class="d-flex flex-grow-1 h-100 overflow-y-hidden"
            >
                <div class="position-relative h-100 w-100 d-flex flex-column">
                    <div class="mt-3 ms-lg-3">
                        <!-- <div class="mt-1 ms-lg-3 mt-lg-3"> -->
                        <app-sub-header></app-sub-header>
                    </div>

                    <div
                        class="d-flex flex-lg-row flex-grow-1 flex-grow-lg-auto overflow-y-auto"
                        [ngClass]="{
                            'flex-column': !isSettings
                        }"
                    >
                        <!-- Desktop aside menu -->
                        <div
                            id="aside-menu"
                            class="d-none d-lg-block"
                            style="overflow-y: auto"
                            *ngIf="!isSettings"
                            style="overflow-y: auto; width: 17vw"
                        >
                            <app-aside-menu></app-aside-menu>
                        </div>

                        <!-- settings mobile aside menu -->
                        <div
                            class="d-lg-none"
                            style="width: 50px"
                            *ngIf="isSettings"
                        >
                            <app-setting-aside-menu></app-setting-aside-menu>
                        </div>

                        <!-- settings desktop aside menu -->
                        <div class="d-none d-lg-block" *ngIf="isSettings">
                            <app-setting-aside-menu></app-setting-aside-menu>
                        </div>

                        <!-- mobile aside menu -->
                        <div
                            id="container-aside-menu"
                            class="aside-menu-overlay d-lg-none"
                        >
                            <div
                                id="aside-menu-overlay"
                                class="clickable"
                                [ngClass]="{
                                    'overlay-light': theme === 'light-mode',
                                    'overlay-dark': theme === 'dark-mode'
                                }"
                            ></div>
                            <div id="aside-menu" class="level-1 h-100">
                                <app-aside-menu></app-aside-menu>
                            </div>
                        </div>

                        <!-- main content -->
                        <div
                            id="mainContent"
                            class="mx-md-3 p-1 pb-3 pt-2"
                            style="
                                overflow-y: auto;
                                flex-grow: 1;
                                overflow-x: hidden;
                            "
                        >
                            <!-- overflow-x:hidden ; -->
                            <router-outlet></router-outlet>
                        </div>
                    </div>
                </div>

                <!-- right sidebar Menu -->
                <div
                    *ngIf="!isSettings"
                    style="
                        width: 23vw;
                        height: calc(100vh - 50px);
                        flex-direction: column;
                        overflow-x: auto;
                    "
                    class="pt-4 position-relative ps-2 d-none d-lg-flex"
                    [ngClass]="{
                        'level-1':
                            theme === 'dark-mode' ||
                            plateform === 'onlineBanking',
                        'level-2':
                            theme === 'light-mode' &&
                            plateform !== 'onlineBanking'
                    }"
                >
                    <div class="" style="flex-grow: 1">
                        <app-setting-online-banking></app-setting-online-banking>
                    </div>

                    <div class="mt-3 justify-content-center text-dark">
                        <app-footer></app-footer>
                    </div>
                </div>
            </section>

            <!-- mobile bottom icons -->
            <section
                [ngClass]="{
                    'bg-primary': plateform !== 'onamob',
                    'primary-onamob': plateform === 'onamob'
                }"
                class="d-lg-none"
                style="
                    height: 50px;
                    z-index: 21;
                    width: 100vw;
                    position: sticky;
                    bottom: 0;
                "
            >
                <app-switch-plateform-icons
                    [organization]="organization"
                ></app-switch-plateform-icons>
            </section>
        </div>

        <!-- workstation layout -->
        <div
            class="d-flex flex-column"
            style="height: 100dvh"
            *ngIf="plateform && plateform === 'workStation'"
        >
            <section class="w-100">
                <app-header></app-header>
            </section>

            <section class="flex-grow-1 height-not-header">
                <div class="d-flex height-not-header">
                    <app-w-aside-menu></app-w-aside-menu>

                    <div
                        class="p-2 ps-4 w-100 height-not-header"
                        style="overflow-y: auto; overflow-x: hidden"
                    >
                        <router-outlet></router-outlet>
                    </div>
                    <div class="mt-2">
                        <app-setting></app-setting>
                    </div>
                </div>
            </section>
            <section
                class="d-lg-none"
                style="
                    height: 50px;
                    z-index: 21;
                    width: 100vw;
                    position: sticky;
                    bottom: 0;
                "
            >
                <app-switch-plateform-icons
                    [organization]="organization"
                ></app-switch-plateform-icons>
            </section>
        </div>
    </div>
</div>
<!-- <div>
    <app-switch-bank></app-switch-bank>
</div> -->
