import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ThemeService, VariableService } from 'src/app/core';
import { AuthState } from 'src/app/shared/states/auth';
import { SelectMarket } from 'src/app/shared/states/menu';
import { SwitchState } from 'src/app/shared/states/switch';
import { SwitchThemeState } from 'src/app/shared/states/theme';

@Component({
    standalone: true,
    selector: 'app-switch-plateform-icons',
    templateUrl: './switch-plateform-icons.component.html',
    styleUrls: ['./switch-plateform-icons.component.scss'],
    imports: [CommonModule],
})
export class SwitchPlateformIconsComponent {
    private onDestroy$: Subject<void> = new Subject<void>();
    public variableService = inject(VariableService);
    public themeService = inject(ThemeService);
    plateform$: Observable<any>;
    plateform = '';
    theme$: Observable<any>;
    theme = '';
    @Input() organization: any;

    constructor(private store: Store, private router: Router) {
        this.plateform$ = this.store.select(SwitchState.GetPlateform);
        this.theme$ = this.store.select(SwitchThemeState.GetTheme);
    }

    ngOnInit() {
        this.plateform$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((plateform: string) => {
                this.plateform = plateform;
                this.variableService.initPlateforms();
                this.variableService.plateforms.find(
                    (plateF: any) => plateF.plateform === plateform
                )!.is_selected = true;
            });

        this.theme$
            .pipe(takeUntil(this.onDestroy$))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((theme) => {
                this.theme = theme;
            });
    }

    public ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    switchPlateform(plateform: string) {
        if (this.plateform !== plateform) {
            if (plateform === 'onamob') {
                if (
                    this.theme === 'light-mode' ||
                    this.theme === 'magis-light'
                ) {
                    this.themeService.switchPlateform(
                        plateform,
                        'light-mode',
                        this.organization
                    );
                }
                if (this.theme === 'dark-mode' || this.theme === 'magis-dark') {
                    this.themeService.switchPlateform(
                        plateform,
                        'dark-mode',
                        this.organization
                    );
                }
            } else if (plateform !== 'onamob') {
                this.themeService.switchPlateform(
                    plateform,
                    this.theme,
                    this.organization
                );
            }

            if (plateform === 'market') {
                this.toggleMarket();
            }
        }
    }

    toggleMarket() {
        this.store.dispatch(new SelectMarket({ marketName: 'market' }));
    }
}
