import { Injectable } from '@angular/core';

import {
    State,
    StateToken,
    Selector,
    Action,
    StateContext,
    Store,
} from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ThemeModel } from './theme.models';
import { SwitchTheme } from './theme.actions';

import { VariableService } from '../../../core';

// import { StateReset } from 'ngxs-reset-plugin';

const SWITCH_THEME = new StateToken<ThemeModel>('theme');
let defaultTheme: any = '';

//Set default theme according to device mode and selected Plateform

const prefersDarkSchemeQuery = '(prefers-color-scheme: dark)';
if (window.matchMedia(prefersDarkSchemeQuery).matches) {
    if (localStorage.getItem('plateform.plateform') === '"workStation"') {
        defaultTheme = 'magis-dark';
    } else {
        defaultTheme = 'dark-mode';
    }
} else {
    if (localStorage.getItem('plateform.plateform') === '"onlineBanking"') {
        defaultTheme = 'light-mode';
    } else {
        defaultTheme = 'magis-light';
    }
}

@State<ThemeModel>({
    name: SWITCH_THEME,
    defaults: {
        theme: defaultTheme,
    },
})
@Injectable()
export class SwitchThemeState {
    defaultTheme: any = '';

    // plateform$: Observable<any>;
    // plateform: any;
    theme: any;

    constructor(private store: Store) {
        this.defaultTheme = '';
        // this.plateform$ = this.store.select(SwitchThemeState.GetTheme);
    }

    // ngOnInit() {
    //     this.plateform$.subscribe({
    //         next: (plateform) => {
    //             this.plateform = plateform;
    //             if (this.plateform === 'workStation') {
    //             }
    //         },
    //     });
    // }

    @Selector()
    static GetTheme(state: ThemeModel): any {
        if (state.theme) {
            return state.theme;
        } else {
            return null;
        }
    }

    @Action(SwitchTheme)
    switchTheme(ctx: StateContext<ThemeModel>, action: SwitchTheme) {
        // document.body.classList.add(
        //     preloadingClass,
        //     initialDarkModeValue ? darkModeClass : lightModeClass
        // );
        const cls = ['light-mode', 'dark-mode', 'magis-light', 'magis-dark'];
        document.body.classList.remove(...cls);
        document.body.classList.add(action.payload.theme);
        ctx.patchState({ theme: action.payload.theme });
    }
}
