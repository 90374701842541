import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

import { switchMap } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import { Store } from '@ngxs/store';

import {
    AdminService,
    ClientService,
    GeneralService,
} from '../../../../core/services';
import { OpenDialog } from '../../../../shared';

@Component({
    selector: 'app-lookup',
    templateUrl: './lookup.component.html',
    styleUrls: ['./lookup.component.scss'],
})
export class LookupComponent implements OnInit {
    adminMenus: any = [];
    showAutoComplete = false;
    selectedItem: any;
    // test = false;
    input: any;
    items: any;
    search: any = new FormControl('');
    isLoading = false;
    @Input() option = 'autocomplete';
    lookup: any = new FormControl('');
    @Output() selectedItemEvent: any = new EventEmitter<any>();
    @Input() url = '';
    @Input() itemsLabel = '';
    @Input() lookupLabel = '';
    @Input() selectedId: any;
    @Input() showProfile = true;
    title = '';
    type = '';
    message = '';
    // private searchQuerySubject = new Subject<string>();

    constructor(
        private adminService: AdminService,
        private clientService: ClientService,
        private generalService: GeneralService,
        private store: Store
    ) {}

    ngOnInit(): void {
        this.title = $localize`title`;
        this.type = $localize`type`;
        this.message = $localize`message`;

        this.getAdminMenus();
        this.search.value = '';
        if (this.option === 'autocomplete') {
            this.initAutocomplete();
        }
        if (this.option === 'autocomplete' && this.selectedId) {
            this.initAutocomplete();
        }
    }

    inputFocused(select: boolean) {
        this.showAutoComplete = true;
        // this.input = document.getElementById("menu_group");
    }

    selectItem(item: any) {
        // this.test = true;
        console.log('Boossss', this.selectedItem);
        this.selectedItem = item;
        this.selectedItemEvent.emit(item);
        this.showAutoComplete = false;
    }

    focusOut() {
        console.log('Focusout');
    }

    deselect() {
        this.selectedItem = null;
        this.showAutoComplete = true;
        this.lookup.value = '';
        this.selectedItemEvent.emit(null);
    }

    close() {
        this.showAutoComplete = false;
    }

    getAdminMenus() {
        this.adminService.getAdminMenuGroupList().subscribe({
            next: (admins) => {
                this.adminMenus = admins;
            },
        });
    }

    initAutocomplete() {
        if (!this.items && this.search.value === '') {
            this.isLoading = true;

            this.generalService
                .DoAutocomplete(this.url, '')
                .subscribe((value: string) => {
                    this.items = value;
                    this.isLoading = false;
                    if (this.selectedId) {
                        const items: any[] = this.items.objects;

                        this.selectedItem = items.filter((item) => {
                            if (item.id === this.selectedId) {
                                return item;
                            }
                        })[0];
                        this.selectItem(this.selectedItem);
                    }
                });
        }
    }

    DoAutocomplete() {
        this.isLoading = true;

        if (this.search.value) {
            this.generalService
                .DoAutocomplete(this.url, this.search.value)
                .subscribe((value: string) => {
                    this.items = value;
                    this.isLoading = false;
                    console.log(!this.search.value);
                });
        } else {
            this.generalService
                .DoAutocomplete(this.url, '')
                .subscribe((value: string) => {
                    this.items = value;
                    this.isLoading = false;
                    console.log('itemss', this.items);
                });
        }
    }

    //       onInputChange(event: any) {
    //   const search = event.target.value;
    //   this.searchQuerySubject.next(search);
    // }

    DoLookup() {
        if (this.lookup.value !== '') {
            this.isLoading = true;
            this.lookup.disable();
            this.generalService
                .DoLookup(this.url, this.lookup.value)
                .subscribe({
                    next: (item) => {
                        this.selectItem(item.objects[0]);
                        // this.selectedItem = client.objects[0];
                        this.isLoading = false;
                        this.lookup.enable();
                        if (!this.selectedItem) {
                            const notFoundMessage = $localize`Search Not Found`;
                            this.store.dispatch(
                                new OpenDialog({
                                    message: notFoundMessage,
                                    title: '',
                                    type: 'failed',
                                })
                            );
                        }
                        // console.log('Loookkss ', this.selectedItem);
                    },
                    error: (msg) => {
                        this.isLoading = false;
                        const notification = {
                            title: '',
                            type: 'failed',
                            message: 'Something went wrong, please retry again',
                        };
                        this.store.dispatch(new OpenDialog(notification));
                    },
                });
        } else {
            const notification = {
                title: '',
                type: 'failed',
                message: 'Please Enter a value',
            };
            this.store.dispatch(new OpenDialog(notification));
        }
    }

    onChangeTest() {
        console.log('333 444 555');
    }
}
