import { Injectable } from '@angular/core';
import { State, StateToken, Selector, Action, StateContext } from '@ngxs/store';

import { AmountModel } from './amount.models';
import { displayAmount } from './amount.actions';

const AMOUNT = new StateToken<AmountModel>('amount');

@State<AmountModel>({
    name: AMOUNT,
    defaults: {
        show: false,
    },
})
@Injectable()
export class AmountState {
    @Selector()
    static isShowed(state: AmountModel): any {
        return state.show;
    }
    @Action(displayAmount)
    displayAmount(ctx: StateContext<AmountModel>, action: displayAmount) {
        ctx.patchState({
            show: action.payload.show,
        });
    }
}
