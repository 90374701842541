<div class="d-flex gap-2 align-items-center">
    <!-- Show amounts if showAmounts is true -->
    <div
        *ngIf="showAmounts"
        class="d-flex align-items-center {{ customClasses }}"
    >
        <div *ngIf="currency">
            {{ amount | currency : currency + ' ' }}
        </div>
        <div *ngIf="!currency">
            {{ amount | currency : ' ' }}
        </div>
    </div>

    <!-- Show placeholder if showAmounts is false -->
    <div
        *ngIf="!showAmounts"
        class="d-flex gap align-items-center {{ customClasses }}"
    >
        <div>{{ currency ?? '' }} **********</div>
    </div>
</div>
