import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class CheckService {
    constructor(private http: HttpClient, private apiService: ApiService) {}

    requestList(): Observable<any> {
        return this.apiService.get('/checks/requests/').pipe(
            map((data) => {
                return data;
            })
        );
    }

    getRequestDetails(request_id: string): Observable<any> {
        return this.apiService.get('/checks/requests/' + request_id + '/').pipe(
            map((data) => {
                return data;
            })
        );
    }

    getNewRequest(data: any): Observable<any> {
        return this.apiService.post('/checks/new-request/', data).pipe(
            map((data) => {
                return data;
            })
        );
    }

    changeStatus(change_status: any, request_id: any): Observable<any> {
        return this.apiService
            .post('/checks/request/' + request_id + '/change/', change_status)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    printCheck(print: any, request_id: any): Observable<any> {
        return this.apiService
            .post('/checks/request/' + request_id + '/print/', print)
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }
}
