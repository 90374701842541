<div *ngIf="plateform && plateform === 'home'">
    <app-home-right-sidebar></app-home-right-sidebar>
</div>
<div *ngIf="plateform && plateform === 'market'">
    <app-market-right-sidebar></app-market-right-sidebar>
</div>
<div *ngIf="plateform && plateform === 'onlineBanking'">
    <!-- <app-referal-clients-tree></app-referal-clients-tree> -->
    <app-right-side-banking></app-right-side-banking>
</div>
