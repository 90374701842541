import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HeaderComponent } from './header/header.component';
import { SettingOnlineBankingComponent } from './setting-online-banking/setting-online-banking.component';
import { SubHeaderComponent } from './online-banking/sub-header/sub-header.component';
import { HomeRightSidebarComponent } from 'src/app/modules/home/home-right-sidebar/home-right-sidebar.component';
import { MarketRightSidebarComponent } from 'src/app/modules/market/market-right-sidebar/market-right-sidebar.component';
import { RouterModule } from '@angular/router';
import { RighSideBankingComponent } from './online-banking/right-side-banking/right-side-banking.component';
import { SwitchPlateformIconsComponent } from './header/switch-plateform-icons/switch-plateform-icons.component';
import { NotFoundPageComponent } from '../reusable/not-found-page/not-found-page.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
    declarations: [
        HeaderComponent,
        SettingOnlineBankingComponent,
        SubHeaderComponent,
        FooterComponent,
        // SwitchPlateformIconsComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        HomeRightSidebarComponent,
        MarketRightSidebarComponent,
        RighSideBankingComponent,
        SwitchPlateformIconsComponent,
        NotFoundPageComponent,
    ],
    exports: [
        HeaderComponent,
        SettingOnlineBankingComponent,
        SubHeaderComponent,
        FooterComponent,
    ],
})
export class LayoutModule {}
