import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { GeneralClientDetailsComponent } from './general-client-details/general-client-details.component';
import { LookupComponent } from './lookup/lookup.component';

@NgModule({
    declarations: [GeneralClientDetailsComponent, LookupComponent],
    imports: [CommonModule, ReactiveFormsModule],
    exports: [GeneralClientDetailsComponent, LookupComponent],
})
export class GeneralModule {}
