<div class="mt-2 me-3">
    <div>
        <div class="d-grid">
            <!--* offers section, the one shown by default && !isBaking-->

            <div class="w-100 mb-4 rounded d-grid" *ngIf="!isTransfer">
                <div
                    class="bg-primary t-rounded d-flex justify-content-center py-2"
                >
                    <b class="" style="font-size: 18px; color: #fff">Offers</b>
                </div>
                <div
                    class="b-rounded d-flex justify-content-center align-items-center picture-container"
                >
                    <img
                        class="img-fluid h-100 w-100"
                        src="../../../assets/images/onamobNewImage.jpeg"
                    />
                </div>
            </div>

            <!--* Last beneficiary section, the one shown when we are on transfer-->
            <div class="w-100 mb-4 rounded d-grid slide-in" *ngIf="isTransfer">
                <div
                    class="bg-primary t-rounded d-flex justify-content-center py-2"
                >
                    <b class="" style="font-size: 18px; color: #fff"
                        >Last beneficiaries</b
                    >
                </div>
                <div class="b-rounded d-grid level-2">
                    <div class="px-2 py-3">
                        <div class="" *ngIf="!favoriteBeneficiaries">
                            <div
                                class="d-flex w-100 align-items-center px-2 mb-2"
                            >
                                <div class="me-3">
                                    <ngx-skeleton-loader
                                        [theme]="{
                                            height: '60px',
                                            width: '60px'
                                        }"
                                        appearance="circle"
                                    ></ngx-skeleton-loader>
                                </div>
                                <div class="w-100">
                                    <ngx-skeleton-loader
                                        [theme]="{height: '10px', }"
                                        appearance="line"
                                    ></ngx-skeleton-loader>
                                    <ngx-skeleton-loader
                                        class="w-25"
                                        [theme]="{height: '10px', }"
                                    ></ngx-skeleton-loader>
                                </div>
                            </div>

                            <div
                                class="d-flex w-100 align-items-center px-2 mb-2"
                            >
                                <div class="me-3">
                                    <ngx-skeleton-loader
                                        [theme]="{
                                            height: '60px',
                                            width: '60px'
                                        }"
                                        appearance="circle"
                                    ></ngx-skeleton-loader>
                                </div>
                                <div class="w-100">
                                    <ngx-skeleton-loader
                                        [theme]="{height: '10px', }"
                                        appearance="line"
                                    ></ngx-skeleton-loader>
                                    <ngx-skeleton-loader
                                        class="w-25"
                                        [theme]="{height: '10px', }"
                                    ></ngx-skeleton-loader>
                                </div>
                            </div>
                            <div
                                class="d-flex w-100 align-items-center px-2 mb-2"
                            >
                                <div class="me-3">
                                    <ngx-skeleton-loader
                                        [theme]="{
                                            height: '60px',
                                            width: '60px'
                                        }"
                                        appearance="circle"
                                    ></ngx-skeleton-loader>
                                </div>
                                <div class="w-100">
                                    <ngx-skeleton-loader
                                        [theme]="{height: '10px', }"
                                        appearance="line"
                                    ></ngx-skeleton-loader>
                                    <ngx-skeleton-loader
                                        class="w-25"
                                        [theme]="{height: '10px', }"
                                    ></ngx-skeleton-loader>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="favoriteBeneficiaries">
                            <div
                                *ngIf="
                                    favoriteBeneficiaries &&
                                    favoriteBeneficiaries.length === 0
                                "
                                class="my-4 text-bold d-flex justify-content-center"
                            >
                                <h4
                                    class="text-secondary d-flex align-items-center"
                                    style="background-color: transparent"
                                >
                                    <span
                                        style="background-color: transparent"
                                        [ngClass]="{
                        'text-secondary': theme==='light-mode',
                        'no-data-text-dark': theme==='dark-mode',

                    }"
                                        class="me-2"
                                    >
                                        <i class="fa fa-ban"></i>
                                    </span>
                                    <span
                                        [ngClass]="{
                        'text-secondary': theme==='light-mode',
                        'no-data-text-dark': theme==='dark-mode',

                    }"
                                        style="background-color: transparent"
                                    >
                                        No beneficiaries to display
                                    </span>
                                </h4>
                            </div>

                            <div
                                *ngFor="
                                    let beneficiary of favoriteBeneficiaries.slice(
                                        0,
                                        3
                                    )
                                "
                                class="d-flex align-items-center mb-2"
                            >
                                <div class="rounded-circle me-2">
                                    <img
                                        src="../../../assets/images/userprofile.png"
                                        style="width: 60px"
                                        class="rounded-circle"
                                        alt=""
                                    />
                                </div>
                                <div class="d-grid">
                                    <span
                                        style="
                                            font-size: 18px;
                                            font-weight: 700;
                                        "
                                        class="text-dark"
                                        >{{
                                            beneficiary.credit_account_holder
                                        }}</span
                                    >
                                    <span *ngIf="beneficiary.credit_account">
                                        <span
                                            [ngClass]="{
                                                'text-dark':
                                                    theme === 'dark-mode',
                                                't-sec': theme === 'light-mode'
                                            }"
                                            style="font-size: 16px"
                                            >{{
                                                beneficiary.credit_account
                                                    .acc_number
                                            }}</span
                                        >
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--* Last transactions section, the one shown by default && !isBaking-->
            <div *ngIf="!isTransfer" class="w-100 mb-4 rounded d-grid">
                <div
                    class="bg-primary t-rounded d-flex justify-content-center py-2"
                >
                    <b class="" style="font-size: 18px; color: #fff"
                        >Last transactions</b
                    >
                </div>
                <div class="b-rounded level-2 sm-border">
                    <!-- <div
                    style="height: 250px"
                    *ngIf="!recentTransactions"
                    class="w-100 d-flex justify-content-center align-items-center"
                >
                    <span class="my-3">
                        <i class="spinner-border"></i>
                    </span>
                </div> -->
                    <div *ngIf="!recentTransactions" class="d-grid px-2 pt-3">
                        <span class="w-100 mb-2">
                            <ngx-skeleton-loader
                                [theme]="{
                                'height.px': 50,
                                'width.%': 100,
                            }"
                            ></ngx-skeleton-loader
                        ></span>
                        <span class="w-100 mb-2">
                            <ngx-skeleton-loader
                                [theme]="{
                                'height.px': 50,
                                'width.%': 100,
                            }"
                            ></ngx-skeleton-loader
                        ></span>
                        <span class="w-100 mb-2">
                            <ngx-skeleton-loader
                                [theme]="{
                                'height.px': 50,
                                'width.%': 100,
                            }"
                            ></ngx-skeleton-loader
                        ></span>
                    </div>
                    <div
                        *ngIf="
                            recentTransactions &&
                            recentTransactions.length === 0
                        "
                        class="my-5 text-bold d-flex justify-content-center"
                    >
                        <h4
                            class="text-secondary d-flex align-items-center"
                            style="background-color: transparent"
                        >
                            <span
                                style="background-color: transparent"
                                [ngClass]="{
                        'text-secondary': theme==='light-mode',
                        'no-data-text-dark': theme==='dark-mode',

                    }"
                                class="me-2"
                            >
                                <i class="fa fa-ban"></i>
                            </span>
                            <span
                                [ngClass]="{
                        'text-secondary': theme==='light-mode',
                        'no-data-text-dark': theme==='dark-mode',

                    }"
                                style="background-color: transparent"
                            >
                                No Transactions to display
                            </span>
                        </h4>
                    </div>
                    <div *ngIf="recentTransactions">
                        <div
                            *ngFor="
                                let transaction of recentTransactions.slice(
                                    0,
                                    3
                                );
                                let i = index;
                                let lastIndex = last
                            "
                            class="d-grid mt-2 mx-2"
                        >
                            <div class="d-flex justify-content-between">
                                <span class="text-dark md-title w-70">
                                    <span
                                        *ngIf="
                                            !transaction.operation_type
                                                .description
                                        "
                                        >---</span
                                    >
                                    <span
                                        *ngIf="
                                            transaction.operation_type
                                                .description
                                        "
                                        >{{
                                            transaction.operation_type
                                                .description
                                        }}</span
                                    ></span
                                >
                                <span class="text-dark sm-sec-text">
                                    <span *ngIf="!transaction.created_at"
                                        >---</span
                                    >
                                    <span *ngIf="transaction.created_at">{{
                                        transaction.created_at
                                            | date : 'yyyy-MM-dd'
                                    }}</span></span
                                >
                            </div>
                            <span class="amount">
                                <span *ngIf="!transaction.amount">---</span>
                                <span *ngIf="transaction.amount">
                                    <span *ngIf="isBalanceShown"
                                        >BIF
                                        {{ transaction.amount | number }}</span
                                    >
                                    <span *ngIf="!isBalanceShown"
                                        >BIF ** **</span
                                    >
                                </span>
                            </span>
                            <span class="text-dark sm-sec-text">
                                <span
                                    *ngIf="
                                        !transaction.account ||
                                        !transaction.account.account_number
                                    "
                                    >---</span
                                >
                                <span
                                    *ngIf="
                                        transaction.account &&
                                        transaction.account.account_number
                                    "
                                    >{{
                                        transaction.account.account_number
                                    }}</span
                                >
                            </span>
                            <hr class="mb-1 mt-1" *ngIf="!lastIndex" />
                        </div>
                    </div>
                </div>
            </div>

            <!-- Last transfers section, the one shown when we are on transfer ngIf="!isBaking" for hidding  the link your perso-->

            <div *ngIf="isTransfer" class="w-100 mb-4 rounded d-grid slide-in">
                <div
                    class="bg-primary t-rounded d-flex justify-content-center py-2"
                >
                    <b class="" style="font-size: 18px; color: #fff"
                        >Last transfers</b
                    >
                </div>
                <div class="b-rounded level-2 sm-border">
                    <div *ngIf="!lastTransfers" class="d-grid px-2 pt-3">
                        <span class="w-100 mb-2">
                            <ngx-skeleton-loader
                                [theme]="{
                                'height.px': 50,
                                'width.%': 100,
                            }"
                            ></ngx-skeleton-loader
                        ></span>
                        <span class="w-100 mb-2">
                            <ngx-skeleton-loader
                                [theme]="{
                                'height.px': 50,
                                'width.%': 100,
                            }"
                            ></ngx-skeleton-loader
                        ></span>
                        <span class="w-100 mb-2">
                            <ngx-skeleton-loader
                                [theme]="{
                                'height.px': 50,
                                'width.%': 100,
                            }"
                            ></ngx-skeleton-loader
                        ></span>
                    </div>
                    <div
                        *ngIf="lastTransfers && lastTransfers.length === 0"
                        class="my-5 text-bold d-flex justify-content-center"
                    >
                        <h4
                            class="text-secondary d-flex align-items-center"
                            style="background-color: transparent"
                        >
                            <span
                                style="background-color: transparent"
                                [ngClass]="{
                            'text-secondary': theme==='light-mode',
                            'no-data-text-dark': theme==='dark-mode',

                        }"
                                class="me-2"
                            >
                                <i class="fa fa-ban"></i>
                            </span>
                            <span
                                [ngClass]="{
                            'text-secondary': theme==='light-mode',
                            'no-data-text-dark': theme==='dark-mode',

                        }"
                                style="background-color: transparent"
                            >
                                No Transactions to display
                            </span>
                        </h4>
                    </div>

                    <div *ngIf="lastTransfers && lastTransfers.length > 0">
                        <div
                            *ngFor="
                                let transaction of lastTransfers.slice(0, 3);
                                let i = index;
                                let lastIndex = last
                            "
                            class="d-grid mt-2 mx-2"
                            role="button"
                            (click)="goToLastTransaction(i)"
                        >
                            <div class="d-flex justify-content-between">
                                <span
                                    class="text-dark md-title w-70"
                                    *ngIf="transaction.operation_type"
                                    >{{
                                        transaction.operation_type.description
                                    }}</span
                                >
                                <span class="text-dark sm-sec-text">{{
                                    transaction.created_at | date : 'yyyy-MM-dd'
                                }}</span>
                            </div>
                            <span class="amount">
                                <span *ngIf="isBalanceShown"
                                    >BIF {{ transaction.amount | number }}</span
                                >
                                <span ngIf="!isBalanceShown">BIF ** ***</span>
                            </span>
                            <span
                                class="text-dark sm-sec-text"
                                *ngIf="transaction.account"
                                >{{ transaction.account.account_number }}</span
                            >
                            <hr class="mb-1 mt-1" *ngIf="!lastIndex" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="level-2 mt-4 p-2">
                <div
                    role="button"
                    routerLink="/b/settings/general"
                    class="d-flex align-items-center w-100"
                >
                    <span
                        style="
                            font-size: 17px;
                            font-weight: 700;
                            line-height: 21px;
                        "
                        class="w-75 text-dark me-2"
                    >
                        <span class="text-primary">Link</span> your personal
                        number with your account
                    </span>
                    <span class="w-25 ms-1">
                        <i
                            class="fa-solid text-primary fa-sim-card fa-rotate-270"
                            style="font-size: 3.7rem"
                        ></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
