<div class="" style="overflow-y: auto">
    <div>
        <div class="d-flex mb-2 justify-content-center">
            <b class="h4 text-dark" i18n style="font-weight: 900"
                >Advertisment</b
            >
        </div>

        <div class="col-10 mx-auto">
            <img
                class="img-fluid sm-radius"
                style="object-fit: cover; max-height: 300px"
                src="/assets/images/onamobNewImage.jpeg"
                alt=""
            />
        </div>
    </div>
    <div class="mx-2 my-4">
        <hr />
    </div>
    <div class="mb-3">
        <div class="d-flex mb-2 justify-content-center text-dark">
            <b class="h4" style="font-weight: 900" i18n>Suggestions</b>
        </div>

        <div
            class="d-flex flex-column justify-content-center align-items-center"
        >
            <span style="width: 45%">
                <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 122 118"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g filter="url(#filter0_i_1432_2791)">
                        <ellipse
                            cx="50"
                            cy="109.5"
                            rx="50"
                            ry="8.5"
                            fill="#D9D9D9"
                        />
                    </g>
                    <rect
                        x="11.5"
                        y="0.5"
                        width="78"
                        height="104"
                        rx="4.5"
                        fill="white"
                        stroke="#9D9D9D"
                    />
                    <rect
                        x="16.5"
                        y="4.5"
                        width="68"
                        height="86"
                        rx="4.5"
                        fill="white"
                        stroke="#9D9D9D"
                    />
                    <circle
                        cx="50.5"
                        cy="98.5"
                        r="3"
                        fill="#D9D9D9"
                        stroke="#9D9D9D"
                    />
                    <line
                        x1="22"
                        y1="14.5"
                        x2="70"
                        y2="14.5"
                        stroke="#4C4C4C"
                        stroke-opacity="0.768627"
                    />
                    <line
                        x1="22"
                        y1="19.25"
                        x2="77"
                        y2="19.25"
                        stroke="#4C4C4C"
                        stroke-opacity="0.768627"
                        stroke-width="1.5"
                    />
                    <rect
                        x="1.5"
                        y="26.5"
                        width="97"
                        height="22"
                        rx="2.5"
                        fill="white"
                        stroke="#C4C4C4"
                    />
                    <circle
                        cx="17.5"
                        cy="37.5"
                        r="6"
                        fill="white"
                        stroke="#C4C4C4"
                    />
                    <line
                        x1="30"
                        y1="37.5"
                        x2="78"
                        y2="37.5"
                        stroke="#4C4C4C"
                        stroke-opacity="0.768627"
                    />
                    <line
                        x1="22"
                        y1="66.5"
                        x2="70"
                        y2="66.5"
                        stroke="#4C4C4C"
                        stroke-opacity="0.768627"
                    />
                    <line
                        x1="22"
                        y1="71.5"
                        x2="70"
                        y2="71.5"
                        stroke="#4C4C4C"
                        stroke-opacity="0.768627"
                    />
                    <line
                        x1="30"
                        y1="42.5"
                        x2="60"
                        y2="42.5"
                        stroke="#4C4C4C"
                        stroke-opacity="0.768627"
                    />
                    <line
                        x1="30"
                        y1="33.25"
                        x2="85"
                        y2="33.25"
                        stroke="#4C4C4C"
                        stroke-opacity="0.768627"
                        stroke-width="1.5"
                    />
                    <line
                        x1="22"
                        y1="59.25"
                        x2="77"
                        y2="59.25"
                        stroke="#4C4C4C"
                        stroke-opacity="0.768627"
                        stroke-width="1.5"
                    />
                    <line
                        x1="22"
                        y1="78.25"
                        x2="77"
                        y2="78.25"
                        stroke="#4C4C4C"
                        stroke-opacity="0.768627"
                        stroke-width="1.5"
                    />
                    <rect
                        x="103.248"
                        y="56.1806"
                        width="12"
                        height="4"
                        transform="rotate(43.7399 103.248 56.1806)"
                        fill="#D9D9D9"
                        stroke="#C4C4C4"
                    />
                    <rect
                        x="111.847"
                        y="62.3535"
                        width="13.5"
                        height="7.5"
                        rx="1.75"
                        transform="rotate(43.7399 111.847 62.3535)"
                        fill="#414141"
                        stroke="black"
                        stroke-width="0.5"
                    />
                    <g filter="url(#filter1_d_1432_2791)">
                        <circle
                            cx="92"
                            cy="49"
                            r="15"
                            fill="#7D91D9"
                            fill-opacity="0.45"
                            shape-rendering="crispEdges"
                        />
                        <circle
                            cx="92"
                            cy="49"
                            r="13.5"
                            stroke="white"
                            stroke-width="3"
                            shape-rendering="crispEdges"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_i_1432_2791"
                            x="0"
                            y="101"
                            width="100"
                            height="18"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                        >
                            <feFlood
                                flood-opacity="0"
                                result="BackgroundImageFix"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="BackgroundImageFix"
                                result="shape"
                            />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="1" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite
                                in2="hardAlpha"
                                operator="arithmetic"
                                k2="-1"
                                k3="1"
                            />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="shape"
                                result="effect1_innerShadow_1432_2791"
                            />
                        </filter>
                        <filter
                            id="filter1_d_1432_2791"
                            x="73"
                            y="32"
                            width="38"
                            height="38"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                        >
                            <feFlood
                                flood-opacity="0"
                                result="BackgroundImageFix"
                            />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="2" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_1432_2791"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_1432_2791"
                                result="shape"
                            />
                        </filter>
                    </defs>
                </svg>
            </span>
            <span class="fw-bold fs-4 mt-2 text-dark" style="opacity: 0.8" i18n
                >No Suggestion</span
            >
        </div>
    </div>
</div>
